import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class WarehouseOutboundConfirmTransFormAdapter extends CerAppFormModelAdapter {

  private onlySalesCmd: UiCommand = {
    id: 'onlySalesCmd', text: 'Vis alle', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Vis kun salg', target: ".e-content", visible: true
  };

  override init() {

    this.toolbarCommands = [this.onlySalesCmd];

    this.viewMetadata = {
      name: 'lager-bekraeftelser', dataApiName: 'Warehouse_OutboundOrderConfirm', text: 'Bekræftelser', textSingular: 'Bekræftelse',
      primaryKey: 'pickOrderNum', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'orderCreated', text: 'Ordre oprettet', format: 'DateShort', orderBy: 'Descending', orderByPriority: 1},
      { name: 'outboundOrderNum', text: 'Forsendelse', widthType: FieldWidthEnum.XS, orderBy: 'Descending', orderByPriority: 2  },
      { name: 'warehouseCounterpart', text: 'Kundenr.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'pickOrderNum', text: 'Lagerordre', widthType: FieldWidthEnum.S },
      { name: 'customerRef', text: 'Kunderef.', widthType: FieldWidthEnum.XS },
      { name: 'orderReference', text: 'Reference', widthType: FieldWidthEnum.XS },
      { name: 'orderStatus', text: 'Status', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'product', text: 'ISBN', widthType: FieldWidthEnum.L },
      { name: 'productName', text: 'Titel', widthType: FieldWidthEnum.XL },
      { name: 'orderedQty', text: 'Lagerantal', format: 'Amount', visible: false},
      { name: 'salesQty', text: 'Antal', format: 'Amount' },
      { name: 'nameDlv', text: 'Navn', widthType: FieldWidthEnum.M },
      { name: 'streetDlv', text: 'Adresse', widthType: FieldWidthEnum.M },
      { name: 'zipCodeDlv', text: 'Postnr', widthType: FieldWidthEnum.XS },
      { name: 'cityDlv', text: 'Bynavn', widthType: FieldWidthEnum.XS },
      { name: 'countryRegionIdDlv', text: 'Land', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'email', text: 'Email', format: 'Email', widthType: FieldWidthEnum.S },
      { name: 'phone', text: 'Telefon', format: 'Phone', widthType: FieldWidthEnum.S },
      { name: 'carrierCode', text: 'Transportør', widthType: FieldWidthEnum.XXS },
      { name: 'carrierServiceCode', text: 'Transportør service', widthType: FieldWidthEnum.XXS },
      { name: 'deliveryHandlingType', text: 'Ordretype', widthType: FieldWidthEnum.XXS },
      { name: 'currencyCode', text: 'Valuta', widthType: FieldWidthEnum.XXS, visible: false },
      { name: 'customerLineNum', text: 'Linje', format: 'Number' },
      { name: 'salesUnit', text: 'Enhed', widthType: FieldWidthEnum.XS },
      { name: 'salesPrice', text: 'Pris', format: 'Amount' },
      { name: 'discAmount', text: 'Rabat', format: 'Amount' },
      { name: 'discPercent', text: 'Rabat%', format: 'Amount' },
      { name: 'lineAmount', text: 'Linjebeløb', format: 'Amount' },
      { name: 'inventLocationId', text: 'Lagersted'},
      { name: 'lineStatus', text: 'Status'},
      { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'createdJobId', text: 'Oprettet job', format: 'Number', visible: false },
      { name: 'modifiedJobId', text: 'Ændret job', format: 'Number', visible: false }
    ];
  }
}
