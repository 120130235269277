import { UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";
import { CerAppFormModelAdapter } from "src/platform/models/cer-app-form-model-adapter";

export class AppDataTableFormAdapter extends CerAppFormModelAdapter {

  public override init() {

    this.viewMetadata = {
      name: 'tabeller', dataApiName: 'DataTable', text: 'App Tabeller', textSingular: 'App Tabel',
      primaryKey: 'id', baseFields: ['id','name','history']
    };

    //var userId: number = this.appStateService.appUserId();
    this.fieldMetadata = [
        { name: 'isDynamic', text: 'Tilpasning', required: true, allowEdit: false, allowEditOnCreate: false, format: 'CheckBox', defaultValue: true}
    ];

    this.detailViewMetadata = {
      name: 'tabel-felter', dataApiName: 'DataTableColumns', text: 'Felter', textSingular: 'Felt',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'dataProcessStage', baseFields: ['id','name','history']
    };

    this.detailFieldMetadata =
      [
        { name: 'isDynamic', text: 'Tilpasning', required: true, allowEdit: false, allowEditOnCreate: false, format: 'CheckBox', defaultValue: true},
        { name: 'order', text: 'Rækkefølge', required: true,  format: 'Integer', orderBy: 'Ascending', orderByPriority: 1 },
        { name: 'title', text: 'Titel', required: true }
      ];

    this.detailPaneSizePct = 70;
  }

  public override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      //      case this.uiCmdReOpen.id:
      //        this.reOpen();
      //        break;
    }
  }

  public override onDetailCommand(event: UiCommandEvent) {
  }
}
