import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductFormatFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'udgivelse-formater', dataApiName: 'ProductFormat', text: 'Formater, udgivelser', textSingular: "Format",
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };

    this.fieldMetadata = [
      { name: 'isDigital', text: 'Er digital', tooltipText: 'Er udgivelser af denne type digital (modsat fysisk)', format: 'CheckBox' }
    ];
  }
}
