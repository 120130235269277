import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class WarehouseCarrierFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'transport', dataApiName: 'WarehouseCarrier', text: 'Transportører', textSingular: 'Transportør',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
    ];
   
    this.detailViewMetadata = { name: 'transport-pakke-shops', dataApiName: 'WarehouseCarrierServicePoint', text: 'Pakkeshops', textSingular: 'Pakkeshop',
      primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'keyType', 
      baseFields: ['id'], titleFields: ['num', 'product', 'name'] };  
   this.detailFieldMetadata = [
     { name: 'keyCountry', text: 'Land', required: true, foreignKeyTableName: 'Country', foreignKeyField: 'iso2', widthType: FieldWidthEnum.S },
     { name: 'keyType', text: 'Transportør', required: true, foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num', widthType: FieldWidthEnum.S, visible: false },
     { name: 'key', text: 'Pakkeshop nr.',  tooltipText: 'Pakkenshoppens id', required: true, widthType: FieldWidthEnum.S },  
     { name: 'name', text: 'Navn', widthType: FieldWidthEnum.M }, 
     { name: 'address1', text: 'Adresse 1', widthType: FieldWidthEnum.M }, 
     { name: 'address2', text: 'Adresse 2', widthType: FieldWidthEnum.M }, 
     { name: 'postalCode', text: 'Postnr', widthType: FieldWidthEnum.S }, 
     { name: 'postalDistrict', text: 'By', widthType: FieldWidthEnum.M }, 
     { name: 'phone', text: 'Telefon', widthType: FieldWidthEnum.M }
   ]
 }
}
