import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class SalesChannelOrderFormAdapter extends CerAppFormModelAdapter {

  override init() {
    this.detailPaneVisible = 'show';
    this.detailPaneVertical = true;
    this.detailPaneSizePct = 25;

    this.viewMetadata = {
      name: 'salg-kanal-order', dataApiName: 'SalesChannel_Order', text: 'Salgskanal ordrer', textSingular: 'Salgskanal ordre',
      allowCreate: false, allowEdit: false, allowDelete: false,
      primaryKey: 'id', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'salesChannel', text: 'Salgskanal', tooltipText: 'Kanal', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'SalesChannel', foreignKeyField: 'num' },
      { name: 'num', text: 'Ordrenr.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S },
      { name: 'status', text: 'Status', tooltipText: 'Ordrestatus', widthType: FieldWidthEnum.M},
      { name: 'custNote', text: 'Notat', tooltipText: 'Kunde notat', format: 'TextArea' },
      { name: 'amountCur', text: 'Fakturabeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'amountCurTax', text: 'Momsbeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'amountCurExclTax', text: 'Fakturabeløb u. moms', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.S },
      { name: 'custType', text: 'Kundetype', widthType: FieldWidthEnum.S, visible: true, visibleEdit: true },
      { name: 'paymentMethodTitle', text: 'Betalingstype', tooltipText: 'Afregningstype', widthType: FieldWidthEnum.S},
      { name: 'paymentReference', text: 'Indløser ref.', tooltipText: 'Betalingsindløser ref.', allowEdit: true, widthType: FieldWidthEnum.S },
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'billFirstName', text: 'Fak. fornavn', tooltipText: 'Faktura fornavn', widthType: FieldWidthEnum.L, visible: false},
      { name: 'billLastName', text: 'Fak. efternavn', tooltipText: 'Faktura efternavn', widthType: FieldWidthEnum.L, visible: false },
      { name: 'billCompanyName', text: 'Fak. firmanavn', tooltipText: 'Faktura firmanavn', widthType: FieldWidthEnum.L },
      { name: 'custEmail', text: 'E-mail', tooltipText: 'Kunde e-mail', format: 'Email' },
      { name: 'custPhone', text: 'Telefon', tooltipText: 'Kunde telefon', format: 'Phone' },
      { name: 'custNum', text: 'Kundenr.', tooltipText: 'Kundenr.', widthType: FieldWidthEnum.M, visible: false },
      { name: 'custEAN', text: 'EAN nr.', widthType: FieldWidthEnum.M },
      { name: 'custVATNum', text: 'CVR nr.', tooltipText: 'Kundens CVR nummer', widthType: FieldWidthEnum.M },
      { name: 'custVATExempt', text: 'Momsfri', tooltipText: 'Er ordren momsfri?', format: 'CheckBox', visible: false},
      { name: 'billAddress1', text: 'Fak. adresse 1', tooltipText: 'Faktura adresse 1', widthType: FieldWidthEnum.L },
      { name: 'billAddress2', text: 'Fak. adresse 2', tooltipText: 'Faktura adresse 2', widthType: FieldWidthEnum.L },
      { name: 'billPostalCode', text: 'Fak. postnr.', tooltipText: 'Faktura postnr.', widthType: FieldWidthEnum.S },
      { name: 'billPostalDistrict', text: 'Fak. by', tooltipText: 'Faktura by', widthType: FieldWidthEnum.L },
      { name: 'billCountryCode', text: 'Fak. land', tooltipText: 'Faktura landekode', widthType: FieldWidthEnum.S },
      { name: 'shipToInvoiceAddress', text: 'Lev. fak.adresse', tooltipText: 'Levering til fakturaadresse', format: 'CheckBox' },
      { name: 'shipFirstName', text: 'Lev. fornavn', tooltipText: 'Levering fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipLastName', text: 'Lev. efternavn', tooltipText: 'Levering efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true  },
      { name: 'shipCompanyName', text: 'Lev. firmanavn', tooltipText: 'Leveringfirmanavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true  },
      { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true  },
      { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true  },
      { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true  },
      { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true  },
      { name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true  },
      { name: 'source', text: 'Device', tooltipText: 'Webshop device', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true  },
      { name: 'sourceUTM', text: 'Kampagne', tooltipText: 'Webshop kampagne', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true  },
      { name: 'source', text: 'Kildedata', tooltipText: 'Alle data for ordren', widthType: FieldWidthEnum.XXL, visible: false  }

      /*
          ,[DateCreated]
          ,[DateCompleted]
          ,[DateModified]
          ,[Created]
          ,[CreatedBy]
          ,[Modified]
          ,[ModifiedBy]    */
    ];


  this.fieldGroupMetadata = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'channel', 'type'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'customer', text: 'Kunde', fields: ['name', 'custEmail', 'custPhone',  'custNum', 'custEan', 'custVATNum', 'custVATExempt',  'custType'] },
    { idx: 3, tabIdx: 0, name: 'amounts', text: 'Beløb', fields: ['amountCurExclTax', 'amountCurTax', 'amountCur', 'currency'] },
    { idx: 3, tabIdx: 0, name: 'settlement', text: 'Afregning', fields: ['settlementType', 'paymentType', 'paymentMethod', 'paymentTransaction', 'paymentReference', 'closed', 'paymentCaptured'] },
    { idx: 4, tabIdx: 0, name: 'note', text: 'Notat', fields: ['custNote'] },
    { idx: 5, tabIdx: 0, name: 'shipment', text: 'Levering', fields: ['shipFirstName', 'shipLastName', 'shipCompanyName', 'shipAddress1', 'shipAddress2', 'shipPostalCode', 'shipPostalDistrict', 'shipCountryCode', 'shipToInvoiceAddress'] },
    { idx: 6, tabIdx: 0, name: 'bill', text: 'Faktura', fields: ['billFirstName', 'billLastName', 'billCompanyName', 'billAddress1', 'billAddress2', 'billPostalCode', 'billPostalDistrict', 'billCountryCode'] }
  ];



  this.detailViewMetadata = { name: 'sales-channel-order-lines', dataApiName: 'SalesChannel_OrderLine', text: 'Salgskanal ordrelinjer',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'salesChannelOrder', 
    allowEdit: false, allowCreate: false, allowDelete: false,
    baseFields: ['id', 'history'], titleFields: ['num', 'name'] };  

  // Detail  fields
  this.detailFieldMetadata =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false, isIdentity: true, widthType: FieldWidthEnum.S },
    { name: 'orderNum', text: 'Ordrenr.', visible: false },
    { name: 'salesId', text: 'Ordre ID', visible: false },
    { name: 'num', text: 'Varenr.', visible: false }, 
    { name: 'itemId', text: 'Varenr.' },
    { name: 'name', text: 'Varenavn' },
    { name: 'salesQty', text: 'Antal', allowEdit: false, width: '80px', format: 'Amount' },
    { name: 'salesPrice', text: 'Salgspris', allowEdit: false, width: '80px', format: 'Amount' },
    { name: 'salesDiscount', text: 'Rabat', allowEdit: false, width: '80px', format: 'Amount', visible: false },
    { name: 'salesAmount', text: 'Beløb', format: 'Amount' }
    ];
  }
}
