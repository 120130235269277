import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FieldMetadata, FieldWidthEnum, KeyValue, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from "src/cer/cer-grid/cer-grid-command.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";

@Injectable({ providedIn: 'root' })
export class CerDataNoteService implements OnInit, OnDestroy {
 
  private subscriptionManager$: Subscription = new Subscription();

  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }
  
  private viewMetadata: ViewMetadata = {
    name: 'noter', dataApiName: 'DataNote', text: 'Noter', textSingular: 'Note',
    primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'row', titleFields: ['txt'], 
    allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['id','history']
  };

  public fieldMetadata: FieldMetadata[] = [
    { name: 'type', text: 'Type', foreignKeyTableName : 'DataNoteType', widthType: FieldWidthEnum.XS, orderBy: 'Ascending', orderByPriority: 1,
      defaultValue: 1, allowEdit: true, allowEditOnCreate: false},
    { name: 'text', text: 'Beskrivelse', widthType: FieldWidthEnum.L },
    { name: 'created', text: 'Oprettet', format: 'DateLong', widthType: FieldWidthEnum.XXS,  orderBy: 'Descending', orderByPriority: 2,
      defaultValue: new Date(), allowEdit: false, allowEditOnCreate: false},
  ];

  private dataNoteGrid: CerGridComponent;
  public setDataNoteGrid: CerGridComponent;
  Grid(grid: CerGridComponent) {
    this.dataNoteGrid = grid;
    this.subscriptionManager$.add(this.dataNoteGrid.commmand.subscribe(event => this.onCommand(event)));
  }
  private onCommand(event: UiCommandEvent): void {
    if (event.actionType == UiActionTypeEnum.Add && event.source == UiCommandSourceEnum.ActionBegin)
      {
        console.log(event.args);
      };
  }

  public viewMetadataGet(dataApiParams : string[] | KeyValue[]) : ViewMetadata
  {
    var view : ViewMetadata = Object.assign({},this.viewMetadata); 
    view.dataApiParams = dataApiParams;
    return view;
  }
}