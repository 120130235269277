import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductSeriesFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'serier', dataApiName: 'ProductSeries', text: 'Serier', textSingular: 'Serie',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'author', text: 'Forfatter' },
      { name: 'responsible', text: 'Redaktør', foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
      { name: 'group', text: 'Gruppe', foreignKeyTableName: 'ProductGroup', foreignKeyField: 'num', foreignKeyValue: 'search', foreignKeyAllowFiltering: true },
      { name: 'theme', text: 'Temakode', widthType: FieldWidthEnum.XXL, foreignKeyTableName: 'ProductTheme', foreignKeyField: 'num', foreignKeyValue: 'search',  foreignKeyColumns: ['search', 'description'], foreignKeyTitles: ['Temakode','Beskrivelse'],  foreignKeyAllowFiltering: true },
      { name: 'description', text: 'Beskrivelse' },
      { name: 'closed', text: 'Lukket', format: 'DateShort' },
      { name: 'closedBy', text: 'Lukket af ', allowEdit: false, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' }
    ];

  }
}
