import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';

export function calcDaysDiff(d1: Date, d2: Date) {
  var date1: any = d1;
  var date2: any = d2;
  var diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

  return diffDays;
}

export function totalDifferenceInMinutes(from: Date, to: Date) {
  return differenceInMinutes(from, to);
}

export function formatDateDifference(from: Date, to: Date): string {
  var value: number = totalDifferenceInMinutes(from, to);
  var unit: Intl.RelativeTimeFormatUnit = "minutes";

  var absHours = Math.abs(value / 60);
  if (absHours >= 1) {
    value = value / 60;
    unit = "hours";
    if (absHours >= 48) {
      value = value / 24;
      unit = "days";
    }
  }
  value = Math.trunc(value);
  var options: Intl.RelativeTimeFormatOptions = {
    localeMatcher: "best fit", // other values: "lookup"
    numeric: "auto", // other values: "auto"
    style: "short", // other values: "short" or "narrow"
  };
  return new Intl.RelativeTimeFormat(<any>options).format(value, unit);
}

/*
  private convertDateToUTC(date: Date): Date {
    if (date != null) {
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    return date;
  }
  */
