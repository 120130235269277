import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class WarehouseOutboundCompleteTransFormAdapter extends CerAppFormModelAdapter {

  private onlySalesCmd: UiCommand = {
    id: 'onlySalesCmd', text: 'Vis alle', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Vis kun salg', target: ".e-content", visible: true
  };

  override init() {

    this.toolbarCommands = [this.onlySalesCmd];

    this.viewMetadata = {
      name: 'lager-afsluttede', dataApiName: 'Warehouse_OutboundOrderComplete', text: 'Afsluttede', textSingular: 'Afsluttet',
      primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'invoicedDate', text: 'Leveret dato', format: 'DateShort', orderBy: 'Descending', orderByPriority: 1 },
      { name: 'outboundOrderNum', text: 'Forsendelse', widthType: FieldWidthEnum.XS, orderBy: 'Descending', orderByPriority: 2 },
      { name: 'warehouseCounterpart', text: 'Kundenr.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'pickOrderNum', text: 'Lagerordre', widthType: FieldWidthEnum.S, orderBy: 'Descending', orderByPriority: 3 },
      { name: 'orderReference', text: 'Reference', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'warehouseInvoiceNum', text: 'Fakturanr. lager'},
      { name: 'custInvoiced', text: 'Faktureret kunde', format: 'CheckBox' },
      { name: 'orderedDate', text: 'Ordredato', format: 'DateShort' },
      { name: 'orderType', text: 'Ordretype', widthType: FieldWidthEnum.XS },
      { name: 'returnOrderType', text: 'Returordretype', widthType: FieldWidthEnum.XS },
      { name: 'product', text: 'ISBN', widthType: FieldWidthEnum.L },
      { name: 'productName', text: 'Titel', widthType: FieldWidthEnum.XL },
      { name: 'orderedQty', text: 'Ordreantal', format: 'Amount', visible: false },
      { name: 'invoicedQty', text: 'FakturaAntal', format: 'Amount' },
      { name: 'warehouseOwner', text: 'Lager ejer', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'warehouseItemNum', text: 'Lager varenr.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'custDlvName', text: 'Navn lev.', widthType: FieldWidthEnum.M },
      { name: 'custDlvStreet', text: 'Adresse lev.', widthType: FieldWidthEnum.M },
      { name: 'custDlvZip', text: 'Postnr lev.', widthType: FieldWidthEnum.XS },
      { name: 'custDlvCity', text: 'Bynavn lev.', widthType: FieldWidthEnum.XS },
      { name: 'custDlvCountry', text: 'Land lev.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'custName', text: 'Navn fak.', widthType: FieldWidthEnum.M },
      { name: 'custAddressName', text: 'Navn fak.', widthType: FieldWidthEnum.M },
      { name: 'custAddressStreet', text: 'Adresse fak.', widthType: FieldWidthEnum.M },
      { name: 'custAddressZip', text: 'Postnr fak.', widthType: FieldWidthEnum.XS },
      { name: 'custAddressCity', text: 'Bynavn fak.', widthType: FieldWidthEnum.XS },
      { name: 'custAddressCountry', text: 'Land fak.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'carrier', text: 'Transportør', widthType: FieldWidthEnum.XXS },
      { name: 'deliveryHandlingType', text: 'Ordretype', widthType: FieldWidthEnum.XXS },
      { name: 'lineReference', text: 'Linjereference'},
      { name: 'salesPrice', text: 'Pris', format: 'Amount' },
      { name: 'discPrice', text: 'Rabat', format: 'Amount' },
      { name: 'discPercent', text: 'Rabat', format: 'Amount' },
      { name: 'taxAmount', text: 'Momsbeløb', format: 'Amount' },
      { name: 'lineAmountExTax', text: 'Beløb eks. moms', format: 'Amount' },
      { name: 'retailPriceExTax', text: 'Udsalgspris', format: 'Amount' },
      { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'createdJobId', text: 'Oprettet job', format: 'Number', visible: false },
      { name: 'modifiedJobId', text: 'Ændret job', format: 'Number', visible: false }
    ];
  }
}
