import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class CerAppMetaUserRouteFormAdapter extends CerAppFormModelAdapter {

  constructor() {
    super();

    this.viewMetadata = {
      name: 'menupunkt-brugere', dataApiName: 'AppUserRoute', text: 'Brugere', textSingular: 'Bruger',
      primaryKey: 'id', parentPrimaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: true, baseFields: ['id', 'history']
    };

    this.fieldMetadata =
      [
        { name: 'userId', text: 'Bruger', foreignKeyTableName: 'User', foreignKeyValue: 'shortName', allowEdit: false },
        { name: 'routeId', text: 'Menupunkt', foreignKeyTableName: "AppRoute", foreignKeyValue: 'url', allowEdit: false },
        { name: 'defaultView', text: 'Standard visning' }
      ];


    this.detailViewMetadata = {
      name: 'bruger-visninger', dataApiName: 'AppUserRouteView', text: 'Visninger', textSingular: 'Visning',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'userRouteId', allowEdit: true, allowCreate: false, allowDelete: true, baseFields: ['id', 'history']
    };

    this.detailFieldMetadata =
      [
        { name: 'name', text: 'Visning' }
      ];

    this.detailSelectionMode = "checkboxMulti";
    this.detailPaneSizePct = 50;

  }
}
