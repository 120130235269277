import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiCommandSourceEnum, UiCommand, UiCommandEvent } from '../../../cer/cer-grid/cer-grid-command.service';
import { ExchangeMsgViewDto as Dto, ExchangeMsgViewClient as Client } from '../../../app/api';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

const uiCmdExchangeRerun: UiCommand = { id: 'ExchangeRerun', text: 'Kør markeret igen', iconCss: "e-menu-icon e-icons e-research", tooltipText: 'Kør integration igen for markeret post', target: ".e-content", needSelection: true };
const uiCmdExchangeLogShowHide: UiCommand = { id: 'ExchangeLogShowHide', text: 'Vis log', iconCss: "e-menu-icon e-icons e-history", tooltipText: 'Vis detaljeret integration log for besked', target: ".e-content", needSelection: true };

@Component({
  selector: 'app-exchange-msg-view',
  templateUrl: './app-exchange-msg-view.component.html',
  styleUrls: ['./app-exchange-msg-view.component.css']
})
export class AppExchangeMsgViewComponent {

  private form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommandMain(e));
    }
  }

  protected panes: CerFormPanesSettings = { main: { visible: "show", size: "60%" }, right: { visible: "collapse", size: "40%" } };

  mainGrid: CerGridComponent;
  @ViewChild('mainGrid', { static: false }) set mainGridContent(content: CerGridComponent) {
    if (content && !this.mainGrid) {
      this.mainGrid = content;
      this.mainGrid.commmand.subscribe(e => this.onCommandMain(e));
    }
  }

  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set detailGridContent(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
      this.detailGridGetData(null);
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'data-udveksling-status', text: 'Udvekslinger',
    primaryKey: 'id',
    allowEdit: false, allowCreate: false, allowDelete: true,
    dataApiName: 'ExchangeMsgView',
  };

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
    { name: 'owner', text: 'Afsender', tooltipText: 'Afsender af besked', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ExchangeParty' },
    { name: 'ownerId', text: 'Post Id', tooltipText: 'Id for posten der afsendes', visible: false },
    { name: 'created', text: 'Oprettet', tooltipText: 'Dato for oprettelse', format: 'DateLong', orderBy: 'Descending' },
    { name: 'createdBy', text: 'Oprettet af', tooltipText: 'Bruger der har oprettet', foreignKeyTableName: 'User' },
    { name: 'type', text: 'Integration', tooltipText: 'Beskrivelse beskedtype', widthType: FieldWidthEnum.L, foreignKeyTableName: 'ExchangeType' },
    { name: 'num', text: 'Nr', tooltipText: 'Nøgle for post', widthType: FieldWidthEnum.M },
    { name: 'msgClientId', text: 'Oprettet post', tooltipText: 'Post id hos modtager', visible: false, format: 'TextRight' },
    { name: 'subscriberId', text: 'Modtager', tooltipText: 'Modtageraf af dataudvekling', widthType: FieldWidthEnum.M, foreignKeyTableName: 'ExchangeParty' },
    { name: 'msgSubscriberId', text: 'Modtager besked Id', tooltipText: 'Id for posten der modtages', visible: false },
    { name: 'subscriberPickedUp', text: 'Modtaget', tooltipText: 'Modtaget dato', format: 'DateLong' },
    { name: 'subscriberProcessed', text: 'Behandlet', tooltipText: 'Behandlet dato hos modtager', format: 'DateLong', visible: false },
    { name: 'isFailed', text: 'Fejl', tooltipText: 'Er fejlet hos modtager?', format: 'CheckBox' },
    { name: 'isPickedUp', text: 'Modtaget', tooltipText: 'Er modtaget hos modtager?', format: 'CheckBox' },
    { name: 'isProcessed', text: 'Behandlet', tooltipText: 'Er behandlet hos modtager?', format: 'CheckBox' }];

  public toolbarCommands: UiCommand[] = [uiCmdExchangeRerun, uiCmdExchangeLogShowHide];
  public contextMenuCommands: UiCommand[] = [uiCmdExchangeRerun, uiCmdExchangeLogShowHide];

  public logViewMetadata: ViewMetadata = {
    name: 'data-udveksling-log', text: 'Log',
    primaryKey: 'id', parentPrimaryKey: 'msgSubscriberId', parentForeignKey: 'msgSubscriberId',
    allowEdit: false, allowCreate: false, allowDelete: false,
    dataApiName: 'ExchangeMsgSubscriberLog'
  };

  public logFieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
    { name: 'log', text: 'Log', tooltipText: 'Besked fra ny status', allowEdit: false, widthType: FieldWidthEnum.XXL },
    { name: 'created', text: 'Oprettet', tooltipText: 'Dato for oprettelse', allowEdit: false, format: 'DateLong', widthType: FieldWidthEnum.S },
    { name: 'msgSubscriberId', text: 'Modtager besked Id', tooltipText: 'Modtager besked id', allowEdit: false, allowEditOnCreate: true, visible: false }];

  public filterWaiting: boolean;
  public filterFailed: boolean;

  constructor(private client: Client, route: ActivatedRoute) {
    this.setInitialFilter(route);
  }

  // Data
  public setInitialFilter(route: ActivatedRoute) {
    this.filterWaiting = (route.snapshot.data['waiting'] == true);
    this.filterFailed = (route.snapshot.data['failed'] == true);

    if (this.filterWaiting) {
      this.toolbarCommands = this.toolbarCommands.filter(i => i.id != uiCmdExchangeRerun.id);
      this.contextMenuCommands = this.contextMenuCommands.filter(i => i.id != uiCmdExchangeRerun.id);

      this.fieldMetadata.filter(f => (f.name == 'isPickedUp')).forEach(f => {
        f.filterOperator = "equal";
        f.filterValue = false;
      });
    }

    if (this.filterFailed) {
      this.fieldMetadata.filter(f => (f.name == 'isFailed')).forEach(f => {
        f.filterOperator = "equal";
        f.filterValue = true;
      });
    }
  }

  public reRunExchange() {
    var dto: Dto = <Dto>this.mainGrid.rowSelectedData();
    if (dto && dto.msgSubscriberId) {
      this.client.rerunSubscriber(dto.msgSubscriberId).subscribe(ok => this.mainGrid.research());
    }
  }


  // Commands
  public onCommandMain(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          //case UiActionTypeEnum.RowSelect:
          //  this.rowSelect();
          //  break;
        }
        break;
      default:
        switch (event.commandId) {
          case uiCmdExchangeRerun.id:
            this.reRunExchange();
            break;
          case uiCmdExchangeLogShowHide.id:
            this.exchangeLogShowHide();
            break;
        }
    }
  }

  public exchangeLogShowHide() {
    if (this.form) {
      this.form.panesService.toggle('right');
      this.mainGrid.selection.rowSelectRefresh();
    }
  }

  // Used for manuel Parent/Child sync.
  //public rowSelect() {
  //  this.detailGridGetData(<Dto>this.mainGrid.appGridService.rowSelectedData());
  //}


  // Details data
  public detailGridGetData(dto: Dto) {
    var id: number = dto && dto.msgSubscriberId ? dto.msgSubscriberId : null;
    if (id && this.form.panesService.visible('right')) {
      this.detailGrid.research();
    }
  }

}
