import { Component, ViewChild } from '@angular/core';
import { Dialog } from '@syncfusion/ej2-popups';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent, CerGridSelectionInitial, CerGridSelectionMode } from '../../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum, UiCommand, UiKeyboardShortcut } from '../../../cer/cer-grid/cer-grid-command.service';
import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { FormValidator } from '@syncfusion/ej2-angular-inputs';
import { ActivatedRoute } from '@angular/router';
import { ExchangeMsgQueueClient, PartyExternalRefClient, PartyExternalRefSearchQuery, PartyExternalRefDto } from '../../api';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';

@Component({
  selector: 'app-forfatter',
  templateUrl: './forfatter.component.html',
  styleUrls: ['./forfatter.component.css']
})

export class ForfatterComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.grid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'forfattere', dataApiName: 'Forfatter',
    primaryKey: 'id',
    allowEdit: true, allowCreate: true, allowDelete: true,
    text: 'Forfattere', textSingular: 'Forfatter', titleFields: ['forfatterId', 'navn'], dialogWidth: "900px",
  };

  public fieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, name: 'identification', text: 'Identifikation', fields: ['forfatterId', 'searchName', 'navn', 'attention'] },
    { idx: 2, name: 'address', text: 'Adresse', fields: ['searchAddress', 'adresse1', 'adresse2', 'postNr', 'land'] },
    { idx: 3, name: 'communication', text: 'Kommunikation', fields: ['telefonNr', 'email'] },
    { idx: 3, name: 'setup', text: 'Opsætning', fields: ['cvrCpr', 'valuta'] },
    { idx: 4, name: 'bank', text: 'Bank', fields: ['bankRegNr', 'bankKonto', 'swift', 'iban'] },
    { idx: 4, name: 'status', text: 'Status', fields: ['moms', 'inAktiv'] }
  ];

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'forfatterId', text: 'Nr.', tooltipText: 'Part Id', allowEdit: false, widthType: FieldWidthEnum.XS, visibleEdit: false, visibleAdd: false, orderBy: 'Descending' },
    { name: 'searchName', text: 'Søg navn (virksomhed)', tooltipText: 'Søg virksomhedsnavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: false, visibleAdd: false },
    { name: 'navn', text: 'Navn', tooltipText: 'Navn på part', widthType: FieldWidthEnum.L, required: true, visibleEdit: true, visibleAdd: true },
    { name: 'attention', text: 'Attention', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'adresse1', text: 'Adresse 1', tooltipText: 'Adresselinje 1', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'adresse2', text: 'Adresse 2', tooltipText: 'Adresselinje 2', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'postNr', text: 'Postnr/by', tooltipText: 'Postnr. og by', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'land', text: 'Land', tooltipText: 'Land', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true },
    { name: 'telefonNr', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'email', text: 'E-mail', tooltipText: 'E-mail adresse', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true, format: 'Email' },
    { name: 'bankRegNr', text: 'Bank reg.nr', tooltipText: 'Bank reg.nr', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'bankKonto', text: 'Bank konto', tooltipText: 'Bank konto', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'swift', text: 'SWIFT', tooltipText: 'SWIFT', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'iban', text: 'IBAN', tooltipText: 'IBAN konto', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'cvrCpr', text: 'CPR/CVR', tooltipText: 'CPR/CVR nr.', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'moms', text: 'Momsregistreret', tooltipText: 'Findes der en momsregistrering', format: 'CheckBox', visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'valuta', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'ValutaKoder', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'inAktiv', text: 'Inaktiv', tooltipText: 'Er denne inaktiv', widthType: FieldWidthEnum.XXS, format: 'CheckBox', filterOperator: 'notequal', filterValue: true, filterOnClient: true, visibleEdit: true, visibleAdd: true },
    { name: 'searchAddress', text: 'Søg dansk adresse', tooltipText: 'Søg dansk adresse', /*format: 'TextArea',*/ visible: false },
    { name: 'source', text: 'Datakilde', tooltipText: 'Hvilken database kommer data fra?', /*format: 'TextArea',*/ visible: false }
    ];


  private exchangeFieldsVisible: string[] = ['cvrCpr', 'bankRegNr', 'bankKonto', 'swift', 'iban'];

  private uiCmdExchangeRelease: UiCommand = { id: 'ExchangeRelease', text: 'Frigiv markerede', iconCss: "e-menu-icon e-icons e-check", tooltipText: 'Frigiv markerede poster til dataudveksling', target: ".e-content", needSelection: true };

  public toolbarCommands: UiCommand[] = [];
  public contextMenuCommands: UiCommand[] = [];
  public addressSearchActive: boolean = false;
  public fromExchange: boolean = false;
  public selectionMode: CerGridSelectionMode = 'row';
  public selectionInitial: CerGridSelectionInitial = 'first';

  //private formValidator: FormValidator;

  constructor(
    private exchangeMsgQueueClient: ExchangeMsgQueueClient,
    private partyExternalRefClient: PartyExternalRefClient,
    private ui: CerAppDialogService,
    route: ActivatedRoute) {
    this.fromExchange = route.snapshot.data['fromExchange'];
    if (this.fromExchange) {
      this.fromExchangeSetup();
    }
  }

  private fromExchangeSetup() {
    this.toolbarCommands.unshift(this.uiCmdExchangeRelease);
    this.selectionMode = 'checkboxMulti';
    this.selectionInitial = 'none';
    this.viewMetadata.dataApiParams = ['$sourceFilter'];
    this.fieldMetadata.forEach(f => { if (this.exchangeFieldsVisible.includes(f.name)) { f.visible = true } });
  }

  // Commands
  public release() {
    var idList: number[] = this.grid.selection.rowsSelectedId();
    if (idList.length > 0) {
      this.grid.selection.rowSelectedKeep();
      this.exchangeMsgQueueClient.releaseVendor(idList).subscribe(
        data => this.grid.research(),
        error => this.ui.handleApiError(error)
      );
    }
  }

  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
          case UiActionTypeEnum.BeginEdit:
            break;
          case UiActionTypeEnum.Save:
            if (this.addressSearchActive) {
              this.addressSearchActive = false;
              event.args.cancel = true;
            }
            break;
          case UiActionTypeEnum.Cancel:
            if (this.addressSearchActive) {
              this.addressSearchActive = false;
              event.args.cancel = true;
            }
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Add:
          case UiActionTypeEnum.BeginEdit:
            this.setupControls(event.args.dialog)
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case this.uiCmdExchangeRelease.id:
            this.release();
            break;
        }
    }
  }

  public formInputField(form: HTMLFormElement, id: string): HTMLInputElement {
    return <HTMLInputElement>this.grid.editService.dialogTableInputFind(form, id);
  }

  public hasValue(value: string): boolean {
    return (value && value.length > 0);
  }

  public isInputFieldEmpty(input: HTMLInputElement): boolean {
    return (input && !this.hasValue(input.value));
  }

  public setupControls(dialog: Dialog) {
    if (this.grid.grid.editSettings.mode == "Dialog" && dialog) {
      if (dialog.content && dialog.content instanceof HTMLElement) {
        var form: HTMLFormElement = dialog.content.querySelector('.e-gridform');
        this.setupFormValidator(form);
        this.setupControlsDialogForm(form);
      }
    }
  }

  protected setupControlsDialogForm(form: HTMLFormElement) {
    if (form) {

      /*      this.inputSearchName = this.formInputField(form, 'searchName');
            this.inputName = this.formInputField(form, 'navn');
            this.inputAddress1 = this.formInputField(form, 'adresse1');
            this.inputAddress2 = this.formInputField(form, 'adresse2');
            this.inputZipCity = this.formInputField(form, 'postNr');
            this.inputCountry = this.formInputField(form, 'land');
            this.inputPhone = this.formInputField(form, 'telefonNr');
            this.inputEmail = this.formInputField(form, 'email');
            this.inputSearchAddress = this.formInputField(form, 'searchAddress');
            this.inputCvr = this.formInputField(form, 'cvrCpr');
      */
      var that = this;
      /*
      var inputSearchAddress = this.formInputField(form, 'searchAddress');
      if (inputSearchAddress) {
        inputSearchAddress.onkeydown = (e => {
          if (e.keyCode in [13,27]) {
            that.addressSearchActive = true;
            console.log(that);
          }
        });
        this.searchAddressSetup(inputSearchAddress);
      }
      */
      /*
      var inputSearchName = this.formInputField(form, 'searchName');
      if (inputSearchName) {
        inputSearchName.onchange = (e => that.searchName(that.inputSearchName.value, that.inputCountry?.value));
      }
      */
      /*
      if (inputCvr) {
        inputCvr.onchange = (e => that.searchCvr(that.inputCvr.value, that.inputCountry?.value));
      }
      */
    }
  }

  protected setupFormValidator(form: HTMLFormElement) {
    if (form) {
      var fv: any = (<any>form).ej2_instances[0];
      if (fv && fv instanceof FormValidator) {
        //this.formValidator = <FormValidator>fv;
        //this.formValidator.focusout(null);
      }
    }
  }

  protected searchAddressSetup(input: HTMLInputElement) {
    var dawaAutocomplete2 = require('dawa-autocomplete2');
    var that = this;
    dawaAutocomplete2.dawaAutocomplete(input,
      { supplerendebynavn: true, select: that.searchAddressSelect, that: that });
  }

  protected searchAddressSelect(result: any) {
    /*
    var data = result.data;
    var that : ForfatterComponent = <ForfatterComponent> (<any> this).that;
    console.log(that);
    if (that.inputAddress1) {
      var adr1 = (((data.adresseringsvejnavn ?? '') + ' ').trimStart() +
                   (data.husnr ?? '').trimEnd()) +
                   (data.etage ? ', ' + data.etage + '.' : '').trimEnd() +
                   (data.dør ? ' ' + data.dør : '').trimEnd();
      that.inputAddress1.value = adr1;
    }
    if (that.inputAddress2) {
      var adr2 = data.supplerendebynavn ?? '';
      that.inputAddress2.value = adr2;
    }
    if (that.inputZipCity) {
      that.inputZipCity.value = ((data.postnr ? data.postnr : '') + ' ').trimStart() + (data.postnrnavn ? data.postnrnavn : '');
    }
    if (that.inputCountry) {
      that.inputCountry.value = '';
    }
    */
  }

  protected searchName(searchName: string, country: string) {
    if ((searchName != null && searchName.length > 0) && (country == null || country.length == 0)) {
      var that = this;
      var q: PartyExternalRefSearchQuery = new PartyExternalRefSearchQuery({ searchName: searchName });
      this.partyExternalRefClient.search(q).subscribe(
        res => that.searchExternalRefSelect(res, true),
        error => this.ui.handleApiError(error)
      );
    }
  }

  protected searchCvr(cvr: string, country: string) {
    if ((cvr != null && cvr.length == 8 && !isNaN(+cvr)) && (country == null || country.length == 0)) {
      var that = this;
      var q: PartyExternalRefSearchQuery = new PartyExternalRefSearchQuery({ searchExternalRefId: parseInt(cvr) });
      this.partyExternalRefClient.search(q).subscribe(
        res => that.searchExternalRefSelect(res, false),
        error => this.ui.handleApiError(error)
      );
    }
  }

  protected searchExternalRefSelect(results: PartyExternalRefDto[], isNameSearch: boolean) {
    /*
    if (results && results.length > 0) {
      var result = results[0];
      var isNameEmpty : boolean = result.name && this.inputName && this.isInputFieldEmpty(this.inputName);
      if (isNameSearch || isNameEmpty) {
        this
        if (this.inputName) {
          this.inputName.value = result.name;
        }
        if (this.inputAddress1) {
          this.inputAddress1.value = result.address1;
        }
        if (this.inputAddress2) {
          this.inputAddress2.value = result.address2;
        }
        if (this.inputZipCity) {
          this.inputZipCity.value = result.zipCodeCity;
        }
        if (this.inputPhone) {
          this.inputPhone.value = result.phone;
        }
        if (this.inputEmail) {
          this.inputEmail.value = result.email;
        }
        if (this.inputCvr) {
          this.inputCvr.value = result.externalRefId.toString();
        }
      }
    }
    */
  }
}





