<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #projectGrid id="projectGrid" [viewMetadata]="projectViewMetadata" [dataParent]="true"
    [fieldMetadata]="projectFieldMetadata" >
    </cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #taskGrid id="taskGrid" [viewMetadata]="taskViewMetadata" [dataParent]="true" [dataChild]="taskDataChild" dataChildOfId="projectGrid"
      [fieldMetadata]="taskFieldMetadata" [fieldGroupMetadata]="taskFieldGroupMetadata" 
      [contextMenuCommands]="taskContextMenuCommands" [toolbarCommands]="taskToolbarCommands"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <ejs-toolbar (clicked)="editDescription()">
      <e-items>
        <e-item text="Rediger"></e-item>
      </e-items>
    </ejs-toolbar>
    <div #descriptionContainer id="descriptionContainer" >
      <div style="padding: 4px" [innerHTML]="description$ | async"></div>
    </div>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #contactGrid id="contactGrid" [viewMetadata]="contactViewMetadata" [dataChild]="true" dataChildOfId="taskGrid"
      [fieldMetadata]="contactFieldMetadata" [toolbarCommands]="contactToolbarCommands"
      selectionMode="checkboxMulti">
    </cer-grid>
  </ng-template>
</cer-form>
