import { UiCommand } from 'src/cer/cer-grid/cer-grid-command.service';
import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';

export class CerAppMetaRouteFormAdapter extends CerAppFormModelAdapter {

  private uiCmdViews: UiCommand = { id: 'Views', text: 'Visninger', iconCss: 'e-menu-icon e-icons e-open', target: ".e-content", needSelection: true };

  constructor() {
    super();

    this.viewMetadata = {
      name: 'menupunkter', dataApiName: 'AppRoute', text: 'Menupunkter', textSingular: 'Menupunkt',
      primaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: false, baseFields: ['id', 'history']
    };

    this.fieldMetadata =
      [
        { name: 'url', text: 'URL', allowEdit: false, orderBy: 'Ascending' },
        { name: 'titel', text: 'Overskrift' }
      ];


    this.detailViewMetadata = {
      name: 'menupunkt-brugere', dataApiName: 'AppUserRoute', text: 'Brugere', textSingular: 'Bruger',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'routeId', allowEdit: true, allowCreate: false, allowDelete: true, baseFields: ['id', 'history']
    };

    this.detailFieldMetadata =
      [
        { name: 'userId', text: 'Bruger', foreignKeyTableName: "User", foreignKeyValue: "shortName", allowEdit: false },
        { name: 'routeId', text: 'Menupunkt', foreignKeyTableName: "AppRoute", allowEdit: false, visible: false },
        { name: 'defaultView', text: 'Visning valgt ved opstart' }
      ];

    this.detailPaneSizePct = 50;
    this.detailToolbarCommands = [this.uiCmdViews];

  }
}
