<cer-form #form pattern="parentChildPane" [panes]="panes" (commmand)="onCommand($event)">
  <ng-template #mainTemplate>
    <cer-grid #grid 
    #Grid
    id="Grid"
    [viewMetadata]="viewMetadata" 
    [dataParent]="true"
    [fieldMetadata]="fieldMetadata" 
    [toolbarCommands]="toolbarCommands"
    [contextMenuCommands]="contextMenuCommands" 
    [keyboardShortcuts]="keyboardShortcuts"
    (commmand)="onCommand($event)"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #DetailGrid
      id="DetailGrid"
      [viewMetadata]="detailViewMetadata"
      [dataChild]="true"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarCommands]="detailToolbarCommands"
      [contextMenuCommands]="detailContextMenuCommands" 
      [keyboardShortcuts]="keyboardShortcuts"
      [editOnDoubleClick]="false"
      (commmand)="onDetailCommand($event)"
    ></cer-grid>
  </ng-template>
</cer-form>
