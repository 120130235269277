<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #outboundGrid id="outboundGrid" [viewMetadata]="outboundViewMetadata" [dataParent]="true"
      [fieldMetadata]="outboundFieldMetadata" [fieldGroupMetadata]="outboundFieldGroupMetadata"
      [toolbarCommands]="outboundToolbarCommands" [contextMenuCommands]="outboundContextMenuCommands">
    </cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #outboundLineGrid id="outboundLineGrid" [viewMetadata]="outboundLineViewMetadata" [dataChild]="true" dataChildOfId="outboundGrid" 
      [fieldMetadata]="outboundLineFieldMetadata">
    </cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #dataNoteGrid id="dataNoteGrid" [viewMetadata]="dataNoteViewMetadata" [dataChild]="true" dataChildOfId="outboundGrid"
      [fieldMetadata]="dataNoteFieldMetadata">
    </cer-grid>
  </ng-template>
</cer-form>
