<cer-form #form [panesRequired]="true">
  <ng-template #mainTemplate>
    <cer-grid
      #grid
      id="grid"
      [viewMetadata]="viewMetadata"
      [fieldGroupMetadata]="fieldGroupMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      [keyboardShortcuts]="keyboardShortcuts"
      [selectionMode]="selectionMode"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldGroupMetadata]="detailFieldGroupMetadata"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarType]="detailToolbarType"
      [toolbarCommands]="detailToolbarCommands"
      autoFocus="disabled"
      selectionInitial="none"
      [selectionMode]="detailSelectionMode"
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldGroupMetadata]="detailFieldGroupMetadata"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarType]="detailToolbarType"
      [toolbarCommands]="detailToolbarCommands"
      autoFocus="disabled"
      selectionInitial="none"
      [selectionMode]="detailSelectionMode"
      dataRelationRole="child"
    ></cer-grid>
  </ng-template>
</cer-form>
