<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid #mainGrid id="mainGrid" [viewMetadata]="viewMetadata" [dataParent]="true" [fieldMetadata]="fieldMetadata"
      ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <ejs-richtexteditor id="defaultRTE" height="100%" width="100%" [toolbarSettings]="tools">
      <ng-template #valueTemplate>
        <p>Mail besked.</p>
      </ng-template>
    </ejs-richtexteditor>
  </ng-template>
</cer-form>