import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class VoucherRequestFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'bilags-anmodninger', dataApiName: 'VoucherRequest', text: 'Bilagsanmodninger', textSingular: 'Anmodning',
      primaryKey: 'id', titleFields: ['description'], baseFields: ['id']
    };

    this.fieldMetadata =
      [
        { name: 'description', text: 'Beskrivelse', tooltipText: 'Navn på anmodning', widthType: FieldWidthEnum.XL },
        { name: 'amountCur', text: 'Beløb', tooltipText: 'Beløb i valuta', format: 'Amount', widthType: FieldWidthEnum.S },
        { name: 'currency', text: 'Valuta', foreignKeyTableName: 'Currency', foreignKeyField: 'id', foreignKeyValue: 'iso', widthType: FieldWidthEnum.XXS },
        { name: 'transDate', text: 'Dato', format: 'DateShort', widthType: FieldWidthEnum.XXS },
        { name: 'responsible', text: 'Modtager', tooltipText: 'Navn på modtager af anmodning', widthType: FieldWidthEnum.S, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
        { name: 'bankTransaction', text: 'Banktransaktion', tooltipText: 'Banktransaktion reference', /*foreignKeyTableName: 'BankAccountTransaction', foreignKeyValue: 'txt',*/ visible: false },
        { name: 'notified', text: 'Notifikation sendt', tooltipText: 'Er notifikation afsendt?', format: 'CheckBox' },
        { name: 'completed', text: 'Afsluttet', tooltipText: 'Er svar modtaget?', format: 'CheckBox', filterOperator: 'equal', filterValue: 0, filterOnClient: true },
        { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, visibleAdd: false, visibleEdit: false, format: 'DateLong' },
        { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, visibleAdd: false, visibleEdit: false, format: 'DateLong' },
      ];
  }
}
