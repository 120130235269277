import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class AppNumberSequenceFormAdapter extends CerAppFormModelAdapter {

  public override init() {

    this.viewMetadata = {
      name: 'nummerserier', dataApiName: 'DataNumberSequence', text: 'Nummerserier', textSingular: 'Nummerserie',
      primaryKey: 'id', baseFields: ['id', 'name', 'history']
    };

    //var userId: number = this.appStateService.appUserId();
    this.fieldMetadata =
      [
        { name: 'name', text: 'Navn', required: true, orderBy: 'Ascending' },
        { name: 'maskNum', text: 'Maske', tooltipText: 'Maske, f.eks. F#######', required: true },
        { name: 'nextId',text: 'Næste nummer', format: 'Integer', required: true },
        { name: 'maxId', text: 'Max nummer', format: 'Integer', required: true }
      ];

    this.toolbarCommands = [];
    this.keyboardShortcuts = [];

    this.detailViewMetadata = {
      name: 'nummerserie-numre', dataApiName: 'DataNumberSequence', text: 'Numre', textSingular: 'Nummer',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'dataNumberSequence', baseFields: ['id', 'history']
    };

    this.detailFieldMetadata =
      [
        { name: 'dataNumberSequence', text: 'Nummerserie', required: true, foreignKeyTableName: 'DataNumberSequence', visible: false },
        { name: 'sequenceId', text: 'Nummerisk værdi', required: true, format: 'Integer' },
        { name: 'sequenceNum', text: 'Værdi', required: true, orderBy: 'Ascending', orderByPriority: 1 },
        { name: 'isUsed', text: 'Er brugt?', required: true, format: 'CheckBox', defaultValue: false }
      ];

    this.detailPaneSizePct = 70;
  }
}