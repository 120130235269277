import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class WarehouseOnHandStatusFormAdapter extends CerAppFormModelAdapter {

  private updatedDateCmd: UiCommand = {
    id: 'updatedDate', text: 'Opdateret: ', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Dato hvor data er opdateret', target: ".e-content", visible: true
  };
  private onlySales: UiCommand = {
    id: 'updatedDate', text: 'Opdateret: ', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Dato hvor data er opdateret', target: ".e-content", visible: true
  };

  override init() {

    this.toolbarCommands = [this.updatedDateCmd];

    this.viewMetadata = {
      name: 'lagerbeholdninger', dataApiName: 'Warehouse_OnHandProductStatus', text: 'Lagerbeholdninger', textSingular: 'Lagerbeholdning',
      primaryKey: 'product', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'product', text: 'ISBN' },
      { name: 'name', text: 'Titel', width: '150px' },
      { name: 'name2', text: 'Undertitel', visible: false },
      { name: 'seriesId', text: 'Serie', visible: false },
      { name: 'bookstoreClassification', text: 'Varegruppe', format: 'Integer', visible: false },
      { name: 'categories', text: 'Kategorier', visible: false },
      { name: 'physicalQty', text: 'Fysisk antal', format: 'Number' },
      { name: 'reservedQty', text: 'Reserveret antal', format: 'Number' },
      { name: 'availableQty', text: 'Disponibelt antal', format: 'Number', onQueryCellInfo: this.gridQueryCellInfo },
      { name: 'updated', text: 'Opdateret', format: 'DateShort', visible: false },
      { name: 'created', text: 'Rest opdateret', format: 'DateShort', visible: false },
      { name: 'publishedDate', text: 'Udgivet dato', tooltipText: 'Udgivet dato fra Bogportalen', format: 'DateShort', onQueryCellInfo: this.gridQueryCellInfoPublishedDate },
      { name: 'buyable', text: 'Kan købes', format: 'CheckBox', onQueryCellInfo: this.gridQueryCellInfoBuyable },
      { name: 'apologyCode', text: 'Undskyldningskode', format: 'NumberLeft', widthType: FieldWidthEnum.XS, onQueryCellInfo: this.gridQueryCellInfoApologyCode },
      { name: 'minimumInventory', text: 'Min. lager', format: 'TextRight', onQueryCellInfo: this.gridQueryCellInfoMinimumInventory },
      { name: 'warehouseItemNum', text: 'Lager varenr.', visible: false },
      { name: 'originalTitle', text: 'Orig. titel' },
      { name: 'originalLanguage', text: 'Orig. sprog', visible: false },
      { name: 'authors', text: 'Forfatter' },
      { name: 'shreddedYear', text: 'Markuleret', format: 'Integer', visible: false },
      { name: 'forceVisible', text: 'Tving synlig', format: 'CheckBox', visible: false },
      { name: 'forceBuyable', text: 'Tving kan købes', format: 'CheckBox', visible: false },
      { name: 'printPublishedDate', text: 'Udgivet bogportal print', format: 'DateShort', visible: false },
      { name: 'availableForPurchaseDate', text: 'Tilgængelig bogportal køb', format: 'DateShort', visible: false },
      { name: 'statusCode', text: 'Status', visible: false },
      { name: 'approximateInventoryNumber', text: 'Ca. lager', format: 'TextRight', visible: false },
      { name: 'exchangePool', text: 'Ombytning', visible: false },
      { name: 'salesPrice', text: 'Salgspris', format: 'Integer', visible: false },
      { name: 'modifiedJobId', text: 'Rest opdateret job', format: 'Number', visible: false }
    ];

    if (this.routeParams) {
      //this.viewMetadata.dataApiParams = this.routeParams;
    }

    this.detailPaneVertical = true;
    this.detailPaneSizePct = 25;
  }

  override onCommand(event: UiCommandEvent) {
    if (event.actionType == UiActionTypeEnum.DataBound) {
      console.log('DataLoad', event);
      var data: any[] = event.args?.result;
      if (event.appComponent && data && data.length > 0) {
        this.setupUpdatedDate(<CerGridComponent>event.appComponent, new Date(data[0].updated));
      }
    }
  }

  private setupUpdatedDate(grid: CerGridComponent, updatedDate: Date) {
    var text: string = 'Opdateret: ' + updatedDate.toLocaleDateString();
    grid.grid.toolbarModule.getFocusableToolbarItems().forEach((item) => {
      if (item && (<HTMLDivElement>item).title == this.updatedDateCmd.tooltipText) {
        var buttons: HTMLCollection = item.getElementsByTagName('button')
        if (buttons && buttons.length > 0) {
          var button: HTMLButtonElement = <HTMLButtonElement>buttons[0];
          button.title = text;
          var txts = button.getElementsByClassName('e-tbar-btn-text');
          if (txts && txts.length > 0) {
            (<HTMLSpanElement>txts[0]).innerText = text;
          }
        }
      }
    });
  }

  public gridQueryCellInfo(args: any) {
    var dto: any = args.data;

    if (dto.publishedDate > new Date() || dto.buyable != true) {
      return;
    }

    if ((dto.availableQty == 0)) {
      if (dto.preorder) {
        args.cell.style.backgroundColor = '#CCCCFF';
      }
      else {
        args.cell.style.backgroundColor = '#FFBBBB';
      }
    }
  }

  public gridQueryCellInfoPublishedDate(args: any) {
    var dto: any = args.data;

    if (dto.publishedDate >= new Date()) {
      args.cell.style.backgroundColor = '#BBFFBB';
    }
  }

  public gridQueryCellInfoBuyable(args: any) {
    var dto: any = args.data;

    if (dto.buyable != true) {
      args.cell.style.backgroundColor = '#FFFFCC';
    }
  }

  public gridQueryCellInfoApologyCode(args: any) {
    var dto: any = args.data;

    if (dto.apologyCode >= 500) {
      args.cell.style.backgroundColor = '#FFFFCC';
    }
  }


  public gridQueryCellInfoMinimumInventory(args: any) {
    var dto: any = args.data;

    if (dto.publishedDate <= new Date() && dto.buyable && dto.apologyCode < 500) {
      var min = Number(dto.minimumInventory);
      if (min > 0 && min > dto.availableQty) {
        args.cell.style.backgroundColor = '#FFBBBB';
      }
    }
  }
  /*
  Tolkning på om vare kan vises og købes
  --------------------------------------
  En vare kan købes hvis:
  Varen skal være til salg med en aktiv købspris større end nul. Samtidig skal der være lager eller en
  undskyldningskode mindre end 500.
  Det kan omsættes til, at følgende 3 bolske udtryk skal være sande:
  buyable = 1
  amountInSmallestUnit > 0
  approximateInventoryNumber > 0 ELLER ApologyCode < 500
  *bemærk hvis felter validFrom og validTo har værdi, så skal dagsdato ligge indenfor deres interval før en
  pris er aktuel.
  Eksportfunktionaliteten i systemet genere kun nye produkter(XML) der er ændret siden sidste kørsel og
  altid med den aktuelle pris for dagen i feltet <price>
  Ved flere priser findes listen <priceDataList> med priser og gyldighedsdatoer som bruges til at bestemme
  den aktuelle pris.
  *bemærk at et forlag kan sætte forceVisible = false, det betyder de ikke vil have bogen vist
*/
}
