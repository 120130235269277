// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

// SyncFusion
import { DataManager, Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnModel, QueryCellInfoEventArgs, DropDownEditCell, BooleanEditCell, AggregateColumnModel, AggregateRowModel, Column, DefaultEditCell, DatePickerEditCell, NumericEditCell } from '@syncfusion/ej2-angular-grids';

// Third party
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from './cer-grid-command.service';
import { CerDataService, FieldFormatType, FieldMetaForeignKey, FieldMetadata, FieldWidthEnum } from '../cer-data/cer-data.service';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import { CerGridEditTextArea } from './cer-grid-edit-textarea';
import { CerGridEditDropDownList } from './cer-grid-edit-dropdownlist';
import { CerGridEditBoolean } from './cer-grid-edit-boolean';
import { CerGridSelectionService } from './cer-grid-selection.service';
import { CerGridEditDefault } from './cer-grid-edit-default';
import { CerGridEditDatePicker } from './cer-grid-edit-date-picker';
import { CerLocaleService } from '../cer-locale/cer-locale.service';
import { CerGridEditNumeric } from './cer-grid-edit-numeric';

@Injectable()
export class CerGridEditCellService implements OnDestroy {

  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  private grid: GridComponent;
  private design: GridDesign;
  private gridAggregates: AggregateRowModel[] = [];

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;

    this.footerInit()
    this.queryFieldInfoFieldsInit();
  }

  constructor(
    private command: CerGridCommandService,
    private data: CerDataService,
    private selection: CerGridSelectionService,
    private meta: CerEditMetaService,
    private localeService: CerLocaleService
  ) {
    this.subscriptionManager.add(this.command.commandPre$.subscribe(event => this.onCommandPre(event)));
  }

  // Subscriptions
  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
    if (this.chatColumn) {
      this.grid.element.removeEventListener("click", (e) => this.onChatClicked(e));
    }
  }

  private onCommandPre(event: UiCommandEvent) {
    if (event.cancel) {
      return;
    }
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.QueryCellInfo:
            this.onQueryCellInfoActionBegin(event.args);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.Created:
            this.chatColumnInit();
            this.editCellCerInit();
            break;
        }
        break;
    }
  }

  public foreignKeySetup(column: ColumnModel, meta: FieldMetadata) {
    column.foreignKeyField = FieldMetaForeignKey.keyFieldGet(meta);
    column.foreignKeyValue = FieldMetaForeignKey.valueFieldGet(meta);
    column.editType = "dropdownedit";
    this.columnSetupDDL(column, meta);
  }

  private columnSetupDDL(column: ColumnModel, meta: FieldMetadata) {
    if (meta.foreignKeyTableName) {
      var dm: DataManager = this.data.fkDataManagerSetup(meta);
      column.dataSource = dm;
    }
    else {
      column.dataSource = new DataManager(meta.foreignKeySubject.getValue());
      this.subscriptionManager.add(meta.foreignKeySubject.subscribe(data => {

        column.dataSource = new DataManager(data);
        //this.columnSetupDDLParams(column, meta, dm);
        this.data.fkDataLoaded();
      }));
    }
  }

  // Purpose: Wrap standard edit cell with our edit cell
  // We cannot replace the base/standard edit cell with our own edit cell, so we have to wrap the base/standard edit cell with our own edit cell
  // We save our own edit cell in the column object as a property "cerEdit"
  private editCellCerInit() {
    if (this.grid?.columns) {
      this.grid.columns.forEach((column: ColumnModel) => {
        switch (column.editType) {
          case 'dropdownedit':
            var editCellStdDdl: DropDownEditCell = <DropDownEditCell>column.edit;
            var metaField: FieldMetadata = this.meta.columnMetaGet(column);
            var fkQuery: Query = this.data.fkQuery(metaField);
            (<any>column).cerEdit = new CerGridEditDropDownList(this.grid, editCellStdDdl, metaField, fkQuery);
            break;
          case 'datepickeredit':
            var editCellStdDatePicker: DatePickerEditCell = <DatePickerEditCell>column.edit;
            (<any>column).cerEdit = new CerGridEditDatePicker(this.grid, editCellStdDatePicker, this.meta.columnMetaGet(column), this.localeService);
            break;
          case 'booleanedit':
            var editCellStdBoolean: BooleanEditCell = <BooleanEditCell>column.edit;
            (<any>column).cerEdit = new CerGridEditBoolean(this.grid, editCellStdBoolean, this.meta.columnMetaGet(column));
            break;
          case 'numericedit':
            var editCellStdNumeric: NumericEditCell = <NumericEditCell>column.edit;
            (<any>column).cerEdit = new CerGridEditNumeric(this.grid, editCellStdNumeric, this.meta.columnMetaGet(column), this.localeService);
            break;
          case undefined:
            var editCellDefault: DefaultEditCell = <DefaultEditCell>column.edit;
            (<any>column).cerEdit = new CerGridEditDefault(this.grid, editCellDefault, this.meta.columnMetaGet(column));
            break;
        }
      });
    }
  }

  // Format
  public columnSetAttributes(column: ColumnModel, meta: FieldMetadata) {
    if (meta.cellAttributes) {
      column.customAttributes = meta.cellAttributes;
    }
  }

  public columnSetFormatter(column: ColumnModel, meta: FieldMetadata) {
    if (meta.formatter) {
      column.formatter = meta.formatter;
      column.disableHtmlEncode = false; // for html formatted text
    }
  }

  public columnSetTemplate(column: ColumnModel, meta: FieldMetadata) {
    if (meta.template) {
      column.template = meta.template;
    }
  }

  public columnSetFormat(column: ColumnModel, meta: FieldMetadata) {
    if (!meta.format && meta.name == 'id') {
      meta.format = 'Integer';
    }
    if (meta.format) {
      if (Array.isArray(meta.format)) {
        meta.format.forEach(formatEle => this.columnSetFormatElement(column, meta, formatEle));
      }
      else {
        this.columnSetFormatElement(column, meta, meta.format);
      }
    }
    this.columnSetFormatDefault(column);
    this.columnSetWidthDefault(column);
  }

  private columnSetFormatDefault(column: ColumnModel) {
    if (column.type === undefined) {
      column.type = "string";
    }
    // Default format
    if (!column.format) {
      switch (column.type) {
        case "number":
          column.format = "N0";
          column.textAlign = "Right";
          break;
        case "date":
          column.format = { type: "date", format: "dd.MM.yyyy" };
          break;
      }
    }
  }

  public columnSetupByMeta(column: ColumnModel, meta: FieldMetadata) {
    this.columnMetaSet(column, meta);

    // Header
    column.headerText = meta.text;
    column.visible = (meta.visible !== undefined) ? meta.visible : true;

    if (meta.headerTemplate !== undefined) {
      column.headerTemplate = meta.headerTemplate;
    }

    // Data
    if (meta.isIdentity != undefined) {
      column.isIdentity = meta.isIdentity;
    }

    // Foreign Key Setup
    if (meta.foreignKeySubject || meta.foreignKeyTableName) {
      this.foreignKeySetup(column, meta);
    }

    if (meta.allowEdit !== undefined) {
      column.allowEditing = meta.allowEdit;
    }

    if (meta.defaultValue !== undefined) {
      column.defaultValue = meta.defaultValue;
    }

    var validationRules: any = null;
    if (meta.required !== undefined && meta.required) {
      if (validationRules == null) {
        validationRules = new Object();
      }
      validationRules.required = [true, '"' + meta.text + '" skal indtastes'];
    }
    if (meta.validationMaxLen !== undefined) {
      if (validationRules == null) {
        validationRules = new Object();
      }
      validationRules.maxLength = meta.validationMaxLen;
    }
    column.validationRules = validationRules;

    // Apperance
    if (meta.width !== undefined) {
      column.width = meta.width;
    }
    else if (meta.widthType != undefined) {
      column.width = this.columnWidthByType(meta.widthType);
    }
    if (meta.minWidth !== undefined) {
      column.minWidth = meta.minWidth;
    }
    if (meta.maxWidth !== undefined) {
      column.maxWidth = meta.maxWidth;
    }
    else if (meta.maxWidthType != undefined) {
      column.maxWidth = this.columnWidthByType(meta.maxWidthType);
    }
    
    // Format
    this.columnSetTemplate(column, meta)
    this.columnSetAttributes(column, meta);
    this.columnSetFormat(column, meta);
    this.columnSetFormatter(column, meta);

    // Footer
    if (!(meta.footerType == null && meta.footerTemplate == null && meta.foooterFunction == null)) {
      this.footerAddColumn(column, meta);
    }
  }

  public columnMetaSet(column: ColumnModel, meta: FieldMetadata) {
    if (column) {
      (<any>column).meta = meta;
    }
  }

  public columnWidthByType(widthType: FieldWidthEnum): string {
    return (<number>widthType) + 'px';
  }

  private columnSetWidthDefault(column: ColumnModel) {
    // Default width
    if (!column.width) {
      switch (column.type) {
        case "number":
          column.width = this.columnWidthByType(FieldWidthEnum.XS);
          break;
        case "date":
          column.width = this.columnWidthByType(FieldWidthEnum.S);
          break;
        case "string":
          column.width = this.columnWidthByType(FieldWidthEnum.M);
          break;
      }
    }
  }

  public columnSetFormatElement(column: ColumnModel, meta: FieldMetadata, format: FieldFormatType) {
    switch (format) {
      case 'Amount':
        column.type = "number";
        column.editType = "numericEdit";
        column.edit = { params: { validateDecimalOnType: true, decimals: 2, format: "N2" } };
        column.format = "N2";
        column.textAlign = "Right";
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.S);
        }
        break;
      case 'NumberLeft':
          column.type = "number";
          column.editType = "numericEdit";
          column.edit = { params: { validateDecimalOnType: true, decimals: 0, format: "N" } };
          column.format = "N";
          column.textAlign = 'Left';
          if (!column.width) {
            column.width = this.columnWidthByType(FieldWidthEnum.XS);
          }
          break;
      case 'Number':
        column.type = "number";
        column.editType = "numericEdit";
        column.edit = { params: { validateDecimalOnType: true, decimals: 0, format: "N" } };
        column.format = "N";
        column.textAlign = "Right";
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.XS);
        }
        break;
      case 'Integer':
        column.type = "number";
        column.editType = "numericEdit";
        column.edit = { params: { validateDecimalOnType: true, decimals: 0, format: "N" } };
        //column.format = "N";
        column.textAlign = "Right";
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.XS);
        }
        break;
      case 'DateLong':
        column.type = "datetime";
        column.editType = "dateTimePickerEdit";
        column.format = { type: "date", format: "dd.MM.yyyy HH:mm" };
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.M);
        }
        break;
      case 'DateShort':
        column.type = "date";
        column.editType = "datePickerEdit";
        column.format = { type: "date", format: "dd.MM.yyyy" };
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.S);
        }
        break;
      case 'CheckBox':
        column.type = "boolean";
        column.displayAsCheckBox = true;
        column.editType = "booleanedit";
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.XS);
          column.maxWidth = this.columnWidthByType(FieldWidthEnum.S);
        }
        break;
      case 'TextRight':
        column.type = "string";
        column.textAlign = 'Right';
        break;
      case 'Text':
        column.type = "string";
        break;
      case 'TextArea':
        column.type = "string";
        column.edit = new CerGridEditTextArea(this.grid, column);
        break;
      case 'Url':
        column.type = "string";
        column.template = this.templateUrl(column.field, meta?.urlBase);
        var validationRuleUrl: object = { url: [true, 'En valid web adresse skal angives i feltet "' + meta.text + '".'] };
        column.validationRules = validationRuleUrl;
        break;
      case 'Email':
        column.type = "string";
        column.template = this.templateEmail(column.field);
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.L);
        }
        var validationRuleEmail: object = { email: [true, 'En valid e-mail skal angives i feltet "' + meta.text + '".'] };
        column.validationRules = validationRuleEmail;

        break;
      case 'Phone':
        column.type = "string";
        column.template = this.templatePhone(column.field);
        break;
      case 'Chip':
        column.template = this.templateChip(column.field, meta.formatClass ? meta.formatClass : 'chip-primary');
        break;
      case 'Chat':
        column.allowEditing = false;
        column.allowFiltering = false;
        column.allowGrouping = false;
        column.allowSearching = false;
        column.allowSorting = false;
        this.chatColumn = column;
        if (this.chatTemplate) {
          (<any>column.template) = this.chatTemplate;
        }
        //column.template = '<ng-template #template let-data><input [value]="data.id" />><mat-icon color="primary" class="icon-small" [matBadgeHidden] = "!data.chat" [matBadge]="data.chat" matBadgeColor="info">chat</mat-icon></ng-template>';
        //column.template = "\${ if (chat === undefined)}<mat-icon color='primary' class='icon-small' matBadgeHidden=true [matBadge]=" + column.field.length + " matBadgeColor='info'>chat</mat-icon>\${/if}";
        //column.template = "\${ if (" + column.field + ")}chat</mat-icon>";
        if (!column.width) {
          column.width = this.columnWidthByType(FieldWidthEnum.XXXS);
        }
        break;
    }
  }

  /*************
   * Footer
   *************/
  private footerInit() {
    this.gridAggregates = [];
  }

  private footerAddColumn(column: ColumnModel, meta: FieldMetadata) {
    var aggregate: AggregateColumnModel = {
      field: column.field,
      type: meta.footerType,
      customAggregate: meta.foooterFunction,
      format: column.format
    };

    var aggregateRow: AggregateRowModel;
    if (this.gridAggregates.length == 0) {
      aggregateRow = { columns: [] };
      this.gridAggregates.push(aggregateRow);
    }
    else {
      aggregateRow = this.gridAggregates[0];
    }
    aggregateRow.columns.push(aggregate);
    this.grid.aggregates = this.gridAggregates;
  }


  /**********************
   * Template field type
   **********************/
  private templateUrl(field: string, urlPrefix: string): string {
    var fieldPrefix : string = urlPrefix ?? '';
    return "\${if(" + field + ")}<a rel='nofollow' target='_blank' href='http://"+fieldPrefix+"${" + field + "}'>${" + field + "}</a>\${/if}";
  }

  private templateEmail(field: string): string {
    return "\${if(" + field + ")}<a rel='nofollow' href = 'mailto:\${" + field + "}' >\${ " + field + " }</a>\${/if}";
  }

  private templatePhone(field: string): string {
    return "\${ if (" + field + ")}<a rel='nofollow' href='tel:\${" + field + "}'>\${" + field + "}</a>\${/if}"
  }

  private templateChip(field: string, cls: string): string {
    return "\${ if (" + field + ")}<div class='e-chip-list'><div class='e-chip " + cls + "'><div class='e-chip-text'>\${" + field + "}</div></div></div>\${/if}";
  }

  /****************
  * QueryFieldInfo
  ****************/
  public queryCellInfoFields: FieldMetadata[] = [];
  public queryCellInfoFieldNames: string[] = [];

  private queryFieldInfoFieldsInit() {
    this.queryCellInfoFields = this.data?.fieldMetadata?.filter(f => f.onQueryCellInfo);
    if (this.queryCellInfoFields?.length > 0) {
      this.queryCellInfoFields.forEach(f => this.queryCellInfoFieldNames.push(f.name));
      this.command.queryCellInfoEnable = true;
    }
  }

  private onQueryCellInfoActionBegin(args: QueryCellInfoEventArgs) {
    if (args.column) {
      if (this.queryCellInfoFieldNames.includes(args.column.field)) {
        this.queryCellInfoFields.filter(f => f.name  == args.column.field)
        .forEach(f => f.onQueryCellInfo(args));
      }
      this.chatCellInfo(args);
    }
  }

  /******
  * Chat
  *******/
  public chatTemplate: NgModel = null;
  public chatColumn: ColumnModel = null;

  private chatColumnInit() {
    if (this.chatColumn) {
      this.grid.element.addEventListener("click", this.onChatClicked.bind(this));
      this.command.queryCellInfoEnable = true;
    }
  }

  private onChatClicked(args: any) {
    if ((args.target as any).classList.contains("chat-link-cell")) {
      this.command.cmdDispatchCommand(UiActionTypeEnum.ChatClicked, args);
    }
  }

  public chatColumnRefresh(data: object) {
    var idx = this.selection.rowSelectedIdx();
    if (idx != undefined && idx !== null && data && this.chatColumn && this.chatColumn.field) {
      try {
        var column: Column = this.grid.getColumnByField(this.chatColumn.field);
        var cell: Element = this.grid.getCellFromIndex(idx, column.index);
        var args: QueryCellInfoEventArgs = { column: column, cell: cell, data: data };
        this.chatCellInfo(args);
      }
      catch {
        console.log("Chat refresh failed");
      }
    }
  }

  private chatCellInfo(args: QueryCellInfoEventArgs) {

    if (this.chatColumn?.field && args.column?.field === this.chatColumn?.field) {
      args.cell.classList.add('chat-link-cell');

      args.cell.classList.remove('chat-link-active');
      args.cell.classList.remove('chat-link-none');
      args.cell.classList.remove('chat-link-unknown');

      var data: any = args.data;
      var chat = data[this.chatColumn.field];
      //console.log(data.id);
      //console.log(chat);
      if (chat) {
        if (chat.length > 0) {
          args.cell.classList.add('chat-link-active');
        }
        else {
          args.cell.classList.add('chat-link-none');
        }
      }
      else {
        args.cell.classList.add('chat-link-unknown');
      }
    }
  }
}
