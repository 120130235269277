import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class SalesOrderLineTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-ordrelinje-typer', dataApiName: 'SalesOrderLineType', text: 'Salg ordrelinje typer',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'logisticsOutboundActive', text: 'Forsendes fra lager?', format: 'CheckBox' },
      { name: 'logisticsInboundActive', text: 'Returneres til lager?', format: 'CheckBox' },
      { name: 'isBundle', text: 'Skal udfoldes før forsendelse?', format: 'CheckBox' }
    ];

  }
}
