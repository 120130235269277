import { Component, Injector, ViewChild } from '@angular/core';
import { AppStateService } from 'src/app-core/app-state/app-state.service';
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerGridComponent, CerGridSelectionMode, CerGridToolbarType } from '../../../cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter'
import { UiCommand } from '../../../cer/cer-grid/cer-grid-command.service';
import { ActivatedRoute } from '@angular/router';
import { CerAppFormGridComponent } from '../cer-app-form-grid/cer-app-form-grid.component';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'cer-app-form-grid-detail',
  templateUrl: './cer-app-form-grid-detail.component.html',
  styleUrls: ['./cer-app-form-grid-detail.component.css']
})

export class CerAppFormGridDetailComponent extends CerAppFormGridComponent {

  protected panes: CerFormPanesSettings = null;

  protected override initForm() {
    this.adapter.form = this.form;
    this.form.commmand.subscribe(e => this.adapter.onCommand(e));
    this.initPanes();
  }

  private initPanes() {
    if (this.form && this.panes) {
      this.form.panesService.panesInit(this.panes);
    }
  }

  @ViewChild('grid', { static: false }) set gridContentThis(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.adapter.grid = this.grid;
      this.grid.commmand.subscribe(e => this.adapter.onCommand(e));
    }
  }
  detailGrid: CerGridComponent;
  @ViewChild('detailGrid', { static: false }) set gridContentDetail(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
      this.adapter.detailGrid = this.detailGrid;
      this.detailGrid.commmand.subscribe(e => this.adapter.onDetailCommand(e));
    }
  }

  public detailViewMetadata: ViewMetadata;
  public detailFieldGroupMetadata: FieldGroupMetadata[] = [];
  public detailFieldMetadata: FieldMetadata[] = [];
  public detailToolbarType: CerGridToolbarType = 'simple';
  public detailToolbarCommands: UiCommand[];
  public detailContextMenuCommands: UiCommand[];
  public detailSelectionMode: CerGridSelectionMode = 'row';

  constructor(
    route: ActivatedRoute,
    routeService: CerAppRouteService,
    dialogService: CerDialogService,
    appDialogService: CerAppDialogService,
    appStateService: AppStateService,
    injector: Injector
  ) {
    super(route, routeService, dialogService, appDialogService, appStateService, injector);
    this.initFromAdapterMasterDetail();
  }

  protected initFromAdapterMasterDetail() {
    if (this.adapter) {
      var adapter: CerAppFormModelAdapter = this.adapter;

      var v = adapter.detailPaneVertical;
      // Detail grid
      this.panes = {
        main: { visible: 'show', size: (100 - adapter.detailPaneSizePct) + '%' },
        right: { visible: !v ? adapter.detailPaneVisible : 'hide', size: adapter.detailPaneSizePct + '%' },
        bottom: { visible: v ? adapter.detailPaneVisible : 'hide', size: adapter.detailPaneSizePct + '%' },
        orientationPrimary: v ? 'vertical' : 'horizontal'
      };
      this.initPanes();

      if (adapter.detailSelectionMode) {
        this.detailSelectionMode = adapter.detailSelectionMode;
      }

      if (adapter.detailViewMetadata) {
        this.detailViewMetadata = adapter.detailViewMetadata;
      }
      if (adapter.detailFieldMetadata) {
        this.detailFieldMetadata = adapter.detailFieldMetadata;
      }
      if (adapter.detailFieldGroupMetadata) {
        this.detailFieldGroupMetadata = adapter.detailFieldGroupMetadata;
      }
      if (adapter.detailToolbarCommands) {
        this.detailToolbarCommands = adapter.detailToolbarCommands;
      }
      if (adapter.detailContextMenuCommands) {
        this.detailContextMenuCommands = adapter.detailContextMenuCommands;
      }
    }
  }
}
