import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { baseUrlTrackTrace } from '../warehouse-outbound-order.service';

export class WarehouseOutboundDispatchTransFormAdapter extends CerAppFormModelAdapter {

  private onlySalesCmd: UiCommand = {
    id: 'onlySalesCmd', text: 'Vis alle', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Vis kun salg', target: ".e-content", visible: true
  };

  override init() {

    this.toolbarCommands = [this.onlySalesCmd];

    this.viewMetadata = {
      name: 'lager-afsendte', dataApiName: 'Warehouse_OutboundOrderDispatch', text: 'Afsendte', textSingular: 'Afsendt',
      primaryKey: 'pickOrderNum', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'deliveryDate', text: 'Afsendelsesdato', format: 'DateShort', orderBy: 'Descending', orderByPriority: 1 },
      { name: 'outboundOrderNum', text: 'Forsendelse', widthType: FieldWidthEnum.XS, orderBy: 'Descending', orderByPriority: 2 },
      { name: 'warehouseCounterpart', text: 'Kundenr.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'pickOrderNum', text: 'Lagerordre', widthType: FieldWidthEnum.S, orderBy: 'Descending', orderByPriority: 3 },
      { name: 'customerRef', text: 'Kunderef.', widthType: FieldWidthEnum.XS },
      { name: 'purchOrderNum', text: 'Reference', widthType: FieldWidthEnum.XS },
      { name: 'orderStatus', text: 'Ordrestatus', tooltipText: 'Ordrens samlede status (linjestatus ses ikke)', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'product', text: 'ISBN', widthType: FieldWidthEnum.L },
      { name: 'productName', text: 'Titel', widthType: FieldWidthEnum.XL },
      { name: 'deliveredQty', text: 'Leveret antal', format: 'Amount'},
      { name: 'orderedQty', text: 'Lagerantal', format: 'Amount', visible: false},
      { name: 'salesQty', text: 'Antal', format: 'Amount' },
      { name: 'nameDlv', text: 'Navn', widthType: FieldWidthEnum.M },
      { name: 'streetDlv', text: 'Adresse', widthType: FieldWidthEnum.M },
      { name: 'zipCodeDlv', text: 'Postnr', widthType: FieldWidthEnum.XS },
      { name: 'cityDlv', text: 'Bynavn', widthType: FieldWidthEnum.XS },
      { name: 'countryRegionIdDlv', text: 'Land', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'carrierTrackingNum', text: 'Track trace', widthType: FieldWidthEnum.S, urlBase: baseUrlTrackTrace },
      { name: 'weight', text: 'Vægt', format: 'Amount'},
      { name: 'inventLocationId', text: 'Lagersted'},
      { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, format: 'DateLong'},
      { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'createdJobId', text: 'Oprettet job', format: 'Number' },
      { name: 'modifiedJobId', text: 'Ændret job', format: 'Number', visible: false }
    ];
  }
}
