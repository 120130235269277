import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { CerAppRouteService } from '../cer-app-route.service';
import { CerAppMetaUserRoute } from 'src/cer-app/cer-app-meta/cer-app-meta-user.service';
import { CerAppMetaUserRouteViewFormAdapter } from 'src/cer-app/cer-app-meta/cer-app-meta-user-route-view.form.adapter';
import { CerAppDialogService, DialogInput } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerMetaUserView, ICerViewPersistenceService } from 'src/cer/cer-form/cer-form-persistence.service';

export class ViewMenuItem {
  public icon: string;
  public name?: string;
  public text: string;
  public isPrimary: boolean;
  public isSelected: boolean;
  public type: 'standard' | 'design' | 'complete' | 'custom' | 'edit' | 'save';
  public trackId: number;
}

@Component({
  selector: 'cer-app-route-title',
  templateUrl: './cer-app-route-title.component.html',
  styleUrls: ['./cer-app-route-title.component.css']
})
export class CerAppRouteTitleComponent implements OnInit {
  private subscriptionManager$: Subscription = new Subscription();

  public routeTitle$: BehaviorSubject<string>;
  public activeViewPersistenceService$: BehaviorSubject<ICerViewPersistenceService>;
  protected pageIsLoading$: BehaviorSubject<boolean>;

  public viewNameTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public pageTitleViewMenu: ViewMenuItem[];

  public selectedView: CerMetaUserView = null;
  public selectedMenuItem: ViewMenuItem;
  public viewMenuItems: ViewMenuItem[];
  public primaryMenuItems: ViewMenuItem[];
  private trackId: number = 0;

  constructor(private routeService: CerAppRouteService, private appDialogService: CerAppDialogService) {
    this.routeTitle$ = this.routeService.routeTitle$;
    this.pageIsLoading$ = this.routeService.isLoading$;

    this.activeViewPersistenceService$ = this.routeService.activeViewPersistenceService$;

    this.subscriptionManager$.add(
      this.routeService.activeUserRoute$.subscribe(userRoute => this.setViewMenuItems(userRoute))
    );

    this.subscriptionManager$.add(
      this.routeService.activeView$.subscribe(view => this.onActiveViewSelected(view))
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptionManager$.unsubscribe();
  }

  private setViewMenuItems(userRoute: CerAppMetaUserRoute) {
    this.trackId++;
    var trackId = this.trackId;
    var menuItems: ViewMenuItem[] = [];
    if (userRoute?.views) {
      userRoute.views.forEach(view => menuItems.push(
        {
          text: view.name, name: view.name,
          icon: view.type == 'standard' ? 'view_column' : view.type == 'complete' ? 'view_comfy' : 'bookmark',
          type: view.type,
          isPrimary: (view.type == 'custom' && view.isPrimary),
          isSelected: false,
          trackId: trackId
        })
      );

      if (menuItems.filter(view => view.type == 'custom').length) {
        menuItems.push({ text: 'Vedligehold', icon: 'build', type: 'edit', isPrimary: false, isSelected: false, trackId: trackId });
      }

      menuItems.push({ text: 'Gem nuværende', icon: 'save_alt', type: 'save', isPrimary: false,  isSelected: false, trackId: trackId });
    }
    this.viewMenuItems = menuItems;
    this.primaryMenuItems = menuItems.filter(mi => (mi.type == 'custom' && mi.isPrimary));
  }

  public trackViewMenuItem(index: number, item: ViewMenuItem) {
    return item.trackId;
  }

  public onActiveViewSelected(view: CerMetaUserView) {
    var title: string = null;
    switch (view?.type) {
      case 'standard':
        break;
      case 'complete':
        title = view?.name;
        break;
      case 'custom':
        if (!view?.isPrimary) {
          title = view?.name;
        }
    }
    this.selectedView = view;
    this.viewMenuItems.forEach(mi => 
      {
        if (mi?.name && view?.name && view?.name == mi.name) {
          mi.isSelected = true;
          this.selectedMenuItem = mi;
        }
        else {
          mi.isSelected = false;
        }
      });
    this.viewMenuItems = this.viewMenuItems.slice();
    this.primaryMenuItems = this.viewMenuItems.filter(mi => (mi.type == 'custom' && mi.isPrimary));
    this.viewNameTitle$.next(title);
  }

  public clickedViewMenu(mi: ViewMenuItem) {
    switch (mi.type) {
      case 'edit':
        this.manageViews();
        break;
      case 'save':
        this.saveCurrent();
        break;
      default:
        this.selectView(mi.name);
    }
  }

  public reloadUrl() {
    this.routeService.reloadUrl();
  }

  public selectView(viewName: string) {
    var view = this.routeService?.getView(viewName);
    if (view) {
      this.routeService?.activeView$?.next(view);
    }
  }

  public manageViews() {
    this.appDialogService.showDialogFormGridAdapter(CerAppMetaUserRouteViewFormAdapter)
      .then(
        diaglogResult => {
          this.routeService.reloadViews();
          this.routeService.isLoading$.next(false);
        }
      ).catch(
        error => {
          window.alert("Error: " + error);
        }
      );
  }

  public saveCurrent() {
    var nameId = 'name';
    var isDefaultId = 'isDefault';
    var isPrimaryId = 'isPrimary';
    var inputs: DialogInput[] = [
      { label: 'Visningsnavn', id: nameId, placeholder: 'Angiv visningsnavn', value: '', element: null },
      { label: 'Er primær?', id: isPrimaryId , placeholder: 'Skal visning være primær og vises som primære valgmulighed', value: '', element: null, type: 'CheckBox' },
      { label: 'Valgt ved opstart?', id: isDefaultId , placeholder: 'Skal visning vælges ved opstart', value: '', element: null, type: 'CheckBox' }
    ];
    this.appDialogService.inputPrompt('', 'Gem visning', inputs).then(
      result => {
        if (result.ok) {
          var viewName = result.dialogInputs.find(i => i.id == nameId).value;
          var isPrimary: boolean = result.dialogInputs.find(i => i.id == isPrimaryId).valueNumber == 1;
          var isDefault: boolean = result.dialogInputs.find(i => i.id == isDefaultId).valueNumber == 1;
          if (viewName?.length > 0) {
            this.routeService.setView(viewName, isPrimary, isDefault);
          }
        }
      }
    );
  }
}
