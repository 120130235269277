<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid #grid [viewMetadata]="viewMetadata"  [dataParent]="true" [fieldMetadata]="fieldMetadata"
      [fieldGroupMetadata]="fieldGroupMetadata" [tabMetadata]="tabMetadata" [toolbarCommands]="toolbarCommands" 
      [contextMenuCommands]="contextMenuCommands" [keyboardShortcuts]="keyboardShortcuts" 
      (commmand)="onCommand($event)"
      ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #detailGrid [viewMetadata]="detailViewMetadata" [dataChild]="true" [fieldMetadata]="detailFieldMetadata" 
      [toolbarCommands]="detailToolbarCommands" [keyboardShortcuts]="keyboardShortcuts" 
      (commmand)="onDetailCommand($event)"></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #bottomGrid [viewMetadata]="bottomViewMetadata" [dataChild]="true" [fieldMetadata]="bottomFieldMetadata" 
      [toolbarCommands]="bottomToolbarCommands" [keyboardShortcuts]="keyboardShortcuts"
      (commmand)="onBottomCommand($event)"></cer-grid>
  </ng-template>
</cer-form>