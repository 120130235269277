import { Component, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { uiCmdSeparator, UiCommand, UiCommandEvent } from '../../cer/cer-grid/cer-grid-command.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

// WebApi
import { SalesOrderClient } from "../api";
import { SalesOrderService, uiCmdSyncSalesOrders, uiCmdSyncSalesOrderSelected } from './sales-order.service';

export const uiCmdSearchName: UiCommand = { id: 'uiCmdSearchName', text: 'Søg firmanavn', tooltipText: 'Søg datakilder på firmanavn', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSearchVATNum: UiCommand = { id: 'uiCmdSearchVATNum', text: 'Søg CVR', tooltipText: 'Søg datakilder på CVR', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSearchEAN: UiCommand = { id: 'uiCmdSearchEAN', text: 'Søg EAN', tooltipText: 'Søg datakilder på EAN', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSelectForCustomer: UiCommand = { id: 'uiCmdSelectForCustomer', text: 'Anvend som kunde', tooltipText: 'Anvend data for kunden', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };

@Component({
  selector: 'sales-order-cust',
  templateUrl: './sales-order-cust.component.html',
  styleUrls: ['../component.css'],
  providers: [SalesOrderService]
})

export class SalesOrderCustComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected showWarehouse: boolean = false;

  protected panes: CerFormPanesSettings = {
    top: { visible: 'show', size: '30%' },
    left: { visible: 'show', size: '40%' },
    main: { visible: 'show', size: '50%', sizeSecondary: '30%' },
    right: { visible: 'show', size: '30%' },
    bottom: { visible: 'show', size: '20%' },
    orientationPrimary: 'vertical'
  };


  private salesOrderGrid: CerGridComponent;
  @ViewChild('salesOrderGrid', { static: false }) set salesOrderGridContent(content: CerGridComponent) {
    if (content && !this.salesOrderGrid) {
      this.salesOrderGrid = content;
      this.subscriptionManager.add(this.salesOrderGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private custGrid: CerGridComponent;
  @ViewChild('custGrid', { static: false }) set custGridContent(content: CerGridComponent) {
    if (content && !this.custGrid) {
      this.custGrid = content;
      this.subscriptionManager.add(this.custGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private refDataGrid: CerGridComponent;
  @ViewChild('refDataGrid', { static: false }) set refDataGridContent(content: CerGridComponent) {
    if (content && !this.refDataGrid) {
      this.refDataGrid = content;
      this.subscriptionManager.add(this.refDataGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private refDataNemHandelGrid: CerGridComponent;
  @ViewChild('refDataNemHandelGrid', { static: false }) set refDataNemHandelGridContent(content: CerGridComponent) {
    if (content && !this.refDataNemHandelGrid) {
      this.refDataNemHandelGrid = content;
      this.subscriptionManager.add(this.refDataNemHandelGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private custCreateGrid: CerGridComponent;
  @ViewChild('custCreateGrid', { static: false }) set custCreateGridGridContent(content: CerGridComponent) {
    if (content && !this.custCreateGrid) {
      this.custCreateGrid = content;
    }
  }

  // Order view
  protected salesOrderViewMetadata: ViewMetadata;
  protected salesOrderFieldMetadata: FieldMetadata[] =
    [
      { name: 'num', text: 'Ordrenr.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S, orderBy: 'Descending', orderByPriority: 3 },
      { name: 'status', text: 'Status', tooltipText: 'Ordrestatus', foreignKeyTableName: 'SalesOrderStatus', foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'], orderBy: 'Ascending', orderByPriority: 1 },
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'paymentType', text: 'Betalingstype', tooltipText: 'Afregningstype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesPaymentType', foreignKeyField: 'num' },
      { name: 'custNum', text: 'Kundenr.', tooltipText: 'Kundenr.', widthType: FieldWidthEnum.M },
      { name: 'custEAN', text: 'EAN nr.', widthType: FieldWidthEnum.M },
      { name: 'custVATNum', text: 'CVR nr.', tooltipText: 'Kundens CVR nummer', widthType: FieldWidthEnum.M },
      { name: 'custVATExempt', text: 'Momsfri', tooltipText: 'Er ordren momsfri?', format: 'CheckBox', visible: false },
      { name: 'billFirstName', text: 'Fak. fornavn', tooltipText: 'Faktura fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'billLastName', text: 'Fak. efternavn', tooltipText: 'Faktura efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'billCompanyName', text: 'Fak. firmanavn', tooltipText: 'Faktura firmanavn', widthType: FieldWidthEnum.XL },
      { name: 'custNote', text: 'Notat kunde', tooltipText: 'Kundens notat', format: 'TextArea', widthType: FieldWidthEnum.L },
      { name: 'custEmail', text: 'E-mail', tooltipText: 'Kunde e-mail', format: 'Email' },
      { name: 'custPhone', text: 'Telefon', tooltipText: 'Kunde telefon', format: 'Phone' },
      { name: 'orderDate', text: 'Ordredato', format: 'DateShort', orderBy: 'Descending', orderByPriority: 2 },
      { name: 'orderChangeDate', text: 'Ordre ændring', format: 'DateShort', visible: false },
      { name: 'channel', text: 'Salgskanal', tooltipText: 'Kanal', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'SalesChannel', foreignKeyField: 'num' },
      { name: 'channelOrder', text: 'Ordre (kanal)', tooltipText: 'Kanalordre', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'reservedDate', text: 'Reserveret', format: 'DateShort', visible: false },
      { name: 'shipDate', text: 'Leveret', format: 'DateShort', visible: false },
      { name: 'invoiceDate', text: 'Faktureret', format: 'DateShort', visible: false },
      { name: 'invoiceNum', text: 'Faktura', format: 'DateShort', visible: false },
      { name: 'captureDate', text: 'Indløst', format: 'DateShort', visible: false },
      { name: 'settleDate', text: 'Udlignet', format: 'DateShort', visible: false },
      { name: 'paymentReference', text: 'Indløser ref.', tooltipText: 'Betalingsindløser ref.', allowEdit: true, widthType: FieldWidthEnum.S },
      { name: 'type', text: 'Type', tooltipText: 'Ordretype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesOrderType', foreignKeyField: 'num' },
      { name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.XXXS },
      { name: 'amountCurExTax', text: 'Fakturabeløb u. moms', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'amountCurTax', text: 'Momsbeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M, visible: false },
      { name: 'amountCur', text: 'Fakturabeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'billAddress1', text: 'Fak. adresse 1', tooltipText: 'Faktura adresse 1', widthType: FieldWidthEnum.L },
      { name: 'billAddress2', text: 'Fak. adresse 2', tooltipText: 'Faktura adresse 2', widthType: FieldWidthEnum.L },
      { name: 'billPostalCode', text: 'Fak. postnr.', tooltipText: 'Faktura postnr.', widthType: FieldWidthEnum.S },
      { name: 'billPostalDistrict', text: 'Fak. by', tooltipText: 'Faktura by', widthType: FieldWidthEnum.L },
      { name: 'billCountryCode', text: 'Fak. land', tooltipText: 'Faktura landekode', widthType: FieldWidthEnum.S },
      { name: 'carrier', text: 'Transportør', foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num' },
      { name: 'carrierServicePoint', text: 'Pakkeshop', foreignKeyTableName: 'WarehouseCarrierServicePoint', foreignKeyField: 'keyCode', foreignKeyColumns: ['keyCountryCode', 'keyType', 'key'], visible: false, visibleEdit: true },
      { name: 'shipToInvoiceAddress', text: 'Lev. fak.adresse', tooltipText: 'Levering til fakturaadresse', format: 'CheckBox' },
      { name: 'shipFirstName', text: 'Lev. fornavn', tooltipText: 'Levering fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipLastName', text: 'Lev. efternavn', tooltipText: 'Levering efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCompanyName', text: 'Lev. firmanavn', tooltipText: 'Leveringfirmanavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'sourceUTM', text: 'Kampagne', visible: false },
      { name: 'sourceDevice', text: 'Device', visible: false },
      { name: 'createdJobId', text: 'Job oprettet', format: 'Integer', visible: false },
      { name: 'modifiedJobId', text: 'Job rettet', format: 'Integer', visible: false }
    ];
  protected salesOrderFieldGroupMetadata: FieldGroupMetadata[];
  protected salesOrderToolbarCommands: UiCommand[] = [uiCmdSearchName, uiCmdSearchVATNum, uiCmdSearchEAN];
  protected salesOrderContextMenuCommands: UiCommand[] = [uiCmdSearchName, uiCmdSearchVATNum, uiCmdSearchEAN];

  protected custViewMetadata: ViewMetadata = {
    name: 'kunder', dataApiName: 'Debtor', text: 'Kunder', textSingular: 'Kunde', allowEdit: false, allowCreate: false, allowDelete: false,
    primaryKey: 'num', titleFields: ['name'], baseFields: ['id', 'num', 'name']
  };
  public custFieldMetadata: FieldMetadata[] = [
    { name: 'num', text: 'Nr.', tooltipText: 'Part Id', widthType: FieldWidthEnum.M, orderBy: 'Descending' },
    { name: 'name', text: 'Navn', tooltipText: 'Navn på part', widthType: FieldWidthEnum.L },
    { name: 'participantId', text: 'EAN', tooltipText: 'Elektronisk fakturering netværk id / EAN', widthType: FieldWidthEnum.M },
    { name: 'taxId', text: 'CVR', tooltipText: 'CVR nr.', widthType: FieldWidthEnum.S },
    { name: 'attention', text: 'Attention', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'address1', text: 'Adresse 1', tooltipText: 'Adresselinje 1', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'address2', text: 'Adresse 2', tooltipText: 'Adresselinje 2', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'address3', text: 'Adresse 3', tooltipText: 'Adresselinje 3', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'country', text: 'Land', tooltipText: 'Land', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'phone', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'group', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'email', text: 'E-mail', tooltipText: 'E-mail adresse', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true, format: 'Email' },
//    { name: 'language', text: 'Sprog', tooltipText: 'Sprogkode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'ValutaKoder', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
//    { name: 'currency', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'Language', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'paymentTerm', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 1 },
    { name: 'taxCode', text: 'Momskode', tooltipText: 'Findes der en momsregistrering', format: 'CheckBox', visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'blocked', text: 'Spærret', tooltipText: 'Er konto spærret', widthType: FieldWidthEnum.XXS, format: 'CheckBox', filterOperator: 'notequal', filterValue: true, filterOnClient: true, visibleEdit: true, visibleAdd: true },
    { name: 'source', text: 'Datakilde', tooltipText: 'Hvilken database kommer data fra?', /*format: 'TextArea',*/ visible: false }
    ];
  protected custToolbarCommands: UiCommand[];
  protected custContextMenuCommands: UiCommand[] = [uiCmdSearchName, uiCmdSearchVATNum, uiCmdSearchEAN, uiCmdSeparator, uiCmdSelectForCustomer];

  protected refDataNemHandelViewMetadata: ViewMetadata = {
    name: 'ref-data-nem-handels-register', dataApiName: 'RefData_NemHandelsRegister', text: 'NemHandelsregister',
    primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false
  };
  protected refDataNemHandelFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Nøgle', format: 'Text', visible: false, widthType: FieldWidthEnum.M },
    { name: 'keyType', text: 'Addressetype', format: 'Text', widthType: FieldWidthEnum.XXS },
    { name: 'key', text: 'GLN/CVR Adresse', format: 'Text', widthType: FieldWidthEnum.XS },
    { name: 'unitName', text: 'Navn', format: 'Text', widthType: FieldWidthEnum.XXXL },
    { name: 'unitCVR', text: 'CVR nr.', format: 'Text', widthType: FieldWidthEnum.XXS }
  ];
  protected refDataNemHandelToolbarCommands: UiCommand[] = [uiCmdSearchVATNum];
  protected refDataNemHandelContextMenuCommands: UiCommand[] = [uiCmdSearchName, uiCmdSearchVATNum, uiCmdSearchEAN, uiCmdSeparator, uiCmdSelectForCustomer];;

  protected refDataViewMetadata: ViewMetadata = {
    name: 'ref-data', dataApiUrl: '/api/PartyExternalRef', text: 'CVR register',
    primaryKey: 'externalRefId', allowEdit: false, allowCreate: false, allowDelete: false
  };
  protected refDataFieldMetadata: FieldMetadata[] = [
    { name: 'externalRefId', text: 'CVR', tooltipText: 'CVR nummer', widthType: FieldWidthEnum.M },
    { name: 'externalRefMatchScore', text: 'Match', orderBy: 'Descending', visible: false},
    { name: 'name', text: 'Navn', tooltipText: 'Navn på part', widthType: FieldWidthEnum.L },
    { name: 'isCompany', text: 'Selskab?', tooltipText: 'Er dette et selskab?', widthType: FieldWidthEnum.XXS, format: 'CheckBox', visible: false },
    { name: 'coAddress', text: 'C/O Adresse', tooltipText: 'C/O Adresselinje' },
    { name: 'address1', text: 'Adresse 1', tooltipText: 'Adresselinje 1' },
    { name: 'address2', text: 'Adresse 2', tooltipText: 'Adresselinje 2' },
    { name: 'address3', text: 'Adresse 3', tooltipText: 'Adresselinje 3' },
    { name: 'zipCode', text: 'Postnr', widthType: FieldWidthEnum.S, visible: false },
    { name: 'city', text: 'By', widthType: FieldWidthEnum.M, visible: false },
    { name: 'zipCodeCity', text: 'Postnr/By', widthType: FieldWidthEnum.M},
    { name: 'countryCode', text: 'LandeKode', widthType: FieldWidthEnum.M},
    { name: 'countryName', text: 'Land', tooltipText: 'Land', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'phone', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'email', text: 'E-mail', tooltipText: 'E-mail adresse', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true, format: 'Email' },
    { name: 'language', text: 'Sprog', tooltipText: 'Sprogkode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'ValutaKoder', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'currency', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'Language', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'nace', text: 'Nacekode', visible: false },
    { name: 'naceName', text: 'Nace', visible: false },
    { name: 'status', text: 'Status'},
    { name: 'active', text: 'Aktiv', tooltipText: 'Er selskab aktivt?', widthType: FieldWidthEnum.XXS, format: 'CheckBox' }
    ];
  protected refDataToolbarCommands: UiCommand[] = [];
  protected refDataContextMenuCommands: UiCommand[] = [uiCmdSearchName, uiCmdSearchVATNum, uiCmdSeparator, uiCmdSelectForCustomer];

  protected custCreateViewMetadata: ViewMetadata;
  protected custCreateFieldMetadata: FieldMetadata[];
  protected custCreateToolbarCommands: UiCommand[];
  protected custCreateContextMenuCommands: UiCommand[];

  private isNew: boolean = false;

  private subscriptionManager: Subscription = new Subscription();

  constructor(
    private salesOrderService: SalesOrderService,
    private routeService: CerAppRouteService,
    private client: SalesOrderClient,
    private dialog: CerDialogService,
    private ui: CerAppDialogService) {

    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private setupFromRouteData(routeData: any) {
    if (routeData?.params) {
      var params = routeData.params;
      this.salesOrderViewMetadata.dataApiParams = params;
    }
    this.initSales();
  }

  private initSales() {
    var svc: SalesOrderService = this.salesOrderService;
    this.salesOrderViewMetadata = svc.salesOrderViewMetadata;
    //this.salesOrderFieldMetadata = svc.salesOrderFieldMetadata;
    this.salesOrderFieldGroupMetadata = svc.salesOrderFieldGroupMetadata;
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdSyncSalesOrders.id:
        this.syncSalesOrders();
        break;
      case uiCmdSyncSalesOrderSelected.id:
        this.syncSalesOrder(event);
        break;
      case uiCmdSearchName.id:
        this.searchSourcesByNameFromEvent(event);
        break;
      case uiCmdSearchEAN.id:
        this.searchSourcesByEANFromEvent(event);
        break;
      case uiCmdSearchVATNum.id:
        this.searchSourcesByVATNumFromEvent(event);
        break;

    }
  }

  private searchSourcesByNameFromEvent(event: UiCommandEvent) {
    var dto = this.getRowFromEvent(event);
    if (dto != null) {
      var s : string = null;
      if (event.appComponent == this.salesOrderGrid) {
        this.searchSources(dto.billCompanyName,true,true,true);
      }
      else if (event.appComponent == this.custGrid) {
        this.searchSources(dto.name,false,true,true);
      }
      else if (event.appComponent == this.refDataNemHandelGrid) {
        this.searchSources(dto.unitName, true, false, true);
        s = dto.name;
      }
      else if (event.appComponent == this.refDataGrid) {
        this.searchSources(dto.address1, true, true, false);
        s = dto.name;
      }
    }
  }

  private searchSourcesByVATNumFromEvent(event: UiCommandEvent) {
    var dto = this.getRowFromEvent(event);
    if (dto != null) {
      var s : string = null;
      if (event.appComponent == this.salesOrderGrid) {
        this.searchSources(dto.custVATNum,true,true,true);
      }
      else if (event.appComponent == this.custGrid) {
        this.searchSources(dto.taxNum,false,true,true);
      }
      else if (event.appComponent == this.refDataNemHandelGrid) {
        this.searchSources(dto.unitCVR, true, false, true);
        s = dto.name;
      }
      else if (event.appComponent == this.refDataGrid) {
        this.searchSources(dto.externalRefId.toString(), true, true, false);
        s = dto.name;
      }
    }
  }

  private searchSourcesByEANFromEvent(event: UiCommandEvent) {

    var dto = this.getRowFromEvent(event);
    if (dto != null) {
      var s : string = null;
      if (event.appComponent == this.salesOrderGrid) {
        this.searchSources(dto.custEAN, true, true, true);
      }
      else if (event.appComponent == this.custGrid) {
        this.searchSources(dto.participantId, false, true, true);
      }
      else if (event.appComponent == this.refDataNemHandelGrid) {
        this.searchSources(dto.key, true, false, true);
        s = dto.name;
      }
    }
  }

  private searchSources(searchString: any, searchCust: boolean, searchNemHandel: boolean,  searchRefData: boolean) {
    if (searchString?.length > 2) {
      if (searchCust) {
        this.custGrid.grid.search(searchString);
      }
      if (searchNemHandel) {
        this.refDataNemHandelGrid.grid.search(searchString);
      }
      if (searchRefData) {
        this.refDataGrid.grid.search(searchString);
      }
    }
    else {
      this.custGrid.grid.search(null);
      this.refDataNemHandelGrid.grid.search(null);
    }
  }

  private getRowFromEvent(event: UiCommandEvent): any {
    return event?.rowData;
  }

  private getOrderNumFromEvent(event: UiCommandEvent): string {
    return event?.rowData?.num;
  }

  // Sync
  private syncSalesOrder(event: UiCommandEvent = null) {
    var salesOrderNum = this.getOrderNumFromEvent(event);
    if (salesOrderNum != null) {
      this.syncSalesOrders(salesOrderNum);
    }
  }

  private syncSalesOrders(salesOrderNum: string = null) {
    var snackBarRef = this.dialog.snackBar('Henter nye ordrer fra salgskanaler', 'Vent venligst...', 10000);
    this.routeService.isLoading$.next(true);
    (salesOrderNum != null ? this.client.syncOrder(salesOrderNum) : this.client.syncOrders())
      .subscribe({
        next: result => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.salesOrderGrid.research();
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.ui.handleApiStatusMessage(error);
        }
      });
  }
}
