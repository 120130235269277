import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';

import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CerAppMetaService } from 'src/cer-app/cer-app-meta/cer-app-meta.service';
import { CerAppMetaUserService, CerAppMetaUserRoute} from 'src/cer-app/cer-app-meta/cer-app-meta-user.service';
import { CerSessionService } from 'src/cer-platform/cer-session/cer-session.service';
import { CerMetaUserView, ICerViewPersistenceService } from 'src/cer/cer-form/cer-form-persistence.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

export class RouteCount {
  public url: string;
  public nodeId: string;
  public count: number;
}

@Injectable({
  providedIn: 'root'
})
export class CerAppRouteService implements OnDestroy {
  private subscriptionManager$: Subscription = new Subscription();

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeUserRoute$: BehaviorSubject<CerAppMetaUserRoute> = new BehaviorSubject<CerAppMetaUserRoute>(null);
  public activeView$: BehaviorSubject<CerMetaUserView> = new BehaviorSubject<CerMetaUserView>(null);
  public activeViewPersistenceService$: BehaviorSubject<ICerViewPersistenceService> = new BehaviorSubject<ICerViewPersistenceService>(null);
  public routeTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public routeData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public routeCounts$: BehaviorSubject<RouteCount[]> = new BehaviorSubject<RouteCount[]>(null);
  public url$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private url: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private sessionService: CerSessionService,
    private metaService: CerAppMetaService, private metaUserService: CerAppMetaUserService,
    private authorizeService: AuthorizeService) {

    // Navigate start
    this.subscriptionManager$.add(
      this.router.events.pipe(
        // identify navigation start
        filter((event) => event instanceof NavigationStart),
        // now query the activated route
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
        .subscribe((route: ActivatedRoute) => {
          this.navigateStart();
        })
    );

    // Navigate end
    this.subscriptionManager$.add(
      this.router.events.pipe(
        // identify navigation end
        filter((event) => event instanceof NavigationEnd),
        // now query the activated route
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
        .subscribe((route: ActivatedRoute) => {
          this.url$.next(route.snapshot.url.join('/'));
        })
    );

    this.subscriptionManager$.add(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        // now query the activated route
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
      .subscribe((route) => {
        route.data.forEach((data: any) => {
        this.routeData$.next(data);
        this.routeTitle$.next(data.title);
      })})
    );

    this.subscriptionManager$.add(
      combineLatest([this.metaService.title$, this.routeTitle$]).subscribe(
        ([title, routeTitle]) => this.setPageTitle(title, routeTitle))
    );

    this.subscriptionManager$.add(
      combineLatest([this.metaUserService.settings$, this.url$]).subscribe(([settings, url]) => {
        this.url = url;
        if (url !== null) {
          this.activeUserRoute$.next(this.metaUserService.getRouteOrDefault(url));
        }
      })
    );

    this.subscriptionManager$.add(
      this.activeUserRoute$.subscribe(userRoute => {
        var view = this.metaUserService.getView(userRoute, userRoute.defaultView);
        this.activeView$.next(view);
      })
    )

    this.subscriptionManager$.add(
      this.sessionService.state$.subscribe(state =>
        this.sessionStateSubscribe(state))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  public navigateStart() {
    this.isLoading$.next(true);
  }

  public reloadUrl() {
    this.redirectToUrl(this.url);
  }

  public reSignin() {
    var location: any = window.location;
    this.authorizeService.signOut(location);
  }

  public reloadForcedUrl() {
    var location: any = window.location;
    location.reload(true);
  }

  public redirectToUrl(url: string, force: boolean = true) {
    if (force || url != this.url) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([url]));
    }
  }

  public setPageTitle(appTitle: string, routerTitle: string) {
    // Combine routerTitle and appTitle in browser tab caption
    var pageTitle = '';
    if (appTitle && appTitle.length > 0) {
      pageTitle = appTitle;
    }
    if (routerTitle && routerTitle.length > 0) {
      pageTitle = pageTitle.length > 0 ? pageTitle + ' - ' + routerTitle : routerTitle;
    }
    this.titleService.setTitle(pageTitle);
  }

  private sessionStateSubscribe(state: string): void {
    if (state == 'expired') {
      this.reSignin();
    }
  }

  public reloadViews() {
    if (this.url !== null) {
      this.metaUserService.userRouteLoad$.next(this.url);
    }
  }

  public getView(viewName: string): CerMetaUserView {
    var view = this.metaUserService.getRouterView(this.url, viewName);
    return view;
  }

  public setView(viewName: string, isPrimary: boolean, isDefault: boolean = null) {
    if (viewName) {
      var route = this.metaUserService.getRouteOrDefault(this.url, true);
      var viewPersistenceData: string = this.getPersistenceView();
      if (viewPersistenceData) {
        var view = this.metaUserService.setView(route, viewName, isPrimary, isDefault, viewPersistenceData);
        this.activeUserRoute$.next(route);
        this.activeView$.next(view);
      }
    }
  }

  public getPersistenceView(): string {
    var persistenceView: string = null;
    var service : ICerViewPersistenceService = this.activeViewPersistenceService$.getValue();
    if (service) {
      persistenceView = service.getPersistenceView();
    }
    return persistenceView;
  }
}
