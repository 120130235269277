import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class WarehouseOutboundBackOrderOnHandStatusFormAdapter extends CerAppFormModelAdapter {

  private updatedDateCmd: UiCommand = { id: 'updatedDate', text: 'Opdateret: ', disabled: true, align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Dato hvor data er opdateret', target: ".e-content", visible: true };
  private onlySales: UiCommand = { id: 'updatedDate', text: 'Opdateret: ', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Dato hvor data er opdateret', target: ".e-content", visible: true };
  
  override init() {

    this.toolbarCommands = [this.updatedDateCmd];

    this.viewMetadata = {
      name: 'lager-restordrer', dataApiName: 'Warehouse_OutboundBackOrderOnHandProductStatus', text: 'Restordrer', textSingular: 'Restordre',
      primaryKey: 'warehouseId', allowEdit: false, allowCreate: false, allowDelete: false
    };

    this.fieldMetadata = [
      { name: 'outboundOrderNum', text: 'Forsendelse', orderBy: 'Descending', orderByPriority: 1, visible: true },
      { name: 'pickOrderNum', text: 'Lager ordre', orderBy: 'Ascending', orderByPriority: 2, visible: false },
      { name: 'warehouseCounterpart', text: 'Lager kundenr.', visible: false },
      { name: 'purchOrderNum', text: 'Ordrenr', visible: false },
      { name: 'custReference', text: 'Reference', visible: false },
      { name: 'requested', text: 'Ønsket dato', format: 'DateShort' },
      { name: 'product', text: 'ISBN' },
      { name: 'name', text: 'Titel', width: '150px' },
      { name: 'name2', text: 'Undertitel', visible: false },
      { name: 'seriesId', text: 'Serie', visible: false },
      { name: 'bookstoreClassification', text: 'Varegruppe', format: 'Integer', visible: false },
      { name: 'categories', text: 'Kategorier', visible: false },
      { name: 'warehouseItemNum', text: 'Lager varenr.', visible: false },
      { name: 'orderedQty', text: 'Bestilt antal', format: 'Number' },
      { name: 'remainQty', text: 'Ej leveret antal', format: 'Number' },
      { name: 'reservedPhysicalQty', text: 'Reserveret antal', format: 'Number' },
      { name: 'unreservedQty', text: 'Ej reserveret antal', format: 'Number', onQueryCellInfo: this.gridQueryCellInfo },
      { name: 'availableQty', text: 'Lager ej reserveret', format: 'Number', onQueryCellInfo: this.gridQueryCellInfo },
      { name: 'reservedQty', text: 'Lgaer reserveret', format: 'Number', visible: false },
      { name: 'physicalQty', text: 'Lager fysisk', format: 'Number', },
      { name: 'orderNumAlt', text: 'Ordrenr. alternativ', visible: false },
      { name: 'updated', text: 'Opdateret', format: 'DateShort', visible: false },
      { name: 'created', text: 'Rest opdateret', format: 'DateShort', visible: false },
      { name: 'released', text: 'Udgivet dato', format: 'DateShort' },
      { name: 'preorder', text: 'Forudbestilling', format: 'CheckBox', width: '60px' },
      { name: 'published', text: 'Udgivet', format: 'CheckBox', visible: false },
      { name: 'buyable', text: 'Køb mulig', format: 'CheckBox' },
      { name: 'shreddedYear', text: 'Markuleret', format: 'Integer', visible: false },
      { name: 'forceVisible', text: 'Tving synlig', format: 'CheckBox', visible: false },
      { name: 'forceBuyable', text: 'Tving kan købes', format: 'CheckBox', visible: false },
      { name: 'publishedDate', text: 'Udgivet bogportal', format: 'DateShort', visible: false },
      { name: 'printPublishedDate', text: 'Udgivet bogportal print', format: 'DateShort', visible: false },
      { name: 'availableForPurchaseDate', text: 'Tilgængelig bogportal køb', format: 'DateShort', visible: false },
      { name: 'statusCode', text: 'Status', visible: false },
      { name: 'onHandUpdated', text: 'Lager opdateret ', format: 'DateShort', visible: false },
      { name: 'approximateInventoryNumber', text: 'Ca. lager', format: 'TextRight', visible: false },
      { name: 'minimumInventory', text: 'Min. lager', format: 'TextRight', visible: false },
      { name: 'apologyCode', text: 'Undskyldningskode', format: 'Integer', widthType: FieldWidthEnum.XS, onQueryCellInfo: this.gridQueryCellInfo }, 
      { name: 'exchangePool', text: 'Ombytning', visible: false },
      { name: 'salesPrice', text: 'Salgspris', format: 'Integer', visible: false },
      { name: 'modifiedJobId', text: 'Rest opdateret job', format: 'Number', visible: false }
    ];

    this.fieldGroupMetadata = [
      { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'name', 'warehouse'] },
      { idx: 2, tabIdx: 0, name: 'setup', text: 'Opsætning', fields: ['orderCreate', 'requireCustomerKnown', 'requirePublished', 'requireBuyable', 'requireOnHand'] },
      { idx: 2, tabIdx: 0, name: 'bom', text: 'Bundles', fields: ['bomConsolidate', 'bomExpand', 'bomConsolidateRequireOnHand', 'bomLineExpandWhenBOMOnHand'] },
      { idx: 4, tabIdx: 0, name: 'ext', text: 'Eksternt', fields: ['extOrderType', 'extOrderGroup', 'extHandlingType', 'extCarrierType', 'extPriority'] },
      { idx: 5, tabIdx: 0, name: 'forms', text: 'Formular', fields: ['extPrintDeliveyNote', 'extPrintInvoice', 'extPrintFooter'] }
    ];

    if (this.routeData?.hasDetails) {
      this.detailViewMetadata = Object.assign({}, this.viewMetadata);
      this.detailViewMetadata.parentPrimaryKey = 'pickOrderNum';
      this.detailViewMetadata.parentForeignKey = 'pickOrderNum';

      this.viewMetadata.dataApiName = 'Warehouse_OutboundBackOrderStatus';
      this.viewMetadata.primaryKey = 'pickOrderNum';

      this.detailFieldMetadata = [];
      this.fieldMetadata.forEach(f => this.detailFieldMetadata.push(Object.assign({}, f)));

      var bothFields = ['orderedQty',  'remainQty', 'reservedPhysicalQty', 'unreservedQty'];
      var detailOnlyFields = ['id','warehouseId','released', 'published', 'buyable', 'shreddedYear', 'forceVisible', 'forceBuyable', 'publishedDate', 'printPublishedDate', 'availableForPurchaseDate',
        'statusCode', 'product', 'name', 'name2', 'seriesId', 'bookstoreClassification', 'categories', 'warehouseItemNum',
        'availableQty', 'reservedQty', 'physicalQty', 'orderNumAlt', 'created', 'modifiedJobId', 'onHandUpdated', 'approximateInventoryNumber', 'minimumInventory', 'apologyCode',
        'exchangePool', 'salesPrice'];
      this.fieldMetadata = this.fieldMetadata.filter(f => bothFields.includes(f.name) || !detailOnlyFields.includes(f.name));
      this.detailFieldMetadata.filter(f => !bothFields.includes(f.name) && !detailOnlyFields.includes(f.name)).forEach(f => f.visible = false);
      this.detailFieldMetadata.filter(f => f.name == 'unreservedQty').forEach(f => { f.orderBy = 'Descending'; f.orderByPriority = 1; });
      this.detailViewMetadata.text = 'Linjer';
    }
    else {
      this.viewMetadata.text = 'Restordrelinjer';
    }

    if (this.routeParams) {
      //this.viewMetadata.dataApiParams = this.routeParams;
    }

    this.detailPaneVertical = true;
    this.detailPaneSizePct = 25;
  }

  override onCommand(event: UiCommandEvent) {
    if (event.actionType == UiActionTypeEnum.DataBound) {
      console.log('DataLoad', event);
      var data: any[] = event.args?.result;
      if (event.appComponent && data && data.length > 0) {
        this.setupUpdatedDate(<CerGridComponent>event.appComponent, new Date(data[0].updated));
      }
    }
  }

  private setupUpdatedDate(grid: CerGridComponent, updatedDate: Date) {
    var text: string = 'Opdateret: ' + updatedDate.toLocaleDateString();
    var item: HTMLDivElement = grid.commandService.toolbarElementGet(this.updatedDateCmd.tooltipText);
    if (item) {
        var buttons : HTMLCollection = item.getElementsByTagName('button')
        if (buttons && buttons.length > 0) {
          var button: HTMLButtonElement = <HTMLButtonElement> buttons[0];
          button.title = text;
          var txts = button.getElementsByClassName('e-tbar-btn-text');
          if (txts && txts.length > 0) {
            (<HTMLSpanElement> txts[0]).innerText = text;
          }
      }
    }
  }

  
  public gridQueryCellInfo(args: any) {
    var dto: any = args.data;
   
    if ((dto.unreservedQty != 0)) {
      if (dto.preorder) {
        args.cell.style.backgroundColor = '#CCCCFF';
      }
      else {
        args.cell.style.backgroundColor = '#FFBBBB';
      }
    }
  }
}

