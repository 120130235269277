import { Component, ViewChild } from '@angular/core';
import { FieldGroupMetadata, FieldMetadata, KeyValue, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiCommand, UiCommandEvent } from '../../cer/cer-grid/cer-grid-command.service';

// WebApi
import { SalesOrderClient } from "../api";
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerDataNoteService } from 'src/cer-app/cer-data-data/cer-data-note.service';
import { Subscription } from 'rxjs';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

import { WarehouseOutboundOrderService } from '../warehousing/warehouse-outbound-order.service';
import { SalesOrderService, uiCmdOrderSyncAll, uiCmdOrderSyncSelected } from './sales-order.service';

@Component({
  selector: 'sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['../component.css'],
  providers: [SalesOrderService,WarehouseOutboundOrderService]
})

export class SalesOrderComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected showWarehouse: boolean = false;

  protected panesSales: CerFormPanesSettings = {
    top: { visible: 'show', size: '60%' },
    main: { visible: 'show', size: '40%', sizeSecondary: '80%' },
    right: { visible: 'show', size: '20%' },
    orientationPrimary: 'vertical'
  };

  protected panesSalesWarehouse: CerFormPanesSettings = {
    top: { visible: 'show', size: '50%' },
    left: { visible: 'show', size: '30%' },
    main: { visible: 'show', size: '25%', sizeSecondary: '50%' },
    right: { visible: 'show', size: '20%' },
    bottom: { visible: 'show', size: '25%' },
    orientationPrimary: 'vertical'
  };


  tableGrid: CerGridComponent;
  @ViewChild('tableGrid', { static: false }) set tableGridContent(content: CerGridComponent) {
    if (content && !this.tableGrid) {
      this.tableGrid = content;
      this.tableGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  lineGrid: CerGridComponent;
  @ViewChild('lineGrid', { static: false }) set lineGridContent(content: CerGridComponent) {
    if (content && !this.lineGrid) {
      this.lineGrid = content;
    }
  }

  outboundGrid: CerGridComponent;
  @ViewChild('outboundGrid', { static: false }) set outboundGridContent(content: CerGridComponent) {
    if (content && !this.outboundGrid) {
      this.outboundGrid = content;
      this.outboundOrderService.setWarehouseOutboundOrderGrid(this.outboundGrid);
    }
  }

  outboundLineGrid: CerGridComponent;
  @ViewChild('outboundLineGrid', { static: false }) set outboundLineGridContent(content: CerGridComponent) {
    if (content && !this.outboundLineGrid) {
      this.outboundLineGrid = content;
    }
  }

  // Order view
  protected tableViewMetadata: ViewMetadata;
  protected tableFieldMetadata: FieldMetadata[];
  protected tableFieldGroupMetadata: FieldGroupMetadata[];
  public tableToolbarCommands: UiCommand[];
  public tableContextMenuCommands: UiCommand[];

  // Line 
  protected lineViewMetadata: ViewMetadata; 
  protected lineFieldMetadata: FieldMetadata[];

  protected outboundViewMetadata: ViewMetadata;
  protected outboundFieldMetadata: FieldMetadata[];
  protected outboundFieldGroupMetadata: FieldGroupMetadata[];
  protected outboundToolbarCommands: UiCommand[];
  protected outboundContextMenuCommands: UiCommand[];
  protected outboundLineViewMetadata: ViewMetadata;
  protected outboundLineFieldMetadata: FieldMetadata[];
  protected dataNoteViewMetadata: ViewMetadata;
  protected dataNoteFieldMetadata: FieldMetadata[];

  private isNew: boolean = false;
  private isPlanning: boolean = false;
  private isRemain: boolean = false;
  private isDelivered: boolean = false;
  private isCaptureReady: boolean = false;
  private isCaptured: boolean = false;
  private isInvoiceCreditReady: boolean = false;
  private isInvoiced: boolean = false;
  private isCompleted: boolean = false;
  private isCancelled: boolean = false;

  private subscriptionManager: Subscription = new Subscription();

  constructor(
    private salesOrderService: SalesOrderService,
    private outboundOrderService: WarehouseOutboundOrderService,
    private dataNoteService: CerDataNoteService,
    private routeService: CerAppRouteService,
    private client: SalesOrderClient,
    private dialog: CerDialogService,
    private ui: CerAppDialogService) {

    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private setupFromRouteData(routeData: any) {
    if (routeData?.params) {
      var params = routeData.params;
      this.tableViewMetadata.dataApiParams = params;
      this.isNew = params.includes("$statusGroup=New");
      this.isPlanning = params.includes("$statusGroup=Planning");
      this.isRemain = params.includes("$statusGroup=Remain");
      this.isDelivered = params.includes("$statusGroup=Delivered");
      this.isCaptureReady  = params.includes("$statusGroup=CaptureReady");
      this.isInvoiceCreditReady = params.includes("$statusGroup=InvoiceCreditReady");
      this.isCaptured  = params.includes("$statusGroup=Captured");
      this.isInvoiced  = params.includes("$statusGroup=Invoiced");
      this.isCompleted = params.includes("$statusGroup=Completed");
      this.isCancelled = params.includes("$statusGroup=Cancelled");
    }
    this.showWarehouse = !this.isNew;
    this.initSales();
    this.initOutbound();
    var dataNoteDataApiParams: KeyValue[] = [
      { key: '$dataLinkTable', value: 'SalesOrder' },
      { key: '$dataLink2Table', value: 'WarehouseOutboundOrder' }
    ];
    this.dataNoteViewMetadata = this.dataNoteService.viewMetadataGet(dataNoteDataApiParams);
    this.dataNoteFieldMetadata = this.dataNoteService.fieldMetadata;
  }

  private initSales() {
      var svc: SalesOrderService = this.salesOrderService;
      this.tableViewMetadata = svc.salesOrderViewMetadata;
      this.tableFieldMetadata = [...svc.salesOrderFieldMetadata];
      if (this.isCaptureReady || this.isCaptured) {
        this.tableFieldMetadata.filter(f => { f.name == 'custVATNum' || f.name == 'custEAN'}).forEach(f => f.visible = false);
      }
      if (this.isInvoiceCreditReady) {
        this.tableFieldMetadata.filter(f => f.name == 'paymentReference').forEach(f => f.visible = false);
      }
      this.tableFieldGroupMetadata = svc.salesOrderFieldGroupMetadata;
      this.tableToolbarCommands = svc.orderToolbarCommands;
      this.tableContextMenuCommands = svc.orderContextMenuCommands;
      this.lineViewMetadata = svc.salesLineViewMetadata;
      this.lineFieldMetadata = svc.salesLineFieldMetadata;
  }

  private initOutbound() {
    if (this.showWarehouse) {
      var svc: WarehouseOutboundOrderService = this.outboundOrderService;
      this.outboundViewMetadata = svc.orderViewMetadata;
      this.outboundFieldMetadata = svc.orderFieldMetadata;
      this.outboundFieldGroupMetadata = svc.orderFieldGroupMetadata;
      this.outboundToolbarCommands = svc.orderToolbarCommands;
      this.outboundContextMenuCommands = svc.orderContextMenuCommands;
      this.outboundLineViewMetadata = svc.orderLineViewMetadata;
      this.outboundLineFieldMetadata = svc.orderLineFieldMetadata;

    }
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case uiCmdOrderSyncAll.id:
        this.syncSalesOrders();
        break;
      case uiCmdOrderSyncSelected.id:
        this.syncSalesOrder(event);
        break;
    }
  }

  private getOrderNumFromEvent(event: UiCommandEvent): string {
    return event?.rowData?.num;
  }

  // Sync
  private syncSalesOrder(event: UiCommandEvent = null) {
    var salesOrderNum = this.getOrderNumFromEvent(event);
    if (salesOrderNum != null) {
      this.syncSalesOrders(salesOrderNum);
    }
  }

  private syncSalesOrders(salesOrderNum: string = null) {
    var snackBarRef = this.dialog.snackBar('Henter nye ordrer fra salgskanaler', 'Vent venligst...', 10000);
    this.routeService.isLoading$.next(true);
    (salesOrderNum != null ? this.client.syncOrder(salesOrderNum) : this.client.syncOrders())
      .subscribe({
        next: result => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.tableGrid.research();
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.ui.handleApiStatusMessage(error);
        }
      });
  }
}
