import { Component, ViewChild } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';

import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, uiCmdSeparator, UiCommand, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut } from '../../cer/cer-grid/cer-grid-command.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';

// WebApi
import { DebtorClient, DebtorDto, SalesOrderClient, SalesOrderDto, SalesOrderSetCommand } from "../api";
import { SalesOrderService } from './sales-order.service';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';

export const uiCmdSearchName: UiCommand = { id: 'uiCmdSearchName', text: 'Søg navn', tooltipText: 'Søg datakilder på firmanavn', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSearchVATNum: UiCommand = { id: 'uiCmdSearchVATNum', text: 'Søg CVR', tooltipText: 'Søg datakilder på CVR', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSearchEAN: UiCommand = { id: 'uiCmdSearchEAN', text: 'Søg EAN', tooltipText: 'Søg datakilder på EAN [Ctrl]+[Shift]+[F]', iconCss: 'e-menu-icon e-icons-org e-search', target: ".e-content", needSelection: true };
export const uiCmdSelectForCustomerCreate: UiCommand = { id: 'uiCmdSelectForCustomerCreate', text: 'Kladde', tooltipText: 'Anvend data til oprettelse af debitor [Ctrl]+[Shift]+[Pil ned]', iconCss: 'e-menu-icon e-icons-org e-changes-track', target: ".e-content", needSelection: true };
export const uiCmdDebtorSelect: UiCommand = { id: 'uiCmdDebtorSelect', text: 'Brug eksisterende', tooltipText: 'Tilknyt eksisterende debitor til salgsordre', iconCss: 'e-menu-icon e-icons-org e-history', target: ".e-content", needSelection: true };
export const uiCmdCustomerCreate: UiCommand = { id: 'uiCmdCustomerCreate', text: 'Opret debitor', tooltipText: 'Opret debitor og anvend på salgsordre  [Ctrl]+[Shift]+[Pil op]', iconCss: 'e-menu-icon e-icons-org e-changes-accept' };


function trimEllip(s: string, length: number): string {
  return (s != null && s.length > length) ? s.substring(0, length) + "..." : s;
}

@Component({
  selector: 'sales-order-cust',
  templateUrl: './sales-order-cust.component.html',
  styleUrls: ['../component.css'],
  providers: [SalesOrderService]
})

export class SalesOrderCustComponent {
  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected panes: CerFormPanesSettings = {
    top: { visible: 'show', size: '40%' },
    left: { visible: 'show', size: '50%' },
    main: { visible: 'show', size: '30%', sizeSecondary: '25%' },
    right: { visible: 'show', size: '25%' },
    bottom: { visible: 'show', size: '30%' },
    orientationPrimary: 'vertical'
  };

  private salesOrderGrid: CerGridComponent;
  @ViewChild('salesOrderGrid', { static: false }) set salesOrderGridContent(content: CerGridComponent) {
    if (content && !this.salesOrderGrid) {
      this.salesOrderGrid = content;
      this.subscriptionManager.add(this.salesOrderGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private debtorGrid: CerGridComponent;
  @ViewChild('debtorGrid', { static: false }) set custGridContent(content: CerGridComponent) {
    if (content && !this.debtorGrid) {
      this.debtorGrid = content;
      this.subscriptionManager.add(this.debtorGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private refDataNemHandelGrid: CerGridComponent;
  @ViewChild('refDataNemHandelGrid', { static: false }) set refDataNemHandelGridContent(content: CerGridComponent) {
    if (content && !this.refDataNemHandelGrid) {
      this.refDataNemHandelGrid = content;
      this.subscriptionManager.add(this.refDataNemHandelGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private refDataGrid: CerGridComponent;
  @ViewChild('refDataGrid', { static: false }) set refDataGridContent(content: CerGridComponent) {
    if (content && !this.refDataGrid) {
      this.refDataGrid = content;
      this.subscriptionManager.add(this.refDataGrid.commmand.subscribe(e => this.onCommand(e)));
    }
  }

  private custCreateGrid: CerGridComponent;
  @ViewChild('custCreateGrid', { static: false }) set custCreateGridGridContent(content: CerGridComponent) {
    if (content && !this.custCreateGrid) {
      this.custCreateGrid = content;
      var custCreateFieldMataData : FieldMetadata[] = [];
      this.debtorFieldMetadata.forEach(f => { custCreateFieldMataData.push({ ...f }) });
      custCreateFieldMataData.filter(f => f.name == 'id').forEach(f => { f.orderBy = 'Ascending' });
      custCreateFieldMataData.filter(f => f.name == 'num').forEach(f => { f.orderBy = null });
      custCreateFieldMataData.filter(f => f.name == 'eInvoicingId').forEach(f => { f.onQueryCellInfo = this.gridQueryCellInfoEInvoicingIdLast }),
      custCreateFieldMataData.filter(f => f.name == 'eInvoicing').forEach(f => { f.onQueryCellInfo = this.gridQueryCellInfoEInvoicingLast });
      this.custCreateGrid.fieldMetadata = custCreateFieldMataData
      this.subscriptionManager.add(this.custCreateGrid.commmand.subscribe(e => this.onCommand(e)));
      this.debtorDefaultsGet().then(() => { });
    }
  }

  // Form shotcuts
  formShortcuts: UiKeyboardShortcut[] = [
    { cmd: uiCmdSearchEAN, code: 'KeyF', ctrl: true, alt: false, shift: true },
    { cmd: uiCmdSelectForCustomerCreate, code: 'ArrowDown', ctrl: true, alt: false, shift: true },
    { cmd: uiCmdCustomerCreate, code: 'ArrowUp', ctrl: true, alt: false, shift: true }
  ];

  // Order view
  protected salesOrderViewMetadata: ViewMetadata;
  protected salesOrderFieldMetadata: FieldMetadata[] =
    [
      { name: 'num', text: 'Ordrenr.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S, orderBy: 'Ascending', orderByPriority: 3 },
      { name: 'custNum', text: 'Kundenr.', tooltipText: 'Kundenr.', widthType: FieldWidthEnum.L, orderBy: 'Ascending', orderByPriority: 1, onQueryCellInfo: this.gridQueryCellInfoCustNum },
      { name: 'custEAN', text: 'EAN nr.', width: '120px', onQueryCellInfo: this.gridQueryCellInfoCustEAN },
      { name: 'custVATNum', text: 'CVR nr.', tooltipText: 'Kundens CVR nummer', width: '90px' },
      { name: 'billCompanyName', text: 'Fak. firmanavn', tooltipText: 'Faktura firmanavn', width: '200px' },
      { name: 'custNote', text: 'Notat kunde', tooltipText: 'Kundens notat', format: 'TextArea', widthType: FieldWidthEnum.L },
      { name: 'status', text: 'Status', tooltipText: 'Ordrestatus', foreignKeyTableName: 'SalesOrderStatus', foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'], orderBy: 'Descending', orderByPriority: 2 },
      { name: 'orderDate', text: 'Ordredato', format: 'DateShort' },
      { name: 'orderChangeDate', text: 'Ordre ændring', format: 'DateShort', visible: false },
      { name: 'name', text: 'Navn', tooltipText: 'Navn', required: true, allowEdit: true, widthType: FieldWidthEnum.M },
      { name: 'paymentType', text: 'Betalingstype', tooltipText: 'Afregningstype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesPaymentType', foreignKeyField: 'num', visible: false },
      { name: 'custVATExempt', text: 'Momsfri', tooltipText: 'Er ordren momsfri?', format: 'CheckBox', visible: false },
      { name: 'billFirstName', text: 'Fak. fornavn', tooltipText: 'Faktura fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'billLastName', text: 'Fak. efternavn', tooltipText: 'Faktura efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'custEmail', text: 'E-mail', tooltipText: 'Kunde e-mail', format: 'Email' },
      { name: 'custPhone', text: 'Telefon', tooltipText: 'Kunde telefon', format: 'Phone' },
      { name: 'channel', text: 'Salgskanal', tooltipText: 'Kanal', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'SalesChannel', foreignKeyField: 'num', visible: false },
      { name: 'channelOrder', text: 'Ordre (kanal)', tooltipText: 'Kanalordre', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'reservedDate', text: 'Reserveret', format: 'DateShort', visible: false },
      { name: 'shipDate', text: 'Leveret', format: 'DateShort', visible: false },
      { name: 'invoiceDate', text: 'Faktureret', format: 'DateShort', visible: false },
      { name: 'invoiceNum', text: 'Faktura', format: 'DateShort', visible: false },
      { name: 'captureDate', text: 'Indløst', format: 'DateShort', visible: false },
      { name: 'settleDate', text: 'Udlignet', format: 'DateShort', visible: false },
      { name: 'paymentReference', text: 'Indløser ref.', tooltipText: 'Betalingsindløser ref.', allowEdit: true, widthType: FieldWidthEnum.S, visible: false },
      { name: 'type', text: 'Type', tooltipText: 'Ordretype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'SalesOrderType', foreignKeyField: 'num', visible: false },
      { name: 'currency', text: 'Valuta', widthType: FieldWidthEnum.XXXS, foreignKeyTableName: 'Currency', foreignKeyField: 'iso', foreignKeyValue: 'iso' },
      { name: 'amountCurExTax', text: 'Fakturabeløb u. moms', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M, visible: false },
      { name: 'amountCurTax', text: 'Momsbeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M, visible: false },
      { name: 'amountCur', text: 'Fakturabeløb', allowEdit: true, format: 'Amount', widthType: FieldWidthEnum.M },
      { name: 'billAddress1', text: 'Fak. adresse 1', tooltipText: 'Faktura adresse 1', widthType: FieldWidthEnum.S },
      { name: 'billAddress2', text: 'Fak. adresse 2', tooltipText: 'Faktura adresse 2', widthType: FieldWidthEnum.S },
      { name: 'billPostalCode', text: 'Fak. postnr.', tooltipText: 'Faktura postnr.', widthType: FieldWidthEnum.S },
      { name: 'billPostalDistrict', text: 'Fak. by', tooltipText: 'Faktura by', widthType: FieldWidthEnum.S },
      { name: 'billCountryCode', text: 'Fak. land', tooltipText: 'Faktura landekode', widthType: FieldWidthEnum.S, visible: false },
      { name: 'carrier', text: 'Transportør', foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num', visible: false, visibleEdit: true },
      { name: 'carrierServicePoint', text: 'Pakkeshop', foreignKeyTableName: 'WarehouseCarrierServicePoint', foreignKeyField: 'keyCode', foreignKeyColumns: ['keyCountryCode', 'keyType', 'key'], visible: false, visibleEdit: true },
      { name: 'shipToInvoiceAddress', text: 'Lev. fak.adresse', tooltipText: 'Levering til fakturaadresse', format: 'CheckBox', visible: false, visibleEdit: true },
      { name: 'shipFirstName', text: 'Lev. fornavn', tooltipText: 'Levering fornavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipLastName', text: 'Lev. efternavn', tooltipText: 'Levering efternavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCompanyName', text: 'Lev. firmanavn', tooltipText: 'Leveringfirmanavn', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
      { name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
      { name: 'sourceUTM', text: 'Kampagne', visible: false },
      { name: 'sourceDevice', text: 'Device', visible: false },
      { name: 'createdJobId', text: 'Job oprettet', format: 'Integer', visible: false },
      { name: 'modifiedJobId', text: 'Job rettet', format: 'Integer', visible: false }
    ];
  protected salesOrderFieldGroupMetadata: FieldGroupMetadata[];
  protected salesOrderToolbarCommands: UiCommand[] = [uiCmdSearchEAN, uiCmdSearchVATNum, uiCmdSearchName];
  protected salesOrderContextMenuCommands: UiCommand[] = [uiCmdSearchEAN, uiCmdSearchVATNum, uiCmdSearchName];

  protected debtorViewMetadata: ViewMetadata = {
    name: 'debitorer', dataApiName: 'Debtor', text: 'Debitorer', textSingular: 'Debitor', allowEdit: false, allowCreate: false, allowDelete: false,
    primaryKey: 'num', titleFields: ['name'], baseFields: ['id', 'num', 'name']
  };

  public debtorFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Id', tooltipText: 'Id for posten', format: 'Integer', widthType: FieldWidthEnum.M, visible: false },
    { name: 'num', text: 'Nr.', tooltipText: 'Part Id', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
    { name: 'name', text: 'Navn', tooltipText: 'Navn på debitor', width: '200px', validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoName },
    { name: 'eInvoicingId', text: 'EAN', tooltipText: 'Elektronisk fakturering netværk id / EAN', width: '120px', onQueryCellInfo: this.gridQueryCellInfoEInvoicingId },
    { name: 'taxId', text: 'CVR', tooltipText: 'CVR nr.', width: '90px' },
    { name: 'eInvoicing', text: 'eFaktura?', tooltipText: 'Elektronisk fakturering netværk id / EAN', format: 'CheckBox', onQueryCellInfo: this.gridQueryCellInfoEInvoicing },
    { name: 'attention', text: 'Attention', validationMaxLen: 30, widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true },
    { name: 'address1', text: 'Adresse 1', tooltipText: 'Adresselinje 1', widthType: FieldWidthEnum.L, validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress1 },
    { name: 'address2', text: 'Adresse 2', tooltipText: 'Adresselinje 2', widthType: FieldWidthEnum.M, validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress2  },
    { name: 'address3', text: 'Adresse 3', tooltipText: 'Adresselinje 3', widthType: FieldWidthEnum.M, validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress3  },
    { name: 'country', text: 'Land', tooltipText: 'Land', widthType: FieldWidthEnum.M, allowSearching: false },
    { name: 'phone', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'email', text: 'E-mail', tooltipText: 'E-mail adresse', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true, format: 'Email' },
    { name: 'group', text: 'Gruppe', tooltipText: 'Debitorgruppe', widthType: FieldWidthEnum.M },
    { name: 'paymentTerm', text: 'Betaling', tooltipText: 'Betalingsbetingelse', widthType: FieldWidthEnum.XS, defaultValue: "" },
    { name: 'currency', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 'DKK', foreignKeyTableName: 'Currency', foreignKeyField: 'iso', foreignKeyValue: 'iso' },
    { name: 'taxCode', text: 'Momskode', tooltipText: 'Anvendt momskode',/* format: 'CheckBox',*/ visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'language', text: 'Sprog', tooltipText: 'Sprogkode', widthType: FieldWidthEnum.XS, allowSearching: false, visible: false },
    { name: 'blocked', text: 'Spærret', tooltipText: 'Er konto spærret', allowSearching: false, widthType: FieldWidthEnum.XXS, /* format: 'CheckBox',*/ /*filterOperator: 'notequal', filterValue: true, filterOnClient: true,*/ visibleEdit: true, visibleAdd: true },
    { name: 'source', text: 'Datakilde', tooltipText: 'Hvilken database kommer data fra?', allowSearching: false, format: 'Integer', visible: false }
  ];
  protected debtorToolbarCommands: UiCommand[] = [uiCmdDebtorSelect];
  protected debtorContextMenuCommands: UiCommand[] = [uiCmdSearchEAN, uiCmdSearchVATNum, uiCmdSearchName, uiCmdSeparator, uiCmdDebtorSelect, uiCmdSelectForCustomerCreate];

  protected refDataNemHandelViewMetadata: ViewMetadata = {
    name: 'ref-data-nem-handels-register', dataApiName: 'RefData_NemHandelsRegister', text: 'NemHandelsregister',
    primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: false
  };
  protected refDataNemHandelFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Nøgle', format: 'Text', visible: false, widthType: FieldWidthEnum.M },
    { name: 'key', text: 'GLN/CVR Adresse', format: 'Text', width: '120px' },
    { name: 'unitName', text: 'Navn', format: 'Text', width: '200px', validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoUnitName },
    { name: 'unitCVR', text: 'CVR nr.', format: 'Text', width: '90px' },
    { name: 'keyType', text: 'Addressetype', format: 'Text', widthType: FieldWidthEnum.XXS }
  ];
  protected refDataNemHandelToolbarCommands: UiCommand[] = [uiCmdSelectForCustomerCreate];
  protected refDataNemHandelContextMenuCommands: UiCommand[] = [uiCmdSearchVATNum, uiCmdSearchEAN, uiCmdSearchName, uiCmdSeparator, uiCmdSelectForCustomerCreate];;

  protected refDataViewMetadata: ViewMetadata = {
    name: 'ref-data', dataApiUrl: '/api/PartyExternalRef', text: 'CVR register',
    primaryKey: 'externalRefId', allowEdit: false, allowCreate: false, allowDelete: false
  };
  protected refDataFieldMetadata: FieldMetadata[] = [
    { name: 'externalRefId', text: 'CVR', tooltipText: 'CVR nummer', widthType: FieldWidthEnum.M, onQueryCellInfo: this.gridQueryCellInfoActive },
    { name: 'externalRefMatchScore', text: 'Match', orderBy: 'Descending', visible: false },
    { name: 'name', text: 'Navn', tooltipText: 'Navn på part', widthType: FieldWidthEnum.L },
    { name: 'isCompany', text: 'Selskab?', tooltipText: 'Er dette et selskab?', widthType: FieldWidthEnum.XXS, format: 'CheckBox', visible: false },
    { name: 'address1', text: 'Adresse 1', tooltipText: 'Adresselinje 1', validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress1  },
    { name: 'address2', text: 'Adresse 2', tooltipText: 'Adresselinje 2', validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress2  },
    { name: 'address3', text: 'Adresse 3', tooltipText: 'Adresselinje 3', validationMaxLen: 30, onQueryCellInfo: this.gridQueryCellInfoAddress3  },
    { name: 'zipCode', text: 'Postnr', widthType: FieldWidthEnum.S, visible: false },
    { name: 'city', text: 'By', widthType: FieldWidthEnum.M, visible: false },
    { name: 'zipCodeCity', text: 'Postnr/By', widthType: FieldWidthEnum.M },
    { name: 'coAddress', text: 'C/O Adresse', tooltipText: 'C/O Adresselinje', widthType: FieldWidthEnum.S },
    { name: 'countryCode', text: 'LandeKode', widthType: FieldWidthEnum.M },
    { name: 'countryName', text: 'Land', tooltipText: 'Land', widthType: FieldWidthEnum.M, visible: false, visibleEdit: true, visibleAdd: true },
    { name: 'phone', text: 'Telefon', tooltipText: 'Telefonnr.', widthType: FieldWidthEnum.M, visibleEdit: true, visibleAdd: true, format: 'Phone' },
    { name: 'email', text: 'E-mail', tooltipText: 'E-mail adresse', widthType: FieldWidthEnum.L, visibleEdit: true, visibleAdd: true, format: 'Email' },
    { name: 'language', text: 'Sprog', tooltipText: 'Sprogkode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'ValutaKoder', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'currency', text: 'Valuta', tooltipText: 'Valutakode', widthType: FieldWidthEnum.XS, defaultValue: 1, foreignKeyTableName: 'Language', foreignKeyField: 'id', foreignKeyValue: 'valutaKode', visibleEdit: true, visibleAdd: true },
    { name: 'nace', text: 'Nacekode', visible: false },
    { name: 'naceName', text: 'Nace', visible: false },
    { name: 'status', text: 'Status' },
    { name: 'active', text: 'Aktiv', tooltipText: 'Er selskab aktivt?', widthType: FieldWidthEnum.XXS, format: 'CheckBox' }
  ];
  protected refDataToolbarCommands: UiCommand[] = [uiCmdSelectForCustomerCreate];
  protected refDataContextMenuCommands: UiCommand[] = [uiCmdSearchVATNum, uiCmdSearchName, uiCmdSeparator, uiCmdSelectForCustomerCreate];

  protected custCreateViewMetadata: ViewMetadata = {
    name: 'customer-create', text: 'Kladder oprettelse', textSingular: 'Kladde oprettelse', titleFields: ['num', 'name'],
    primaryKey: 'id', allowEdit: true, allowCreate: false, allowDelete: true, confirmDelete: false
  };
  protected custCreateFieldMetadata: FieldMetadata[];
  protected custCreateFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'blocked'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'customer', text: 'Kunde', fields: ['name', 'email', 'phone',  'taxId', 'eInvoicing', 'eInvoicingId'] },
    { idx: 3, tabIdx: 0, name: 'setup', text: 'Opsætning', fields: ['custGroup', 'paymentTerm', 'paymentMethod' ] },
    { idx: 4, tabIdx: 0, name: 'bill', text: 'Faktura', fields: ['attention','address1', 'address2', 'address3', 'country'] },
    { idx: 5, tabIdx: 0, name: 'other', text: 'Øvrige', fields: [] }
  ];
  protected custCreateToolbarCommands: UiCommand[] = [uiCmdCustomerCreate];
  protected custCreateContextMenuCommands: UiCommand[] = [uiCmdCustomerCreate];

  private debtorDefaults: DebtorDto[];
  private custCreateData: DebtorDto[] = [];
  private isCreditCustReady: boolean = false;

  private subscriptionManager: Subscription = new Subscription();

  constructor(
    private salesOrderService: SalesOrderService,
    private routeService: CerAppRouteService,
    private client: SalesOrderClient,
    private debtorClient: DebtorClient,
    private dialog: CerDialogService,
    private ui: CerAppDialogService) {

    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }


  private setupFromRouteData(routeData: any) {
    var svc: SalesOrderService = this.salesOrderService;
    this.salesOrderViewMetadata = { ...svc.salesOrderViewMetadata };
    this.salesOrderViewMetadata.allowCreate = false;
    this.salesOrderViewMetadata.allowDelete = false;
    //this.salesOrderFieldMetadata = svc.salesOrderFieldMetadata;
    this.salesOrderFieldGroupMetadata = svc.salesOrderFieldGroupMetadata;
    if (routeData?.params) {
      var params = routeData.params;
      this.salesOrderViewMetadata.dataApiParams = params;
      this.isCreditCustReady = params.includes("$statusGroup=CreditCustReady");
    }
    if (this.isCreditCustReady) {
      this.salesOrderFieldMetadata.filter(f => f.name == 'custNum').forEach(f => { f.filterOperator = 'equal'; f.filterOnClient = true, f.filterValue = null });
    }
  }

  private async debtorDefaultsGet() {
    this.debtorDefaults = await firstValueFrom(this.debtorClient.getEANDefault());
    this.debtorDefaults.forEach(d => { d.blocked = 0; })
  }

  private custCreateGridInitData(eInviocing: boolean, reset: boolean = false) {
    if (reset) {
      this.custCreateData = [];
    }
    else if (this.custCreateData?.length == 0) {
      // Add defaults
      var eInviocingNum: number = (eInviocing ? 1 : 0);
      this.custCreateData = ((this.debtorDefaults?.length > 0) ? [...this.debtorDefaults] : []).filter(d => d.eInvoicing == eInviocingNum);
    }
    this.custCreateGrid.grid.dataSource = new DataManager(this.custCreateData);
  }

  // Commands
  public onCommand(event: UiCommandEvent) {

    if (event.source == UiCommandSourceEnum.ActionComplete) {
      switch (event.actionType) {
        case UiActionTypeEnum.DataBound:
          if (event.appComponent == this.refDataGrid) {
            this.refDataGridMarkBusy();
          }
          break;
        case UiActionTypeEnum.RowSelect:
          if (event.appComponent == this.salesOrderGrid) {
            this.custCreateGridInitData(false, true);
            this.searchSourcesFromSalesOrderEvent(event);
          }
          if (event.appComponent == this.refDataNemHandelGrid) {
            this.refDataGridSearchUnitCVRQueued(this.getRowFromEvent(event)?.unitCVR);
          }
          break;
        case UiActionTypeEnum.Searching:
          if (event.appComponent == this.refDataGrid) {
            this.refDataGridMarkDone();
          }
          break;
        case UiActionTypeEnum.Refresh:
          if (event.appComponent == this.refDataGrid) {
            this.refDataGridMarkDone();
          }
          break;
      }
    }
    else {
      switch (event.commandId) {
        case uiCmdSearchName.id:
          this.searchSourcesByNameFromEvent(event, false);
          break;
        case uiCmdSearchEAN.id:
          this.searchSourcesByEANFromEvent(event);
          break;
        case uiCmdSearchVATNum.id:
          this.searchSourcesByVATNumFromEvent(event);
          break;
        case uiCmdDebtorSelect.id:
          this.salesOrderSetCustNumFromEvent(event);
          break;
        case uiCmdSelectForCustomerCreate.id:
          this.customerCreateGridAddFromEvent(event);
          break;
        case uiCmdCustomerCreate.id:
          this.customerCreate();
          break;
      }
    }
  }


  // Search
  private searchSourcesByNameFromEvent(event: UiCommandEvent, searchCustNum: boolean) {
    var dto = this.getRowFromEvent(event);
    var c: CerGridComponent = <CerGridComponent>event.appComponent;
    if (dto && c) {
      if (c == this.salesOrderGrid) {
        this.searchSources(dto.billCompanyName, true, true, true, false, searchCustNum ? dto.custNum : null);
      }
      else if (c == this.debtorGrid) {
        this.searchSources(dto.name, false, true, true);
      }
      else if (c == this.refDataNemHandelGrid) {
        this.searchSources(dto.unitName, true, false, true);
      }
      else if (c == this.refDataGrid) {
        this.searchSources(dto.name, true, true, false);
      }
    }
  }

  private searchSourcesByVATNumFromEvent(event: UiCommandEvent) {
    var isUnitCVR: boolean = true;
    var dto = this.getRowFromEvent(event);
    var c: CerGridComponent = <CerGridComponent>event.appComponent;
    if (dto && c) {
      if (c == this.salesOrderGrid) {
        this.searchSources(dto.custVATNum, true, true, true, isUnitCVR);
      }
      else if (c == this.debtorGrid) {
        this.searchSources(dto.taxNum, false, true, true, isUnitCVR);
      }
      else if (c == this.refDataNemHandelGrid) {
        this.searchSources(dto.unitCVR, true, false, true, isUnitCVR);
      }
      else if (c == this.refDataGrid) {
        this.searchSources(dto.externalRefId.toString(), true, true, false, isUnitCVR);
      }
    }
  }

  private searchSourcesFromSalesOrderEvent(event: UiCommandEvent) {
    var salesOrderDto = this.getRowFromEvent(event);
    if (salesOrderDto) {
      if (salesOrderDto.custEAN?.length > 0) {
        this.searchSourcesByEANFromEvent(event);
      }
      else if (salesOrderDto.custVATNum?.length > 0) {
        this.searchSourcesByVATNumFromEvent(event);
      }
      else if (salesOrderDto.name?.length > 0) {
        this.searchSourcesByNameFromEvent(event, true);
      }
    }
  }

  private searchSourcesByEANFromEvent(event: UiCommandEvent) {
    var dto = this.getRowFromEvent(event);
    var c: CerGridComponent = <CerGridComponent>event.appComponent;
    if (dto && c) {
      if (c == this.salesOrderGrid) {
        this.searchSources(dto.custEAN, true, true, false);
      }
      else if (c == this.debtorGrid) {
        this.searchSources(dto.eInvoiceId, false, true, true);
      }
      else if (c == this.refDataNemHandelGrid) {
        this.searchSources(dto.key, true, false, true);
      }
    }
  }


  // When EAN search, then CVR grid will be empty, but we try to search by the first row from the EAN grid when databound
  private searchUnitCVRDeferred: string = null;

  private refDataGridBusy: boolean = false;
  private refDataGridMarkBusy() {
    this.refDataGridBusy = true;
  }

  private refDataGridMarkDone() {
    this.refDataGridBusy = false;
    if (this.searchUnitCVRDeferred !== undefined) {
      var unitCVR: string = this.searchUnitCVRDeferred;
      this.searchUnitCVRDeferred = undefined;
      this.refDataGridSearchUnitCVRRun(unitCVR);
    }
  }

  private refDataGridSearchUnitCVRQueued(unitCVR: string) {
    if (this.refDataGridBusy) {
      this.searchUnitCVRDeferred = unitCVR;
    }
    else {
      this.searchUnitCVRDeferred = undefined;
      this.refDataGridSearchUnitCVRRun(unitCVR);
    }
  }

  private refDataGridSearchUnitCVRRun(unitCVR: string) {
    var done: boolean = false;
    if (unitCVR?.length > 0) {
      done = this.refDataGrid.selection.rowObjectById(unitCVR) != null;
    }
    else {
      done = this.refDataGrid.selection.rowsObjects()?.length == 0;
    }
    if (done) {
      return;
    }
    this.refDataGrid.grid.search(unitCVR);
  }

  private searchSources(searchString: any, searchCust: boolean, searchNemHandel: boolean, searchRefData: boolean, isUnitCVR: boolean = false, custNum: string = null) {
    var canSearch: boolean = (searchString?.length > 2);
    if (!canSearch) {
      searchString = null;
    }

    if (searchCust) {
      this.debtorGrid.grid.search(custNum ? custNum : searchString);
    }
    if (searchNemHandel) {
      this.refDataNemHandelGrid.grid.search(searchString);
    }

    // RefData
    if (searchRefData) {
      if (isUnitCVR) {
        this.refDataGridSearchUnitCVRQueued(searchString);
      }
      else {
        this.refDataGrid.grid.search(searchString);
      }
    }
  }

  private customerCreateGridAddFromEvent(event: UiCommandEvent) {
    if (event.appComponent == this.salesOrderGrid) {
      // Enulate event from refDataNemHandel grid 
      event.appComponent = this.refDataNemHandelGrid;
      event.rowData = this.refDataNemHandelGrid.rowSelectedData();
    }
    var dto = event.rowData;
    var debtor: DebtorDto = null;
    var c: CerGridComponent = <CerGridComponent>event.appComponent;
    var hasBaseData: boolean = false;
    if (c && dto) {
      if (c == this.refDataNemHandelGrid) {
        debtor = this.refDataNemHandelToDebtorDto(dto);
        this.custCreateGridInitData(debtor.eInvoicing == 1);
        this.customerCreateGridAddFromRefDataGrid(parseInt(dto.unitCVR));
      }
      else if (c == this.refDataGrid) {
        debtor = this.refDataToDebtorDto(dto);
        var salesOrder : any = this.salesOrderGrid.rowSelectedData();
        if (!salesOrder?.CustEAN) {
          debtor.email = debtor.email?.length > 0 ? debtor.email : salesOrder.custEmail;
          debtor.phone = debtor.phone?.length > 0 ? debtor.phone : salesOrder.custPhone;
        }
        this.custCreateGridInitData(false);
      }
      else if (c == this.debtorGrid) {
        debtor = dto;
        this.custCreateGridInitData(debtor.eInvoicing == 1);
      }
      if (debtor) {
        this.customerCreateGridAdd(debtor);
      }
    }
  }

  private sourceRefData: number = 3;
  private sourceRefDataNemHandel: number = 4;
  private customerCreateGridAddFromRefDataGrid(taxId: number) {
    if (taxId != null) {
      var refDataRows = this.custCreateData.filter(r => (<any>r).source == this.sourceRefData);
      if (refDataRows.length == 0) {
        var refDataDto: any = this.refDataGrid.selection.rowObjectById(taxId)?.data;
        if (refDataDto) {
          var debtor: DebtorDto = this.refDataToDebtorDto(refDataDto);
          this.customerCreateGridAdd(debtor);
        }
      }
    }
  }

  private refDataNemHandelToDebtorDto(dto: any): DebtorDto {
    return new DebtorDto({
      num: dto.unitCVR,
      name: trimEllip(dto.unitName, 30),
      taxId: dto.unitCVR,
      eInvoicingId: dto.key,
      eInvoicing: 1,
      source: this.sourceRefDataNemHandel
    });
  }

  private refDataToDebtorDto(dto: any): DebtorDto {
    return new DebtorDto({
      num: dto.key,
      name: trimEllip(dto.name, 30),
      taxId: dto.externalRefId.toString(),
      address1: trimEllip(dto.address1, 30),
      address2: trimEllip(dto.address2, 30),
      address3: trimEllip(dto.zipCodeCity, 30),
      country: dto.countryCode,
      phone: dto.phone,
      email: dto.email,
      source: this.sourceRefData
    });
  }

  private customerCreateGridAdd(dto: DebtorDto) {
    if (dto) {
      var max: number = 0;
      this.custCreateData.forEach(r => { max = r.id > max ? r.id : max });
      dto.id = max + 1;
      this.custCreateData.push(dto);
      this.custCreateGrid.research();
    }
  }

  private salesOrderSetCustNumFromEvent(event: UiCommandEvent) {
    var debtor: DebtorDto = event.rowData;
    if (debtor?.num) {
      var orderNum = this.getOrderNumFromEvent(event);
      if (orderNum) {
        var salesOrder: SalesOrderDto = <SalesOrderDto>this.salesOrderGrid.rowSelectedData();
        if (salesOrder.num) {
          this.salesOrderSetCustNum(salesOrder, debtor.num);
        }
      }
    }
  }

  private salesOrderSetCustNum(salesOrder: SalesOrderDto, custNum: string) {
    var cmd: SalesOrderSetCommand = new SalesOrderSetCommand({ salesOrderNum: salesOrder.num, accountNum: custNum, accountNumSet: true });
    this.client.salesOrderSet(cmd).subscribe(salesOrderUpd => {
      this.custCreateData = this.debtorDefaults;
      this.custCreateGrid.research();
      this.salesOrderGridRowRefresh(salesOrder, salesOrderUpd, true);
    }
    );
  }

  private async customerCreate() {
    var salesOrder: SalesOrderDto = <SalesOrderDto>this.salesOrderGrid.rowSelectedData();
    var salesOrderUpd = await firstValueFrom(this.debtorClient.customerCreateFromDebtorSeqments(salesOrder?.num, this.custCreateData));
    if (salesOrderUpd?.num) {
      this.salesOrderGridRowRefresh(salesOrder, salesOrderUpd, true);
    }
  }

  private salesOrderGridRowRefresh(salesOrder: SalesOrderDto, salesOrderUpd: SalesOrderDto, selectNextRow: boolean = false) {
    if (salesOrder && salesOrderUpd) {
      if (selectNextRow) {
        var idx = this.salesOrderGrid.selection.rowSelectedIdx();
        this.salesOrderGrid.selection.rowSelectByIdx(idx + 1);
      }
      this.salesOrderGrid.selection.rowDataSetById(salesOrder.num, salesOrderUpd);
    }
  }

  private getRowFromEvent(event: UiCommandEvent): any {
    return event?.rowData;
  }

  private getOrderNumFromEvent(event: UiCommandEvent): string {
    return event?.rowData?.num;
  }

  public gridQueryCellInfoCustNum(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoValue(args, 'custNum');
  }

  public gridQueryCellInfoCustEAN(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'custEAN');
  }

  public gridQueryCellInfoEInvoicingIdLast(args: QueryCellInfoEventArgs) {
    if (SalesOrderCustComponent.custCreateGridIsLastRow(args)) {
      SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'eInvoicingId');
    }
  }

  public gridQueryCellInfoEInvoicingLast(args: QueryCellInfoEventArgs) {
    if (SalesOrderCustComponent.custCreateGridIsLastRow(args)) {
      SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'eInvoicing');
    }
  }

  public static custCreateGridIsLastRow(args: QueryCellInfoEventArgs) : boolean {
    var data : Object = args.data;
    var grid : GridComponent = (<any> args).column?.parent;
    if (data && grid) {
      var rowsObjects = (<any>grid).contentModule?.getRows();
      return rowsObjects == null || rowsObjects.length <= 0 || rowsObjects[rowsObjects.length-1].data == data;
    }
    return false;
  }
  public gridQueryCellInfoEInvoicingId(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'eInvoicingId');
  }
  public gridQueryCellInfoEInvoicing(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'eInvoicing');
  }

  public gridQueryCellInfoName(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoMaxLength(args, 'name', 30);
  }
  public gridQueryCellInfoUnitName(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoMaxLength(args, 'unitName', 30);
  }
  public gridQueryCellInfoAddress1(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoMaxLength(args, 'address1', 30);
  }
  public gridQueryCellInfoAddress2(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoMaxLength(args, 'address2', 30);
  }
  public gridQueryCellInfoAddress3(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoMaxLength(args, 'address3', 30);
  }
  public gridQueryCellInfoActive(args: QueryCellInfoEventArgs) {
    SalesOrderCustComponent.gridQueryCellInfoEmpty(args, 'active');
  }

  public static gridQueryCellInfoValue(args: QueryCellInfoEventArgs, field: string) {
    var dto: any = args.data;
    if (dto) {
      var value: any = dto[field];
      if (value) {
        SalesOrderCustComponent.gridQueryCellInfoColorGreen(args);
      }
    }
  }


  public static gridQueryCellInfoEmpty(args: QueryCellInfoEventArgs, field: string) {
    var dto: any = args.data;
    if (dto) {
      var value: any = dto[field];
      if (!value) {
        SalesOrderCustComponent.gridQueryCellInfoColorRed(args);
      }
    }
  }

  public static gridQueryCellInfoMaxLength(args: QueryCellInfoEventArgs, field: string, maxLen: number) {
    var dto: any = args.data;
    var value: string = dto ? dto[field] : null;
    if (value?.length > maxLen) {
      SalesOrderCustComponent.gridQueryCellInfoYellow(args);
    }
  }

  public static gridQueryCellInfoColorGreen(args: QueryCellInfoEventArgs) {
    var colorRed: string = '#DDFFDD';
    SalesOrderCustComponent.gridQueryCellInfoColor(args, colorRed);
  }

  public static gridQueryCellInfoColorRed(args: QueryCellInfoEventArgs) {
    var colorRed: string = '#FFBBBB';
    SalesOrderCustComponent.gridQueryCellInfoColor(args, colorRed);
  }

  public static gridQueryCellInfoYellow(args: QueryCellInfoEventArgs) {
    var colorRed: string = '#FFFFBB';
    SalesOrderCustComponent.gridQueryCellInfoColor(args, colorRed);
  }

  public static gridQueryCellInfoColor(args: QueryCellInfoEventArgs, color: string) {
    if (args.cell) {
      // TODO: Alt row color - parent not known at this time
      //if (args.cell.parentElement?.classList.contains('e-altrow')) {
      //  color.substring(0, 2) + '7' + color.substring(4, 1)+'7' + color.substring(5, 2);
      //}
      (<any>args.cell).style.backgroundColor = color;
    }
  }
}
