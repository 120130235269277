import { Injectable } from "@angular/core";
import { FieldGroupMetadata, FieldMetadata, FieldWidthEnum, KeyValue, ViewMetadata } from "src/cer/cer-data/cer-data.service";
import { uiCmdSeparator, UiCommand, UiCommandEvent } from "src/cer/cer-grid/cer-grid-command.service";
import { CerGridComponent } from "src/cer/cer-grid/cer-grid.component";
import { CerAppDialogService } from "src/cer-app/cer-app-dialog/cer-app-dialog.service";

import { WarehouseOutboundOrderClient, WarehouseOutboundOrderStatusEnum } from "../api";
import { CerDialogService } from "src/cer/cer-dialog/cer-dialog.service";
import { CerDataNoteService } from "src/cer-app/cer-data-data/cer-data-note.service";
import { CerAppRouteService } from "src/cer-app/cer-app-route/cer-app-route.service";

export const uiCmdOrderSyncSelected: UiCommand = { id: 'orderSyncSelected', text: 'Opdater ordre status', tooltipText: 'Opdater status på denne forsendelse/ordre', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content", needSelection: true };
export const baseUrlTrackTrace = 'www.postnord.dk/varktojer/track-trace?shipmentId=';
@Injectable({ providedIn: 'root' })
export class WarehouseOutboundOrderService {
  public uiCmdWarehouseOutboundOrderRelease: UiCommand =
    { id: 'warehouseOutboundOrderRelease', text: 'Frigiv forsendelse', tooltipText: 'Frigiv forsendelse til lager', iconCss: 'e-menu-icon e-icons-org e-changes-accept', target: ".e-content", needSelection: true };
  public uiCmdWarehouseOutboundOrderCancel: UiCommand =
    { id: 'warehouseOutboundOrderCancel', text: 'Annuller forsendelse', tooltipText: 'Annuller forsendelse', iconCss: 'e-menu-icon e-icons-org e-changes-reject', target: ".e-content", needSelection: true };
  public uiCmdWarehouseOutboundOrderComplete: UiCommand =
    { id: 'warehouseOutboundOrderComplete', text: 'Afslut forsendelse', tooltipText: 'Afslut forsendelse', iconCss: 'e-menu-icon e-icons-org e-table-update', target: ".e-content", needSelection: true };
  public uiCmdWarehouseOutboundOrderReorderCancelledLines: UiCommand =
    { id: 'warehouseOutboundOrderReorderCancelledLines', text: 'Genbestil annullerede', tooltipText: 'Genbestil annullerede linjer', iconCss: 'e-menu-icon e-icons-org e-table-update', target: ".e-content", needSelection: true };
  public uiCmdSalesOrderSync: UiCommand = { id: 'uiCmdSalesOrderSync', text: 'Hent status forsendelser', tooltipText: 'Hent data omkring forsendelser', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content" };
  public uiCmdOrderSync: UiCommand = { id: 'uiCmdOrderSync', text: 'Hent status salg og forsendelser', tooltipText: 'Hent data omkring salg og forsendelser', iconCss: 'e-menu-icon e-icons-org e-repeat', target: ".e-content" };

  public orderToolbarCommands: UiCommand[] = [this.uiCmdSalesOrderSync, uiCmdSeparator, this.uiCmdWarehouseOutboundOrderRelease];
  public orderContextMenuCommands: UiCommand[] = [uiCmdOrderSyncSelected, uiCmdSeparator, this.uiCmdWarehouseOutboundOrderRelease, this.uiCmdWarehouseOutboundOrderCancel, this.uiCmdWarehouseOutboundOrderComplete,
    uiCmdSeparator, this.uiCmdWarehouseOutboundOrderReorderCancelledLines, uiCmdSeparator,
    uiCmdSeparator, this.uiCmdOrderSync, this.uiCmdSalesOrderSync];

  public orderViewMetadata: ViewMetadata = {
    name: 'outbound-order', dataApiName: 'WarehouseOutboundOrder', text: 'Forsendelser', textSingular: 'Forsendelse',
    primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'salesOrder', titleFields: ['order', 'type', 'product'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };

  public orderFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Id', required: true, tooltipText: 'Unik nøgle', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
    { name: 'num', text: 'Ordrenr.', required: true, tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.S, orderBy: 'Descending' },
    { name: 'warehouse', text: 'Lager', required: true, widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'Warehouse', foreignKeyField: 'num', foreignKeyValue: 'num', foreignKeyColumns: ['num', 'name'], foreignKeyTitles: ['Lager', 'Navn'] },
    { name: 'type', text: 'Type', tooltipText: 'Forsendelsestype', widthType: FieldWidthEnum.S, foreignKeyTableName: 'WarehouseOutboundOrderType', foreignKeyField: 'num', foreignKeyValue: 'num' },
    { name: 'status', text: 'Status', tooltipText: 'Forsendelsestatus', widthType: FieldWidthEnum.S, foreignKeyTableName: 'WarehouseOutboundOrderStatus', foreignKeyField: 'id', foreignKeySortFields: ['sorting'] },
    { name: 'maxStatus', text: 'Linjestatus', tooltipText: 'Linjestatus', widthType: FieldWidthEnum.S, foreignKeyTableName: 'WarehouseOutboundOrderStatus', foreignKeyField: 'id', foreignKeySortFields: ['sorting'] },
    { name: 'salesOrder', text: 'Salgsordre', widthType: FieldWidthEnum.XS, visible: false, visibleEdit: true },
    { name: 'releasedDate', text: 'Frigivet', format: 'DateShort', visible: false, visibleEdit: true },
    { name: 'confirmedDate', text: 'Bekræftet', format: 'DateShort', visible: false, visibleEdit: true },
    { name: 'reservedDate', text: 'Reserveret', format: 'DateShort', visible: false, visibleEdit: true },
    { name: 'shipDateRequested', text: 'Ønsket', format: 'DateShort' },
    { name: 'shipDateDispatched', text: 'Afsendt', format: 'DateShort', visible: false, visibleEdit: true },
    { name: 'name', text: 'Navn', tooltipText: 'Navn', widthType: FieldWidthEnum.L },
    { name: 'note', text: 'Notat', tooltipText: 'Notat', format: 'TextArea', widthType: FieldWidthEnum.L },
    { name: 'email', text: 'E-mail', tooltipText: 'Advisering e-mail', format: 'Email' },
    { name: 'phone', text: 'Telefon', tooltipText: 'Advisering telefon', format: 'Phone' },
    { name: 'carrier', text: 'Transportør', foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num', foreignKeyValue: 'name' },
    { name: 'carrierServicePoint', text: 'Pakkeshop', foreignKeyTableName: 'WarehouseCarrierServicePoint', foreignKeyField: 'keyCode', foreignKeyValue: 'keyCode', foreignKeyColumns: ['keyCountryCode', 'keyType', 'key'] },
    { name: 'trackTrace', text: 'Tracking', format: 'Url', urlBase: baseUrlTrackTrace },
    { name: 'shipName', text: 'Lev. navn 1', tooltipText: 'Levering navn 1', widthType: FieldWidthEnum.XL, visible: false, visibleEdit: true },
    { name: 'shipName2', text: 'Lev. navn 2', tooltipText: 'Levering navn 2', widthType: FieldWidthEnum.XL, visible: false, visibleEdit: true },
    { name: 'shipAddress1', text: 'Lev. adresse 1', tooltipText: 'Levering adresse 1', widthType: FieldWidthEnum.XL, visible: false, visibleEdit: true },
    { name: 'shipAddress2', text: 'Lev. adresse 2', tooltipText: 'Levering adresse 2', widthType: FieldWidthEnum.XL, visible: false, visibleEdit: true },
    { name: 'shipPostalCode', text: 'Lev. postnr.', tooltipText: 'Levering postnr.', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true },
    { name: 'shipPostalDistrict', text: 'Lev. by', tooltipText: 'Levering by', widthType: FieldWidthEnum.L, visible: false, visibleEdit: true },
    {
      name: 'shipCountryCode', text: 'Lev. land', tooltipText: 'Levering landekode', widthType: FieldWidthEnum.S, visible: false, visibleEdit: true,
      foreignKeyTableName: 'Country', foreignKeyField: 'num', foreignKeyValue: 'name', foreignKeyColumns: ['num', 'name'], foreignKeyTitles: ['Kode', 'Land'], foreignKeyColumnsWidth: ['100px', '300px']
    },
  ];

  public orderFieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'warehouse', 'salesOrder', 'type', 'status'], width: 240 },
    { idx: 2, tabIdx: 0, name: 'shipTo', text: 'Modtager', fields: ['name', 'email', 'phone'] },
    { idx: 3, tabIdx: 0, name: 'carrier', text: 'Transportør', fields: ['carrier', 'carrierServicePoint', 'note', 'trackTrace'] },
    { idx: 4, tabIdx: 0, name: 'shipment', text: 'Adresse', fields: ['shipName', 'shipName2', 'shipAddress1', 'shipAddress2', 'shipPostalCode', 'shipPostalDistrict', 'shipCountryCode'] },
    { idx: 5, tabIdx: 0, name: 'dates', text: 'Datoer', fields: ['created', 'releaseDate', 'confirmDate', 'reservedDate', 'shipDateRequested', 'shipDateDispatched'] },
    { idx: 6, tabIdx: 0, name: 'other', text: 'Øvrige', fields: [] }
  ];

  // Order line view
  public orderLineViewMetadata: ViewMetadata = {
    name: 'outbound-orderline', dataApiName: 'WarehouseOutboundOrderLine', text: 'Forsendelse linjer', textSingular: 'Forsendelse linje',
    dataApiParams: ['$includeDataLinks', '$statusOnHand'], primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'order', titleFields: ['order', 'type', 'product'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };

  public orderLineFieldMetadata: FieldMetadata[] = [
    { name: 'id', text: 'Id', tooltipText: 'Unik nøgle', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
    { name: 'order', text: 'Forsendelse', visible: false },
    { name: 'line', text: 'Linje', format: 'Integer', widthType: FieldWidthEnum.XS, visible: false, orderBy: 'Ascending', orderByPriority: 1 },
    { name: 'product', text: 'ISBN', widthType: FieldWidthEnum.XS },
    { name: 'name', text: 'Navn.', tooltipText: 'Ordrenummer', widthType: FieldWidthEnum.XXL },
    { name: 'orderedQty', text: 'Antal', format: 'Number', footerType: 'Sum', widthType: FieldWidthEnum.XXS },
    { name: 'orderedInventQty', text: 'Antal indeholdt', format: 'Number', footerType: 'Sum', widthType: FieldWidthEnum.XXS },
    { name: 'reservedQty', text: 'Reserveret antal', allowEdit: false, format: 'Number', footerType: 'Sum', widthType: FieldWidthEnum.XXS },
    { name: 'unreservedQty', text: 'Ej reserveret antal', allowEdit: false, format: 'Number', footerType: 'Sum', widthType: FieldWidthEnum.XXS, visible: false },
    { name: 'deliveredQty', text: 'Leveret antal', allowEdit: false, format: 'Number', widthType: FieldWidthEnum.XXS, footerType: 'Sum' },
    { name: 'status', text: 'Status', tooltipText: 'Forsendelse linjestatus', widthType: FieldWidthEnum.S, foreignKeyTableName: 'WarehouseOutboundOrderStatus', foreignKeyField: 'id', foreignKeySortFields: ['sorting'] },
    { name: 'onHandInventQty', text: 'Lagerbeholdning', format: 'Number', allowEdit: false, widthType: FieldWidthEnum.XXS, onQueryCellInfo: this.gridQueryCellInfoOnHandInventQty },
    { name: 'trackTrace', text: 'Tracking', format: 'Url', urlBase: baseUrlTrackTrace, widthType: FieldWidthEnum.XS },
  ];

  // Data notes view
  private dataNoteDataApiParams: KeyValue[] = [
    { key: '$dataLinkTable', value: 'WarehouseOutboundOrder' },
    { key: '$dataLink2Table', value: 'SalesOrder' }
  ];
  public dataNoteViewMetadata: ViewMetadata = null;
  public dataNoteFieldMetadata: FieldMetadata[] = null;

  constructor(
    dataNoteService: CerDataNoteService,
    private outboundOrderClient: WarehouseOutboundOrderClient,
    private routeService: CerAppRouteService,
    private dialog: CerDialogService,
    private ui: CerAppDialogService) {

    this.dataNoteViewMetadata = dataNoteService.viewMetadataGet(this.dataNoteDataApiParams);
    this.dataNoteFieldMetadata = dataNoteService.fieldMetadata;
  }

  private warehouseOutboundOrderGrid: CerGridComponent;
  public setWarehouseOutboundOrderGrid(grid: CerGridComponent) {
    this.warehouseOutboundOrderGrid = grid;
    this.warehouseOutboundOrderGrid.commmand.subscribe((event: UiCommandEvent) => this.onGridCommand(event));
  }

  private onGridCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdWarehouseOutboundOrderRelease.id:
        this.outboundOrderSetStatusFromEvent(event, WarehouseOutboundOrderStatusEnum.Released);
        break;
      case this.uiCmdWarehouseOutboundOrderComplete.id:
        this.outboundOrderSetStatusFromEvent(event, WarehouseOutboundOrderStatusEnum.Delivered);
        break;
      case this.uiCmdWarehouseOutboundOrderCancel.id:
        this.outboundOrderSetStatusFromEvent(event, WarehouseOutboundOrderStatusEnum.Cancelled);
        break;
      case this.uiCmdSalesOrderSync.id:
        this.outboundOrdersSync();
        break;
      case uiCmdOrderSyncSelected.id:
        this.outboundOrderSyncFromEvent(event);
        break;
      case this.uiCmdWarehouseOutboundOrderReorderCancelledLines.id:
        this.outboundOrderReorderCancelledLinesFromEvent(event);
        break;
    }
  }
  private getOrderNumFromEvent(event: UiCommandEvent): string {
    return event?.rowData?.num;
  }

  // Sync
  private outboundOrderSyncFromEvent(event: UiCommandEvent = null) {
    var outboundOrderNum = this.getOrderNumFromEvent(event);
    if (outboundOrderNum != null) {
      this.outboundOrdersSync(outboundOrderNum);
    }
  }

  private outboundOrdersSync(outboundOrderNum: string = null) {
    var snackBarRef = this.dialog.snackBar('Opdaterer forsendelser', 'Vent venligst...', 10000);
    this.routeService.isLoading$.next(true);
    (outboundOrderNum != null ? this.outboundOrderClient.syncOutboundOrder(outboundOrderNum) : this.outboundOrderClient.syncOutboundOrders())
      .subscribe({
        next: result => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.warehouseOutboundOrderGrid.research();
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.ui.handleApiStatusMessage(error);
        }
      });
  }

  private outboundOrderSetStatusFromEvent(event: UiCommandEvent, status: WarehouseOutboundOrderStatusEnum) {
    var orderNum: string = this.getOrderNumFromEvent(event);
    if (orderNum) {
      this.outboundOrderSetStatus(orderNum, status);
    }
  }

  private outboundOrderSetStatus(orderNum: string, status: WarehouseOutboundOrderStatusEnum) {
    if (orderNum) {
      var snackBarRef = this.dialog.snackBar('Opdatering forsendelse fremdrift', 'Vent venligst...', 10000);
      this.routeService.isLoading$.next(true);
      this.outboundOrderClient.setStatus(orderNum, status).subscribe({
        next: (result) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.dialog.snackBar('Opdatering forsendelse fremdrift', 'Afsluttet', 1000);
          if (result) {
            this.warehouseOutboundOrderGrid.research();
          }
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.dialog.snackBar('Opdatering forsendelse fremdrift', 'Fejl', 1000);
          this.ui.handleApiStatusMessage(error);
        }
      });
    }
  }

  // Reorder cancelled lines
  private outboundOrderReorderCancelledLinesFromEvent(event: UiCommandEvent) {
    var outboundOrderNum = this.getOrderNumFromEvent(event);
    if (outboundOrderNum != null) {
      this.outboundOrderReorderCancelledLines(outboundOrderNum);
    }
  }

  private outboundOrderReorderCancelledLines(orderNum: string) {
    if (orderNum) {
      var snackBarRef = this.dialog.snackBar('Genopretter forsendelse annullerede linjer', 'Vent venligst...', 10000);
      this.routeService.isLoading$.next(true);
      this.outboundOrderClient.createOrderFromCancelledLines(orderNum).subscribe({
        next: (result) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.dialog.snackBar('Genopret forsendelse annullerede linjer', 'Afsluttet', 1000);
          if (result) {
            this.warehouseOutboundOrderGrid.research();
          }
        },
        error: (error) => {
          this.routeService.isLoading$.next(false);
          snackBarRef.dismiss();
          this.dialog.snackBar('Genopret forsendelse annullerede linjer', 'Fejl', 1000);
          this.ui.handleApiStatusMessage(error);
        }
      });
    }
  }

  public gridQueryCellInfoOnHandInventQty(args: any) {
    var dto: any = args.data;

    if ((dto != null) && (dto.orderedQty > 0) && (dto.onHandInventQty < dto.orderedQty)) {
      args.cell.style.backgroundColor = '#FFBBBB';
    }
  }
}