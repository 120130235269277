import { Component, OnDestroy, ViewChild } from '@angular/core';

import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiCommand, UiCommandEvent, UiCommandSourceEnum } from 'src/cer/cer-grid/cer-grid-command.service';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';
import { ProjectTaskService } from '../project-task.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerAppFormGridComponent } from 'src/cer-app/cer-app-form-models/cer-app-form-grid/cer-app-form-grid.component';

@Component({
  selector: 'project-task',
  templateUrl: './project-task.component.html',
  styleUrls: ['project-task.component.css']
})
export class ProjectTaskComponent implements OnDestroy {

  protected panes: CerFormPanesSettings = {
    top: { visible: 'show', size: '30%' },
    main: { visible: 'show', size: '50%', sizeSecondary: '60%' },
    right: { visible: 'show', size: '40%' },
    bottom: { visible: 'collapse', size: '20%' },
    orientationPrimary: 'vertical'
  }

  private projectGrid: CerGridComponent;
  @ViewChild('projectGrid', { static: false }) set projectGridContent(content: CerGridComponent) {
    if (content && !this.projectGrid) {
      this.projectGrid = content;
      this.projectGrid.commmand.subscribe(e => { this.onProjectCommand(e); this.onCommand(e); });
    }
  }

  private taskGrid: CerGridComponent;
  @ViewChild('taskGrid', { static: false }) set taskGridContent(content: CerGridComponent) {
    if (content && !this.taskGrid) {
      this.taskGrid = content;
      this.taskGrid.commmand.subscribe(e => { this.onTaskCommand(e); this.onCommand(e) } );
      this.projectTaskService.setTaskGrid(this.taskGrid);
    }
  }

  private contactGrid: CerGridComponent;
  @ViewChild('contactGrid', { static: false }) set contactGridContent(content: CerGridComponent) {
    if (content && !this.contactGrid) {
      this.contactGrid = content;
      this.contactGrid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  protected projectViewMetadata: ViewMetadata;
  protected projectFieldMetadata: FieldMetadata[];

  protected taskViewMetadata: ViewMetadata;
  protected taskFieldMetadata: FieldMetadata[];
  protected taskFieldGroupMetadata: FieldGroupMetadata[];
  protected taskToolbarCommands: UiCommand[] = [];
  protected taskContextMenuCommands: UiCommand[] = [];

  protected contactViewMetadata: ViewMetadata;
  protected contactFieldMetadata: FieldMetadata[];
  protected contactToolbarCommands: UiCommand[] = [];

  constructor(private projectTaskService: ProjectTaskService, private routeService: CerAppRouteService) {
    this.manage(this.routeService.routeData$.subscribe(routeData => {
      this.setupFromRouteData(routeData);
    }));
  }
  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private setupFromRouteData(routeData: any) {
    this.projectViewMetadata = { ...this.projectTaskService.projectViewMetadata };
    this.projectFieldMetadata = this.projectTaskService.projectFieldMetadata;

    this.taskViewMetadata = { ...this.projectTaskService.taskViewMetadata };
    this.taskFieldMetadata = this.projectTaskService.taskFieldMetadata;
    this.taskFieldGroupMetadata = this.projectTaskService.taskFieldGroupMetadata;
    this.taskToolbarCommands = this.projectTaskService.taskToolbarCommands;
    this.taskContextMenuCommands = this.projectTaskService.taskContextMenuCommands;

    this.contactViewMetadata = this.projectTaskService.contactViewMetadata;
    this.contactFieldMetadata = this.projectTaskService.contactFieldMetadata;
    this.contactToolbarCommands = this.projectTaskService.contactToolbarCommands;

    if (routeData) {
      this.setupTaskMode(routeData.type, routeData.params);
    }
  }

  private taskMode: boolean = false;
  private contactMode: boolean = true;
  protected taskDataParent: boolean  = true;
  protected taskDataChild: boolean  = true;
  private setupTaskMode(type: string, params: string[]) {
    this.taskMode = (type == 'task');
    if (this.taskMode) {
      this.taskDataChild = false;
      this.panes.top.visible = 'hide';
      this.panes.main.size = '70%';
      this.panes.bottom.size = '30%';
      if (params) {
        this.taskViewMetadata.dataApiParams = params;
      }
    }
    else {
      if (params && params.length > 0) {
        this.contactMode = true;
        this.projectViewMetadata.dataApiParams = params;
        this.projectViewMetadata.allowCreate = false;
        this.projectViewMetadata.allowEdit = false;
        this.projectViewMetadata.allowDelete = false;
      }
      else {
        this.contactMode = false;
      }
      this.taskViewMetadata.parentPrimaryKey = 'id';
      this.taskViewMetadata.parentForeignKey = 'projectNode';
      this.taskViewMetadata.dataApiParams = ['$childTasks'];
    }
  }

  // Description
  protected description$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected editDescription() {
    this.projectTaskService.editDescription(this.taskGrid, this.description$);
  }

  public onProjectCommand(event: UiCommandEvent) {
    if (this.contactMode) {
      this.checkContactVisibility(event, 'taskType');
    }
  }

  public onTaskCommand(event: UiCommandEvent) {
    if (this.taskMode) {
      this.checkContactVisibility(event, 'type');
    }
  }

  private checkContactVisibility(event: UiCommandEvent, fieldName: string) {
    if (event.source == UiCommandSourceEnum.ActionComplete && event.actionType == 'dataBound') {
      if (this.panes.bottom.visible != 'show') {
        var hasContact = this.hasContactProjectTaskType(event, fieldName);
        if (hasContact) {
          this.form.panesService.visibleSet('bottom', 'show');
        }
      }
    }
  }

  private hasContactProjectTaskType(event: UiCommandEvent, fieldName: string) {
    var hasContact = false;
    if (event.rowsData && event.rowsData.length > 0) {
      var grid: CerAppFormGridComponent = <CerAppFormGridComponent> event.appComponent;
      var fields: FieldMetadata[] = grid.fieldMetadata.filter(field => field.name == fieldName);
      if (fields && fields.length > 0) {
        var field: FieldMetadata = fields[0];
        var types: any[] = field.foreignKeyData.filter(rowType => rowType.productRoles == true);
        if (types && types.length > 0) {
          event.rowsData.forEach(row => {
            types.forEach(t => {
              if (t.num == row[fieldName]) {
                hasContact = true;
              }
            });
          });
        }
      }
    }
    return hasContact;
  }

  public onCommand(event: UiCommandEvent) {
    this.projectTaskService.onCommand(event, this.taskGrid, this.contactGrid, this.description$);
  }

}

