import { Component, ViewChild } from '@angular/core';
import { AppStateService } from 'src/app-core/app-state/app-state.service';
import { FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../../cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum, UiCommand, UiKeyboardShortcut } from '../../../cer/cer-grid/cer-grid-command.service';
import { ExchangeMsgQueueClient as Client } from '../../../app/api';

const uiCmdExchangeRelease: UiCommand = { id: 'ExchangeRelease', text: 'Frigiv markerede', iconCss: "e-menu-icon e-icons e-check", tooltipText: 'Frigiv markerede poster til dataudveksling', target: ".e-content", needSelection: true };

@Component({
  selector: 'app-exchange-msg-queue',
  templateUrl: './app-exchange-msg-queue.component.html',
  styleUrls: ['./app-exchange-msg-queue.component.css']
})
export class AppExchangeMsgQueueComponent {

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.grid.commmand.subscribe(e => this.onCommand(e));
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'udveksling-forslag', dataApiName: 'ExchangeMsgQueue', text: 'Forslag',
    primaryKey: 'id', allowEdit: false, allowCreate: false, allowDelete: true
  };

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', allowEdit: false, visible: false },
    //    { name: 'type', text: 'Type id', tooltipText: 'Id for at type', allowEdit: false, allowEditOnCreate: true, visible: false },
    { name: 'type', text: 'Type', tooltipText: 'Beskrivelse af beskedtype', allowEdit: false, allowEditOnCreate: true, widthType: FieldWidthEnum.L, foreignKeyTableName: 'ExchangeType' },
    { name: 'refId', text: 'Post id', tooltipText: 'Id for post', allowEdit: false, allowEditOnCreate: true, visible: false },
    { name: 'num', text: 'Nummer', tooltipText: 'Nøgle på post', allowEdit: false, allowEditOnCreate: true, widthType: FieldWidthEnum.M },
    { name: 'name', text: 'Navn', tooltipText: 'Navn på post', allowEdit: false, allowEditOnCreate: true, widthType: FieldWidthEnum.L },
    { name: 'created', text: 'Oprettet', tooltipText: 'Dato for oprettelse', allowEdit: false, format: 'DateLong', orderBy: 'Ascending' }
    ];

  public toolbarCommands: UiCommand[] = [uiCmdExchangeRelease];
  public contextMenuCommands: UiCommand[] = [uiCmdExchangeRelease];
  public keyboardShortcuts: UiKeyboardShortcut[] = [{ cmd: uiCmdExchangeRelease, code: 'KeyF', alt: false, ctrl: true, shift: true, enabled: true }];

  constructor(private client: Client, private appStateService: AppStateService) {
  }

  // Data
  /* (Use Auto-generated instead)
  public delete(data: Dto[]) {
    var idList: number[] = this.grid.appGridService.rowSelectedRowsId();
    if (idList.length > 0) {
      this.client.delete(idList).subscribe(
        data => this.grid.appGridService.cmdResearch(),
        error => this.appStateService.handleApiError(error)
      );
    }
  }
  */

  public release() {
    var idList: number[] = this.grid.selection.rowsSelectedId();
    if (idList.length > 0) {
      this.grid.selection.rowSelectedKeep();
      this.client.release(idList).subscribe(
        data => this.grid.research(),
        error => this.appStateService.handleApiError(error)
      );
    }
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Delete:
            //this.delete(event.args.data);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.RowSelect:
            //this.rowSelect();
            break;
        }
        break;
      default:
        switch (event.commandId) {
          case uiCmdExchangeRelease.id:
            this.release();
            break;
        }
    }
  }
}
