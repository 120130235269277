import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class BankCreditCardFormAdapter extends CerAppFormModelAdapter {

  public override init() {

    this.viewMetadata = {
      name: 'kreditkort', dataApiName: 'BankCreditCard', text: 'Kreditkort',
      primaryKey: 'id', baseFields: ['id','history']
    };

    this.fieldMetadata =
      [
      { name: 'name', text: 'Navn', tooltipText: 'Navn på kreditkort', widthType: FieldWidthEnum.L, required: true },
      { name: 'cardNumber', text: 'Kortnr.', required: true },
      { name: 'cardNumberPublic', text: 'Kortnr. anonymt', required: false }, // Auto-init on save
      { name: 'bankAccount', text: 'Bankkonto', foreignKeyTableName: 'BankAccount', required: true },
      { name: 'user', text: 'Udstedt til', foreignKeyTableName: 'User', foreignKeyField: 'id', foreignKeyValue: 'shortName' },
      { name: 'active', text: 'Aktiv', format: 'CheckBox' }
      ];
  }
}
