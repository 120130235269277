<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid
      #mainGrid
      id="mainGrid"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      dataRelationRole="parent"
    ></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid
      #invoiceGrid
      id="lineGrid"
      [viewMetadata]="lineViewMetadata"
      [fieldMetadata]="lineFieldMetadata"
      [toolbarCommands]="lineToolbarCommands"
      toolbarTitle="metadata-text"
      toolbarType="simple"
      dataRelationRole="child"
      dataParentSenderId="mainGrid"
    ></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid
      #invoiceGrid
      id="invoiceGrid"
      [viewMetadata]="invoiceViewMetadata"
      [fieldMetadata]="invoiceFieldMetadata"
      [toolbarCommands]="invoiceToolbarCommands"
      toolbarType="simple"
      [pageSize]=1000
      toolbarTitle="metadata-text"
      dataRelationRole="childAndParent"
      dataParentSenderId="mainGrid"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarCommands]="detailToolbarCommands"
      toolbarTitle="metadata-text"
      toolbarType="simple"
      dataRelationRole="child"
      dataParentSenderId="mainGrid"
    ></cer-grid>
  </ng-template>
</cer-form>
