import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';

export class VoucherMessageChannelUserFormAdapter extends CerAppFormModelAdapter {
  constructor() {
    super();

    this.viewMetadata = {
      name: 'voucher-channel-user', dataApiName: 'VoucherMessageChannelUser', text: 'Superbrugere',
      primaryKey: 'id', titleFields: ['id'], baseFields: ['id', 'history']
    };
    // Fields
    this.fieldMetadata =
      [
        { name: 'channelId', text: 'Kanal', tooltipText: 'Bilagskanal', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.M, foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeyTableName: 'VoucherMessageChannel' },
        { name: 'userId', text: 'Bruger', tooltipText: 'Bruger', required: true, allowEdit: true, allowEditOnCreate: true, widthType: FieldWidthEnum.M, foreignKeyField: 'id', foreignKeyValue: 'name', foreignKeyTableName: 'User', foreignKeyTableParams: ['$filterSystemRows'] },
        { name: 'primary', text: 'Primær', tooltipText: 'Primær kanal', allowEdit: true, defaultValue: '', allowEditOnCreate: true, format: 'CheckBox' },
        { name: 'admin', text: 'Synkroniser', tooltipText: 'Kan synkronisere', allowEdit: true, allowEditOnCreate: true, format: 'CheckBox' },
        { name: 'ledgerJournalName', text: 'Finanskladde', tooltipText: 'Finanskladde som bilag placeres i', foreignKeyTableName: 'FinanceLedgerJournalName', foreignKeyField: 'num' }
      ];
  }
}
