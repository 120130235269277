// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// SyncFusion
import { GridComponent, Row, SelectionSettingsModel, row } from '@syncfusion/ej2-angular-grids';

// Cerium
import { GridDesign } from './cer-grid.component';
import { CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum } from './cer-grid-command.service';

@Injectable()
export class CerGridSelectionService implements OnDestroy {
  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid edit
  public grid: GridComponent;
  public design: GridDesign;

  // Selection
  private selectionInitialFirst: boolean = true;
  private selectionInitialFirstNoFocus: boolean = true;
  public rowSelectedKeepIdx: number = null;
  //public rowSelectedKeepFocus: boolean = false;

  // Constructor
  constructor(private command: CerGridCommandService) {
    this.subscriptionManager.add(command.commandPre$.subscribe(cmd => this.onCommandPre(cmd)));
    this.subscriptionManager.add(command.commandPost$.subscribe(cmd => this.onCommandPost(cmd)));
  }

  // Subscriptions
  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  public init(grid: GridComponent, design: GridDesign) {
    this.grid = grid;
    this.design = design;

    this.initGridSelectionSettings();
  }

  private initGridSelectionSettings() {
    var s: SelectionSettingsModel = {};

    //s.checkboxMode = 'ResetOnRowClick';
    //s.checkboxOnly = true;
    //s.enableSimpleMultiRowSelection = true;

    this.selectionInitialFirst = (['first', 'first-no-focus'].includes(this.design.selectionInitial));
    this.selectionInitialFirstNoFocus = (['first-no-focus'].includes(this.design.selectionInitial));
    switch (this.design.selectionMode) {
      case 'row':
        s.mode = 'Row';
        s.type = 'Single'
        break;
      case 'checkboxMulti':
        s.mode = 'Row';
        s.type = 'Multiple';
        break;
      case 'checkboxSingle':
        s.mode = 'Row';
        s.type = 'Single';
        //s.checkboxOnly = true;
        break;
      case 'multiple':
        s.mode = 'Row';
        s.type = 'Multiple';
        break;
      case 'cellBox':
        s.mode = 'Cell';
        s.type = 'Multiple';
        s.cellSelectionMode = 'Box';
        break;
    }
    this.grid.selectionSettings = s;
    this.grid.selectionSettings.type = s.type;  // Change detect trigger?
  }


  public onCommandPre(event: UiCommandEvent) {
    if (event.source == UiCommandSourceEnum.ActionBegin) {
      switch (event.actionType) {
        case UiActionTypeEnum.BeginEdit:
          this.rowSelectedKeep(event.args.index);
          break;
        case UiActionTypeEnum.Save:
          this.rowSelectedKeep(event.args.index);
          break;
        case UiActionTypeEnum.Delete:
          this.rowSelectedKeep();
          break;
        case UiActionTypeEnum.RowSelecting:
          this.onRowSelectingPre(event.args);
          break;
        case UiActionTypeEnum.RowSelect:
          this.onRowSelectedPre(event.args);
          break;
        case UiActionTypeEnum.RowDeselect:
          //this.onRowDeselectBegin(cmd.args);
          break;
      }
    }
  }

  public onCommandPost(event: UiCommandEvent) {
    if (event.source == UiCommandSourceEnum.ActionComplete) {
      switch (event.actionType) {
        case UiActionTypeEnum.DataBound:
          this.rowSelectKeepIdxOrFirst();
          break;
        case UiActionTypeEnum.Refresh:
          if (this.design?.autoFocus) {
            this.rowSelectKeepIdx();
          }
          break;
        case UiActionTypeEnum.Save:
          if (event.args.action == "edit" && this.grid.editSettings.mode == "Dialog") {
            this.grid.selectRow(event.args.rowIndex);
            //this.rowSelectedKeep(args.rowIndex);
            //this.rowSelectedKeepFocus = true;
            //this.grid.focusModule.focusContent();
          }
          else {
            //    this.rowSelectKeepIdx();
          }
          break;

      }
    }
  }

  private onRowSelectingPre(args: any) {
    if (this.design?.selectionMode == 'checkboxSingle') { //
      if (this.grid.getSelectedRecords().length) {
        this.grid.clearSelection();
      }
    }
  }

  public onRowSelectedPre(args: any) {
    this.nextSelectionIsInternal(); // Make sure next selection can focus
    /*if (this.grid.isEdit) {
      this.rowSelectedKeep(args.index);
      args.cancel = true;
      this.grid.endEdit();
    }
    else {*/

    //if (this.rowSelectedKeepFocus) {
    //  this.rowSelectedKeepFocus = false;
    //  var idx = args.rowIndex;
    //  if (idx) {
    //  this.grid.clearSelection();
    //this.grid.element.focus();
    //  this.grid.focusModule.focusContent();
    //  this.grid.selectRow(idx);
    //  }
    //}
  }

  // Row selection
  public nextSelectionIsExtenal() {
    if (this.design?.autoFocus && this.selectionInitialFirstNoFocus) {
      // Make sure intial selection from parent will not change focus to selection
      this.grid.selectionModule.preventFocus = true; // Next selection should not focus
    }
  }

  public nextSelectionIsInternal() {
    if (this.design?.autoFocus && this.selectionInitialFirstNoFocus) {
      this.grid.selectionModule.preventFocus = false; // Further selections should focus
    }
  }

  // Set Selection
  public rowLastIdx(): number {
    var rows = this.grid.getRows()
    return rows ? rows.length - 1 : null; // Last visible row index
  }

  public rowSelectFirst(keep: boolean = false): boolean {
    return this.rowSelectByIdx(0);
  }

  public rowSelectByIdx(idx: number, keep: boolean = false): boolean {
    var ok: boolean = (keep && this.rowSelectedIdx != null);  // Keep existing selection

    if (!ok) {
      var selectIdx = idx <= this.rowLastIdx() ? idx : this.rowLastIdx();  // Re-select idx or last idx visible list shorter
      if (selectIdx >= 0) {
        this.grid.selectRow(selectIdx);
      }
      ok = true;
    }
    return ok;
  }

  public rowSelectRefresh(research: boolean = false): boolean {
    var selectedIdx = this.rowSelectedIdx();
    if (selectedIdx) {
      if (research) {
        // TODO: refresh this record ?
      }
      this.rowSelectByIdx(selectedIdx);
    }

    return (selectedIdx != null);
  }

  public rowSelectKeepIdx(/*setFocus: boolean = false*/): boolean {
    var ok: boolean = false;
    if (this.rowSelectedKeepIdx != null) {
      ok = this.rowSelectByIdx(this.rowSelectedKeepIdx);
      //if (setFocus) {
      //  this.rowSelectedKeepFocus = true;
      //}
    }
    return ok;
  }

  public rowSelectKeepIdxOrFirst() {
    if (this.selectionInitialFirst) {
      if (this.rowSelectedIdx() == null) {
        if (!this.rowSelectKeepIdx() && this.design.autoFocus) {
          this.rowSelectFirst();
        }
      }
    }
    else {
      this.rowSelectedClear();
    }
    this.rowSelectedKeepIdx = null;
  }

  public rowSelectClearExceptIdx(idx: number) {
    var rows = this.rowSelectedRowsIdx();
    rows.forEach(rowIdx => { if (rowIdx != idx) { this.rowSelectToggle(rowIdx) } });
  }

  public rowSelectToggle(idx: number) {
    this.grid.selectRow(idx, true);
  }

  // Get Selection
  public rowSelectedActive(): boolean {
    return (this.grid.getSelectedRecords().length > 0);
  }

  public rowSelectedRowsIdx(): number[] {
    return this.grid.getSelectedRowIndexes();
  }

  public rowSelectedIdx(): number {
    var idx: number = this.grid.selectedRowIndex;
    if (idx == -1) {
      idx = null;
    }
    return idx;
  }

  public rowsSelectedData(): object[] {
    return this.grid.getSelectedRecords();
  }

  public rowsSelectedId(): number[] {
    var idList: number[] = [];
    this.rowsSelectedData().forEach(row => idList.push((<any>row).id));
    return idList;
  }

  public rowSelectedData(): object {
    var selectedRow1: object = null;
    var selectedRows: object[] = this.rowsSelectedData();
    if (selectedRows.length >= 1) {
      selectedRow1 = selectedRows[0];
    }
    return selectedRow1;
  }

  public rowSelectedKeep(idx: number = null, relativeRowIdx: number = null): number {
    if (idx !== undefined) {
      var keepIdx: number = (idx ? idx : this.rowSelectedIdx());
      if (relativeRowIdx != null) {
        keepIdx = this.rowRelativeIdx(keepIdx, relativeRowIdx);
      }
      this.rowSelectedKeepIdx = keepIdx;
      return this.rowSelectedKeepIdx;
    }
    return null;
  }

  public rowRelativeIdx(idxOrig: number, relativeIdx: number): number {
    var idx = idxOrig;
    if (relativeIdx <= -1) {
      if (idx + relativeIdx >= 0) {
        idx += relativeIdx;
      }
      else {
        idx = 0;
      }
    }
    else if (relativeIdx >= 1) {
      if (idx + relativeIdx <= this.grid.getRows().length) {
        idx += relativeIdx;
      }
      else {
        idx = this.grid.getRows().length;
      }

    }
    return idx;
  }

  public rowSelectedClear() {
    this.grid.clearSelection();
  }

  public rowSelectedAllowMulti(): boolean {
    return this.grid.selectionSettings.type == 'Multiple';
  }

  // Row object
  private contentModule(): any {
    return this.grid?.contentModule;
  }


  public rowRemove(row: any) {
    var data = this.grid.currentViewData;
    if (data?.length > 0 && row) {
      var removeIndex = data.findIndex((currRow: any) => currRow['id'] === row['id']);
      if (removeIndex >= 0) {
        data.splice(removeIndex, 1); // remove data based on selected index
        this.command.cmdDispatchCommand(UiActionTypeEnum.Redraw);
      }
    }
  }
  public rowsObjects(): any[] {
    return this.contentModule()?.getRows();
  }

  public rowObjectById(id: any): any {
    var pkName = this.grid?.getPrimaryKeyFieldNames()[0];
    return (pkName?.length > 0) ? this.rowsObjects().filter((row: any) => row.isDataRow == true && row.data[pkName] === id)[0]
      : null;
  }

  private rowObjectByElementId(rowElementId: any): any {
    return this.grid.getRowObjectFromUID(rowElementId);
  }

  private rowObjectByElement(rowElement: Element): any {
    return this.rowObjectByElementId(rowElement?.getAttribute('data-uid'));
  }

  // Row data
  public rowDataById(id: any): any {
    return this.rowObjectById(id)?.data;
  }

  public rowDataSetById(id: any, rowData: any) {
    var rowElement: Element = this.rowElementById(id);
    if (rowElement) {
      var stateList: any[] = this.rowElementState(rowElement);
    }
    this.grid.setRowData(id, rowData);
    if (rowElement) {
      this.rowElementStateSet(rowElement, stateList);
    }
  }

  public static rowDataClone(data: any): any {
    return Object.assign({}, data);
  }

  public static rowDataIsEqual(dataRow1: any, dataRow2: any): boolean {
    var dataRow1Json = JSON.stringify(dataRow1);
    var dataRow2Json = JSON.stringify(dataRow2);
    if (dataRow1Json === dataRow2Json) {
      return true;
    }
    return false;
  }

  public static rowsDataEqual(dataRows1: any[], dataRows2: any[]): boolean {
    if (dataRows1?.length != dataRows2?.length) {
      return false;
    }
    for (var i = 0; i < dataRows1.length; i++) {
      if (!CerGridSelectionService.rowDataIsEqual(dataRows1[i], dataRows2[i])) {
        return false;
      }
    }
    return true;
  }

  // Row element
  private gridElement(): HTMLElement {
    return this.grid?.element;
  }

  private rowElementByElementUniqueId(elementUniqueId: any): Element {
    if (elementUniqueId) {
      return this.gridElement().querySelector('[data-uid=' + elementUniqueId + ']');
    }
    return null;
  }

  public rowElementById(id: any): Element {
    var rowObject: any = this.rowObjectById(id);
    var rowElement: Element = this.rowElementByElementUniqueId(rowObject?.uid);
    return rowElement;
  }


  private rowElementState(rowElement: Element): any[] {
    var stateList: any[] = [];
    var cells: NodeListOf<Element> = rowElement?.querySelectorAll('.e-rowcell');
    for (var i = 0, len = cells.length; i < len; i++) {
      if (cells[i] instanceof HTMLElement) {
        var cell: HTMLElement = cells[i] as HTMLElement;
        var state: any = {};
        state.classList = [].slice.call(cell.classList);

        if (cell == document.activeElement) {
          state.focus = true;
        }
        stateList.push(state);
      }
    }
    return stateList;
  }

  // Restore state of classes + focus after row data update
  private rowElementStateSet(rowElement: Element, stateList: any[]) {
    if (rowElement && stateList) {
      var j: number = 0;
      var cells: NodeListOf<Element> = rowElement?.querySelectorAll('.e-rowcell');
      for (var i = 0, len = cells?.length; i < len; i++) {
        if (j < stateList.length && cells[i] instanceof HTMLElement) {
          var cell: HTMLElement = cells[i] as HTMLElement;
          var cellClassList: string[] = [].slice.call(cell.classList);
          var state: any = stateList[j]; j++;
          // classList example: 'e-rowcell e-focus e-focused e-selectionbackground e-active'
          if (state.classList) {
            if (state.classList.includes('e-focus') && !cellClassList.includes('e-focus')) {
              state.classList.splice(state.classList.indexOf('e-focus'), 1);
            }
            if (state.classList.includes('e-focused') && !cellClassList.includes('e-focused')) {
              state.classList.splice(state.classList.indexOf('e-focused'), 1);
            }
            state.classList.forEach((c: string) => { if (!cellClassList.includes(c)) { cell.classList.add(c) } });
            cellClassList.forEach((c: string) => { if (!state.classList.includes(c)) { cell.classList.remove(c) } });
          }
          if (state.focus && cell != document.activeElement) {
            if (!document.activeElement?.classList.contains('e-rowcell')) {
              cell.focus();
            }
          }
        }
      }
    }
  }
}
