<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      id="mainGrid"
      [viewMetadata]="viewMetadata"
      [dataParent]="true"
      [fieldMetadata]="fieldMetadata"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [dataChild]="true"
      [fieldMetadata]="detailFieldMetadata"
    ></cer-grid>
  </ng-template>
</cer-form>
