import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class PartyFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'modparter', dataApiName: 'Party', text: 'Modparter', numberSeq: 'Party',
      primaryKey: 'id', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'address', text: 'Adresse', format: 'TextArea', widthType: FieldWidthEnum.XXXL },
      { name: 'email', text: 'E-mail', format: 'Email'},
      { name: 'phone', text: 'Telefon', format: 'Phone' },
      { name: 'isAuthor', text: 'Forfatter', format: 'CheckBox', defaultValue: false },
      { name: 'isPublisher', text: 'Forlag', format: 'CheckBox', defaultValue: false },
      { name: 'isAgent', text: 'Agent', format: 'CheckBox', defaultValue: false },
      { name: 'legacyId', text: 'Opr. Id', tooltipText: 'Id fra oprindelig database', visible: false, format: 'Integer'}
    ];
  }
}
