// Angular
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiKeyboardShortcut } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldMetadata, FieldWidthEnum, ViewMetadata } from 'src/cer/cer-data/cer-data.service';

import { RoyaltyService } from './royalty.service';
import { RoyaltyApproveStatusClass, RoyaltyApproveStatusFormatter } from './royalty-approve-status-formatter';
import { CerFormPanesSettings, uiCmdPaneRightToggle } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'royalty-forlag',
  templateUrl: './royalty-forlag.component.html',
  providers: [RoyaltyService]
})

export class RoyaltyForlagComponent implements OnDestroy{

  protected panes: CerFormPanesSettings = {
    'main': { visible: 'show', size: '65%' },
    'right': { visible: 'show', size: '35%' },
    orientationPrimary: 'vertical'
  };

  protected viewMetadata: ViewMetadata = {
    name: 'royalty-forlag', dataApiName: "RoyaltyForlagView", text: "Royalty forlag",
    primaryKey: "fid", allowEdit: false, allowCreate: false, allowDelete: false
  };

  protected fieldMetadata: FieldMetadata[] = [
    { name: "fid", text: "FID", visible: false },
    { name: "afregningId", text: "Modtager ID", minWidth: "60px", maxWidth: "60px", width: "60px" },
    { name: "afregningNavn", text: "Modtager", minWidth: "60px", maxWidth: "60px", width: "60px"  },
    { name: "afregningAdresse1", text: "Modtager adresse 1", visible: false },
    { name: "afregningAdresse2", text: "Modtager adresse 2", visible: false },
    { name: "afregningAdresse3", text: "Modtager adresse 3", visible: false },
    { name: "afregningLand", text: "Modtager Land", visible: false },
    { name: "afregningEmail", text: "Modtager e-mail", format: 'Email' },
    { name: "forlagId", text: "Forlag ID", width: "60px", visible: false },
    { name: "forlagNavn", text: "Forlag", widthType: FieldWidthEnum.XXL, visible: false },
    { name: "forlagAdresse1", text: "Forlag adresse 1", visible: false },
    { name: "forlagAdresse2", text: "Forlag adresse 2", visible: false },
    { name: "forlagAdresse3", text: "Forlag adresse 3", visible: false },
    { name: "forlagLand", text: "Forlag land", visible: false },
    { name: "forlagEmail", text: "Forlag e-mail", format: 'Email', visible: false },
    { name: "royaltyAmount", text: "Royalty saldo", format: 'Amount' },
    { name: "royaltyDue", text: "Royalty skyldig", format: 'Amount' },
    { name: "valutaKode", text: "Valuta", widthType: FieldWidthEnum.XS },
    { name: "valutaKurs", text: "Kurs", format: 'Amount', visible: false },
    { name: "antalStatus0", text: "Til godkendelse", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(0) },
    { name: "antalStatus7", text: "Godkend rettelse", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(7) },
    { name: "antalStatus1", text: "Godkendt", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(1) },
    { name: "antalStatus3", text: "Godkendt og lukkes", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(3) },
    { name: "antalStatus2", text: "Afvist godkendt", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(2) },
    { name: "antalStatus4", text: "Afsendt", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(4) },
    { name: "antalStatus5", text: "Annulleret", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(5) },
    { name: "antalStatus6", text: "Udbetalt", widthType: FieldWidthEnum.XS, format: 'Chip', formatClass: RoyaltyApproveStatusClass(6) }
  ];

  private paneRightToggleCmd: UiCommand = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Kontrakter', tooltipText: 'Vis kontrakter i højre side [Ctrl]+[Pil venstre]' });
  private showDetailsCmd: UiCommand = { id: 'details', text: "Vis detaljer (Alt+O)", iconCss: "e-menu-icon e-icons e-edit", target: ".e-content" };
  private reCalcRoyaltyCmd: UiCommand = { id: 'reCalcRoyalty', text: "Genberegn alle kontrakter", iconCss: "e-menu-icon material-icons e-table-update", target: ".e-headercontent" };

  protected keyboardShortcuts: UiKeyboardShortcut[] =
  [
          { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.paneRightToggleCmd },
          { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.paneRightToggleCmd },
          { code: 'KeyO', alt: true, ctrl: false, shift: false, enabled: true,  cmd: this.showDetailsCmd }

    ];

  protected toolbarCommands: UiCommand[] =
  [
    this.reCalcRoyaltyCmd,
    this.paneRightToggleCmd
  ]

  protected contextMenuCommands: UiCommand[] =
    [
      this.paneRightToggleCmd,
      { id: 'sep', text: '', separator: 'content' },
      { id: 'wordMerge', text: "Dan afregning", iconCss: "e-menu-icon e-icons-org e-wordexport", target: ".e-content" },
      { id: 'pdfMerge', text: "Dan afregning (PDF) ", iconCss: "e-menu-icon e-icons e-pdfexport", target: ".e-content" },
      { id: 'sep', text: '', separator: 'content' },
      { id: 'wordMergeDraft', text: "Dan afregning (kladde)", iconCss: "e-menu-icon e-icons-org e-wordexport", target: ".e-content" },
      { id: 'pdfMergeDraft', text: "Dan afregning (kladde/PDF) ", iconCss: "e-menu-icon e-icons e-pdfexport", target: ".e-content" },
      this.reCalcRoyaltyCmd
    ];

  protected detailViewMetadata: ViewMetadata = {
    name: 'royalty-approve', dataApiName: "RoyaltyAfregningAktuelView", text: "Royalty godkendelser", textSingular: "Royalty godkendelser",
    primaryKey: "id", allowEdit: false, allowCreate: false, allowDelete: false,
    parentForeignKey: "fid", parentPrimaryKey: "fid"
  };

  protected detailFieldMetadata: FieldMetadata[] = [
    { name: "id", text: "Royalty ID", visible: false, allowEdit: false },
    { name: "redaktorNavn", text: "Redaktør" },
    { name: "afregningId", text: "Modtager ID", width: "60px", visible: false },
    { name: "afregningNavn", text: "Modtager", widthType: FieldWidthEnum.XXL, visible: false },
    { name: "forlagId", text: "Forlag ID", width: "60px", visible: false },
    { name: "forlagNavn", text: "Forlag", visible: false },
    { name: "forfatterNavn", text: "Forfatter" },
    { name: "hID", text: "Varenummer ID", visible: false },
    { name: "hovedKontraktId", text: "HKontrakt ID", visible: false },
    { name: "hovedKontrakt", text: "Hovedkontrakt", visible: false },
    { name: "kontrakt", text: "Kontrakt", width: "90px" },
    { name: "vareNR", text: "Varenr", visible: false },
    { name: "arbTitel", text: "Arb. titel" },
    { name: "bogTitel", text: "Bog titel", visible: false },
    { name: "kontraktType", text: "Kontrakt type", visible: false },
    { name: "afregningstype", text: "Afregn. type", visible: false },
    { name: "afregningAdresse1", text: "Modtager adresse 1", visible: false },
    { name: "afregningAdresse2", text: "Modtager adresse 2", visible: false },
    { name: "afregningAdresse3", text: "Modtager adresse 3", visible: false },
    { name: "afregningLand", text: "Modtager Land", visible: false },
    { name: "forlagAdresse1", text: "Adresse 1", visible: false },
    { name: "forlagAdresse2", text: "Adresse 2", visible: false },
    { name: "forlagAdresse3", text: "Adresse 3", visible: false },
    { name: "forlagLand", text: "Land", visible: false },
    { name: "endeligSalgspris", text: "Endelig salgspris", format: 'Amount', visible: false },
    { name: "royaltyAmount", text: "Royalty beløb", format: 'Amount' },
    { name: "valutaKode", text: "Valuta", width: "40px" },
    { name: "valutaKurs", text: "Kurs", format: 'Amount', visible: false },
    { name: "godkendt", text: "Godkendt", visible: false },
    { name: "godkendtAf", text: "Godkendt af", visible: false },
    { name: "godkendtDato", text: "Godkendt dato", visible: false, format: 'DateShort' },
    { name: "afsluttet", text: "Afsluttet", visible: false },
    { name: "afsluttetAf", text: "Afsluttet af", visible: false },
    { name: "afsluttetDato", text: "Afsluttet dato", visible: false, format: 'DateShort' },
    { name: "createdDate", text: "Beregnet dato", visible: false, format: 'DateShort' },
    { name: "status", text: "Status kode", visible: false },
    { name: "statusText", text: "Status", widthType: FieldWidthEnum.XL, formatter: new RoyaltyApproveStatusFormatter() }
  ];

  protected detailToolbarCommands: UiCommand[] =
    [
      this.showDetailsCmd
    ]

  protected detailContextMenuCommands: UiCommand[] =
    [
      this.showDetailsCmd,
      { id: 'sep', text: '', separator: 'content' },
      { id: 'wordMergeDraft', text: "Dan afregning (kladde)", iconCss: "e-menu-icon e-icons e-wordexport", target: ".e-content" },
      { id: 'pdfMergeDraft', text: "Dan afregning (kladde/PDF) ", iconCss: "e-menu-icon e-icons e-pdfexport", target: ".e-content" },
    ];


  private form: CerFormComponent;
  @ViewChild(CerFormComponent) set setForm(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onCommand(e));
    }
  }

  private grid: CerGridComponent;
  @ViewChild('Grid') set setGrid(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
      this.royaltyService.onRefresh$.subscribe(() => this.grid.research());
    }
  }

  private detailGrid: CerGridComponent;
  @ViewChild('DetailGrid') set setDeailGrid(content: CerGridComponent) {
    if (content && !this.detailGrid) {
      this.detailGrid = content;
    }
  }

  constructor(
    private royaltyService: RoyaltyService,
    public dialog: MatDialog,
  ) {
  }

  ngOnDestroy(): void {
    this.royaltyService.onRefresh$.unsubscribe();
  }

  protected onCommand(e: UiCommandEvent) {
    if (e.actionType == UiActionTypeEnum.RowDoubleClick) {
      e.cancel = true
    }
    else {
      this.royaltyService.onGridCommand(e, this.dialog, this.grid);
    }
  }

  protected onDetailCommand(e: UiCommandEvent) {
    this.royaltyService.onGridCommand(e, this.dialog, this.detailGrid);
  }
}





