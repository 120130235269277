import { FieldWidthEnum } from '../../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductThemeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'temakoder', dataApiName: 'ProductTheme', text: 'Temakoder', textSingular: 'Temakode',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'num', text: 'Temakode', tooltipText: 'Kode for tema', allowEdit: true, allowEditOnCreate: true, required: true, widthType: FieldWidthEnum.XS, orderBy: 'Ascending', orderByPriority: 1, filterOperator: 'startswith' },
      { name: 'description', text: 'Beskrivelse', format: 'Text', widthType: FieldWidthEnum.XL }
    ];

  }
}
