<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid
      #selectedGrid
      id="selectedGrid"
      [viewMetadata]="selectedViewMetadata"
      [fieldMetadata]="selectedFieldMetadata"
      [toolbarCommands]="selectedToolbarCommands"
      [contextMenuCommands]="selectedContextMenuCommands"
      [keyboardShortcuts]="selectedKeyboardShortcuts"
      selectionMode="checkboxMulti"
      selectionInitial="none"
    ></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid
      #grid
      id="grid"
      [viewMetadata]="viewMetadata"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
      [keyboardShortcuts]="keyboardShortcuts"
      [dataParent]="true"
      selectionMode="checkboxMulti"
      selectionInitial="none"
    ></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid
      #detailGrid
      id="detailGrid"
      [viewMetadata]="detailViewMetadata"
      [fieldMetadata]="detailFieldMetadata"
      [toolbarCommands]="detailToolbarCommands"
      [contextMenuCommands]="detailContextMenuCommands"
      [keyboardShortcuts]="detailKeyboardShortcuts"
      selectionMode="checkboxMulti"
      selectionInitial="none"
    ></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid
      #balanceGrid
      id="balanceGrid"
      [viewMetadata]="balanceViewMetadata"
      [fieldMetadata]="balanceFieldMetadata"
      [toolbarCommands]="balanceToolbarCommands"
      [keyboardShortcuts]="balanceKeyboardShortcuts"
      selectionInitial="none"
    ></cer-grid>
  </ng-template>
</cer-form>
