import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class SalesOrderTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-ordretyper', dataApiName: 'SalesOrderType', text: 'Salg ordretyper',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
    ];
   
  }
}
