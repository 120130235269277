import { FieldWidthEnum } from '../../cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../platform/models/cer-app-form-model-adapter';
import { UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';

export class AppDataProcessStageFormAdapter extends CerAppFormModelAdapter {

  //private uiCmdReOpen: UiCommand = { id: 'Reopen', text: 'Genåbn afstemning', tooltipText: 'Genåbn alle afstemte transaktioner (ALT+O)', iconCss: 'e-menu-icon e-icons e-research', target: ".e-content", needSelection: true };
  //private uiCmdReOpenShortcut: UiKeyboardShortcut = { cmd: this.uiCmdReOpen, code: 'KeyO', shift: false, ctrl: false, alt: true };

  public override init() {

    this.viewMetadata = {
      name: 'data-proces-stadier', dataApiName: 'DataProcessStage', text: 'Stadier',
      primaryKey: 'id', baseFields: ['id','history']
    };

    //var userId: number = this.appStateService.appUserId();
    this.fieldMetadata =
      [{ name: 'dataProcess', text: 'Proces', required: true, foreignKeyTableName: 'DataProcess', orderBy: 'Ascending', orderByPriority: 1 /*, groupBy: true*/ },
      { name: 'name', text: 'Stadienavn', required: true },
      { name: 'order', text: 'Rækkefølge', format: 'Integer', required: true, orderBy: 'Ascending', orderByPriority: 2 },
      { name: 'responsibleUser', text: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
      { name: 'responsibleRole', text: 'Ansvarlig rolle', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'Role' }
      ];

    this.toolbarCommands = [/*this.uiCmdReOpen*/];
    this.keyboardShortcuts = [/*this.uiCmdReOpenShortcut*/];

    this.detailViewMetadata = {
      name: 'data-proces-stadie-egenskaber', dataApiName: 'DataProcessStageProperty', text: 'Egenskaber', textSingular: 'Egenskab',
      primaryKey: 'id', parentPrimaryKey: 'id', parentForeignKey: 'dataProcessStage', baseFields: ['id','history']
    };

    this.detailFieldMetadata =
      [
      { name: 'dataProcessStage', text: 'Stadie', required: true, foreignKeyTableName: 'DataProcessStage', visible: false, orderBy: 'Ascending', orderByPriority: 1 },
      { name: 'name', text: 'Egenskabnavn', required: true, orderBy: 'Ascending', orderByPriority: 2 },
      { name: 'responsibleUser', text: 'Ansvarlig medarbejder', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'User', foreignKeyValue: 'shortName' },
      { name: 'responsibleRole', text: 'Ansvarlig rolle', widthType: FieldWidthEnum.XXS, foreignKeyTableName: 'Role' }
      ];

    this.detailPaneSizePct = 70;
  }

  public override onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      //      case this.uiCmdReOpen.id:
      //        this.reOpen();
      //        break;
    }
  }

  public override onDetailCommand(event: UiCommandEvent) {
  }
}
