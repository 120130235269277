<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #salesOrderGrid id="salesOrderGrid" [viewMetadata]="salesOrderViewMetadata"
      [fieldMetadata]="salesOrderFieldMetadata" [fieldGroupMetadata]="salesOrderFieldGroupMetadata"
      [toolbarCommands]="salesOrderToolbarCommands" [contextMenuCommands]="salesOrderContextMenuCommands"></cer-grid>
  </ng-template>
  <ng-template #leftTemplate>
    <cer-grid #custGrid id="custGrid" [viewMetadata]="custViewMetadata" [fieldMetadata]="custFieldMetadata"
      [toolbarCommands]="custToolbarCommands" [contextMenuCommands]="custContextMenuCommands"
      toolbarType="search"></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
  <cer-grid #refDataNemHandelGrid id="refDataNemHandelGrid" [viewMetadata]="refDataNemHandelViewMetadata"
    [fieldMetadata]="refDataNemHandelFieldMetadata" [toolbarCommands]="refDataNemHandelToolbarCommands"
    [contextMenuCommands]="refDataNemHandelContextMenuCommands" toolbarType="search"></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #refDataGrid id="refDataGrid" [viewMetadata]="refDataViewMetadata" [fieldMetadata]="refDataFieldMetadata"
      [toolbarCommands]="refDataToolbarCommands" [contextMenuCommands]="refDataContextMenuCommands"
      toolbarType="search"></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #custCreateGrid id="custCreateGrid" [viewMetadata]="custCreateViewMetadata"
      [fieldMetadata]="custCreateFieldMetadata"></cer-grid>
  </ng-template>
</cer-form>