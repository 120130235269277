<div class="chat-container">
  <ul #chatList class="chat-list">
    <ng-container *ngFor="let chat of chats; let i = index">
      <li class="chat-list-date" *ngIf="chatShowDate(chat, i)">
        <div>{{ this.chatShowDate(chat, i) }}</div>
      </li>
      <li class="chat-list-name" *ngIf="chatShowCreator(i)">
        <div>{{ chatUserName(chat) }}</div>
      </li>
      <li [class]="chatListItemClass(chat, i)">
        <div>{{ chat.txt }}</div>
      </li>
    </ng-container>
  </ul>
  <div class="chat-add">
    <textarea #chatAddTextArea id="name" rows="2" class="e-input" type="text"
      placeholder="Skriv chat besked og tast [Enter]" (keyup.enter)="addChatBtnClick()" mdInput></textarea>
  </div>
  <div *ngIf="privateNoteActive" class="private-note-active"><mat-checkbox class="private-note" [checked]="markPrivateNote" (change)="onMarkPrivateChanged($event)">Tilføj privat note</mat-checkbox></div>
</div>