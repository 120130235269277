import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';
import { UiActionTypeEnum, UiCommand, UiCommandEvent } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class WarehouseOutboundReserveTransFormAdapter extends CerAppFormModelAdapter {

  private onlySalesCmd: UiCommand = {
    id: 'onlySalesCmd', text: 'Vis alle', align: 'Right',
    iconCss: "e-menu-icon e-icons e-circle-check", tooltipText: 'Vis kun salg', target: ".e-content", visible: true
  };

  override init() {

    this.toolbarCommands = [this.onlySalesCmd];

    this.viewMetadata = {
      name: 'lager-reservationer', dataApiName: 'Warehouse_OutboundOrderReserve', text: 'Lagerreservationer', textSingular: 'Lagerreservation',
      primaryKey: 'pickOrderNum', allowEdit: false, allowCreate: false, allowDelete: false
    };



    this.fieldMetadata = [
      { name: 'warehouseCounterpart', text: 'Kundenr.', widthType: FieldWidthEnum.XS, visible: false },
      { name: 'outboundOrderNum', text: 'Forsendelse', widthType: FieldWidthEnum.XS },
      { name: 'pickOrderNum', text: 'Lagerordre', widthType: FieldWidthEnum.S },
      { name: 'customerRef', text: 'Kunderef.', widthType: FieldWidthEnum.XS },
      { name: 'purchOrderNum', text: 'Reference', widthType: FieldWidthEnum.XS },
      { name: 'orderLockedDate', text: 'Ordre låst', format: 'DateShort'},
      { name: 'created', text: 'Oprettet', tooltipText: 'Oprettet dato', allowEdit: false, format: 'DateLong'},
      { name: 'modified', text: 'Ændret', tooltipText: 'Ændret dato', allowEdit: false, format: 'DateLong', visible: false },
      { name: 'createdJobId', text: 'Oprettet job', format: 'Number'},
      { name: 'modifiedJobId', text: 'Ændret job', format: 'Number', visible: false }
    ];
  }
}
