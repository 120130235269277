import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class WarehouseOutboundOrderTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'forsendelse-ordre-type', dataApiName: 'WarehouseOutboundOrderType', text: 'Forsendelse ordretyper', textSingular: 'Forsendelse ordretype',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'warehouse', text: 'Lagersted', foreignKeyTableName: 'Warehouse', foreignKeyField: 'num' },
      { name: 'orderNumNext', text: 'Næste ordrenr.', format: 'Number' },
      { name: 'orderCreate', text: 'Opret forsendelse?', format: 'CheckBox' },
      { name: 'orderCreateFromConfirmed', text: 'Opret fra bekræftelse?', format: 'CheckBox' },
      { name: 'orderCreateFromLineStatusCancelled', text: 'Genopret fra annulleret?', format: 'CheckBox' },
      { name: 'orderAutoRelease', text: 'Frigiv automatisk?', format: 'CheckBox' },
      { name: 'orderAutoReleaseFromLineStatusCancelled', text: 'Frigiv ved genopret automatisk?', format: 'CheckBox' },
      { name: 'requireCustomerKnown', text: 'Kendt debitor krav?', format: 'CheckBox' },
      { name: 'requirePublished', text: 'Produkt udgivet krav?', format: 'CheckBox' },
      { name: 'requireBuyable', text: ' Produkt kan købes krav?', format: 'CheckBox' },
      { name: 'requireOnHand', text: 'Produkt på lager krav?', format: 'CheckBox' },
      { name: 'bomConsolidate', text: 'Lever produkt som bundle?', format: 'CheckBox' },
      { name: 'bomExpand', text: 'Udfold bundle?', format: 'CheckBox' },
      { name: 'bomConsolidateRequireOnHand', text: 'Lever bundle, når på lager?', format: 'CheckBox' },
      { name: 'bomLineExpandWhenBOMOnHand', text: 'Udfold, når bundle på lager?', format: 'CheckBox' },
      { name: 'carrier', text: 'Standard transportør', foreignKeyTableName: 'WarehouseCarrier', foreignKeyField: 'num' },
      { name: 'dataPublishing_OrderRelease', text: 'Job frigivelse', tooltipText: 'Jobnavn der afvikles ved frigivelse af ordre' },
      { name: 'extCustNum', text: 'Vores kundenr.' },
      { name: 'extOrderGroup', text: 'Ordregruppe' },
      { name: 'extOrderType', text: 'Ordretype' },
      { name: 'extHandlingType', text: 'Emballagetype' },
      { name: 'extCarrierType', text: 'Fragttype' },
      { name: 'extPriority', text: 'Prioritet' },
      { name: 'extPrintDeliveyNote', text: 'Følgeseddel udskriv', format: 'CheckBox' },
      { name: 'extPrintInvoice', text: 'Faktura udskriv ', format: 'CheckBox' },
      { name: 'extPrintFooter', text: 'Sidefod udskriv' }
    ];
   
    this.fieldGroupMetadata = [
      { idx: 1, tabIdx: 0, name: 'identification', text: 'Identifikation', fields: ['id', 'num', 'name', 'warehouse', 'orderNumNext', 'carrier'] },
      { idx: 2, tabIdx: 0, name: 'setup', text: 'Opsætning', fields: ['orderCreate','orderCreateFromLineStatusCancelled','orderAutoRelease','orderAutoReleaseFromLineStatusCancelled','requireCustomerKnown', 'requirePublished', 'requireBuyable', 'requireOnHand','orderCreateFromConfirmed'] },
      { idx: 2, tabIdx: 0, name: 'bom', text: 'Bundles',  fields: ['bomConsolidate',  'bomExpand', 'bomConsolidateRequireOnHand', 'bomLineExpandWhenBOMOnHand']},
      { idx: 4, tabIdx: 0, name: 'ext', text: 'Ekstern ordre', fields: ['dataPublishing_OrderRelease', 'extCustNum', 'extOrderType', 'extOrderGroup', 'extHandlingType', 'extCarrierType', 'extPriority']},
      { idx: 5, tabIdx: 0, name: 'forms', text: 'Formular', fields: ['extPrintDeliveyNote', 'extPrintInvoice', 'extPrintFooter'] }
    ];
  
  }
}
