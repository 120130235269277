import { Component, ViewChild } from '@angular/core';
import { FieldGroupMetadata, FieldMetadata, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiCommand, UiCommandEvent } from '../../cer/cer-grid/cer-grid-command.service';

// WebApi
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { CerFormPanesSettings, uiCmdBottomToggle } from 'src/cer/cer-form/cer-form-panes.service';
import { CerDataNoteService } from 'src/cer-app/cer-data-data/cer-data-note.service';
import { WarehouseOutboundOrderService } from '../warehousing/warehouse-outbound-order.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'warehouse-outbound-order',
  templateUrl: './warehouse-outbound-order.component.html',
  styleUrls: ['../component.css'],
  providers: [WarehouseOutboundOrderService]
})

export class WarehouseOutboundOrderComponent {

  protected panes: CerFormPanesSettings = {
    top: { visible: 'show', size: '50%' },
    left: { visible: 'hide', size: '20%' },
    main: { visible: 'show', size: '50%', sizeSecondary: '70%' },
    right: { visible: 'show', size: '30%' },
    bottom: { visible: 'hide', size: '25%' },
    orientationPrimary: 'vertical'
  };

  protected outboundGrid: CerGridComponent;
  @ViewChild('outboundGrid', { static: false }) set outboundGridContent(content: CerGridComponent) {
    if (content && !this.outboundGrid) {
      this.outboundGrid = content;
      this.outboundOrderService.setWarehouseOutboundOrderGrid(this.outboundGrid);
    }
  }

  protected outboundLineGrid: CerGridComponent;
  @ViewChild('outboundLineGrid', { static: false }) set outboundLineGridContent(content: CerGridComponent) {
    if (content && !this.outboundLineGrid) {
      this.outboundLineGrid = content;
    }
  }

  // Toolbar
  private uiCmdBottomToggle = Object.assign(new Object(), uiCmdBottomToggle, { text: 'Linjer', tooltipText: 'Vis linjer' });

  public tableToolbarCommands: UiCommand[] = [this.uiCmdBottomToggle];

  protected outboundViewMetadata: ViewMetadata;
  protected outboundFieldMetadata: FieldMetadata[];
  protected outboundFieldGroupMetadata: FieldGroupMetadata[];
  protected outboundToolbarCommands: UiCommand[];
  protected outboundContextMenuCommands: UiCommand[];
  protected outboundLineViewMetadata: ViewMetadata;
  protected outboundLineFieldMetadata: FieldMetadata[];
  protected dataNoteViewMetadata: ViewMetadata;
  protected dataNoteFieldMetadata: FieldMetadata[];

  private subscriptionManager: Subscription = new Subscription();

  constructor(private outboundOrderService: WarehouseOutboundOrderService, private routeService: CerAppRouteService,
    private dataNoteService: CerDataNoteService, private ui: CerAppDialogService) {

    this.manage(this.routeService.routeData$.subscribe(data => this.setupFromRouteData(data)));
  }

  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.manage(this.form.commmand.subscribe(e => this.onCommand(e)));
    }
  }


  private params: string[] = [];
  private isDeliveredVisible: boolean = false;
  private isDraft: boolean = false;
  private isReleased: boolean = false;
  private isRemain: boolean = false;
  private isDelivered: boolean = false;
  private routeDataComplete: boolean = false;

  private setupFromRouteData(routeData: any) {
    if (routeData?.params != undefined) {
      this.params = <string[]>routeData.params;
      this.isDraft = this.params.includes("$statusGroup=Draft");
      this.isReleased = this.params.includes("$statusGroup=Released");
      this.isRemain = this.params.includes("$statusGroup=Remain");
      this.isDelivered = this.params.includes("$statusGroup=Delivered");
    }
    this.isDeliveredVisible = !this.isDraft && !this.isReleased && !this.isRemain;

    this.initOutbound();
  }

  private initOutbound() {
    var svc: WarehouseOutboundOrderService = this.outboundOrderService;
    this.outboundViewMetadata = { ...svc.orderViewMetadata };
    this.outboundViewMetadata.dataApiParams = this.params;
    this.outboundFieldMetadata = [...svc.orderFieldMetadata];
    this.outboundFieldMetadata.filter(f => f.name == 'status').forEach(f => { f.orderBy = 'Ascending'; f.orderByPriority = 1; });
    this.outboundFieldMetadata.filter(f => f.name == 'created').forEach(f => { f.orderBy = 'Descending'; f.orderByPriority = 2; });
    this.outboundFieldMetadata.filter(f => f.name == 'num').forEach(f => { f.orderBy = 'Descending'; f.orderByPriority = 3; });
    this.outboundFieldGroupMetadata = svc.orderFieldGroupMetadata;
    this.outboundToolbarCommands = svc.orderToolbarCommands;
    this.outboundContextMenuCommands = svc.orderContextMenuCommands;
    this.outboundLineViewMetadata = {...svc.orderLineViewMetadata}
    this.outboundLineFieldMetadata =  [...svc.orderLineFieldMetadata];
    if (!this.isDeliveredVisible) {
      this.outboundFieldMetadata.filter(f => f.name == 'trackTrace').forEach(f => { f.visible = false; });
      this.outboundLineFieldMetadata.filter(f => f.name == 'trackTrace').forEach(f => { f.visible = false; });
    }
    this.dataNoteViewMetadata = svc.dataNoteViewMetadata;
    this.dataNoteFieldMetadata = svc.dataNoteFieldMetadata;
  }

  // Commands
  public onCommand(event: UiCommandEvent) {
    switch (event.commandId) {
      case this.uiCmdBottomToggle.id:
        //this.syncSalesChannelAll();
        break;
    }
  }

  // Sync
  private syncSalesChannelAll() {
    /*
    var cmd = <SalesChannelSyncCommand>{ allSalesChannels: true };
    this.client.post(cmd).subscribe({
      next: result => {
        this.tableGrid.research();
      },
      error: (error) => {
        this.ui.handleApiStatusMessage(error);
      }
    });
    */
  }
}
