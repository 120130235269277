<cer-form #form [panes]="panes">
  <ng-template #mainTemplate>
    <cer-grid
      #mainGrid
      [viewMetadata]="viewMetadata"
      [dataParent]="true"
      [fieldMetadata]="fieldMetadata"
      [toolbarCommands]="toolbarCommands"
      [contextMenuCommands]="contextMenuCommands"
    ></cer-grid>
  </ng-template>
  <ng-template #detailTemplate>
    <cer-grid
      #detailGrid
      [viewMetadata]="logViewMetadata"
      [dataChild]="true"
      [fieldMetadata]="logFieldMetadata"
    ></cer-grid>
  </ng-template>
</cer-form>
