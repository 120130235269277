<div *ngIf="(isAuthenticated$ | async) && (isReady$ | async) && splitterVerticalPriority" class="div-form">
  <ng-template [ngTemplateOutlet]="verticalTemplate"></ng-template>
</div>
<div *ngIf="(isAuthenticated$ | async) && (isReady$ | async) && !splitterVerticalPriority" class="div-form">
  <ng-template [ngTemplateOutlet]="horizontalTemplate"></ng-template>
</div>
<ng-template #verticalTemplate>
  <ng-container *ngIf="!splitterVerticalVisible">
    <ng-template [ngTemplateOutlet]='horizontalTemplate'></ng-template>
  </ng-container>
  <ng-container *ngIf="splitterVerticalVisible">
    <ejs-splitter #vSplitter orientation='Vertical' height="100%" width="100%" class="splitter"
      (resizing)="panesService.onVSplitterResizing($event)" (collapsed)="panesService.onSplitterPaneState($event)"
      (expanded)="panesService.onSplitterPaneState($event)">
      <e-panes>
        <e-pane *ngIf="paneTopVisible" id="top-pane" [size]="paneTopSize" [collapsed]="paneTopCollapsed"
          [collapsible]="paneTopCollapsible" class="pane">
          <ng-template #content>
            <ng-template [ngTemplateOutlet]="topTemplate"></ng-template>
          </ng-template>
        </e-pane>
        <e-pane id="main-pane-vertical" [size]="paneMainVerticalSize" [collapsible]="true" class="pane">
          <ng-template #content>
            <ng-template *ngIf="splitterVerticalPriority" [ngTemplateOutlet]="horizontalTemplate"></ng-template>
            <ng-template *ngIf="!splitterVerticalPriority" [ngTemplateOutlet]="mainTemplate"></ng-template>
          </ng-template>
        </e-pane>
        <e-pane *ngIf="paneBottomVisible" id="bottom-pane" [size]="paneBottomSize" [collapsed]="paneBottomCollapsed"
          [collapsible]="paneBottomCollapsible" class="pane">
          <ng-template #content>
            <ng-template [ngTemplateOutlet]="bottomTemplate"></ng-template>
          </ng-template>
        </e-pane>
      </e-panes>
    </ejs-splitter>
  </ng-container>
</ng-template>
<ng-template #horizontalTemplate>
  <ng-container *ngIf="!splitterHorizontalVisible">
    <ng-template *ngIf="(!splitterVerticalPriority && splitterVerticalVisible)"
      [ngTemplateOutlet]="verticalTemplate"></ng-template>
    <ng-template *ngIf="!(!splitterVerticalPriority && splitterVerticalVisible)"
      [ngTemplateOutlet]="mainTemplate"></ng-template>
  </ng-container>
  <ng-container *ngIf="splitterHorizontalVisible">
    <ejs-splitter #hSplitter orientation='Horizontal' height="100%" width="100%" class="splitter"
      (resizing)="panesService.onHSplitterResizing($event)" (collapsed)="panesService.onSplitterPaneState($event)"
      (expanded)="panesService.onSplitterPaneState($event)">
      <e-panes>
        <e-pane *ngIf="paneLeftVisible" id="left-pane" [size]="paneLeftSize" [collapsed]="paneLeftCollapsed" [collapsible]="paneLeftCollapsible" class="pane">
          <ng-template #content>
            <ng-template [ngTemplateOutlet]="leftTemplate"></ng-template>
          </ng-template>
        </e-pane>
        <e-pane id="main-pane-horizontal" [size]="paneMainHorizontalSize" [collapsible]="true" class="pane">
          <ng-template #content>
            <ng-template *ngIf="!(!splitterVerticalPriority && splitterVerticalVisible)" [ngTemplateOutlet]="mainTemplate"></ng-template>
            <ng-template *ngIf="(!splitterVerticalPriority && splitterVerticalVisible)" [ngTemplateOutlet]="verticalTemplate"></ng-template>
          </ng-template>
        </e-pane>
        <e-pane *ngIf="paneRightVisible" id="right-pane" [size]="paneRightSize" [collapsed]="paneRightCollapsed"
          [collapsible]="paneRightCollapsible" class="pane">
          <ng-template #content>
            <ng-template [ngTemplateOutlet]='rightTemplate'></ng-template>
          </ng-template>
        </e-pane>
      </e-panes>
    </ejs-splitter>
  </ng-container>
</ng-template>
<div class="popup-form" *ngIf="(popupComponent$ | async) != null">
  <ng-container 
    [ngTemplateOutlet]="popupTemplate"
    [ngTemplateOutletContext]="popupTemplateContext">
  </ng-container>
</div>