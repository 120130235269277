import { Injectable, OnDestroy } from '@angular/core';
import { CerBaseRealtimeServerService } from './cer-base-realtime-server.service';
import { ChatDto, NotificationMessageDto, UserStatusVm } from 'src/app/api';
import { CerAppChatService } from 'src/cer-app/cer-app-chat/cer-app-chat.service';
import { CerAppNotificationService } from 'src/cer-app/cer-app-notifications/cer-app-notification.service';
import { Subscription } from 'rxjs';
import { AppUser, UserSessionService } from 'src/platform/app-user/user-session-service';

@Injectable({
  providedIn: 'root'
})
export class CerBaseRealtimeService implements OnDestroy {

  private subscriptionManager$: Subscription = new Subscription();

  constructor(
    private serverService: CerBaseRealtimeServerService,
    private userSessionService: UserSessionService,
    private chatService: CerAppChatService,
    private notificationsService: CerAppNotificationService) {

    this.subscriptionManager$.add(
      userSessionService.userSession$.subscribe(user => this.onSessionUser(user))
    );

    this.subscriptionManager$.add(
      serverService.isStarted$.subscribe(started => { if (started) this.onStart(); })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptionManager$.unsubscribe();
  }

  private onSessionUser(user: AppUser): void {
    if (user?.sessionUser?.access_token) {
      this.start(user.sessionUser.access_token);
    }
    else {
      this.stop();
    }
  }

  private start(loginToken: string) {
    this.serverService.start(loginToken);
  }

  private stop() {
    this.serverService.stop();
  }

  private onStart() {
    this.serverService.on('BroadcastUserStatus', (userStatus: UserStatusVm) => this.userSessionService.userStatusVm$.next(userStatus));
    this.serverService.on('BroadcastChatPosted', (chats: ChatDto[]) => this.chatService.chatsNext(chats));
    this.serverService.on('UserNotifications', (userNotifications: NotificationMessageDto[]) => this.notificationsService.userNotifications$.next(userNotifications));
  }

}
