import { Component, ViewChild } from '@angular/core';

import { HtmlEditorService, ImageService, LinkService, RichTextEditorComponent, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';

import { FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../../cer/cer-data/cer-data.service';
import { UiCommandEvent, UiCommandSourceEnum } from '../../../cer/cer-grid/cer-grid-command.service';
import { CerFormComponent } from '../../../cer/cer-form/cer-form.component';
import { CerFormPanesSettings } from 'src/cer/cer-form/cer-form-panes.service';

@Component({
  selector: 'app-mail-template',
  templateUrl: './app-mail-template.component.html',
  styleUrls: ['./app-mail-template.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]

})
export class AppMailTemplateComponent {

  protected panes: CerFormPanesSettings = {
    main: { visible: 'show', size: '70%' },
    right: { visible: 'show', size: '30%' },
    orientationPrimary: 'horizontal'
  };

  form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
      this.form.commmand.subscribe(e => this.onFormCommand(e));
    }
  }

  // View
  public viewMetadata: ViewMetadata = {
    name: 'mailTemplates', dataApiName: 'MailTemplate', text: 'Mail skabeloner',
    primaryKey: 'id', titleFields: ['name'], allowEdit: true, allowCreate: true, allowDelete: true, baseFields: ['history']
  };
  // Fields
  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for skabelonen', allowEdit: false, visible: false, widthType: FieldWidthEnum.XS },
    { name: 'name', text: 'Navn', tooltipText: 'Navn', widthType: FieldWidthEnum.M, orderBy: 'Ascending' },
    { name: 'channel', text: 'Kanal', tooltipText: 'Hvilken kanal afsendes mail med', foreignKeyTableName: 'MailChannel' },
    { name: 'fromEmail', text: 'Fra e-mail', tooltipText: 'Afsender e-mail adresser', widthType: FieldWidthEnum.M, orderBy: 'Ascending' }
    ];

  // Rich text
  public tools: object = {
    type: 'Expand',
    items: ['FullScreen', '|', 'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  public richText: RichTextEditorComponent = null;
  @ViewChild(RichTextEditorComponent, { static: false }) set setViewChildRichText(richText: RichTextEditorComponent) {
    this.richText = richText;
  }

  constructor() {
  }

  // Commands
  public onFormCommand(event: UiCommandEvent) {
    switch (event.source) {
      case UiCommandSourceEnum.DesignChange:
        this.richTextReflow();
        break;
      case UiCommandSourceEnum.ActionBegin:
        break;
      case UiCommandSourceEnum.ActionComplete:
        break;
    }
  }

  private richTextReflow() {
    if (this.richText && this.richText.toolbarModule && this.richText.toolbarModule) {
      this.richText.toolbarModule.refreshToolbarOverflow();
    }
  }
}
