// Angular
import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { _MatTabGroupBase } from '@angular/material/tabs';

// SyncFusion
import { GridComponent, ColumnModel, ExcelExportProperties, SearchSettingsModel } from '@syncfusion/ej2-angular-grids';

// App
import { CerDataService, FieldFormatType, FieldMetaForeignKey, FieldMetadata, ViewMetadata } from '../cer-data/cer-data.service';
import { GridDesign } from './cer-grid.component';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import {
  CerGridCommandService, UiActionTypeEnum, UiCommandEvent, UiCommandSourceEnum,
  uiCmdColumnGroup, uiCmdColumnHide, uiCmdColumnsChoose, uiCmdCopy, uiCmdCopyWithHeader, uiCmdDebugModeToogle,
  uiCmdExportExcel, uiCmdPagingToggle, uiCmdResearch
} from './cer-grid-command.service';
import { CerGridSelectionService } from './cer-grid-selection.service';
import { CerGridFilterService } from './cer-grid-filter.service';
import { CerGridEditCellService } from './cer-grid-edit-cell.service';

// Meta sort settings
export interface MetaSortField {
  priority: number,
  field: string,
  assending: boolean
}

@Injectable()
export class CerGridSetupService implements OnDestroy {

  // Subscriptions
  private subscriptionManager: Subscription = new Subscription();

  // Grid
  public grid: GridComponent;
  public design: GridDesign;

  // Columns
  public columnsIsSetup: boolean = false;
  public groupByColumnCount: number = 0;

  // Settings
  public gridColumns: ColumnModel[] = [];
  public gridSortMetaSettings: MetaSortField[];

  public debugMode: boolean = false;

  // Constructor
  constructor(public data: CerDataService,
    private editCell: CerGridEditCellService,
    private filter: CerGridFilterService,
    private selection: CerGridSelectionService,
    private command: CerGridCommandService,
    private meta: CerEditMetaService,
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar) {

    this.subscriptionManager.add(
      this.command.command$.subscribe(event => this.onCommand(event))
    );
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  // Setup grid

  public initGridParam() {
    this.grid.enableVirtualMaskRow = false;
    if (this.data) {

      var viewMetadata = this.meta.viewMetadata;
      // Editing
      if (viewMetadata) {
        if (viewMetadata.dialogEdit === false) {
          this.grid.editSettings.mode = 'Normal';
        }
        if (viewMetadata.dialogWidth) {
          if (this.grid.editSettings.dialog.params) {
            this.grid.editSettings.dialog.params.width = viewMetadata.dialogWidth;
          }
          else {
            this.grid.editSettings.dialog.params = { width: viewMetadata.dialogWidth };
          }
        }
        if (viewMetadata.allowEdit != undefined) {
          this.grid.editSettings.allowEditing = viewMetadata.allowEdit;
        }
        // Adding
        if (viewMetadata.allowCreate != undefined) {
          this.grid.editSettings.allowAdding = viewMetadata.allowCreate;
        }
        // Delete
        if (viewMetadata.allowDelete != undefined) {
          this.grid.editSettings.allowDeleting = viewMetadata.allowDelete;
        }
      }
    }

    if (this.design.selectionMode == 'checkboxMulti' || this.design.selectionMode == 'checkboxSingle') {
      var c: ColumnModel = { type: "checkbox", width: "30px", maxWidth: "30px", headerTextAlign: "Center", textAlign: "Center" };
      this.gridColumns.unshift(c);
    }

    this.initColumnsSetup();
  }

  public init(g: GridComponent, d: GridDesign) {

    this.grid = g;
    this.design = d;

    //get metadata from AppData (primary idx) etc
    //g.dataSource = this.appDataService.dataManager;
    g.allowPaging = d.allowPaging;
    g.enableVirtualization = d.pagingVirtual;
    g.allowResizing = true;
    //g.autoFit = true;
    g.allowReordering = true;
    g.allowSorting = !(this.meta.viewMetadata?.allowSorting === false);
    g.allowFiltering = !(this.meta.viewMetadata?.allowFiltering === false);
    g.allowGrouping = d.toolbarType == 'default';
    g.allowExcelExport = true;
    g.showColumnChooser = true;
    g.pageSettings = { pageSizes: false, pageSize: d.pageSize };
    g.sortSettings = {};
    g.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog', allowEditOnDblClick: d.editOnDoubleClick, showDeleteConfirmDialog: false, newRowPosition: 'Bottom', dialog: { params: {} }, /*, allowNextRowEdit: true */ };;
    //this.gridGroupSettings.enableLazyLoading = this.design.groupingLazyLoading;
    g.groupSettings = { disablePageWiseAggregates: true, showDropArea: false, showUngroupButton: true, showGroupedColumn: false };;

    //this.grid.enableAdaptiveUI = true;
    //this.grid.rowRenderingMode = 'Horizontal';
    //this.grid.rowRenderingMode = 'Vertical';
    this.initGridParam();
    this.grid.columns = this.gridColumns;
  }

  public onCommand(event: UiCommandEvent) {
    if (event.cancel) {
      return;
    }
    var done = false;
    switch (event.source) {
      case UiCommandSourceEnum.Command:
        switch (event.actionType) {
          case UiActionTypeEnum.Refresh:
            this.doRefresh();
            break;
          case UiActionTypeEnum.RefreshColumns:
            this.doRefreshColumns();
            break;
        }
        break;
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Created:
            break;
          case UiActionTypeEnum.DataBound:
            this.onDataBoundBegin(event.args);
            break;
          case UiActionTypeEnum.BeforeExcelExport:
            this.onBeforeExcelExport(event.args);
            break;
        }
        break;
      case UiCommandSourceEnum.ActionComplete: {
        switch (event.actionType) {
          case UiActionTypeEnum.DataBound:
            this.onDataBoundComplete(event.args);
            break;
          case UiActionTypeEnum.DataLoad:
          case UiActionTypeEnum.DataFKLoad:
            this.doRefreshColumns();
            break;
        }
        break;
      }
    }

    switch (event.commandId) {
      case uiCmdColumnHide.id:
        this.doColumnHide(event.columnName);
        break;
      case uiCmdColumnsChoose.id:
        this.doColumnsChoose();
        break;
      case uiCmdColumnGroup.id:
        this.doColumnGroup(event.columnName);
        break;
      case uiCmdResearch.id:
        this.doRefresh();
        break;
      case uiCmdPagingToggle.id:
        this.doPagingToggle();
        break;
      case uiCmdCopy.id:
        this.grid.copy();
        break;
      case uiCmdCopyWithHeader.id:
        this.grid.copy(true);
        break;
      case uiCmdExportExcel.id:
        this.doExportExcel();
        break;
      case uiCmdDebugModeToogle.id:
        done = this.doDebugModeToogle();
        break;
    }

    if (done) {
      event.cancel = true;
      event.args.cancel = true;
    }
  }

  private isGridReady(): boolean {
    return (this.grid?.headerModule != undefined);
  }

  public doRefresh() {
    if (this.isGridReady()) {
      this.selection.rowSelectedKeep();
      this.grid.refresh();
    }
  }

  private doRefreshColumns() {
    if (this.isGridReady()) {
      this.selection.rowSelectedKeep();
      this.grid.refreshColumns();
    }
  }

  private onDataBoundBegin(args: any) {
    this.onDataBoundUpdateFKFromNavigation(args);
  }

  private onDataBoundComplete(event: any) {
    //this.columnsSetup(); // If no field meta data
    this.searchInputAllowClear();
    if (event.args) {
      this.columnsGroupBySetup(event.args);
    }
  }


  private onDataBoundUpdateFKFromNavigation(args: any) {
    var data = args.result /* query+insert */ ?? [args.data] /* update */;
    if (data?.length > 0) {
      FieldMetaForeignKey.dataMergeFromNavigationFields(this.meta.fieldMetadata, data);
    }
  }

  public gridRedraw(data: Object[] = null) {
    if (!data) {
      data = this.grid.currentViewData;
    }
    this.grid.renderModule.dataManagerSuccess({ result: data, count: this.grid.pageSettings.totalRecordsCount })
  }

  // Columns data
  public refreshColumns() {
    if (this.grid && this.grid.headerModule) {
      //console.log("Keep before refresh " + this.selection.rowSelectedKeepIdx);
      this.grid.refreshColumns();
    }
  }

  public dataRowReplace(origDto: object, newDto: object, relativeSelectionIdx: number = null) {
    Object.assign(origDto, newDto);
    this.refreshPage(relativeSelectionIdx);
  }

  public refreshPage(relativeSelectionIdx: number = null) {
    this.selection.rowSelectedKeep(null, relativeSelectionIdx);
    this.refreshColumns();
  }

  // Command handlers
  public doColumnHide(columnName: string) {
    if (columnName) {
      this.grid.hideColumns(columnName, 'field');
    }
  }

  public doColumnsChoose() {
    this.grid.openColumnChooser(0, 0);
  }

  public doColumnGroup(columnName: string) {
    if (columnName) {
      if (!this.grid.groupSettings.columns.includes(columnName)) {
        this.grid.groupColumn(columnName);
      }
    }
  }

  public doPagingToggle() {
    if (this.grid && false) {
      this.selection.rowSelectedKeep();
      if (this.grid.allowPaging) {
        if (this.grid.enableVirtualization) {
          this.grid.enableVirtualization = false;
        }
        else {
          this.grid.allowPaging = !this.grid.allowPaging;
          this.grid.height = "100%";
        }
      }
      else {
        this.grid.allowPaging = !this.grid.allowPaging;
        if (this.design.pagingVirtual) {
          this.grid.enableVirtualization = true;
        }
      }
      this.grid.refresh();
    }
  }

  private onBeforeExcelExport(args: any) {
    console.log(args);
  }

  public doExportExcel() {
    var p: ExcelExportProperties = {};

    if (this.data && this.data.viewMetadata && this.data.viewMetadata.name) {
      var fileName: string = this.data.viewMetadata.name + '-' + this.getDateStampStr() + '.xlsx';
      p.fileName = fileName;
    }

    this.grid.excelExport(p);
  }

  private getDateStampStr() {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd-hh-mm');
  }

  public doDebugModeToogle(): boolean {
    var ok: boolean = false;
    this.debugMode = !this.debugMode;
    this.data.setDebugMode(this.debugMode);
    //this.ui.snack(this.design.dataRelationRole + ' grid: Debug mode ' + this.debugMode);
    console.log(this.design.dataParent + ' ' + this.design.dataChild + ' grid: Debug mode ' + this.debugMode);
    ok = true;
    return ok;
  }

  public debug(txt: string) {
    if (this.debugMode) {
      console.log(this.design.dataParent + ' ' + this.design.dataChild + ' grid: ' + txt);
    }
  }

  // Column setup
  public initColumnsSetup() {
    if (this.data && this.data.fieldMetadata) {
      if (!this.columnsIsSetup) {
        this.columnsIsSetup = true;


        this.searchSettingsInit();

        this.data.fieldMetadata.forEach(m => this.columnCreateByMeta(m, this.data.viewMetadata));
        this.columnSortSetup();
      }
    }
  }

  public columnSetup(column: ColumnModel) {
    if (column.field) {
      var meta: FieldMetadata = this.data.fieldMetadata.find(f => f.name == column.field);
      if (meta) {
        this.columnSetupByMeta(column, meta);
      }
    }
  }

  public columnCreateByMeta(meta: FieldMetadata, viewMetadata: ViewMetadata) {
    var column: ColumnModel = {
      field: meta.field !== undefined ? meta.field : meta.name,
      headerText: meta.text
    };
    
    column.visible = (meta.visible !== false);

    // Data
    if (viewMetadata?.primaryKey) {
      if (Array.isArray(viewMetadata.primaryKey)) {
        viewMetadata.primaryKey.forEach(pk => {
          if (pk == meta.name) {
            column.isPrimaryKey = true;
          }
        });
      }
      else {
        if (viewMetadata.primaryKey == meta.name) {
          column.isPrimaryKey = true;
        }
      }
    }

    this.columnSetupByMeta(column, meta);

    this.gridColumns.push(column);
  }

  public field(fieldName: string): ColumnModel {
    return this.gridColumns.find(c => c.field == fieldName);
  }

  public columnSetupByMeta(column: ColumnModel, meta: FieldMetadata) {
    // Edit cell
    this.editCell.columnSetupByMeta(column, meta);

    // Order by
    if (meta.orderBy != undefined && meta.orderBy != 'None') {
      this.columnSortColumnsByMeta(meta, column);
    }

    // Group by
    if (meta.groupBy === true) {
      this.columnGroupByFieldName(column.field);
    }

    // Filter
    if (meta.allowFiltering == false) {
      column.allowFiltering = false;
    }
    this.filter.filterColumnSetOnClient(column, meta);

    // Search
    this.columSearchSetup(meta, column);

    // Column chooser
    if (meta.allowColumnChooser === false) {
      column.showInColumnChooser = false;
    }
  }


  // Order by
  private columnSortColumnsByMeta(meta: FieldMetadata, column: ColumnModel) {
    //if (meta.visible !== false) {
    var priority: number = meta.orderByPriority ?? 1;
    var assending: boolean = (meta.orderBy === 'Ascending');
    var sortField: MetaSortField = { priority: priority, field: column.field, assending: assending };
    if (this.gridSortMetaSettings == null) {
      this.gridSortMetaSettings = [sortField];
    }
    else {
      this.gridSortMetaSettings.push(sortField);
    }
    //}
  }

  private searchSettingsInit() {
    var fields: string[] = [];
    var searchSettings: SearchSettingsModel = { operator: 'contains', fields: fields, ignoreCase: true, ignoreAccent: false };
    this.grid.searchSettings = searchSettings;
  }

  private columSearchSetup(meta: FieldMetadata, column: ColumnModel) {
    if (meta.allowSearching !== false && meta.visible !== false) {
      if (meta.format === undefined || this.columnSearchContainsAllowed(meta.format)) {
        this.grid.searchSettings.fields.push(column.field);
      }
    }
  }

  private fieldFormatCanSearchContains: FieldFormatType[] = ['Email', 'Phone', 'Text', 'TextArea', 'TextRight'];
  private columnSearchContainsAllowed(format: FieldFormatType | FieldFormatType[]): boolean {
    var canSearch: boolean = true;
    if (Array.isArray(format)) {
      format.forEach(f => {
        if (!this.columnSearchContainsAllowed(f)) {
          canSearch = false;
        }
      });
    }
    else {
      canSearch = this.fieldFormatCanSearchContains.includes(format as FieldFormatType);
    }
    return canSearch;
  }

  private columnSortSetup() {
    if (this.gridSortMetaSettings != null) {
      var columns: object[] = [];
      this.gridSortMetaSettings.sort((a, b) => a.priority - b.priority).forEach(f => {
        var column = { field: f.field, direction: f.assending ? 'Ascending' : 'Descending' };
        if (!columns) {
          columns = [column];
        }
        else {
          columns.push(column);
        }
      });
      this.grid.sortSettings.columns = columns;
    }
  }

  // Group by
  private columnGroupByFieldName(fieldName: string) {
    if (fieldName) {
      var columns: string[] = this.grid.groupSettings.columns;
      if (!columns) {
        columns = [fieldName];
      }
      else {
        columns.push(fieldName);
      }
      this.grid.groupSettings.columns = columns;
    }
  }

  public columnsGroupBySetup(args: any) {

    var groupByColumnCount: number = this.grid.groupSettings.columns.length;
    if (groupByColumnCount != this.groupByColumnCount) {
      this.groupByColumnCount = groupByColumnCount;

      if (groupByColumnCount > 0) {
        this.grid.allowPaging = false;
        //this.grid.groupModule?ollapseAll();
        this.grid.groupSettings.showDropArea = true;
      }
      else {
        this.grid.allowPaging = true;
        this.grid.groupSettings.showDropArea = false;
      }
    }
  }

  // Search input
  public searchInputAllowClear() {
    var eSearchList = this.grid.element.getElementsByClassName('e-search');
    if (eSearchList && eSearchList.length > 0) {
      var eSearch: Element = eSearchList[0];
      //  checks whether the cancel icon is already present or not
      /*
    if (!eSearch.classList.contains('e-search-can-clear')) {
      var span = document.createElement('span');
      span.id = this.grid.element.id + '_searchcancelbutton';
      span.className = 'e-clear-icon';
      span.title = "Nulstil (Esc)";
      span.addEventListener('click', (args) => {
        var input: HTMLInputElement = this.grid.element.querySelector('.e-search').getElementsByTagName('input')[0];
        if (input && input.value != "") {
          input.value = "";
          this.grid.search("");
        }
      });
      eSearch.appendChild(span);
      eSearch.classList.add('e-search-can-clear');
      eSearch.classList.add('e-search-small');
    }
      */
    }
  }

  // Snackbar
  public showMessage(msg: string, action: string = null, duration: number = null) {
    this.snackBar.open(msg, action, { duration: 1000 });
  }
}


