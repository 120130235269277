<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #outboundGrid id="outboundGrid" [viewMetadata]="outboundViewMetadata" [toolbarCommands]="outboundToolbarCommands" [contextMenuCommands]="outboundContextMenuCommands"
      [fieldMetadata]="outboundFieldMetadata" [fieldGroupMetadata]="outboundFieldGroupMetadata" dataRelationRole="parent"></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
    <cer-grid #outboundLineGrid id="outboundLineGrid" [viewMetadata]="outboundLineViewMetadata"
      [fieldMetadata]="outboundLineFieldMetadata" dataRelationRole="child" dataParentSenderId="outboundGrid" 
      autoFocus="disabled" toolbarType="minimal" toolbarTitle="metadata-text-only"></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #dataNoteGrid id="dataNoteGrid" [viewMetadata]="dataNoteViewMetadata"
      [fieldMetadata]="dataNoteFieldMetadata" dataRelationRole="child" dataParentSenderId="outboundGrid"
      autoFocus="disabled" toolbarType="minimal" toolbarTitle="metadata-text-only"></cer-grid>
  </ng-template>
</cer-form>
