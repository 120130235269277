// Angular
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

// SyncFusion
import {
  AggregateService, ColumnChooserService, ColumnMenuService, ColumnModel, ContextMenuService, DetailRowService, EditService,
  ExcelExportService, FilterService, ForeignKeyService, GridComponent, GroupService, PageService, PdfExportService, ReorderService,
  ResizeService, SelectionService, SortService, ToolbarService, RowDDService, VirtualScrollService, SearchService, FreezeService, InfiniteScrollService, LazyLoadGroupService
} from '@syncfusion/ej2-angular-grids';
import { DataManager } from '@syncfusion/ej2-data';

// Cer
import { CerFormService, ParentDataArgs } from '../cer-form/cer-form.service';
import { CerDataRelationRole, CerFormActiveDisabled } from '../cer-form/cer-form.component';
import { CerEditMetaService } from '../cer-edit/cer-edit-meta.service';
import { CerGridSetupService } from './cer-grid-setup.service';
import { CerGridCommandService, UiActionTypeEnum, UiCommand, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut } from './cer-grid-command.service';
import { CerDataService, ViewMetadata, FieldMetadata, FieldGroupMetadata, TabMetadata } from '../cer-data/cer-data.service';
import { CerGridEditService } from './cer-grid-edit.service';
import { CerFormPersistenceService, CerFormUserViewType, ICerViewPersistenceComponent } from '../cer-form/cer-form-persistence.service';
import { CerEditTemplateService, CerEditTemplateSetup } from '../cer-edit/cer-edit-template.service';

// Platform
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { CerGridSelectionService } from './cer-grid-selection.service';
import { CerGridPersistenceService } from './cer-grid-persistence.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerGridFilterService } from './cer-grid-filter.service';
import { CerGridEditCellService } from './cer-grid-edit-cell.service';
import { CerGridVirtualizeService } from './cer-grid-virtualize.service';
import { CerAppDialogService } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';

export type CerGridSelectionInitial = 'first' | 'none';
export type CerGridSelectionMode = 'checkboxMulti' | 'checkboxSingle' | 'multiple' | 'row' | 'cell' | 'cellBox';
export type CerGridToolbarType = 'default' | 'simple' | 'minimal' | 'search' | 'none';
export type CerGridPaging = 'virtual' | 'pager' | 'none';
export type CerGridHeight = 'maximize' | 'auto';
export type CerToolbarTitle = 'metadata' | 'metadata-text' | 'metadata-text-only' | 'disabled';

export class GridDesign {
  public id: string;
  public allowPaging: boolean;
  public virtualPaging: boolean;
  public pageSize: number;
  public useEditTemplate: boolean;
  public groupingLazyLoading: boolean;
  public useMaxHeight: boolean;
  public autoFocus: boolean;
  public toolbarType: CerGridToolbarType;
  public toolbarTitle: CerToolbarTitle;
  public columnFilterVisible: boolean = true;
  public dataRelationRole: CerDataRelationRole;
  public selectionMode: CerGridSelectionMode;
  public selectionInitial: CerGridSelectionInitial;
  public editOnDoubleClick: boolean;
}

/*
    <ng-container *ngIf="data.dataTypeText == 'STRING'">
        <input class="e-input" name="value" required [(ngModel)]="gridData.value" type="text" />
    </ng-container>
    <ng-container *ngIf="data.editTypedataTypeText == 'DATE'">
        <ejs-datepicker id="value" placeholder="Date" format='MM/dd/yyyy' [(ngModel)]="data.value"
            floatLabelType='Never'></ejs-datepicker>
    </ng-container>
    <ng-container *ngIf="data.dataTypeText == 'BOOLEAN'">
        <ejs-checkbox [(ngModel)]="data.value"></ejs-checkbox>
    </ng-container>

*/

@Component({
  selector: 'cer-grid',
  templateUrl: './cer-grid.component.html',
  styleUrls: ['./cer-grid.component.css'],
  providers: [CerGridSetupService, CerGridCommandService, CerEditMetaService,
    CerGridVirtualizeService, CerGridSelectionService, CerGridFilterService, CerGridPersistenceService,
    CerGridEditService, CerGridEditCellService, CerEditTemplateService, CerDataService,
    PageService, EditService, ForeignKeyService, FilterService, SortService, SearchService,
    GroupService, ResizeService, ReorderService, SelectionService, ToolbarService,
    ColumnChooserService, ColumnMenuService, ContextMenuService,
    ExcelExportService, PdfExportService, DetailRowService, AggregateService,
    RowDDService, VirtualScrollService, FreezeService, InfiniteScrollService, LazyLoadGroupService]
})


export class CerGridComponent implements OnInit, AfterViewInit, OnDestroy, ICerViewPersistenceComponent {

  @Input() id: string;
  @Input() editTemplate: TemplateRef<any> = null;
  @Input() selectionInitial: CerGridSelectionInitial = 'first';
  @Input() selectionMode: CerGridSelectionMode = 'row';
  @Input() autoFocus: CerFormActiveDisabled = 'active';
  @Input() viewMetadata: ViewMetadata;
  @Input() dataRelationRole: CerDataRelationRole;
  @Input() dataParentSenderId: string;
  @Input() fieldMetadata: FieldMetadata[];
  @Input() fieldGroupMetadata: FieldGroupMetadata[];
  @Input() tabMetadata: TabMetadata[];
  @Input() toolbarCommands: UiCommand[] = [];
  @Input() toolbarType: CerGridToolbarType = 'default';
  @Input() toolbarTitle: CerToolbarTitle = 'metadata';
  @Input() contextMenuCommands: UiCommand[] = [];
  @Input() keyboardShortcuts: UiKeyboardShortcut[] = [];
  @Input() data: Object[];
  @Input() height: CerGridHeight = 'maximize';
  @Input() width: string | number = '100%';
  @Input() rowHeight: number = 36;
  @Input() pageSize: number = null;
  @Input() paging: CerGridPaging = 'virtual';
  @Input() editOnDoubleClick: boolean = true;
  @Output() commmand: EventEmitter<UiCommandEvent> = new EventEmitter<UiCommandEvent>()
  private subscriptionManager$: Subscription = new Subscription();
  public isGridInit: boolean = false;
  public isGridDataBound: boolean = false;
  public design: GridDesign;

  private parentData: any = null;

  public columns?: ColumnModel[];
  public templateSetup: CerEditTemplateSetup = null;

  // Container div
  @ViewChild('containerDiv') containerDiv: ElementRef;

  // Grid reference
  public grid: GridComponent = null;
  @ViewChild(GridComponent, { static: false }) set setViewChildGrid(grid: GridComponent) {
    if (grid && !this.grid) {
      this.grid = grid;
      this.init();
    }
  }

  @ViewChild('chatTemplate', { static: false }) set chatTemplateContent(content: NgModel) {
    if (content && !this.editCellService.chatTemplate) {
      this.editCellService.chatTemplate = content;
      var chatColumn: ColumnModel = this.editCellService.chatColumn;
      if (chatColumn) {
        (chatColumn.template as any) = this.editCellService.chatTemplate;
      }
    }
  }

  constructor(
    public gridService: CerGridSetupService,
    public dataService: CerDataService,
    public editService: CerGridEditService,
    public editCellService: CerGridEditCellService,
    public selection: CerGridSelectionService,
    public metaService: CerEditMetaService,
    public commandService: CerGridCommandService,
    public filterService: CerGridFilterService,
    private virtualizeService: CerGridVirtualizeService,
    private formService: CerFormService,
    private formPersistenceService: CerFormPersistenceService,
    private gridPersistenceService: CerGridPersistenceService,
    private editTemplateService: CerEditTemplateService,
    private appDialogService: CerAppDialogService,
    private routeService: CerAppRouteService,
    private userSessionService: UserSessionService,
    private authorize: AuthorizeService) {

    if (this.paging == 'virtual') {
      if (this.userSessionService?.userDto()?.uiPagerShow == true) {
        this.paging = 'pager';
      }
    }
  }

  ngOnInit(): void {

    this.subscriptionManager$.add(this.authorize.getAccessToken().subscribe(token => this.authTokenSetup(token)));
    this.subscriptionManager$.add(this.formService.formCommand$.subscribe(event => this.onFormCommand(event)));
    this.subscriptionManager$.add(this.editTemplateService.templateSetup$.subscribe(setup => this.templateSetup = setup));
    this.subscriptionManager$.add(this.commandService.command$.subscribe(cmd => this.onCommand(cmd)));
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    //this.formPersistenceService.unregisterComponent(this);
    this.commandService.cmdDispatchActionBegin(UiActionTypeEnum.Destroyed);
    this.subscriptionManager$.unsubscribe();
    if (this.grid) {
      var grid: GridComponent = this.grid;
      this.grid = null;
      grid.destroy();
    }
  }

  public edit(): CerGridEditService {
    return this.editService;
  }

  public dataNext(data: Object[]) {
    if (data) {
      var isChanged: boolean = false;
      if (this.grid) {
        if (this.grid.dataSource instanceof DataManager) {
          var dm: DataManager = this.grid.dataSource as DataManager;
          if (dm && !CerGridSelectionService.rowsDataEqual(dm.dataSource.json, data)) {
            dm.dataSource.json = data;
            isChanged = true;
          }
        }
        else {
          if (!CerGridSelectionService.rowsDataEqual(this.grid.dataSource as any[], data)) {
            this.grid.dataSource = data;
            isChanged = true;
          }
        }
      }
      if (this.dataService && isChanged) {
        this.dataService.data$.next(data);
        this.grid?.refresh();
      }
    }
  }

  // When parent row selected
  public formCommandNext(event: UiCommandEvent) {
    this.formService.childCommandNext(event);
  }

  // When parent row selected/deselected
  public parentDataNext(data: any, stable: boolean = false) {
    if (this.dataRelationRole == "parent" || this.dataRelationRole == "childAndParent") {
      var parentData: ParentDataArgs = new ParentDataArgs(data, this, stable);
      this.formService.parentData$.next(parentData);
      //this.grid.element.focus(); (try to send focus back to parent grid, but this is not the right hook)
    }
  }

  // When child role, and parent has new data range
  public onParentData(parentDataArgs: ParentDataArgs) {
    if (parentDataArgs && this.dataRelationRole == "child" || this.dataRelationRole == "childAndParent") {
      if (this.dataParentSenderId == null || parentDataArgs.sender?.id == this.dataParentSenderId) {
        if (parentDataArgs.row || parentDataArgs.stable) {
          this.parentData = parentDataArgs.row;
          if (this.dataService.refreshFromParentData(parentDataArgs.row)) {
            if (this.grid && parentDataArgs) {
              this.grid.dataSource = this.dataService.dataManager;
              this.grid.query = this.dataService.query;
            }
          }
        }
        else {
          var dm = new DataManager(new Array<object>());
          this.grid.dataSource = dm;
          if (this.grid.renderModule) {
            this.grid.renderModule.data.dataManager = dm;
          }
        }
        this.refreshColumns();
      }
    }
  }

  private refreshColumns() {
    if (this.grid && this.grid.headerModule) {
      this.grid.refreshColumns();
    }
  }

  private setGridSize() {
    if (this.containerDiv) {
      var rect: DOMRect = this.containerDiv.nativeElement.getBoundingClientRect();
      if (rect?.height) {
        this.commandService.reflow();
      }
    }
  }

  public onFormCommand(event: UiCommandEvent) {
    if (event.source == UiCommandSourceEnum.DesignChange) {
      this.commandService.reflow();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    this.commandService.gridOnKeyDown(e);
  }

  public rowSelectedData(): object {
    return this.selection.rowSelectedData();
  }

  public rowSelectedRowsData(): object[] {
    return this.selection.rowsSelectedData();
  }

  public disable() {
    this.commandService.disable();
  }

  public enable() {
    this.commandService.enable();
  }

  public research() {
    this.commandService.refresh();
  }

  public authTokenSetup(authToken: string) {
    if (authToken && authToken.length && this.dataService) {
      this.dataService.authToken = authToken;
      this.init();
    }
  }

  private init() {
    if (!this.isGridInit) {
      if (this.grid) {
        if ((this.dataService && this.dataService.authToken) || (this.viewMetadata && this.viewMetadata.data)) {
          this.isGridInit = true;
          this.metaService.init(this.viewMetadata, this.fieldMetadata, this.fieldGroupMetadata, this.tabMetadata);
          this.dataSetup();
          this.gridDesignSetup();
          this.gridSetup()
          this.gridPersistenceService.init(this.grid, this.design);
          this.formPersistenceService.registerComponent(this);
          this.dataServiceSetupPost();
        }
      }
    }
  }

  private dataSetup() {
    this.dataService.viewMetadataSetup(this.viewMetadata);
    this.dataService.fieldMetadataSetup(this.fieldMetadata);
    this.dataService.dataManagerSetup();
    this.gridService.data = this.dataService; // TODO: cleanup ref
  }

  private dataServiceSetupPost() {
    this.dataService.setupPost();
  }

  private gridDesignSetup() {
    // Basic setup of grid

    var d = new GridDesign();
    d.id = this.id;
    d.toolbarType = this.toolbarType;
    d.virtualPaging = (this.paging == 'virtual');
    d.pageSize = this.pageSize;
    d.groupingLazyLoading = d.virtualPaging;
    d.allowPaging = ((this.paging == 'pager') && d.toolbarType == 'default');
    d.useEditTemplate = (this.editTemplate != null || this.tabMetadata?.length > 0);
    d.useMaxHeight = (['maximize'].includes(this.height));
    d.autoFocus = (this.autoFocus === 'active');
    d.toolbarTitle = this.toolbarTitle;
    d.dataRelationRole = this.dataRelationRole;
    d.editOnDoubleClick = this.editOnDoubleClick;
    d.selectionMode = this.selectionMode;
    d.selectionInitial = this.selectionInitial;

    this.design = d;
  }

  private gridSetup() {
    this.virtualizeService.init(this.grid, this.design);
    this.editCellService.init(this.grid, this.design);
    this.filterService.init(this.grid, this.design);
    this.gridService.init(this.grid, this.design);
    this.grid.width = this.width;
    this.grid.rowHeight = this.rowHeight;
    if (this.dataRelationRole == 'child' || this.dataRelationRole == 'childAndParent') {
      this.subscriptionManager$.add(this.formService.parentData$.subscribe(data =>
        this.onParentData(data)
      ));
    }
    else if (this.dataService.dataSource == 'datamanager' && this.dataService.dataManager) {
      this.grid.dataSource = this.dataService.dataManager;
      this.grid.query = this.dataService.query;
    }

    this.editService.init(this.grid, this.design);
    this.selection.init(this.grid, this.design);
    this.commandService.init(this.grid, this.design, this.toolbarCommands, this.contextMenuCommands, this.keyboardShortcuts);
  }

  public onCommand(event: UiCommandEvent) {
    event.appComponent = this;

    if (event.source == UiCommandSourceEnum.ActionComplete) {
      switch (event.actionType) {
        case UiActionTypeEnum.DataBound:
          this.onDataBound();
          break;
        case UiActionTypeEnum.DataLoad:
          this.onDataLoad(event.args);
          break;
        case UiActionTypeEnum.ActionFailure:
          this.appDialogService.handleApiError(event.args);
          break;
      }
    }

    this.formService.childCommand$.next(event);
    if (!event.cancel) {
      if (event.source == UiCommandSourceEnum.ActionBegin && (event.actionType == UiActionTypeEnum.Add)) {
        if (this.dataRelationRole == "child" || this.dataRelationRole == "childAndParent") {
          if (this.viewMetadata && this.viewMetadata.parentPrimaryKey && this.viewMetadata.parentForeignKey &&
            this.parentData && this.parentData[this.viewMetadata.parentPrimaryKey]) {
            event.args.data[this.viewMetadata.parentForeignKey] = this.parentData[this.viewMetadata.parentPrimaryKey];
          }
        }
      }
      if (this.dataRelationRole == "parent" || this.dataRelationRole == "childAndParent") {
        this.onCommandParentDataRole(event);
      }
    }
    if (!event.cancel) {
      this.commmand.emit(event);
    }
  }

  private onDataBound() {
    this.isGridDataBound = true;
    this.routeService.isLoading$.next(false);

  }

  private onDataLoad(data: Object[]) {
    if (data && this.grid) {
      this.grid.dataSource = data;
      if (this.isGridDataBound) {
        this.grid.refresh();
      }
      this.isGridDataBound = true;
    }
  }


  // Refresh child data from parent data (optimize during grid data refresh)
  private parentDataSelectingStarted: boolean = false;
  private parentDataResetOnRefreshComplete: boolean = false;
  private onCommandParentDataRole(event: UiCommandEvent) {
    switch (event.source) {
      // ActionBegin: Refresh
      case UiCommandSourceEnum.ActionBegin:
        switch (event.actionType) {
          case UiActionTypeEnum.Refresh:
            this.parentDataResetOnRefreshComplete = true;  // Delay refresh parent data on coming selections
            break;
        }
        break;
      // ActionComplete: Selection+Refresh
      case UiCommandSourceEnum.ActionComplete:
        switch (event.actionType) {
          case UiActionTypeEnum.RowSelecting:
            this.parentDataSelectingStarted = true; // We have a new selection - don't reset on coming RowDeselect
            break;
          case UiActionTypeEnum.RowSelect:
            if (!this.parentDataResetOnRefreshComplete) {
              this.parentDataNext(event.args.data, true);
            }
            this.parentDataSelectingStarted = false;
            break;
          case UiActionTypeEnum.RowDeselect:
            if (!this.parentDataResetOnRefreshComplete && !this.parentDataSelectingStarted) {
              this.parentDataNext(null, false);
            }
            break;
          case UiActionTypeEnum.Refresh:
            if (this.parentDataResetOnRefreshComplete) {
              this.parentDataResetOnRefreshComplete = false;
              this.parentDataNext(this.selection.rowSelectedData(), true);
            }
            break;
          case UiActionTypeEnum.DataBound:
            if (!event.rowsData || event.rowsData.length == 0) {
              this.parentDataNext(null, true);
            }

        }
    }
  }

  public getPersistenceId(): string {
    return this.gridPersistenceService.getId();
  }

  public getPersistence(): any {
    return this.gridPersistenceService.get();
  }

  public applyPersistence(viewType: CerFormUserViewType, persistenceObject: any): void {
    this.gridPersistenceService.apply(viewType, persistenceObject);
  }

  public applyPersistenceComplete(viewType: CerFormUserViewType): void {
    this.gridPersistenceService.applyComplete(viewType);
  }

}

