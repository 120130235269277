// Angular
import { Component, ViewChild, LOCALE_ID, Inject, OnInit, OnDestroy, AfterViewChecked, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

// Material design
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Grid
import { setCulture, Internationalization, extend } from '@syncfusion/ej2-base';
import {
  EditSettingsModel, Column, ForeignKeyService, PageService, ColumnChooserService,
  ToolbarService, SortService, GroupService, ResizeService, ReorderService, AggregateService,
  ColumnMenuService, ContextMenuService, GroupSettingsModel, GridComponent, ExcelExportService, PdfExportService, ContextMenuItemModel,
  ExcelExportProperties, ContextMenuClickEventArgs, CommandModel, DetailRowService, ToolbarItems, IEditCell, ContextMenuOpenEventArgs, FilterService, EditService,
  CustomSummaryType, AggregateColumnModel, RowInfo
} from '@syncfusion/ej2-angular-grids';
import { DataManager, WebApiAdaptor, Query, Predicate } from '@syncfusion/ej2-data';
import { FilteringEventArgs, ChangeEventArgs, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { SplitterComponent } from '@syncfusion/ej2-angular-layouts';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBox } from '@syncfusion/ej2-angular-inputs';

// WebApi
import {
  VoucherViewClient as Client,
  VoucherMessageViewClient as MessageClient,
  VoucherUtilClient as UtilClient,
  VoucherViewVm as Vm,
  VoucherMessageViewDetailsClient as DetailsClient,
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherViewDto as VoucherDto,
  VoucherPostingViewDto as PostingDto,
  VoucherMessageViewDto as MessageDto,
  VoucherApprovalStatusDto as ApprovalStatusDto,
  VoucherApprovalStatusEnum,
  UserDto,
  VendorDto,
  VoucherBaseDataClient,
  VoucherBaseDataVm,
  CurrencyDto,
  VoucherPostingTypeDto,
  ProjectDto,
  ProjectCostTypeDto,
  VoucherPostingTypeEnum,
  FinanceLedgerAccountDto,
  VoucherMessageStatusEnum,
  DebtorDto,
  BankAccountDto,
  FinanceTaxCodeDto,
  VoucherViewDto,
  LinkedAttachmentDto
} from 'src/app/api';

//import { LookupTable, RecordSelectedEventArg } from './commerce-voucher-basedata.component';
import { VoucherHelper, VoucherService } from '../voucher.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { CerAppChatService } from '../../../cer-app/cer-app-chat/cer-app-chat.service';
import { CerAppFileViewerTabComponent } from 'src/cer-app/cer-app-file-viewer-tab/cer-app-file-viewer-tab.component';
import { CerDropDownList } from 'src/cer/cer-control/cer-drop-down-list/cer-drop-down-list';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { CerLocaleService } from 'src/cer/cer-locale/cer-locale.service';
import { CerEditDatePickerAdapter } from 'src/cer/cer-control/cer-date-edit/cer-edit-cell-date-picker-adapter';
import { VoucherDataService } from '../voucher-data.service';
import { VoucherAttachmentService } from '../voucher-attachment.service';
import { CerAppDialogService, DialogInput } from 'src/cer-app/cer-app-dialog/cer-app-dialog.service';
import { AppStateService, DataTableCache } from 'src/app-core/app-state/app-state.service';

enum GridNavigationCmd {
  None,
  Default,
  Voucher,
  Posting,
  PostingFirst,
  PostingLast
};

type ActionType = 'ApprovedAndClose' | 'PendingApprovalAndClose' | 'OnHoldAndClose' | 'NewApproverAndClose' | 'SaveAndClose' | 'Close' | 'AdjustAmounts';

@Component({
  selector: 'voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.css'],
  providers: [VoucherService, PageService, EditService, ForeignKeyService, FilterService, SortService, GroupService, ResizeService, ReorderService,
    ToolbarService, ColumnChooserService, ColumnMenuService, ContextMenuService,
    ExcelExportService, PdfExportService, DetailRowService, AggregateService]
  //    : ChangeDetectionStrategy.OnPush // ChangeDetectionStrategy.Default
})

export class VoucherDetailComponent implements OnInit, OnDestroy, AfterViewChecked {
  // State
  protected pageIsLoading: boolean = true;
  protected isExpenseVoucher: boolean = false;
  protected isProformaEditFromInbox: boolean = false;
  protected attachmentsIsActive: boolean = false;
  protected attachmentSelectedIdxDefault: number = 0;
  protected isAnyVoucherPosted: boolean = false;
  protected isStatusColumnVisible: boolean = false;
  protected categoryVisible: boolean = false;
  protected canEdit: boolean = true;
  protected canNewApprover: boolean = false;
  protected canSendToPending: boolean = false;

  private voucherGridIsDataBound: boolean = false;
  private postingGridIsDataBound: boolean = false;
  private gridNavigationCmd: GridNavigationCmd = GridNavigationCmd.Default;
  private gridCancelledConfirmed: GridComponent = null;
  //private dialogObj: Dialog = null;

  // Form dialog
  protected showChat: boolean = false;
  private callerMsgId: number = null;
  protected voucherCanSaveAndClose: boolean = true;

  private splitterObj: SplitterComponent;
  @ViewChild('splitterInstance', { static: false }) set splitterContent(splitterObj: SplitterComponent) {
    if (splitterObj && !this.splitterObj) {
      this.splitterObj = splitterObj;
      if (this.attachmentsIsActive) {
        this.splitterObj.paneSettings[1].collapsed = false;
      }
    }
  }

  // File viewer
  @ViewChild(CerAppFileViewerTabComponent) fileViewerTab: CerAppFileViewerTabComponent;

  // WebApi
  //public data: DataManager = null;
  private messageDto: MessageDto = null;
  protected voucherDtoList: VoucherDto[] = null;
  private voucherDtoActive: VoucherDto = null;
  protected postingDtoListAll: PostingDto[] = null;
  protected postingDtoList: PostingDto[] = null;
  protected approvalStatusDto: ApprovalStatusDto[] = null;
  private detailsVmMap = new Map<number, DetailsVm>();
  protected detailsVm: DetailsVm = null;
  private voucherBaseDataLoaded: boolean = false;
  private users: UserDto[] = null;
  protected usersDM: DataManager;
  private userCurrent: UserDto = null;
  private taxCodes: FinanceTaxCodeDto[] = null;
  protected taxCodesDM: DataManager;
  private currencies: CurrencyDto[] = null;
  protected currenciesDM: DataManager;
  private postingTypes: VoucherPostingTypeDto[] = null;
  protected postingTypesDM: DataManager;
  private vendorsDefault: VendorDto[] = null;
  private debtorsDefault: DebtorDto[] = null;
  private projectsDefault: ProjectDto[] = null;
  private projectLedgerAccounts: FinanceLedgerAccountDto[] = null;
  private ledgerAccounts: FinanceLedgerAccountDto[] = null;
  protected ledgerAccountsDM: DataManager;
  private projectCostTypes: ProjectCostTypeDto[] = null;
  protected projectCostTypesDM: DataManager;
  private projectCostTypesFiltered: ProjectCostTypeDto[] = null;
  protected projectCostTypesFilteredDM: DataManager;
  private bankAccounts: BankAccountDto[] = null;
  protected userIsAdmin: boolean = false;
  protected dataIsReady: boolean = false;
  protected fromName: string = null;

  private voucherSkipCancelDialog: boolean = false;
  protected voucherIsStatusCreated: boolean = false;
  protected voucherCanApprove: boolean = false;
  protected voucherCanApproveByPostingEdit: boolean = false;
  protected voucherCanHold: boolean = false;

  private postingDtoEdit: PostingDto = null;
  private postingAmountCurEditValue: number = null;

  private vendorPostingProformaDtoOrg: PostingDto = null;
  private projectPostingProformaDtoOrg: PostingDto = null;

  // Grid options
  // Formatting
  protected dateFormat: any = { type: "date", format: "dd.MM.yyyy" };
  protected dateTimeFormat: any = { type: "date", format: "dd.MM.yyyy HH:mm" };

  // Message grid
  private messageGrid: GridComponent;
  @ViewChild('messageGrid', { static: false }) set messageGridContent(content: GridComponent) {
    if (content && !this.messageGrid) {
      this.messageGrid = content;
    }
  }
  protected messageGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons .e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: ".e-grid-menu e-icons .e-columnchooserdiv", target: ".e-headercontent" }
  ];


  // Voucher grid
  private voucherGrid: GridComponent;
  @ViewChild('voucherGrid', { static: false }) set voucherGridContent(content: GridComponent) {
    if (content && !this.voucherGrid) {
      this.voucherGrid = content;
      this.gridEditRowOnClickSetup(this.voucherGrid);
      var transDateCol: Column = this.voucherGrid.getColumnByField('transDate');
      new CerEditDatePickerAdapter(this.voucherGrid, transDateCol, this.localeService);
      var documentDateCol: Column = this.voucherGrid.getColumnByField('documentDate');
      new CerEditDatePickerAdapter(this.voucherGrid, documentDateCol, this.localeService);
    }
  }

  protected voucherGridToolbar: ToolbarItems[] | object = [
    { text: 'Vis vedhæftninger', tooltipText: 'Vis/skjul vedhæftninger (ALT+V)', id: 'attachmentsShowHide' },
    'Edit',
    'Update',
    'Cancel'
  ];
  protected voucherGridGroupSettings: GroupSettingsModel = { disablePageWiseAggregates: true, showDropArea: false, showUngroupButton: true, showGroupedColumn: false };
  protected gridCommands: CommandModel[] = [
    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
    { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
    { type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
    { type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' } }];
  protected voucherGridEditSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', showDeleteConfirmDialog: true, newRowPosition: 'Bottom' };
  protected voucherGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnGroup', text: "Grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: "e-grid-menu e-icons e-columnchooserdiv", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'gridExcelExport', text: "Excel eksport", iconCss: "e-menu-icon e-icons e-excelexport", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'dataBind', text: "Genlæs data", iconCss: "e-menu-icon e-icons e-reload-01", target: ".e-headercontent" },
    { id: 'setStatusPending', text: "Sæt status 'Til godkendelse'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusOnHold', text: "Sæt status 'Afventer'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusApproved', text: "Sæt status 'Godkendt'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" },
    { id: 'setStatusAccounted', text: "Sæt status 'Afsluttet'", iconCss: "e-menu-icon e-icons e-close-01", target: ".e-content" },
    { id: 'setStatusPosted', text: "Sæt status 'Bogført'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content" }];

  // Posting grid
  private postingGrid: GridComponent;
  private postingGridAutoGroupDone: boolean = false;
  @ViewChild('postingGrid', { static: false }) set postingGridContent(content: GridComponent) {
    if (content && !this.postingGrid) {
      this.postingGrid = content;
    }
  }
  protected postingGridEditSettings: EditSettingsModel = {
    allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', showConfirmDialog: false,
    showDeleteConfirmDialog: true, newRowPosition: 'Bottom'
  };
  protected postingGridGroupSettings: GroupSettingsModel = {/* disablePageWiseAggregates: true,*/ showDropArea: false, showUngroupButton: true, showGroupedColumn: false };
  protected postingGridToolbar: ToolbarItems[] | object = [
    { text: 'Fordel beløb', tooltipText: 'Fordel beløb konteret til at matche fakturabeløb', id: 'postingAdjustAmounts' },
    'Edit',
    'Update',
    'Cancel',
    { text: 'Ny post', id: 'postingAdd', prefixIcon: 'e-btn-icon e-add e-icons' },
    { text: 'Slet', id: 'postingDelete', prefixIcon: 'e-btn-icon e-delete e-icons' }
  ];
  protected postingGridContextMenuItems: ContextMenuItemModel[] = [
    { id: 'gridColumnGroup', text: "Grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnUnGroup', text: "Fjern grupper", iconCss: "e-menu-icon e-icons e-icon-group", target: ".e-headercontent" },
    { id: 'gridColumnHide', text: "Skjul kolonne", iconCss: "e-menu-icon e-icons e-icon-hide", target: ".e-headercontent" },
    { id: 'gridColumnsChoose', text: "Kolonner ..", iconCss: "e-grid-menu e-icons e-columnchooserdiv", target: ".e-headercontent" },
    { separator: true, target: ".e-headercontent" },
    { id: 'gridExcelExport', text: "Excel eksport", iconCss: "e-menu-icon e-icons e-excelexport", target: ".e-headercontent" }
  ];

  // Controls
  private balanceAccountNumElement: HTMLInputElement;
  private documentNumElement: HTMLInputElement;


  protected voucherVendorAccountNumEditCell: IEditCell;
  private voucherVendorAccountNumDdl: CerDropDownList;
  private voucherVendorAccountNumDdlElement: HTMLInputElement;

  protected voucherProjectNumEditCell: IEditCell;
  private voucherProjectNumDdl: CerDropDownList;
  private voucherProjectNumDdlElement: HTMLInputElement;

  protected currencyEditCell: IEditCell;
  private currencyDdl: CerDropDownList;
  private currencyDdlElement: HTMLInputElement;

  protected approverEditCell: IEditCell;
  private approverDdl: CerDropDownList;
  private approverDdlElement: HTMLInputElement;

  protected postingTypeEditCell: IEditCell;
  private postingTypeDdl: CerDropDownList;
  private postingTypeDdlElement: HTMLInputElement;

  protected accountNumEditCell: IEditCell;
  private accountNumDdl: CerDropDownList;
  private accountNumDdlElement: HTMLInputElement;
  private accountNumEditDdlCurrentTableName: string = '';
  private accountNumFilterDefaultListData: object[] = null;

  private projectCostTypeDdl: CerDropDownList;
  public projectCostTypeEditCell: IEditCell;
  private projectCostTypeDdlElement: HTMLInputElement;

  private projectLedgerNumDdl: CerDropDownList;
  public projectLedgerNumEditCell: IEditCell;
  private projectLedgerNumDdlElement: HTMLInputElement;

  private taxCodeDdl: CerDropDownList;
  public taxCodeEditCell: IEditCell;
  private taxCodeDdlElement: HTMLInputElement;

  public avoidFocusOnVendor: boolean = false;

  protected keyDownEventListener = this.handleKeyDownEvent.bind(this);;

  // inject the Element
  // Constructor
  constructor(private authorize: AuthorizeService, private stateService: AppStateService,
    private client: Client, private messageClient: MessageClient, private detailsClient: DetailsClient, private voucherBaseDataClient: VoucherBaseDataClient,
    private utilClient: UtilClient,
    private router: Router, private chatService: CerAppChatService,
    private dialogService: CerDialogService, private ui: CerAppDialogService,
    private localeService: CerLocaleService, public dialog: MatDialog, private dialogRef: MatDialogRef<VoucherDetailComponent>,
    public sanitizer: DomSanitizer, private voucherService: VoucherService, @Inject(MAT_DIALOG_DATA) callerData: any, @Inject(LOCALE_ID) locale: string) {

    setCulture(locale);

    if (callerData != null) {
      this.callerMsgId = callerData.dto.msgId ? callerData.dto.msgId : callerData.dto.id; // Called from 1) voucher or 2) message
      this.userIsAdmin = callerData.userIsAdmin;
      this.attachmentsIsActive = (callerData.attachmentsIsActive || callerData.showChat);
      this.showChat = callerData.showChat;
      this.avoidFocusOnVendor = this.attachmentsIsActive;
    }

    if (!this.userIsAdmin) {
      this.voucherGridContextMenuItems = this.voucherGridContextMenuItems.filter(mi => !['setStatusApproved', 'setStatusAccounted', 'setStatusPosted'].includes(mi.id));
    }

  }

  ngAfterViewChecked() {
    //console.log('ngAfterViewChecked ' + (new Date()).getSeconds());
  }

  ngOnInit(): void {
    this.chatService.chats$.subscribe(chat => {
      VoucherDataService.detailsVmMapRefreshChat(this.detailsVmMap, this.chatService, chat);
      this.dataBindCalcStatus();
    });

    if (this.callerMsgId != null) {
      this.detailsVmDataBind(true);
      this.voucherBaseDataVmDataBind();
    }

    this.authorize.getAccessToken().subscribe(
      token => this.authToken = token
    );

    document.addEventListener('keydown', this.keyDownEventListener, false);
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.keyDownEventListener, false);
  }


  private authToken: string;
  private apiDataManager(): DataManager {
    return new DataManager({
      url: '/api',
      adaptor: new WebApiAdaptor,
      headers: [{ Authorization: 'Bearer ' + this.authToken }]
    });
  }
  /*
  private pageIsLoadingUpdate() {
    this.pageIsLoading = (this.messageDtoList == null);
  }
  */

  // Router
  private routerShortUrl(): string {
    let routeShortUrl = this.router.url;
    while (routeShortUrl.startsWith('/')) {
      routeShortUrl = routeShortUrl.substr(1);
    }
    return routeShortUrl;
  }

  private voucherBaseDataTableName: string = 'VoucherBaseData';

  private async voucherBaseDataVmDataBind() {
    var cachedVm: DataTableCache = this.stateService.getDataTableCache(this.voucherBaseDataTableName);
    if (cachedVm.loaded) {
      this.baseDataVmUnpack(<VoucherBaseDataVm>cachedVm.data[0]);
    }
    else {
      this.voucherBaseDataClient.get().subscribe({
        next: (voucherBaseDataVm: VoucherBaseDataVm) => {
          this.baseDataVmUnpack(voucherBaseDataVm);
          this.stateService.setDataTableCache(cachedVm, [voucherBaseDataVm]);
        },
        error: (error: string) => {
          this.error(["Indlæsning af godkendelse fejlet", error]);
        }
      });
    }
  }

  private baseDataVmUnpack(baseDataVm: VoucherBaseDataVm) {
    this.users = baseDataVm.users;
    this.usersDM = new DataManager(this.users);
    this.userCurrent = baseDataVm.userCurrent;
    this.taxCodes = baseDataVm.taxCodes;
    this.taxCodesDM = new DataManager(this.taxCodes);
    this.currencies = baseDataVm.currencies;
    this.currenciesDM = new DataManager(this.currencies)
    this.postingTypes = baseDataVm.postingTypes;
    this.postingTypesDM = new DataManager(this.postingTypes);

    this.vendorsDefault = baseDataVm.vendorsDefault;
    this.debtorsDefault = baseDataVm.debtorsDefault;
    this.projectsDefault = baseDataVm.projectsDefault;
    this.projectLedgerAccounts = baseDataVm.projectLedgerAccounts;
    this.ledgerAccounts = baseDataVm.ledgerAccounts;
    this.ledgerAccountsDM = new DataManager(this.ledgerAccounts);
    this.bankAccounts = baseDataVm.bankAccounts;
    this.bankAccounts.forEach(b => b.description = b.description ?? b.bankAccountNum);
    this.projectCostTypes = baseDataVm.projectCostTypes;
    this.projectCostTypes.push(new ProjectCostTypeDto({ id: null, num: '', name: '', description: '', costOnly: true }));
    this.projectCostTypesDM = new DataManager(this.projectCostTypes);
    this.voucherBaseDataLoaded = true;

    if (this.voucherBaseDataLoaded && this.detailsVm) {
      this.setAttachmentSelectextIdxDefault();
      this.dataBindGrids();
    }
  }

  // DetailsVm (including attachments etc)
  private detailsVmReBind(detailsVmNew: DetailsVm, focusVoucherId: number) {
    if (detailsVmNew != null) {
      this.detailsVm = detailsVmNew;
      this.detailsVmMap.set(this.detailsVm.voucherMessageViewDto.id, this.detailsVm);
      this.dataBindGrids();
      this.voucherGridRefreshSavedData();
      console.log('detailsVmReBind completed');
    }
  }

  private voucherGridRefreshSavedData() {
    this.voucherGrid.dataSource = this.voucherDtoList;
    this.postingGrid.dataSource = this.postingDtoList;
    this.postingGrid.refresh();
    this.actionRunDeferred();
  }

  private setAttachmentSelectextIdxDefault() {
    var idx: number = 0;
    var i: number = 0;
    if (this.showChat) {
      this.attachmentSelectedIdxDefault = this.detailsVm.voucherMessageAttachmentDtoList.length;
    }
    else {
      this.detailsVm.voucherMessageAttachmentDtoList.forEach(a => { if (idx == 0 && a.fileExtension.toLocaleLowerCase() == '.pdf') { idx = i }; i++; });
      this.attachmentSelectedIdxDefault = idx;
    }
  }

  private detailsVmDataBind(initialLoad: boolean = false) {
    this.voucherGridIsDataBound = false;
    this.postingGridIsDataBound = false;
    var msgId: number = this.callerMsgId;
    if (msgId) {
      if (this.detailsVmMap.has(msgId)) {
        this.detailsVm = this.detailsVmMap.get(msgId);
        if (initialLoad) {
          this.setAttachmentSelectextIdxDefault();
        }
        this.dataBindGrids();
      }
      else {
        this.detailsVmGetPromise(msgId).then((detailsVm) => {
          if (initialLoad) {
            this.setAttachmentSelectextIdxDefault();
          }
        });
      }
    }
  }

  private detailsVmGet(msgId: number) {
    this.detailsVmGetPromise(msgId).then();
  }

  private detailsVmGetPromise(msgId: number, focusVoucherId: number = null): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve) => {
      this.apiDetailsVmGet(msgId).then((detailsVm) => {
        this.detailsVmBindNonCached(msgId, detailsVm, focusVoucherId);
        resolve(detailsVm);
      });
    });
  }

  private apiDetailsVmGet(msgId: number): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.detailsClient.get(msgId).subscribe({
        next: (detailsVm) => { resolve(detailsVm); },
        error: (error) => {
          this.dialogService.snackBar("Indlæsning af stamdata fejlet : " + error);
          reject(error);
        }
      });
    });
  }

  private detailsVmBindNonCached(msgId: number, detailsVm: DetailsVm, focusVoucherId: number = null) {
    this.voucherService.prepareDetailsVm(detailsVm);
    this.detailsVm = detailsVm;
    this.detailsVmMap.set(msgId, this.detailsVm);
    if (this.detailsVm && this.voucherBaseDataLoaded) {
      this.dataBindGrids();
    }
  }

  protected attachments: LinkedAttachmentDto[] = null;
  public dataBindGrids() {
    if (this.detailsVm && this.voucherBaseDataLoaded) {
      if (!this.isEqual(this.attachments, this.detailsVm.voucherMessageAttachmentDtoList)) {
        this.attachments = this.detailsVm.voucherMessageAttachmentDtoList;
      }
      this.dataIsReady = true;
      this.isExpenseVoucher = this.voucherService.isExpenseVoucher(this.detailsVm.voucherMessageChannelType);

      this.voucherDtoList = this.detailsVm.voucherViewDtoList;
      this.postingDtoListAll = this.detailsVm.voucherPostingViewDtoList;
      this.projectsDefault = this.projectsDefault;

      var voucherDto: VoucherViewDto = this.voucherDtoList.length > 0 ? this.voucherDtoList[0] : null;
      this.messageDto = this.detailsVm.voucherMessageViewDto;
      this.isProformaEditFromInbox = this.voucherService.isVendorVoucher(this.detailsVm.voucherMessageChannelType) && this.messageDto?.status == VoucherMessageStatusEnum.Inbox;

      this.canNewApprover = (!this.userIsAdmin && [VoucherApprovalStatusEnum.Pending, VoucherApprovalStatusEnum.OnHold].includes(voucherDto?.status));
      this.canSendToPending = (this.userIsAdmin && [VoucherApprovalStatusEnum.Created, VoucherMessageStatusEnum.Inbox].includes(voucherDto?.status));
      this.voucherCanSaveAndClose = true;
      if (this.canSendToPending && this.voucherService.isVendorVoucher(this.detailsVm.voucherMessageChannelType)) {
        this.voucherCanSaveAndClose = false;
      }

      if (!this.projectCostTypesFiltered) {
        this.projectCostTypesFiltered = this.isExpenseVoucher ? this.projectCostTypes : this.projectCostTypes.filter(c => c.costOnly);
        this.projectCostTypesFilteredDM = new DataManager(this.projectCostTypesFiltered);
      }

      //this.postingDtoListAll = Object.assign({}, this.detailsVm.voucherPostingViewDtoList);
      if (!this.userIsAdmin) {
        var that = this;
        this.postingDtoList = this.detailsVm.voucherPostingViewDtoList.filter(p => !that.isPostingTypeVendor(p.postingType))
      }
      else {
        this.postingDtoList = this.detailsVm.voucherPostingViewDtoList;
      }

      this.dataBindCalcStatus();

      this.vendorsDefault = this.vendorsDefault;
      this.detailsVm.vendorsSelected.forEach(s => {
        if (!this.vendorsDefault.find(d => d.num == s.num)) {
          this.vendorsDefault.unshift(s);
        }
      });

      this.categoryVisible = (this.isExpenseVoucher);

      this.debtorsDefault = this.debtorsDefault;
      this.detailsVm.debtorsSelected.forEach(s => {
        if (!this.debtorsDefault.find(d => d.num == s.num)) {
          this.debtorsDefault.unshift(s);
        }
      });

      this.projectsDefault = this.projectsDefault;
      this.detailsVm.projectsSelected.forEach(s => {
        if (!this.projectsDefault.find(d => d.num == s.num)) {
          this.projectsDefault.unshift(s);
        }
      });

      this.voucherVendorAccountNumEditCellSetup(); // Voucer vendor account num
      this.voucherProjectNumEditCellSetup();  // Voucher project num
      this.currencyEditCellSetup(); // Currency
      this.approverEditCellSetup();  // User (approver)
      this.postingTypeEditCellSetup();  // Posting type editor
      this.accountNumEditCellSetup();   // Account num editor
      this.projectCostTypeEditCellSetup(); // Cost type editor
      this.projectLedgerNumEditCellSetup(); // Project ledger num editor
      this.taxCodeEditCellSetup();  // Tax code

      this.setFromName();
    }
  }

  private setFromName(data: any = null) {
    this.fromName = this.voucherTitleName(this.messageDto, this.voucherDtoList, data);
  }

  private isPostingTypeProject(postingType: number): boolean {
    return this.voucherService.isPostingTypeProject(postingType);
  }

  private isPostingTypeLedger(postingType: number): boolean {
    return this.voucherService.isPostingTypeLedger(postingType);
  }

  private isPostingTypeVendor(postingType: number): boolean {
    return this.voucherService.isPostingTypeVendor(postingType);
  }

  private voucherTitleName(msgDto: MessageDto, voucherList: VoucherDto[], vendorDto: any): string {
    var fromName: string = null;
    var vendorName: string = null;
    if (this.userIsAdmin) {
      if (msgDto) {
        // FromName
        fromName = '';  //strTrim(msgDto.fromEmail ? msgDto.fromEmail : '') + " " + (msgDto.fromName ? '(' + msgDto.fromName + ')' : '');
        if (msgDto.fromEmail?.length > 0) {
          var fromUsers: UserDto[] = this.users.filter(u => u.email == msgDto.fromEmail);
          if (fromUsers?.length > 0) {
            fromName = fromUsers[0].shortName;
          }
        }

        // Vendor name
        if (vendorDto) {
          vendorName = vendorDto.description;
        }
        else {
          var accountNum = '';
          var vouchers = voucherList?.filter(v => v.balanceAccountDescription?.length > 0);
          if (vouchers.length > 0) {
            accountNum = vouchers[0].balanceAccountNum;
          }
          if (this.voucherGrid?.isEdit) {
            var balanceNumCtrl = this.voucherGridEditFormInputElementGetByColumnName('balanceAccountNum');
            if (balanceNumCtrl) {
              accountNum = balanceNumCtrl.value;
            }
          }
          if (accountNum?.length > 0) {
            var vendors = this.vendorsDefault?.filter(v => v.num == accountNum);
            if (vendors.length > 0) {
              vendorName = vendors[0].description;
            }
          }
        }
      }
      var name = '';
      if (fromName?.length > 0) {
        name = fromName;
      }
      if (vendorName?.length > 0) {
        name = (name.length > 0 ? name + ' / ' : '') + vendorName;
      }
    }
    return name;
  }

  private dataBindCalcStatus() {
    //this.userIsAdmin = this.detailsVm.userIsAdmin;

    this.isAnyVoucherPosted = false;
    this.isStatusColumnVisible = false;
    this.voucherCanApprove = false;
    this.voucherCanHold = false;
    //this.isAllApproved = false;
    this.canEdit = true;

    var isIncomplete: boolean = false;
    this.voucherDtoList.forEach(v => {
      if (this.voucherService.IsStatusPosted(v.status)) {
        this.isAnyVoucherPosted = true;
      }
      if (this.voucherService.IsStatusNonCreatedOrPendingOrOnHold(v.status)) {
        this.isStatusColumnVisible = true;
      }
      if (v.approver == this.userCurrent.id || (v.approver == null && this.userIsAdmin)) {
        if (!this.voucherService.IsStatusNonCreatedOrPendingOrOnHold(v.status)) {
          this.voucherCanApprove = true;
          if (!this.voucherService.voucherIsComplete(v, this.userIsAdmin, this.isExpenseVoucher)) {
            isIncomplete = true;
          }
          this.voucherCanHold = this.voucherService.IsStatusPendingOrCreated(v.status);
        }
        if (this.voucherService.IsStatusApproved(v.status)) {
          this.voucherCanHold = true;
        }
      }
      if (this.voucherService.IsStatusAccountedOrPosted(v.status)) {
        this.canEdit = false;
      }
    });

    var hasApprovedByChat = (!this.userIsAdmin && this.detailsVm?.chatDtoList != null && this.detailsVm.chatDtoList.length > 0);
    this.voucherCanApproveByPostingEdit = (this.voucherCanApprove && !isIncomplete && !hasApprovedByChat);

    var amountSum: number = 0;
    this.postingDtoListAll.forEach(p => {
      if (!this.voucherService.postingIsComplete(p, this.userIsAdmin)) {
        isIncomplete = true;
      }
      amountSum += p.amountCur;
    });
    if (Math.abs(amountSum) > 0.001) {
      isIncomplete = true;
    }

    this.voucherCanApprove = (this.voucherCanApprove && !isIncomplete) || hasApprovedByChat;
    this.voucherCanHold = this.voucherCanHold && !this.userIsAdmin;

    this.voucherIsStatusCreated =
      this.voucherDtoList?.filter(v => v.status == VoucherApprovalStatusEnum.Created)?.length > 0;
    this.voucherGridEditSettings.allowEditing = this.canEdit && (this.userIsAdmin || this.voucherIsStatusCreated);
    this.voucherGridEditSettings.allowAdding = false;
    this.voucherGridEditSettings.allowDeleting = false;
    this.postingGridEditSettings.allowEditing = this.canEdit;
    this.postingGridEditSettings.allowDeleting = this.canEdit;
    if (!this.postingGridEditSettings.allowDeleting) {
      this.postingGridToolbar = (<any[]>this.postingGridToolbar).filter(i => (i.id == null || i.id != 'postingDelete'));
    }
    if (!this.postingGridEditSettings.allowAdding) {
      this.postingGridToolbar = (<any[]>this.postingGridToolbar).filter(i => (i.id == null || i.id != 'postingAdd'));
    }
  }

  private voucherCanApproveByPostingEditCalc(postingEditDataPassed: PostingDto = null) {
    if (this.voucherCanApproveByPostingEdit) {

      var amountSum: number = 0;
      var isIncomplete = false;


      var postingDtoFull: PostingDto = new PostingDto();
      Object.assign(postingDtoFull, this.postingDtoEdit);

      var postingEditData: PostingDto = (postingEditDataPassed != null) ? postingEditDataPassed : this.postingGrid.isEdit ? this.gridCurrentEditDtoGet(this.postingGrid) : null;
      if (postingEditData != null) {
        Object.assign(postingDtoFull, postingEditData);
      }

      this.postingDtoListAll.forEach(p => {
        if (!postingDtoFull || p.id != postingDtoFull.id) {
          if (!this.voucherService.postingIsComplete(p, this.userIsAdmin)) {
            isIncomplete = true;
          }
          amountSum += p.amountCur;
        }
      });

      if (postingDtoFull && !this.voucherService.postingIsComplete(postingDtoFull, this.userIsAdmin)) {
        isIncomplete = true;
      }
      amountSum += postingDtoFull.amountCur;

      if (amountSum != 0) {
        isIncomplete = true;
      }
      this.postingGrid.aggregateModule.refresh(postingEditData);

      this.voucherCanApprove = !isIncomplete;
    }
  }

  /*
  private voucherInitFromMessage(dto: VoucherDto): boolean {
    let ok: boolean = false;
    let messageDto = this.messageDtoList[0];
    if (messageDto.id) {
      ok = true;
      dto.msgId = messageDto.id;
      let dtoPrev: VoucherDto = this.voucherGridRowActive();
      if (dtoPrev) {
        dto.approver = dtoPrev.approver;
        dto.approverName = dtoPrev.approverName;
        dto.approverShortName = dtoPrev.approverShortName;
        dto.currency = dtoPrev.currency;
        dto.currencyISO = dtoPrev.currencyISO;
        dto.currencyName = dtoPrev.currencyName;
        dto.documentDate = dtoPrev.documentDate;
        dto.documentNum = dtoPrev.documentNum;
        dto.notes = dtoPrev.notes;
        dto.recieved = dtoPrev.recieved;
        dto.status = dtoPrev.status;
        dto.statusName = dtoPrev.statusName;
        dto.transDate = dtoPrev.transDate;
        dto.txt = dtoPrev.txt;
        dto.voucherNum = dtoPrev.voucherNum;
        dto.channelNum = dto.channelNum;
        dto.channelName = dto.channelName;
      }
      else {
        dto.txt = messageDto.subject;
        dto.recieved = messageDto.recieved;
        dto.transDate = messageDto.recieved;
        dto.channelNum = messageDto.channelNum;
        dto.channelName = messageDto.channelName;
      }
    }
    return ok;
  }
  */

  protected postingGridLoad(args: any) {
    this.gridEditRowOnClickSetup(this.postingGrid);
  }

  /*
  private voucherGridLoad(args: any) {
  }
  */

  private gridEditRowOnClickSetup(grid: GridComponent) {
    grid.element.addEventListener('mouseup', (e: MouseEvent) => {
      this.gridEditRowOnClick(grid, e);
    });
  }

  private gridEditRowOnClick(grid: GridComponent, e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
      let rowInfo = grid.getRowInfo(e.target);
      this.gridEditRow(grid, rowInfo);
    };
  }

  private gridEditRow(grid: GridComponent, rowInfo: RowInfo) {
    if (grid) {
      if (grid.editSettings.allowEditing) {
        if (this.voucherGrid.isEdit || this.postingGrid.isEdit) {
          this.gridDeferred = grid;
          this.gridRequestTypeDeferred = 'beginEdit';
          this.gridSelectRowIdxDeferred = rowInfo.rowIndex;
          this.gridEditSaveCancel(this.voucherGrid);
          this.gridEditSaveCancel(this.postingGrid);
        }
        else {
          grid.selectRow(rowInfo.rowIndex);
          if (grid.selectedRowIndex >= 0) {
            grid.startEdit();
          }
        }
      }
      else {
        grid.selectRow(rowInfo.rowIndex);
      }
    }
  }

  private isEqual(obj1: any, obj2: any): boolean {
    var s1: string = JSON.stringify(obj1, function (k, v) { return v === undefined ? null : v; });
    var s2: string = JSON.stringify(obj2, function (k, v) { return v === undefined ? null : v; });
    return (s1 === s2);
  }

  private gridCurrentEditedDataGet(grid: GridComponent, dto: any = {}): any {
    var editData: any = dto;
    if (grid != null && grid.editModule != null && grid.isEdit) {
      var form: Element = this.gridEditFormElementGet(grid);
      if (form != null) {
        try {
          editData = grid.editModule.getCurrentEditedData(form, dto);
        }
        catch {
          console.error("Error getting edit data");
        }
      }
    }
    return editData;
  }

  private gridOriginalEditDtoGet(grid: GridComponent): any {
    return grid.editModule?.editModule?.previousData;
  }

  private gridCurrentEditDtoGet(grid: GridComponent): any {
    var dto: any = null;
    if (grid?.editModule?.editModule != null) {
      var dtoOrig: any = this.gridOriginalEditDtoGet(grid);
      dto = this.gridCurrentEditedDataGet(grid, extend({}, {}, dtoOrig, true)) ?? dtoOrig;
    }
    return dto;
  }

  private isEditUnchanged(grid: GridComponent, dtoChanged: any = null): boolean {
    var dtoOrig: any = grid.editModule.editModule.previousData;
    var dtoEdit: any = this.gridCurrentEditDtoGet(grid);
    return this.isEqual(dtoEdit, dtoOrig);
  }

  private gridEditSaveCancel(grid: GridComponent, forceCancelEdit: boolean = false, skipCancelUi: boolean = false): boolean {
    var doSave: boolean = false;
    if (grid && grid.isEdit) {
      if (forceCancelEdit || this.isEditUnchanged(grid)) {
        if (!skipCancelUi) {
          if (grid == this.voucherGrid) {
            this.voucherSkipCancelDialog = true;
          }
          grid.closeEdit();
        }
      }
      else {
        grid.endEdit();
        this.gridAwaitRefresh = true;
        doSave = true;
      }
    }
    return doSave;
  }

  private gridEditFormElementGet(grid: GridComponent): HTMLFormElement {
    return grid.editModule?.formObj?.element;
  }

  protected voucherGridRowSelected(args: any) {
    //console.log('voucherGridRowSelected ' + args.rowIndex);
    this.voucherDtoActive = args.data;
    this.gridOnRowSelectedDeferredNotify(this.voucherGrid, args.RowIndex);
    this.gridsActionDeferred();
  }

  protected postingGridRowSelected(args: any) {
    //console.log('postingGridRowSelected ' + args.rowIndex);
    this.gridOnRowSelectedDeferredNotify(this.postingGrid, args.rowIndex);
    this.gridsActionDeferred();
  }

  private voucherSave(dto: VoucherDto) {
    this.gridNavigationCmd == GridNavigationCmd.None;
    this.gridEditSaveCancel(this.voucherGrid, true);
    this.voucherGridIsDataBound = false;
    this.postingGridIsDataBound = false;
    if (dto.msgId) {
      if (dto.id) {
        this.apiVoucherPutPromise(dto).then(detailsVm => {
          this.voucherSavePost(detailsVm, dto);
        }).catch(error => {
          this.apiError(error);
          this.detailsVmGet(dto.msgId);
        });
      }
    }
    else {
      let voucherDto = new VoucherDto();
      for (var c in dto) {
        (<any>voucherDto)[c] = (<any>dto)[c];
      }
      this.apiVoucherPostPromise(dto).then(detailsVm => {
        this.voucherSavePost(detailsVm, dto);
      });
    }
  }

  private voucherApprovalStatusSetDeferred: VoucherApprovalStatusEnum = null;  // Set status after save complete
  private voucherSavePost(detailsVm: DetailsVm, voucherDtoUpdated: VoucherDto) {
    if (this.voucherApprovalStatusSetDeferred) {
      this.voucherApprovalStatusSetDeferred = null;
      this.setApprovalStatusPromise(voucherDtoUpdated?.id, this.voucherApprovalStatusSetDeferred).then(voucherDto => {
        this.detailsVmReBind(detailsVm, voucherDto.id);
      });
    }
    else {
      this.detailsVmReBind(detailsVm, voucherDtoUpdated.id);
    }
  }

  private apiVoucherPutPromise(dto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve,reject) => {
      var detailsVm: DetailsVm = null;
      this.detailsClient.putVoucher(dto.msgId, dto.id, dto).subscribe({
        next: (detailsVmReturn: DetailsVm) => {
          detailsVm = detailsVmReturn;
          resolve(detailsVm);
        },
        error: (error) => {
          this.apiError(error);
          this.detailsVmGet(dto.msgId);
          reject(error);
        }
      });
    });
  }

  private apiVoucherPostPromise(dto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.detailsClient.postVoucher(dto.msgId, dto).subscribe({
        next: (detailsVm: DetailsVm) => {
          resolve(detailsVm);
        },
        error: (error) => {
          this.apiError(error);
          this.detailsVmGet(dto.msgId);
          reject(error);
        }
      });
    });
  }

  private apiVoucherDeletePromise(dto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve) => {
      this.gridNavigationCmd == GridNavigationCmd.None;
      var detailsVm: DetailsVm = null;
      if (dto?.id != null) {
        this.detailsClient.deleteVoucher(dto.msgId, dto.id).subscribe({
          next: (detailsVmUpdated: DetailsVm) => {
            detailsVm = detailsVmUpdated;
            this.detailsVm = detailsVm;
            this.detailsVmMap.set(this.detailsVm.voucherMessageViewDto.id, this.detailsVm);
            //this.dataBindGrids(this.getLastVoucherId(this.detailsVm.voucherViewDtoList));
          },
          error: (error) => {
            this.error(["Fejl ved sletning af godkendelse", error]);
          },
          complete: () => {
            resolve(detailsVm);
          }
        });
      }
      else {
        resolve(detailsVm);
      }
    });
  }

  private getLastVoucherId(dtoList: VoucherDto[]): number {
    var lastVoucherId: number = null;
    if (dtoList && dtoList.length > 0) {
      lastVoucherId = dtoList[dtoList.length - 1].id;
    }
    return lastVoucherId;
  }

  private postingInitFromVoucher(dto: PostingDto): boolean {
    let ok: boolean = false;
    let voucherDto = this.voucherGridRowActive();
    if (voucherDto.id) {
      ok = true;
      dto.id = null;
      dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Ledger : VoucherPostingTypeEnum.Project;
      dto.msgId = voucherDto.msgId;
      dto.voucherId = voucherDto.id;
      dto.txt = voucherDto.txt;
      dto.amountCur = voucherDto.amountCur;
      dto.transDate = voucherDto.transDate;

      let dtoPrev: PostingDto = this.postingGridLastRow(voucherDto);
      if (dtoPrev) {
        dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Ledger : VoucherPostingTypeEnum.Project;
        dto.lineNum = dtoPrev.lineNum + 1;
      }
      else {
        dto.postingType = this.isExpenseVoucher ? VoucherPostingTypeEnum.Bank : VoucherPostingTypeEnum.Vendor;
        dto.lineNum = 1;
      }
    }
    return ok;
  }

  private clone(from: any, to: any) {
    to = structuredClone(from);
    /*
    for (var attribut in from) {
      if (typeof from[attribut] === "object" && from[attribut] != null) {
        to[attribut] = from[attribut].clone();
      } else {
        to[attribut] = from[attribut];
      }
    }
    */
  }

  private postingAdjustAmountsConfirm() {
    var _this = this;
    this.dialogService.confirmPrompt("Fordel beløb konteret til at matche fakturabeløb?").then(ok => {
      if (ok) {
        this.actionRun("AdjustAmounts");
      }
    }
    );
  }

  private postingAdjustAmountsApi() {
    var msgId: number = this.detailsVm.voucherMessageViewDto.id;
    this.utilClient.adjustPostingAmountToVoucher(msgId).subscribe({
      next: (result) => {
        this.detailsVm = result;
        this.detailsVmMap.set(msgId, this.detailsVm);
        this.dataBindGrids();
      },
      error: (error) => {
        this.apiError(error);

      }
    });
  }

  private postingSave(dto: PostingDto, gridRowIdx: number) {
    this.gridNavigationCmd == GridNavigationCmd.None;
    if (dto.msgId) {
      if (dto.id) {
        var postingDto: PostingDto = dto;
        this.detailsClient.putPosting(dto.msgId, dto.id, dto).subscribe({
          next: (result) => {
            postingDto = result;
            this.postingPutResultApply(postingDto, dto);
            this.postingGridRefreshSavedData(gridRowIdx);
          },
          error: (error) => {
            this.apiError(error);
            this.detailsVmGet(dto.msgId);
          },
        });
      }
      else {
        this.detailsClient.postPosting(dto.msgId, dto).subscribe({
          next: (result) => {
            this.postingPostResultApply(dto, result);
            this.gridNavigationCmd = GridNavigationCmd.PostingLast;
            this.postingGridRefreshSavedData(gridRowIdx);
          },
          error: (error) => {
            this.error(error);
            this.detailsVmDataBind();
          }
        });
      }
    }
  }

  private postingDelete(dtoList: PostingDto[]) {
    this.gridNavigationCmd == GridNavigationCmd.None;
    for (var dto of dtoList) {
      this.detailsClient.deletePosting(dto.msgId, dto.id).subscribe({
        next: (result) => {
          this.postingDeleteResultApply(dto);
          this.gridNavigationCmd = GridNavigationCmd.PostingLast;
          this.postingGridRefresh(-1);
        },
        error: (error) => {
          this.apiError(error);
          this.detailsVmGet(dto.msgId);
        }
      });
    }
  }

  private postingPostResultApply(dto: PostingDto, result: PostingDto) {
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1);
    }
    this.postingDtoList.push(result);

    if (!this.userIsAdmin) {
      var idx = this.postingDtoListAll.findIndex(function (element) { return element.id === dto.id; });
      if (idx !== -1) {
        this.postingDtoListAll.splice(idx, 1);
      }
      this.postingDtoListAll.push(result);
    }
  }

  private postingPutResultApply(result: PostingDto, dto: PostingDto) {
    this.clone(result, dto);
    this.postingGrid.setRowData(dto.id, dto);
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === result.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1, dto);
    }
    else {
      this.postingDtoList.push(dto);
    }
    idx = this.postingDtoListAll.findIndex(function (element) { return element.id === result.id; });
    if (idx !== -1) {
      this.postingDtoListAll.splice(idx, 1, dto);
    }
    else {
      this.postingDtoListAll.push(dto);
    }
  }

  private postingGridRefreshSavedData(gridRowIdx: number) {
    this.dataBindCalcStatus();
    this.postingGridRefresh(gridRowIdx);
    this.actionRunDeferred();
  }

  private postingDeleteResultApply(dto: PostingDto) {
    var idx = this.postingDtoList.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoList.splice(idx, 1);
    }

    idx = this.postingDtoListAll.findIndex(function (element) { return element.id === dto.id; });
    if (idx !== -1) {
      this.postingDtoListAll.splice(idx, 1);
    }
  }

  private postingAmountCurAggregateRow(row: PostingDto): number {
    var amountCur: number = row.amountCur;
    if (this.postingGrid.isEdit && this.postingDtoEdit && this.postingDtoEdit.id == row.id && this.postingAmountCurEditValue) {
      amountCur = this.postingAmountCurEditValue;
    }
    return amountCur;
  }

  protected postingAmountCurAggregate: CustomSummaryType = (data: any, column: AggregateColumnModel) => {
    var credit: number = 0;
    var debit: number = 0;
    var vendor: number = 0;
    var voucher: number = 0;

    if (this.voucherDtoList) {
      this.voucherDtoList.filter((row: VoucherDto) => { voucher += row.amountCur; });
    }

    //if (data.result.records) {
    //  data.result.records.filter((row: PostingDto) => {
    if (this.postingDtoListAll) {
      this.postingDtoListAll.filter((row: PostingDto) => {
        var amt: number = this.postingAmountCurAggregateRow(row);
        if (this.voucherService.isPostingTypeVendor(row.postingType)) {
          vendor += amt;
        }
        if (amt < 0) {
          credit -= amt;
        }
        else {
          debit += amt;
        }
      });
    }

    if (this.postingGrid?.isEdit && this.postingDtoEdit && this.postingDtoEdit.id == Number.MAX_SAFE_INTEGER && this.postingAmountCurEditValue) {
      // In insert
      var amt: number = this.postingAmountCurAggregateRow(this.postingDtoEdit);
      if (amt < 0) {
        credit -= amt;
      }
      else {
        debit += amt;
      }
    }

    voucher = Math.round(voucher * 100) / 100;
    const voucherAbs = Math.abs(voucher);
    vendor = Math.round(vendor * 100) / 100;
    credit = Math.round(credit * 100) / 100;
    debit = Math.round(debit * 100) / 100;

    let agg: string = 'OK';
    const unmatchedVoucher: boolean = (voucher && (credit < voucherAbs || debit < voucherAbs));
    const unbalancedPosting: boolean = (credit != debit);
    const missingVendorPosting: boolean = (!this.isExpenseVoucher && voucher != -vendor);

    if (unmatchedVoucher || unbalancedPosting || missingVendorPosting) {
      let intl: Internationalization = new Internationalization();
      let nFormatter: Function = intl.getNumberFormat({ skeleton: 'N2', minimumFractionDigits: 2, maximumFractionDigits: 2 });

      agg = '<div>Debit: ' + nFormatter(debit) + '</div>';
      agg += '<div>Kredit: ' + nFormatter(-credit) + '</div>';
      if (unmatchedVoucher || missingVendorPosting) {
        agg += '<div>Bilag: ' + nFormatter(voucher) + '</div>';
      }
      if (missingVendorPosting) {
        agg += '<div>Kreditor: ' + nFormatter(vendor) + '</div>';
      }
    }

    return agg;
  }
  // ----------------------- DIALOG ------------------

  private error(message: string | string[]) {
    this.info(message);
  }

  private apiError(error: any) {
    this.ui.handleApiError(error);
  }

  private info(message: string | string[]) {
    let content: string = '';
    if (Array.isArray(message)) {
      message.forEach(function (s) { content = (content == '' ? '' : content + '<br/>') + s.toString() });
    }
    else {
      content = message;
    }

    this.dialogService.snackBar(content);
  }

  // ----------------------- GRID ------------------

  private voucherGridRowActive(): VoucherDto {
    if (this.voucherDtoList && this.voucherDtoList.length == 1) {
      return this.voucherDtoList[0];
    }
    else if (this.voucherDtoActive && this.voucherDtoActive.id) {
      return this.voucherDtoList.find(voucher => voucher.id == this.voucherDtoActive.id);
    }
    else {
      return this.voucherDtoList[this.voucherDtoList.length - 1];
    }
  }

  private gridGetRowIdxById(gridComponent: GridComponent, id: number): number {
    var data = <any[]>gridComponent.getCurrentViewRecords();
    var rec = data.filter(function (r) { return r.id == id })[0];
    return data.indexOf(rec);
  }


  private gridGetFirstSelectedRecord(grid: GridComponent) {
    var record: any = null;
    if (grid) {
      var selectedRecords: Object[] = grid.getSelectedRecords();
      if (selectedRecords.length > 0) {
        record = selectedRecords[0];
      }
    }
    return record;
  }

  voucherGridGetFirstSelectedDto(): VoucherDto {
    return <VoucherDto>this.gridGetFirstSelectedRecord(this.voucherGrid);
  }

  voucherGridActionBegin(args: any) {
    if (this.gridDeferRequestType(this.voucherGrid, args)) {
      args.cancel = true;
      return;
    }
    switch (args.requestType) {
      /*case 'columnstate':
        this.voucherGrid.endEdit();
        break;*/
      case 'save':
        args.cancel = true;
        this.voucherSave(args.data);
        break;
      case 'cancel':
        if (this.voucherSkipCancelDialog) {
          this.voucherSkipCancelDialog = false;
        }
        else {
          args.cancel = this.cancelNeedConfirm(this.voucherGrid, args);
        }
        break;
      case 'add':
        args.cancel = true;
        if (this.postingGrid.editSettings.allowAdding) {
          this.postingGrid.addRecord();
        }
        break;
      case 'delete':
        args.cancel = true;
        if (this.postingGrid.editSettings.allowDeleting) {
          this.postingGrid.deleteRecord();
        }
        break;
    }
    //console.log("Voucher Action begin " + args.requestType + " / cancel=" + args.cancel);
  }

  voucherGridActionFailure(args: any) {
    console.log("Voucher Action Failure");
    console.log(args);
  }

  voucherGridActionComplete(args: any) {
    switch (args.requestType) {
      case 'refresh':
        if (this.gridDeferred == this.voucherGrid) {
          this.gridAwaitRefresh = false;
        }
        this.gridsActionDeferred();
        break;
      case 'beginEdit':
      case 'add':
        this.voucherPostBeginEditAdd(args);
        break;
      case 'cancel':
        this.resetVendorPostingProforma();
        this.resetProjectPostingProforma();
        break;
    }
    //console.log("Voucher Action complete " + args.requestType + " / cancel=" + args.cancel);

  }

  private voucherPostBeginEditAdd(args: any) {
    this.voucherDtoActive = args.rowData;
    // Prepare columns
    this.voucherGridInitControls();
    for (var i = 0; i < args.form.elements.length; i++) {
      if (args.form.elements[i].name == 'balanceAccountNum') {
        this.balanceAccountNumElement = args.form.elements[i];
        this.balanceAccountNumElement.onchange = (args) => {
          this.checkDocumentNumUnique();
        };
      }
      if (args.form.elements[i].name == 'documentNum') {
        this.documentNumElement = args.form.elements[i];
        this.documentNumElement.onchange = (args) => {
          this.checkDocumentNumUnique();
        };
      }

    }
  }

  // Confirm cancel
  private cancelNeedConfirm(grid: GridComponent, args: any) {
    var cancelEvent: boolean = false;
    if (this.gridCancelledConfirmed == null) {
      if (!this.isEditUnchanged(grid, args.rowData)) {
        // Cancel confirm (can be migrated to promise)
        cancelEvent = true;
        this.confirmCancelDialogShow(grid);
      }
    }
    else {
      this.gridCancelledConfirmed = null; // Handle confirm complete
    }
    return cancelEvent;
  }

  private checkDocumentNumUnique() {
    var vendorAccountNum: string = this.balanceAccountNumElement != null ? this.balanceAccountNumElement.value : null;
    var documentNum: string = this.documentNumElement != null ? this.documentNumElement.value : null;
    if (vendorAccountNum && documentNum) {
      this.utilClient.isVendorDocumentNumUnique(this.detailsVm.voucherMessageViewDto.id, vendorAccountNum, documentNum).subscribe(
        data => { if (data.voucherViewDtoList && data.voucherViewDtoList.length > 0) { this.showDocumentNumInfo(data.voucherViewDtoList[0], vendorAccountNum, documentNum); } }
      );
    }
  }

  private showDocumentNumInfo(voucher: VoucherDto, vendorAccountNum: string, documentNum: string) {
    this.dialogService.infoPrompt(
      "Faktura " + documentNum + " er registreret for kreditor " + vendorAccountNum + (voucher.voucherNum ? " - Bilag " + voucher.voucherNum + " findes allerede." : ""),
      "Faktura " + documentNum + " er brugt på kreditor " + vendorAccountNum
    );
  }

  private confirmCancelDialogShow(grid: GridComponent) {
    this.gridCancelledConfirmed = grid;
    this.dialogService.confirmPrompt(
      "Inddata er ændret. Fortryd redigering?",
      "Bekræft").then(ok => {
        var methodBind = null;
        if (ok) {
          methodBind = this.confirmCancelDialogOkClick.bind(this);
        }
        else {
          methodBind = this.confirmCancelDialogCancelClick.bind(this);
        }
        methodBind();
      }
      );
  }

  private confirmCancelDialogOkClick() {
    if (this.gridCancelledConfirmed && this.gridCancelledConfirmed.isEdit) {
      this.gridCancelledConfirmed.closeEdit();
    }
  }

  private confirmCancelDialogCancelClick() {
    this.gridCancelledConfirmed = null;
  }

  private gridActionIs(args: any, actions: string[] | string): boolean {
    let ok: boolean = false;

    if (Array.isArray(actions)) {
      ok = (actions.includes(args.requestType));
    }
    else {
      ok = (actions.includes(args.requestType));
    }
    return ok;
  }

  // Grid row double clicked: Edit row
  public postingGridRecordDoubleClick(args: any) {

    //    (<any>this.voucherGrid.contextMenuModule).element.ej2_instances[0].openMenu(null, null, (<any>event).pageY, (<any>event).pageX, event);
  }

  // Voucher grid toolbar
  public voucherGridToolbarClick(args: ClickEventArgs) {
    switch (args.item.id) {
      case 'attachmentsShowHide':
        this.attachmentsShowHide()
        break;
    }
  }

  // Shortcuts
  public handleKeyDownEvent(event: KeyboardEvent) {
    //console.log(event);
    if (event.code == 'Escape') {
      if (this.attachmentsIsActive) {
        this.attachmentsShowHide()
        event.preventDefault();
      }
    }
    else if (event.altKey == true && !event.ctrlKey && !event.shiftKey) {
      switch (event.code) {
        case 'KeyV':
          this.attachmentsShowHide();
          break;
        case 'KeyS':
          if (!this.actionDisabledClass) {
            this.actionRun(!this.canEdit ? 'Close' : (this.canSendToPending == true ? 'PendingApprovalAndClose' : 'SaveAndClose'));
          }
          break;
        case 'KeyX':
          if (!this.actionDisabledClass) {
            this.actionRun('Close');
          }
          break;
        case 'KeyG':
          if (!this.actionDisabledClass) {
            if (this.canEdit && this.voucherCanApprove) {
              this.actionRun('ApprovedAndClose');
            }
          }
          break;
        case 'KeyO':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.openAttachInNewTab(false);
          }
          break;
        case 'NumpadAdd':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomIn();
            event.preventDefault();
          }
          break;
        case 'NumpadSubtract':
          if (this.attachmentsIsActive) {
            this.fileViewerTab.pdfZoomOut();
            event.preventDefault();
          }
          break;
        case 'PageUp':
          if (this.attachmentsIsActive) {
            this.attachmentsPagePrev();
            event.preventDefault();
          }
          break;
        case 'PageDown':
          if (this.attachmentsIsActive) {
            this.attachmentsPageNext();
            event.preventDefault();
          }
          break;
      }
    }
    else if (event.altKey && !event.ctrlKey && event.shiftKey) {
      switch (event.code) {
        case 'PageUp': // Alt+Shift+PgUp
          this.gridsNavigationCmdSelect(this.userIsAdmin ? GridNavigationCmd.Voucher : GridNavigationCmd.PostingFirst);
          break;
        case 'PageDown': // Alt+Shift+PgDn
          this.gridsNavigationCmdSelect(GridNavigationCmd.PostingLast);
          break;
        case 'NumpadAdd': // Alt +
          this.splitterResize(100);
          event.preventDefault();
          break;
        case 'NumpadSubtract': // Alt -
          this.splitterResize(-100);
          event.preventDefault();
          break;
      }
    }
  }

  // Grid context menu click
  public voucherGridContextMenuClick(args: ContextMenuClickEventArgs) {
    //console.log("Context menu id: "+args.item.id);
    switch (args.item.id) {
      case 'dataBind':
        this.detailsVmGetPromise(this.callerMsgId).then();
        break;
      case 'gridColumnGroup':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.voucherGrid.groupColumn(columnName);
          this.voucherGrid.groupSettings.showDropArea = true;
        }
        break;
      case 'gridColumnHide':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.voucherGrid.hideColumns(columnName, 'field');
        }
        break;
      case 'gridColumnsChoose':
        this.voucherGrid.openColumnChooser();
        break;
      case 'gridExcelExport':
        this.voucherGrid.excelExport(<ExcelExportProperties>{ fileName: this.routerShortUrl() + '.xlsx' });
        break;
      case 'setStatusPending':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Pending);
        break;
      case 'setStatusOnHold':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.OnHold);
        break;
      case 'setStatusApproved':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Approved);
        break;
      case 'setStatusAccounted':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Accounted);
        break;
      case 'setStatusPosted':
        this.setApprovalStatusOnGridContextMenuArgs(args, VoucherApprovalStatusEnum.Posted);
        break;
    }
  }

  private voucherGridContextMenuArgsToDto(args: ContextMenuClickEventArgs | ContextMenuOpenEventArgs): any {
    var dto: any;
    if (this.voucherGrid && this.voucherGrid.isEdit) {
      dto = this.gridCurrentEditDtoGet(this.voucherGrid);
    }
    else {
      dto = (args.rowInfo != null ? args.rowInfo.rowData : null);
    }
    return dto;
  }

  private setApprovalStatusOnGridContextMenuArgs(args: ContextMenuClickEventArgs, status: VoucherApprovalStatusEnum) {
    if (this.voucherGrid.isEdit) {
      this.voucherApprovalStatusSetDeferred = status;
      this.voucherGrid.endEdit();
    }
    else {
      var voucherDto: VoucherDto = this.voucherGridContextMenuArgsToDto(args);
      if (voucherDto) {
        this.setApprovalStatusPromise(voucherDto.id, status).then((voucherDto) => {
          if (voucherDto.msgId) {
            this.detailsVmMap.delete(voucherDto.msgId);
            this.detailsVmGetPromise(voucherDto.msgId, voucherDto.id).then();
          }
        }
        );
      }
    }
  }

  public voucherGridRecordDoubleClick(args: any) {
    //(<any>this.voucherGrid.contextMenuModule).element.ej2_instances[0].openMenu(null, null, (<any>event).pageY, (<any>event).pageX, event);
  }

  public voucherGridContextMenuOpen(args: ContextMenuOpenEventArgs) {
    if (args != null) {
      var approvalStatus: VoucherApprovalStatusEnum = null;
      var voucherDto: VoucherDto = this.voucherGridContextMenuArgsToDto(args);
      approvalStatus = (voucherDto != null ? voucherDto.status : null);
      this.voucherService.EnableContextMenuByApprovalStatus(this.voucherGrid, this.voucherGridContextMenuItems, approvalStatus);
    }
  }

  private setApprovalStatusPromise(voucherId: number, approvalStatus: VoucherApprovalStatusEnum): Promise<VoucherDto> {
    return new Promise<VoucherDto>((resolve) => {
      if (voucherId == null) {
        var error = "Manglende bilag";
        this.error(["Kan opdatere godkendelsesstatus", error]);
        resolve(null);
      }
      else {
        if (this.messageDto.status == VoucherMessageStatusEnum.Inbox) {
          this.setMessageStatusPromise(VoucherMessageStatusEnum.Voucher).then((detailsVmUpdated) => {
            this.apiVoucherSetApprovalStatusPromise([voucherId], approvalStatus).then((voucherDtoUpdated) => { resolve(voucherDtoUpdated); });
          });
        }
        else {
          this.apiVoucherSetApprovalStatusPromise([voucherId], approvalStatus).then((voucherDtoUpdated) => { resolve(voucherDtoUpdated); });
        }
      }
    });
  }

  private apiVoucherSetApprovalStatusPromise(voucherIds: number[], approvalStatus: VoucherApprovalStatusEnum): Promise<VoucherDto> {
    return new Promise<VoucherDto>((resolve) => {
      if (voucherIds == null || voucherIds.length == 0) {
        var error = "Manglende bilag";
        this.error(["Kan opdatere godkendelsesstatus", error]);
        resolve(null);
      }
      else {
        var voucherDto: VoucherDto = null;
        this.client.setApprovalStatus(approvalStatus, voucherIds).subscribe({
          next: (voucherDtoUpdated) => {
            voucherDto = voucherDtoUpdated;
          },
          error: (error) => {
            this.error(["Kan opdatere godkendelsesstatus", error]);
          },
          complete: () => {
            resolve(voucherDto);
          }
        });
      }
    });
  }

  private setMessageStatusPromise(newMessageStatus: VoucherMessageStatusEnum): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      if (this.detailsVm?.voucherMessageViewDto == null) {
        reject("Ingen meddelelse angivet");
      }
      else {
        if (this.detailsVm.voucherMessageViewDto.status == newMessageStatus) {
          resolve(this.detailsVm);
        }
        else {
          this.apiMessageSetStatusPromise(this.detailsVm.voucherMessageViewDto.id, newMessageStatus).then(
            (messageDtoUpdated) => {
              this.detailsVmGetPromise(messageDtoUpdated.id).then((detailsVm) => {
                resolve(this.detailsVm);
              });
            });
        }
      }
    });
  }

  private apiMessageSetStatusPromise(msgId: number, status: VoucherMessageStatusEnum): Promise<MessageDto> {
    return new Promise<MessageDto>((resolve, reject) => {
      this.messageClient.setStatus(msgId, status, false).subscribe({
        next: (messageDtoUpdated: MessageDto) => {
          resolve(messageDtoUpdated);
        },
        error: (error) => {
          this.dialogService.snackBar("Kunne ikke opdatere meddelelsesstatus", error);
          console.error(error);
        }
      });
    });
  }


  // Interconnected grid functions
  protected voucherGridDataBound(args: any) {
    if (!this.voucherGridIsDataBound) {
      this.voucherGridIsDataBound = true;
      this.gridsOnDataBound();
    }
  }

  protected postingGridDataBound(args: any) {
    if (!this.postingGridIsDataBound) {
      this.postingGridIsDataBound = true;
      this.gridsOnDataBound();
    }
  }

  private gridsOnDataBound() {

    if (this.gridDeferred == null) {
      if (this.canEdit && this.voucherGrid && this.postingGrid && this.voucherGridIsDataBound && this.postingGridIsDataBound) {
        this.gridsSelectRowIdxDeferredCalc();
      }
    }
    this.gridsActionDeferred();
  }

  private gridsNavigationCmdSelect(cmd: GridNavigationCmd) {
    this.gridNavigationCmd = cmd;
    this.gridDeferred = null;
    this.gridSelectRowIdxDeferred = -1;
    this.gridRequestTypeDeferred = null;
    this.gridsSelectRowIdxDeferredCalc();
    this.gridsActionDeferred();
  }

  private gridsSelectRowIdxDeferredCalc() {
    if (this.gridNavigationCmd == GridNavigationCmd.None) {
      return;
    }
    if (this.gridNavigationCmd == GridNavigationCmd.Default) {
      if (this.voucherGrid && this.voucherGrid.editSettings.allowEditing && (this.userIsAdmin || this.voucherIsStatusCreated)) {
        // Default row on voucher?
        var rows: any = this.voucherGrid.dataSource;
        if (rows?.length > 0) {
          if (!this.voucherService.voucherIsComplete(<VoucherDto>rows[0], this.userIsAdmin, this.isExpenseVoucher)) {
            this.gridNavigationCmd = GridNavigationCmd.Voucher;
          }
        }
      }
    }

    if (this.gridNavigationCmd == GridNavigationCmd.Voucher) {
      this.gridSelectRowIdxDeferred = 0;
      this.gridDeferred = this.voucherGrid;
      this.gridRequestTypeDeferred = 'beginEdit';
      this.gridNavigationCmd = GridNavigationCmd.None;
    }
    else if (this.postingDtoList.length > 0) {
      var selectPosting: number = -1;
      if (this.gridNavigationCmd == GridNavigationCmd.Default) {
        selectPosting = this.postingGridStartEditDefaultCalc();
      }
      if (this.gridNavigationCmd == GridNavigationCmd.PostingFirst) {
        selectPosting = 0;
      }
      if (this.gridNavigationCmd == GridNavigationCmd.PostingLast) {
        selectPosting = this.postingDtoList.length - 1;
      }
      if (selectPosting >= 0) {
        this.gridDeferred = this.postingGrid;
        this.gridSelectRowIdxDeferred = selectPosting;
        this.gridRequestTypeDeferred = 'beginEdit';
      }
    }
    this.gridNavigationCmd = GridNavigationCmd.None;
  }

  private postingGridStartEditDefaultCalc(): number {
    var idx: number = -1;
    if (this.postingGrid) {
      var rows: any = this.postingGrid.dataSource;
      if (this.postingGrid.editSettings.allowEditing) {
        var r: number = 0;
        if (rows) {
          rows.forEach((row: any) => { if (idx == -1 && !this.voucherService.postingIsComplete(<PostingDto>row, this.userIsAdmin)) { idx = r }; r++; });
        }
      }
      if (idx == -1 && rows?.length > 0) {
        idx = rows.length - 1;
      }
    }
    return idx;
  }

  private gridDeferred: GridComponent = null;
  private gridRequestTypeDeferred: any = null;
  private gridSelectRowIdxDeferred: number = -1;
  private gridAwaitRefresh: boolean = false;

  private gridDeferRequestType(grid: GridComponent, args: any, forceCancelEdit = false): boolean {
    var deferred: boolean = this.gridActionIs(args, ['beginEdit', 'add', 'delete']) &&
      (this.postingGrid.isEdit || this.voucherGrid.isEdit);
    if (deferred) {
      this.gridDeferred = grid;
      this.gridRequestTypeDeferred = args.requestType;
      this.gridSelectRowIdxDeferred = args.rowIndex;
      console.log("Defer grid " + grid.columns[0].toString());
      console.log("Defer request " + args.requestType);
      console.log("Defer idx " + args.rowIdx);

      this.gridEditSaveCancel(this.voucherGrid, forceCancelEdit);
      this.gridEditSaveCancel(this.postingGrid, forceCancelEdit);
      console.log("Defer await refresh " + this.gridAwaitRefresh);
    }
    return deferred;
  }

  private gridsActionDeferred() {
    this.voucherGridActionDeferred();
    this.postingGridActionDeferred();
  }

  private voucherGridActionDeferred() {
    this.gridActionDeferred(this.voucherGrid);
  }

  private postingGridActionDeferred() {
    this.gridActionDeferred(this.postingGrid);
  }

  private gridOnRowSelectedDeferredNotify(grid: GridComponent, rowIndex: number) {
    if (this.gridDeferred != grid) {
      return;
    }
    if (rowIndex >= 0 && rowIndex == this.gridSelectRowIdxDeferred) {
      //console.log("Grid select row notify: " + rowIndex + ' Grid: ' + (<any>grid.columns[1]).field);
      if (this.gridRequestTypeDeferred == null) {
        this.gridSelectRowIdxDeferred = -1;
        this.gridDeferred = null;
      }
    }
  }

  private gridActionDeferred(grid: GridComponent) {
    if (this.gridDeferred != grid) {
      return;
    }
    if (this.gridAwaitRefresh) {
      console.log("Await refresh deferred " + this.gridRequestTypeDeferred);
      return;
    }
    /*
    if (this.gridSelectRowIdxDeferred == -1 && grid == this.voucherGrid &&
      (this.gridRequestTypeDeferred == 'beginEdit' || this.gridRequestTypeDeferred == 'add')) {
      this.gridSelectRowIdxDeferred = 0;
    }
    */
    if (this.gridSelectRowIdxDeferred != -1 &&
      this.gridSelectRowIdxDeferred != grid.selectedRowIndex) {
      grid.selectRow(this.gridSelectRowIdxDeferred);
      return;
    }
    if (this.gridRequestTypeDeferred != null) {
      setTimeout(() => {
        if (this.gridSelectRowIdxDeferred != -1) {
          if (grid.selectedRowIndex != this.gridSelectRowIdxDeferred) {
            grid.selectRow(this.gridSelectRowIdxDeferred);
            return;
          }
        }
        //console.log("Grid request: " + this.gridRequestTypeDeferred + ' Grid: ' + (<any>grid.columns[1]).field);
        var requestType: any = this.gridRequestTypeDeferred;
        this.gridSelectRowIdxDeferred = -1;
        this.gridRequestTypeDeferred = null;
        this.gridDeferred = null;
        if (!grid.isEdit) {
          switch (requestType) {
            case 'beginEdit':
              if (grid.editSettings.allowEditing) {
                if (grid.selectedRowIndex >= 0) {
                  //console.log("start edit (deferred)");
                  grid.startEdit();
                }
                else {
                  console.log(grid.selectedRowIndex);
                  console.log("no row selected (deferred edit)");
                }
              }
              break;
            case 'add':
              if (grid.editSettings.allowAdding) {
                //console.log("add row (deferred)");
                grid.addRecord();
              }
              break;
            case 'delete':
              if (grid.editSettings.allowDeleting) {
                if (grid.selectedRowIndex >= 0) {
                  //console.log("delete (deferred)");
                  grid.deleteRecord();
                }
                else {
                  console.log("no row selected (deferred delete)");
                }
              }
              break;
            case 'startSelectRow':
              console.log("select row (deferred)");
              this.gridsOnDataBound();
          }
        }
      }, 100);
    }
  }

  /*
  private gridSelectedRowIdxSetDeferred(grid: GridComponent) {
    setTimeout(() => {
      if (this.gridSelectRowIdxDeferred != -1) {
        if (this.gridSelectRowIdxDeferred != grid.selectedRowIndex) {
          //console.log("Grid select row: " + this.gridSelectRowIdxDeferred + ' Grid:' + (<any>grid.columns[1]).field);
          grid.selectRow(this.gridSelectRowIdxDeferred);
        }
        else {
          console.log("select row not needed " + this.gridSelectRowIdxDeferred);
        }
      }
    }, 100);
  }
    */

  // Posting grid events
  public postingGridQueryCellInfo(args: any): void {
    VoucherHelper.postingGridQueryCellInfo(args);
  }

  public postingGridActionBegin(args: any) {
    if (this.gridDeferRequestType(this.postingGrid, args)) {
      args.cancel = true;
      return;
    }
    switch (args.requestType) {
      case 'save':
        args.index = this.postingDtoList.length; // Add to end
        this.postingSave(args.data, args.rowIndex ? args.rowIndex : args.index);
        break;
      case 'delete':
        this.postingDelete(args.data);
        break;
      case 'add':
        if (!this.postingInitFromVoucher(args.data)) {
          args.cancel = true;
          this.dialogService.infoPrompt('Mangler valgt bilag');
        }
        break;
      case 'cancel':
        args.cancel = this.cancelNeedConfirm(this.postingGrid, args);
        break;
    }
    //console.log("Posting Action begin " + args.requestType + " / cancel=" + args.cancel);
  }

  public postingGridActionComplete(args: any) {
    switch (args.requestType) {
      case 'refresh':
        if (this.gridDeferred == this.postingGrid) {
          this.gridAwaitRefresh = false;
        }
        this.gridsActionDeferred();
        break;
      case 'beginEdit':
      case 'add':
        this.postingDtoEdit = args.rowData;
        this.postingGridInitControls();
        this.postingGridUpdateDesign(args.rowData);
        this.voucherCanApproveByPostingEditCalc(args.rowData);
        break;
      case 'cancel':
      case 'save':
        this.postingAmountCurEditValue = null;
        this.postingDtoEdit = null;
        this.dataBindCalcStatus();
        this.postingGrid.aggregateModule.refresh(null);
        if (args.requestType == 'cancel') {
          this.voucherGridActionDeferred();
          this.postingGridActionDeferred();
        }
    }
    //console.log("Posting Action complete " + args.requestType + " / cancel=" + args.cancel);
  }

  /*
  private voucherGridEndEditIfTouched(): boolean {
    var save: boolean = this.voucherGridEditTouched()
    if (save) {
      this.voucherGrid.endEdit();
    }
    else {
      this.voucherGrid.closeEdit();
    }
    return save;
  }

  private voucherGridEditTouched(): boolean {
    return true;
  }
    */

  public voucherGridInitControls() {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName('amountCur');
    if (i) {
      (<any>i).ej2_instances[0].showSpinButton = false;
    }
  }

  private postingGridLastRow(voucher: VoucherDto): PostingDto {
    let posting: PostingDto = null;
    if (this.postingDtoList) {
      this.postingDtoList.forEach(row => { if (row.voucherId == voucher.id && (!posting || row.lineNum > posting.lineNum)) { posting = row; } });
    }
    return posting;
  }

  // Pos<ting grid toolbar
  public postingGridToolbarClick(args: ClickEventArgs) {
    switch (args.item.id) {
      case 'postingAdjustAmounts':
        this.postingAdjustAmountsConfirm();
        break;
      case 'postingDelete':
        if (this.postingGrid.editSettings.allowDeleting) {
          if (this.postingGrid.isEdit) {
            var dto: PostingDto = <PostingDto>this.postingGrid.editModule.editModule.previousData;
            if (dto?.id != null) {
              var rowIdx = this.gridGetRowIdxById(this.postingGrid, dto.id);
              this.gridDeferRequestType(this.postingGrid, { requestType: 'delete', rowIndex: rowIdx }, true);
            }
            this.gridCancelledConfirmed = this.postingGrid;
            this.postingGrid.closeEdit(); //cancel the edit 
          }
          else {
            var dtos: any[] = this.postingGrid.getSelectedRecords();
            if (dtos?.length > 0) {
              this.postingGrid.deleteRecord(dtos[0]); //delete the record
            }
          }
        }
        break;
      case 'postingAdd':
        if (this.postingGrid.editSettings.allowAdding) {
          if (this.postingGrid.isEdit) {
            this.gridDeferRequestType(this.postingGrid, { requestType: 'add', rowIndex: -1 }, true);
            this.gridEditSaveCancel(this.postingGrid); //save or cancel the edit 
          }
          else {
            this.postingGrid.addRecord(); // add an empty record
          }
        }
        break;
    }
  }

  public postingGridContextMenuClick(args: ContextMenuClickEventArgs) {

    switch (args.item.id) {
      case 'gridColumnGroup':
        var columnName = <string>args.column.field;
        if (columnName) {
          this.postingGrid.groupColumn(columnName);
          this.postingGrid.groupSettings.showDropArea = true;
        }
        break;
      case 'gridColumnUnGroup':
        this.postingGrid.ungroupColumn('voucherId');
        this.postingGrid.groupSettings.showDropArea = false;
        break;
      case 'gridColumnHide':
        var columnName = args.column.field;
        if (columnName) {
          this.postingGrid.hideColumns(columnName, 'field');
        }
        break;
      case 'gridColumnsChoose':
        this.postingGrid.openColumnChooser();
        break;
      case 'gridExcelExport':
        this.postingGrid.excelExport(<ExcelExportProperties>{ fileName: this.routerShortUrl() + '.xlsx' });
        break;
    }
  }

  // Currency editor setup
  private currencyEditCellSetup() {
    this.currencyEditCell = {
      create: () => {
        this.currencyDdlElement = document.createElement('input');
        return this.currencyDdlElement;
      },
      read: () => {
        return this.currencyDdl.value;
      },
      destroy: () => {
        if (this.currencyDdl) {
          this.currencyDdl.destroy();
          this.currencyDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.currencyDdl = new CerDropDownList({
          fields: { text: 'iso', value: 'id' },
          placeholder: 'Vælg valuta',
          popupWidth: '200px',
          allowFiltering: true,
          dataSource: new DataManager(this.currencies),
          change: (args: ChangeEventArgs) => { this.currencyIdChanged(args); },
        });
        var voucherDto = <VoucherDto>args.rowData;
        this.currencyDdl.value = voucherDto.currency;
        this.currencyDdl.appendTo(this.currencyDdlElement);
      }
    };
  }

  // Approver editor setup
  private approverEditCellSetup() {
    this.approverEditCell = {
      create: () => {
        this.approverDdlElement = document.createElement('input');
        return this.approverDdlElement;
      },
      read: () => {
        return this.approverDdl.value;
      },
      destroy: () => {
        if (this.approverDdl) {
          this.approverDdl.destroy();
          this.approverDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.approverDdl = new CerDropDownList({
          placeholder: 'Vælg godkender',
          fields: { text: 'shortName', value: 'id' },
          popupWidth: '200px',
          popupHeight: '400px',
          columns: [
            { field: 'shortName', headerText: 'Navn', width: '180px', searchOperator: 'StartsWith', allowFiltering: true }
          ],
          allowFiltering: true,
          dataSource: this.usersDM,
          //change: (args: ChangeEventArgs) => { this.approverIdChanged(args); },
        });
        var voucherDto = <VoucherDto>args.rowData;
        this.approverDdl.value = voucherDto.approver;
        this.approverDdl.appendTo(this.approverDdlElement);
      }
    };
  }

  // Posting type editor setup
  private postingTypeEditCellSetup() {
    this.postingTypeEditCell = {
      create: () => {
        this.postingTypeDdlElement = document.createElement('input');
        return this.postingTypeDdlElement;
      },
      read: () => {
        return this.postingTypeDdl.value;
      },
      destroy: () => {
        if (this.postingTypeDdl) {
          this.postingTypeDdl.destroy();
          this.postingTypeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.postingTypeDdl = new CerDropDownList({
          placeholder: 'Vælg type',
          fields: { text: 'name', value: 'id' },
          popupWidth: '140px',
          popupHeight: '300px',
          columns: [
            { field: 'name', headerText: 'Type', width: '120px', searchOperator: 'StartsWith', allowFiltering: true }
          ],
          allowFiltering: true,
          dataSource: new DataManager(this.postingTypes),
          change: (args: ChangeEventArgs) => { this.postingTypeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.postingTypeDdl.value = data.postingType;
        this.postingTypeDdl.appendTo(this.postingTypeDdlElement);
      }
    };
  }

  // Project cost type editor setup
  private projectCostTypeEditCellSetup() {
    this.projectCostTypeEditCell = {
      create: () => {
        this.projectCostTypeDdlElement = document.createElement('input');
        return this.projectCostTypeDdlElement;
      },
      read: () => {
        return this.projectCostTypeDdl.value;
      },
      destroy: () => {
        if (this.projectCostTypeDdl) {
          this.projectCostTypeDdl.destroy();
          this.projectCostTypeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.projectCostTypeDdl = new CerDropDownList({
          placeholder: 'Vælg art',
          fields: { text: 'description', value: 'num' },
          popupHeight: '300px',
          popupWidth: '300px',
          filterType: 'StartsWith',
          columns: [
            { field: 'num', headerText: 'Art', width: '40px', searchOperator: 'StartsWith', allowFiltering: true },
            { field: 'name', headerText: 'Navn', width: '240px', searchOperator: 'Contains', allowFiltering: true }
          ],
          allowFiltering: true,
          dataSource: new DataManager(this.projectCostTypesFiltered),
          showClearButton: true,
          change: (args: ChangeEventArgs) => { this.projectCostTypeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.projectCostTypeDdl.value = data.projectCostType;
        this.projectCostTypeDdl.appendTo(this.projectCostTypeDdlElement);
      }
    };
  }

  // Tax code editor setup
  private taxCodeEditCellSetup() {
    this.taxCodeEditCell = {
      create: () => {
        this.taxCodeDdlElement = document.createElement('input');
        return this.taxCodeDdlElement;
      },
      read: () => {
        return this.taxCodeDdl.value;
      },
      destroy: () => {
        if (this.taxCodeDdl) {
          this.taxCodeDdl.destroy();
          this.taxCodeDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.taxCodeDdl = new CerDropDownList({
          placeholder: 'Vælg momskode',
          fields: { text: 'num', value: 'num' },
          popupHeight: '360px',
          popupWidth: '300px',
          showClearButton: true,
          columns: [
            { field: 'num', headerText: 'Kode', width: '80px', searchOperator: 'StartsWith', allowFiltering: true },
            { field: 'name', headerText: 'Navn', width: '200px', searchOperator: 'StartsWith', allowFiltering: true }
          ],
          allowFiltering: true,
          dataSource: this.taxCodesDM,
          change: (args: ChangeEventArgs) => { this.taxCodeChanged(args); },
        });
        var data = <PostingDto>args.rowData;
        this.taxCodeDdl.value = data.taxCode;
        this.taxCodeDdl.appendTo(this.taxCodeDdlElement);
      }
    };
  }

  public taxCodeChanged(args: any) {
    if (args.itemData) {
      var data = this.gridCurrentEditDtoGet(this.postingGrid);
    }
  }

  public postingGridInitControls() {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName('amountCur');
    if (i) {
      this.postingAmountCurEditValue = null;
      (<any>i).ej2_instances[0].showSpinButton = false;
      i.onchange = (args) => this.postingAmountCur_OnChanged(i, args);
      i.onblur = (args) => this.postingAmountCur_OnChanged(i, args);
    }
  }

  private postingAmountCur_OnChanged(i: HTMLInputElement, args: any) {
    var editData = this.gridCurrentEditDtoGet(this.postingGrid);
    if (editData) {
      var numTextBox: NumericTextBox = (<any>i).ej2_instances[0];
      editData.amountCur = numTextBox.value;
      this.postingAmountCurEditValue = editData.amountCur;
      this.postingValidateRoyalty(editData);
      this.voucherCanApproveByPostingEditCalc(editData);
    }
  }

  public postingGridUpdateDesign(data: PostingDto) {
    let project: boolean = this.voucherService.isPostingTypeProject(data.postingType);
    let vendor: boolean = this.voucherService.isPostingTypeVendor(data.postingType);

    this.postingGridEditFormInputElementDisable('paymentRef', !vendor);
    this.postingGridEditFormInputElementDisable('projectCostType', !project);
    this.postingGridEditFormInputElementDisable('projectLedgerNum', !project);

    if (this.projectCostTypeDdlElement && this.projectCostTypeDdl) {
      this.projectCostTypeDdlElement.disabled = !project;
      this.projectCostTypeDdl.enabled = project;
      this.projectCostTypeDdl.placeholder = project ? 'Vælg art' : '';
    }
    if (this.projectLedgerNumDdlElement && this.projectLedgerNumDdl) {
      this.projectLedgerNumDdlElement.disabled = !project;
      this.projectLedgerNumDdl.enabled = project;
      this.projectLedgerNumDdl.placeholder = project ? 'Vælg finanskonto' : '';
    }
  }

  public postingTypeChanged(args: any) {
    if (args.itemData) {
      var data: PostingDto = this.gridCurrentEditDtoGet(this.postingGrid);
      data.postingType = args.itemData.id;
      data.postingTypeName = args.itemData.description;
      this.postingAccountClear(data);
      this.postingGridUpdateDesign(data);
      this.accountNumDdlSetup(data);
    }
  }

  public postingValidateRoyalty(editData: any) {
    if (editData.amountCur && editData.postingType == <number>VoucherPostingTypeEnum.ProjectRoyalty) {
      //  this.showRoyaltyInfo();
    }
  }

  private showRoyaltyInfo() {

    var vendorAccountNum = "ttt";
    this.dialogService.confirmPrompt(
      "Royalty faktura for kreditor " + vendorAccountNum,
      "Royaltyfaktura")
      .then(ok => { });
  }

  public postingAccountClear(dto: PostingDto) {
    dto.accountNum = "";
    dto.accountDescription = null;
    dto.projectCostType = null;
    dto.projectLedgerNum = null
    dto.taxCode = null;
    dto.vendorBankAccount = '';

    this.postingGridEditFormInputElementSetValue('accountNum', dto.accountNum);
    this.postingGridEditFormInputElementSetValue('accountDescription', dto.accountDescription);
    this.postingGridEditFormInputElementSetValue('projectCostType', dto.projectCostType);
    this.postingGridEditFormInputElementSetValue('projectLedgerNum', dto.projectLedgerNum);
    this.postingGridEditFormInputElementSetValue('taxCode', dto.taxCode, dto.taxCode);
    this.postingGridEditFormInputElementSetValue('vendorBankAccount', dto.vendorBankAccount);
    this.voucherCanApproveByPostingEditCalc();
  }

  public projectCostTypeChanged(args: any) {
    if (args.itemData) {
      var data = null;
      if (this.voucherGrid.isEdit) {
        data = this.gridCurrentEditDtoGet(this.voucherGrid);
        this.setProjectPostingDataProforma(null, data);
      }
      else {
        data = this.gridCurrentEditDtoGet(this.postingGrid);
        data.projectLedgerNum = '';
        this.postingGridEditFormInputElementSetValue('projectLedgerNum', data.projectLedgerNum);
        //this.postingGridUpdateDesign(data);
        //if (this.isExpenseVoucher) {
        this.projectLedgerNumDdlSetup(data);
        //}
      }
    }
    this.voucherCanApproveByPostingEditCalc();
  }

  // Project ledger num editor setup
  private projectLedgerNumEditCellSetup() {
    // Account num dropdown
    this.projectLedgerNumEditCell = {
      create: () => {
        this.projectLedgerNumDdlElement = document.createElement('input');
        return this.projectLedgerNumDdlElement;
      },
      read: () => {
        return this.projectLedgerNumDdl.value;
      },
      destroy: () => {
        if (this.projectLedgerNumDdl) {
          this.projectLedgerNumDdl.destroy();
          this.projectLedgerNumDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.projectLedgerNumDdlSetup(<PostingDto>args.rowData);
      }
    };
  }

  // Project ledger num  editor setup
  private projectLedgerNumDdlSetup(dto: PostingDto) {
    let col: Column = this.postingGrid.getColumnByField('projectLedgerNum');
    if (col /*&& col.allowEditing*/ && col.visible) {
      var isCreate: boolean = (this.projectLedgerNumDdl == null);

      if (isCreate) {
        // Project ledger num dropdown create with params
        this.projectLedgerNumDdl = new CerDropDownList({
          placeholder: 'Vælg finanskonto',
          fields: { text: 'num', value: 'num' },
          popupWidth: '500px',
          popupHeight: '300px',
          showClearButton: true,
          columns: [
            { field: 'num', headerText: 'Konto', width: '60px', searchOperator: 'StartsWith', allowFiltering: true },
            { field: 'description', headerText: 'Navn', width: '420px', searchOperator: 'Contains', allowFiltering: true }],
          allowFiltering: true,
          ignoreAccent: true,
          change: (args: ChangeEventArgs) => { this.projectLedgerNumChanged(args); }
        });
        this.projectLedgerNumDdl.enabled = col.allowEditing;
      }

      if (this.isExpenseVoucher) {
        if (isCreate) {
          this.projectLedgerNumDdl.dataSource = new DataManager(this.ledgerAccounts);
          this.projectLedgerNumDdl.query = new Query();
        }
      }
      else {
        // VendorVoucher: Needs research based on cost type
        this.projectLedgerNumDdl.dataSource = new DataManager(this.projectLedgerAccounts);
        if (dto?.projectCostType) {
          this.projectLedgerNumDdl.query = new Query().where('projCostType', 'equal', dto.projectCostType);
        } else {
          this.projectLedgerNumDdl.query = new Query();
        }
      }
      this.projectLedgerNumDdl.value = dto?.projectLedgerNum;

      if (isCreate) {
        this.projectLedgerNumDdl.appendTo(this.projectLedgerNumDdlElement);
      }
    }
  }

  public projectLedgerNumChanged(args: any) {
    var data: PostingDto = this.gridCurrentEditDtoGet(this.postingGrid);
    data.taxCode = args.itemData?.taxCode ?? null;
    if (data.taxCode == '') {
      data.taxCode = null;
    }
    this.
      postingGridEditFormInputElementSetValue('taxCode', data.taxCode, data.taxCode);
    this.voucherCanApproveByPostingEditCalc();
  }

  // Account num editor setup
  private accountNumEditCellSetup() {
    // Account num dropdown
    this.accountNumEditCell = {
      create: () => {
        this.accountNumDdlElement = document.createElement('input');
        return this.accountNumDdlElement;
      },
      read: () => {
        return this.accountNumDdl.value;
      },
      destroy: () => {
        if (this.accountNumDdl) {
          this.accountNumDdlDestroy(this.accountNumDdl, null);
          this.accountNumDdl.destroy();
          this.accountNumDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        this.accountNumDdlSetup(<PostingDto>args.rowData);
      }
    };
  }

  public voucherVendorAccountNumEditCellSetup() {
    // Voucher Vendor Account num dropdown
    if (this.userIsAdmin || this.voucherIsStatusCreated) {
      this.voucherVendorAccountNumEditCell = {
        create: () => {
          this.voucherVendorAccountNumDdlElement = document.createElement('input');
          return this.voucherVendorAccountNumDdlElement;
        },
        read: () => {
          return this.voucherVendorAccountNumDdl.value;
        },
        destroy: () => {
          if (this.voucherVendorAccountNumDdl) {
            this.accountNumDdlDestroy(this.voucherVendorAccountNumDdl, VoucherPostingTypeEnum.Vendor);
            this.voucherVendorAccountNumDdl.destroy();
            this.voucherVendorAccountNumDdl = null;
          }
        },
        write: (args: { rowData: Object, column: Column }) => {
          var isCreate: boolean = (!this.voucherVendorAccountNumDdl);
          if (isCreate) {
            this.voucherVendorAccountNumDdl = this.accountNumDdlCreate(VoucherPostingTypeEnum.Vendor);
          }
          this.voucherVendorAccountNumDdl.placeholder = 'Vælg kreditor';
          var list: any[] = this.getLookupDefaultListForAccountNum(VoucherPostingTypeEnum.Vendor).slice(); // Clone from read-only list
          var voucherDto: VoucherDto = <VoucherDto>args.rowData;
          this.accountNumListAddDefaultFromDto(list, VoucherPostingTypeEnum.Vendor, true, null, voucherDto);
          this.voucherVendorAccountNumDdl.dataSource = new DataManager(list);
          if (this.isPostingTypeVendor(voucherDto?.balancePostingType)) {
            this.voucherVendorAccountNumDdl.value = voucherDto.balanceAccountNum;
          }
          if (isCreate) {
            this.voucherVendorAccountNumDdl.appendTo(this.voucherVendorAccountNumDdlElement);
          }
        }
      }
    }
  }

  public voucherProjectNumEditCellSetup() {
    // Voucher Project num dropdown
    this.voucherProjectNumEditCell = {
      create: () => {
        this.voucherProjectNumDdlElement = document.createElement('input');
        return this.voucherProjectNumDdlElement;
      },
      read: () => {
        return this.voucherProjectNumDdl.value;
      },
      destroy: () => {
        if (this.voucherProjectNumDdl) {
          this.accountNumDdlDestroy(this.voucherProjectNumDdl, VoucherPostingTypeEnum.Vendor);
          this.voucherProjectNumDdl.destroy();
          this.voucherProjectNumDdl = null;
        }
      },
      write: (args: { rowData: Object, column: Column }) => {
        var isCreate: boolean = (!this.voucherProjectNumDdl);
        if (isCreate) {
          this.voucherProjectNumDdl = this.accountNumDdlCreate(VoucherPostingTypeEnum.Project);
        }
        this.voucherProjectNumDdl.placeholder = 'Vælg projekt';
        var list: any[] = this.getLookupDefaultListForAccountNum(VoucherPostingTypeEnum.Project).slice(); // Clone from read-only list
        var voucherDto: VoucherDto = <VoucherDto>args.rowData;
        this.accountNumListAddDefaultFromDto(list, VoucherPostingTypeEnum.Project, true, null, voucherDto);
        this.voucherProjectNumDdl.dataSource = new DataManager(list);
        if (this.isPostingTypeProject(voucherDto?.costPostingType)) {
          this.voucherProjectNumDdl.value = voucherDto.costAccountNum;
        }
        if (isCreate) {
          this.voucherProjectNumDdl.appendTo(this.voucherProjectNumDdlElement);
        }
      }
    };
  }

  public accountNumDdlCreate(postingType: VoucherPostingTypeEnum): CerDropDownList {
    // Voucher account num dropdown create with common params
    var ddl: CerDropDownList = new CerDropDownList({
      fields: { text: 'num', value: 'num' },
      popupWidth: '600px',
      popupHeight: '360px',
      showClearButton: true,
      columns: [
        { field: 'num', headerText: 'Nummer', width: '100px', filterType: 'StartsWith' },
        { field: 'description', headerText: 'Navn', width: '480px', filterType: 'Contains' }
      ],
      allowFiltering: true,
      autoBuildQuery: false,
      filterRunDelay: 500,
    });

    ddl.addEventListener('actionComplete', (args: object) => {
      this.accountNumActionComplete(args, postingType, ddl);
    });
    ddl.addEventListener('actionFailure', (args: object) => {
      this.accountNumActionFailure(args);
    });
    ddl.addEventListener('change', (args: ChangeEventArgs) => {
      this.accountNumChanged(args, postingType);
    });
    ddl.addEventListener('filtering', (args: FilteringEventArgs) => {
      this.accountNumFilter(args, postingType, ddl);
    });
    return ddl;
  }

  public accountNumDdlDestroy(ddl: CerDropDownList, postingType: VoucherPostingTypeEnum) {
    ddl.removeEventListener('filtering', (args: FilteringEventArgs) => {
      this.accountNumFilter(args, postingType, ddl);
    });
    ddl.removeEventListener('change', (args: ChangeEventArgs) => {
      this.accountNumChanged(args, postingType);
    });
    ddl.removeEventListener('actionFailure', (args: object) => {
      this.accountNumActionFailure(args);
    });
    ddl.removeEventListener('actionComplete', (args: object) => {
      this.accountNumActionComplete(args, postingType, ddl);
    });
  }

  public accountNumDdlSetup(postingDto: PostingDto) {
    var isCreate: boolean = (!this.accountNumDdl);

    if (isCreate) {
      // Account num dropdown create with common params
      this.accountNumDdl = this.accountNumDdlCreate(null);
    }

    switch (<VoucherPostingTypeEnum>postingDto.postingType) {
      case VoucherPostingTypeEnum.Vendor:
        // Vendor dropdown
        this.accountNumEditDdlCurrentTableName = 'vendor';
        this.accountNumDdl.placeholder = 'Vælg kreditor';
        this.accountNumFilterDefaultListData = this.vendorsDefault;
        break;
      case VoucherPostingTypeEnum.Project:
        // Project dropdown
        this.accountNumEditDdlCurrentTableName = 'project';
        this.accountNumDdl.placeholder = 'Vælg projekt';
        this.accountNumFilterDefaultListData = this.projectsDefault;
        break;
      case VoucherPostingTypeEnum.ProjectRoyalty:
        // Project royalty dropdown
        this.accountNumEditDdlCurrentTableName = 'project';
        this.accountNumDdl.placeholder = 'Vælg projekt';
        this.accountNumFilterDefaultListData = this.projectsDefault;
        break;
      case VoucherPostingTypeEnum.Ledger:
        // Ledger account dropdown
        this.accountNumEditDdlCurrentTableName = 'financeLedgerAccount';
        this.accountNumDdl.placeholder = 'Vælg finanskonto';
        this.accountNumFilterDefaultListData = this.ledgerAccounts;
        break;
      case VoucherPostingTypeEnum.Debtor:
        // Debtor dropdown
        this.accountNumEditDdlCurrentTableName = 'debtor';
        this.accountNumDdl.placeholder = 'Vælg debitor';
        this.accountNumFilterDefaultListData = this.debtorsDefault;
        break;
      case VoucherPostingTypeEnum.Bank:
        // Debtor dropdown
        this.accountNumEditDdlCurrentTableName = 'bankAccount';
        this.accountNumDdl.placeholder = 'Vælg bankkonto';
        this.accountNumFilterDefaultListData = this.bankAccounts;
        break;
    }

    var list: any[] = this.accountNumFilterDefaultListData.slice(); // Clone from read-only list
    if (postingDto != null) {
      this.accountNumListAddDefaultFromDto(list, null, true, postingDto);
    }
    this.accountNumDdl.dataSource = new DataManager(list);

    this.accountNumDdl.value = postingDto.accountNum;
    //    this.accountNumDdl.query = new Query();

    if (isCreate) {
      this.accountNumDdl.appendTo(this.accountNumDdlElement);
    }
  }

  // Filter account num dropdown
  public accountNumFilter(args: FilteringEventArgs, postingType: VoucherPostingTypeEnum, ddl: CerDropDownList) {
    var filterText: string = args.text;
    if (!filterText) {
      var listData: any[] = [...this.getLookupDefaultListForAccountNum(postingType)]; // Clone from read-only list
      this.accountNumListAddDefaultFromDto(listData, postingType, true);
      args.updateData(new DataManager(listData));
    }
    else {
      var query: Query = new Query().from(this.getLookupTableNameForAccountNum(postingType)).take(50);
      query = query.where(new Predicate('$txt', 'contains', filterText, true));
      (query as any).filterActiveText = filterText;
      args.updateData(this.apiDataManager(), query);
    }
  }


  private getLookupDefaultListForAccountNum(postingType: VoucherPostingTypeEnum): object[] {
    var table: object[];

    switch (postingType) {
      case VoucherPostingTypeEnum.Vendor:
        table = this.vendorsDefault;
        break;
      case VoucherPostingTypeEnum.Project:
      case VoucherPostingTypeEnum.ProjectRoyalty:
        table = this.projectsDefault;
        break;
      default:
        table = this.accountNumFilterDefaultListData;
    }
    return table;
  }

  private getLookupTableNameForAccountNum(postingType: VoucherPostingTypeEnum): string {
    var tableName: string = '';

    switch (postingType) {
      case VoucherPostingTypeEnum.Vendor:
        tableName = 'vendor';
        break;
      case VoucherPostingTypeEnum.Project:
      case VoucherPostingTypeEnum.ProjectRoyalty:
        tableName = 'project';
        break;
      default:
        tableName = this.accountNumEditDdlCurrentTableName;
    }
    return tableName;
  }

  private postingGridEditFormInputElementGetByColumnName(columnName: string): HTMLInputElement {
    return <HTMLInputElement>this.gridEditFormElementGet(this.postingGrid).querySelector("#PostingGrid" + columnName);
  }

  private postingGridEditFormInputElementDisable(columnName: string, value: boolean) {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.disabled = value;
    }
  }

  private postingGridEditFormInputElementSetValue(columnName: string, value: string, id: any = null) {
    var i: HTMLInputElement = this.postingGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.value = value;
      if (i) {
        this.setDropDownListId(i, id);
      }
    }
  }

  private voucherGridEditFormInputElementGetByColumnName(columnName: string): HTMLInputElement {
    return <HTMLInputElement>this.gridEditFormElementGet(this.voucherGrid).querySelector("#VoucherGrid" + columnName);
  }

  private voucherGridEditFormInputElementDisable(columnName: string, value: boolean) {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.disabled = value;
    }
  }

  private voucherGridEditFormInputElementSetValue(columnName: string, value: string, id: any = null) {
    var i: HTMLInputElement = this.voucherGridEditFormInputElementGetByColumnName(columnName);
    if (i) {
      i.value = value;
      if (id) {
        this.setDropDownListId(i, id);
      }
    }
  }

  private setDropDownListId(i: HTMLInputElement, id: any) {
    var ej2: any = i;
    if (ej2.ej2_instances) {
      var ej2 = ej2.ej2_instances[0];
      if (ej2 instanceof DropDownList) {
        var ddl: DropDownList = <DropDownList>ej2;
        ddl.value = id;
      }
    }
  }

  // Account num drop down data returned to list
  private accountNumListAddDefaultFromDto(listData: any[], fixedType: VoucherPostingTypeEnum, addToStart: boolean = false, postingDto: PostingDto = null, voucherDto: VoucherDto = null) {
    addToStart = false;
    switch (fixedType) {
      case VoucherPostingTypeEnum.Vendor:
        var voucherData: VoucherDto = voucherDto ?? this.gridCurrentEditDtoGet(this.voucherGrid);

        if (voucherData?.balanceAccountNum && this.isPostingTypeVendor(voucherData.balancePostingType)) {
          if (listData.filter((r: any) => r.num == voucherData.balanceAccountNum).length == 0) {
            var vendorDefault: VendorDto[] = this.vendorsDefault.filter(v => v.num == voucherData.balanceAccountNum);
            if (vendorDefault.length >= 1) {
              var vAdd: VendorDto = Object.assign({}, vendorDefault[0]);
              if (addToStart) {
                listData.unshift(vAdd);
              }
              else {
                listData.push(vAdd);
              }
            }
          }
        }
        break;
      case VoucherPostingTypeEnum.Project:
      case VoucherPostingTypeEnum.ProjectRoyalty:
        var voucherData: VoucherDto = voucherDto ?? this.gridCurrentEditDtoGet(this.voucherGrid);
        if (voucherData?.costAccountNum && this.isPostingTypeProject(voucherData.costPostingType)) {
          if (listData.filter((r: any) => r.num == voucherData.costAccountNum).length == 0) {
            var projectDefault: ProjectDto[] = this.projectsDefault.filter(v => v.num == voucherData.costAccountNum);
            if (projectDefault.length >= 1) {
              var pAdd: ProjectDto = Object.assign({}, projectDefault[0]);
              if (addToStart) {
                listData.unshift(pAdd);
              }
              else {
                listData.push(pAdd);
              }
            }
          }
        }
        break;
      default:
        this.accountNumListAddDefaultFromPostingDto(listData, addToStart, postingDto);
    }
  }

  private accountNumListAddDefaultFromPostingDto(listData: any[], addToStart: boolean = false, postingDto: PostingDto = null) {
    var postingData: PostingDto = postingDto ?? <PostingDto>this.gridCurrentEditDtoGet(this.postingGrid);
    if (postingData && postingData.accountNum) {
      if (listData.filter((r: any) => r.num == postingData.accountNum).length == 0) {
        var postingType: VoucherPostingTypeEnum = this.voucherService.postingTypeEnum(postingData.postingType);
        switch (postingType) {
          case VoucherPostingTypeEnum.Vendor:
            var v = new VendorDto();
            v.num = postingData.accountNum;
            v.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(v);
            }
            else {
              listData.push(v);
            }
            break;
          case VoucherPostingTypeEnum.Project:
          case VoucherPostingTypeEnum.ProjectRoyalty:
            var p = new ProjectDto();
            p.num = postingData.accountNum;
            p.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(p);
            }
            else {
              listData.push(p);
            }
            break;
          case VoucherPostingTypeEnum.Ledger:
            var f = new FinanceLedgerAccountDto();
            f.num = postingData.accountNum;
            f.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(f);
            }
            else {
              listData.push(f);
            }
            break;
          case VoucherPostingTypeEnum.Debtor:
            var d = new DebtorDto();
            d.num = postingData.accountNum;
            d.description = postingData.accountDescription;
            if (addToStart) {
              listData.unshift(d);
            }
            else {
              listData.push(d);
            }
            break;
        }
      }
    }
  }

  private accountNumActionComplete(e: any, postingType: VoucherPostingTypeEnum, ddl: CerDropDownList): void {
    this.accountNumListAddDefaultFromDto(e.result, postingType);
  }

  // Account num drop down data returned to list
  public accountNumActionFailure(e: any): void {
    console.log("Kontering liste fejlede");
    console.log(e);
  }


  public accountNumChanged(args: any, postingTypeFixed: VoucherPostingTypeEnum) {
    // Reset account num DDL state
    if (args.itemData && args.element) {
      let data = args.itemData;
      if (data) {
        switch (postingTypeFixed) {
          case VoucherPostingTypeEnum.Vendor:
            if (!this.vendorsDefault.find(d => d.num == data.num)) {
              var vAdd: VendorDto = Object.assign({}, data);
              this.vendorsDefault.push(vAdd);
            }
            this.accountNumChangeSyncVoucherGrid(data.nameCurrency, data.currency, null, postingTypeFixed, true);
            this.setVendorPostingDataProforma(data);
            this.setFromName(data);
            break;
          case VoucherPostingTypeEnum.Project:
            if (this.projectsDefault.filter(d => d.num == data.num).length == 0) {
              var pAdd: ProjectDto = Object.assign({}, data);
              this.projectsDefault.push(pAdd);
            }
            var voucherDto = this.accountNumChangeSyncVoucherGrid(data.description, data.currency, data.responsible, postingTypeFixed, true);
            this.setProjectPostingDataProforma(data);
            break;
          default:
            var dto: PostingDto = this.gridCurrentEditDtoGet(this.postingGrid);
            var responsible: string = null;
            var valid: boolean = true;
            if (dto) {
              switch (dto.postingType) {
                case VoucherPostingTypeEnum.Vendor:
                case VoucherPostingTypeEnum.Debtor:
                  if (data.currency && !this.validateFieldCurrency(data.currency, this.getSelectedVoucher())) {
                    this.postingAccountClear(dto);
                    args.value = '';
                    valid = false;
                  }
                  this.setFromName(data);
                  break;
                case VoucherPostingTypeEnum.Ledger:
                  if (!this.userIsAdmin) {
                    this.error("Finans anvendes kun af økonomi brugerroller");
                    args.value = dto.accountNum;
                    valid = false;
                  }
                  break;
                case VoucherPostingTypeEnum.Project:
                  responsible = data.responsible;
                  break;
              }
            }
            if (valid) {
              var force: boolean = false;
              if (this.isPostingTypeVendor(dto.postingType)) {
                dto.accountDescription = data.nameCurrency;
                dto.vendorBankAccount = data.bankAccount;
                dto.taxCode = data.taxCode;
                if (dto.taxCode == '') {
                  dto.taxCode = null;
                }
                force = true;
              }
              else {
                dto.accountDescription = data.description;
                if (this.isPostingTypeLedger(dto.postingType)) {
                  dto.taxCode = data.taxCode;
                  if (dto.taxCode == '') {
                    dto.taxCode = null;
                  }
                }
                //this.accountNumChangeSyncVoucherGrid(data.description, null, responsible, dto.postingType, force);
              }

              this.postingGridEditFormInputElementSetValue('vendorBankAccount', dto.vendorBankAccount ?? "");
              this.postingGridEditFormInputElementSetValue('accountDescription', dto.accountDescription ?? "");
              this.postingGridEditFormInputElementSetValue('taxCode', dto.taxCode, dto.taxCode);

            }
        }
      }
    }
    this.voucherCanApproveByPostingEditCalc();
  }

  private accountNumChangeSyncVoucherGrid(description: string, currencyISO: string, responsible: string, postingType: VoucherPostingTypeEnum, force: boolean) {
    var voucherDto: VoucherDto = this.gridCurrentEditDtoGet(this.voucherGrid);

    if (this.voucherService.isPostingTypeBalance(postingType)) {
      if (force || (!voucherDto.balancePostingType) || (voucherDto.balancePostingType === <number>postingType)) {
        voucherDto.balanceAccountDescription = description;
        this.voucherGridEditFormInputElementSetValue('balanceAccountDescription', voucherDto.balanceAccountDescription);
        if (currencyISO) {
          this.setVoucherFieldCurrencyISO(voucherDto, currencyISO);
        }
      }
    }
    else {
      if (force || (!voucherDto.costPostingType) || (voucherDto.costPostingType === <number>postingType)) {
        voucherDto.costAccountDescription = description;
        this.voucherGridEditFormInputElementSetValue('costAccountDescription', voucherDto.costAccountDescription);
        if (responsible) {
          var user: UserDto = this.users.find(u => u.shortName == responsible);
          if (user) {
            voucherDto.approverName = user.name;
            voucherDto.approverShortName = user.shortName;
            voucherDto.approver = user.id;
            this.voucherGridEditFormInputElementSetValue('approverName', user.name);
            this.voucherGridEditFormInputElementSetValue('approverShortName', user.shortName);
            this.voucherGridEditFormInputElementSetValue('approver', user.name, user.id);
          }
        }
      }
    }
    return voucherDto;
  }

  private setProjectPostingDataProforma(project: ProjectDto, voucher: VoucherDto = null) {
    if (this.postingDtoList) {
      for (var i: number = 0; i < this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Project ||
          row.postingType == <number>VoucherPostingTypeEnum.ProjectRoyalty) {
          if (this.projectPostingProformaDtoOrg == null) {
            this.projectPostingProformaDtoOrg = new PostingDto();
            Object.assign(this.projectPostingProformaDtoOrg, row);
          }
          if (project) {
            row.accountNum = project.num;
            row.accountDescription = project.description;
          }
          if (voucher) {
            row.projectCostType = voucher.projectCostType;
          }
          this.postingGrid.setRowData(row.id, row);
          break;
        }
      }
    }
  }

  private resetProjectPostingProforma() {
    if (this.projectPostingProformaDtoOrg && this.postingDtoList) {
      for (var i: number = 0; i <= this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Project) {
          Object.assign(row, this.projectPostingProformaDtoOrg);
          this.postingGrid.setRowData(row.id, row);
          this.projectPostingProformaDtoOrg = null;
          break;
        }
      }
    }
  }

  private setVendorPostingDataProforma(vendor: VendorDto) {
    if (this.postingDtoList) {
      for (var i: number = 0; i < this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (this.isPostingTypeVendor(row.postingType)) {
          if (this.vendorPostingProformaDtoOrg == null) {
            this.vendorPostingProformaDtoOrg = new PostingDto();
            Object.assign(this.vendorPostingProformaDtoOrg, row);
          }
          row.accountNum = vendor.num;
          row.accountDescription = vendor.nameCurrency;
          row.taxCode = vendor.taxCode;
          row.vendorBankAccount = vendor.bankAccount;
          this.postingGrid.setRowData(row.id, row);
          break;
        }
      }
    }
  }

  private resetVendorPostingProforma() {
    if (this.vendorPostingProformaDtoOrg && this.postingDtoList) {
      for (var i: number = 0; i <= this.postingDtoList.length; i++) {
        var row: PostingDto = this.postingDtoList[i];
        if (row.postingType == <number>VoucherPostingTypeEnum.Vendor) {
          Object.assign(row, this.vendorPostingProformaDtoOrg);
          this.postingGrid.setRowData(row.id, row);
          this.vendorPostingProformaDtoOrg = null;
          this.setFromName();
          break;
        }
      }
    }
  }

  private currencyIdChanged(args: any) {
    if (args.itemData) {
      var voucherDto: VoucherDto = this.gridCurrentEditDtoGet(this.voucherGrid);
      this.setVoucherFieldCurrencyISO(voucherDto, args.itemData.iso);
    }
  }

  private setVoucherFieldCurrencyISO(voucherDto: VoucherDto, currencyISO: string) {
    if (currencyISO) {
      currencyISO = currencyISO.toUpperCase();
      var currency: CurrencyDto = this.currencies.find(c => c.iso == currencyISO);
      if (!currency) {
        var currencies: CurrencyDto[] = this.currencies.filter(c => c.iso.startsWith(currencyISO));
        if (currencies.length == 1) {
          currency = currencies[0];
        }
        else if (currencies.length == 0) {
          currencies = this.currencies.filter(c => c.iso.includes(currencyISO));
          if (currencies.length == 1) {
            currency = currencies[0];
          }
        }
      }
      if (currency) {
        voucherDto.currency = currency.id;
        voucherDto.currencyISO = currency.iso;
        this.voucherGridEditFormInputElementSetValue('currency', currency.iso, currency.id);
      }
      else {
        this.setVoucherFieldCurrencyId(voucherDto, voucherDto.currency);
      }
    }
  }

  private setVoucherFieldCurrencyId(voucherDto: VoucherDto, currencyId: number) {
    if (currencyId) {
      var currency: CurrencyDto = this.currencies.find(c => c.id == currencyId);
      if (!currency && voucherDto.currency) {
        currency = this.currencies.find(c => c.id == voucherDto.currency);
      }
      voucherDto.currency = currency ? currency.id : null;
      voucherDto.currencyISO = currency ? currency.iso : null;
      voucherDto.currencyName = currency ? currency.name : null;
      this.voucherGridEditFormInputElementSetValue('currency', voucherDto.currencyISO, voucherDto.currency);
    }
  }

  private validateFieldCurrency(currency: string, voucherDto: VoucherDto): boolean {
    var ok: boolean = true;
    if (voucherDto.currencyISO != currency) {
      this.dialogService.infoPrompt("Valuta på kreditor '" + currency + "' matcher ikke valuta på bilag '" + voucherDto.currencyISO + "'");
      ok = false;
    }
    return ok;
  }

  // Misc ----------------------------------
  private getSelectedVoucher(): VoucherDto {
    var dto: VoucherDto = null;

    // Use first selected row
    var firstRow = this.voucherGrid.getSelectedRows()[0];
    // If none selected use first row
    if (!firstRow && this.voucherGrid.getDataRows().length > 0) {
      firstRow = this.voucherGrid.getDataRows()[0];
    }
    if (firstRow) {
      var gridRow = this.voucherGrid.getRowObjectFromUID(firstRow.getAttribute('data-uid'));
      if (gridRow) {
        dto = <VoucherDto>gridRow.data;
      }
    }
    return dto;
  }

  private splitterResize(addPx: number) {
    if (this.attachmentsIsActive) {
      var size: string = this.splitterObj.paneSettings[1].size;
      var sizeNum: number = Number(size.substring(0, size.length - 2)) + addPx;
      size = sizeNum + 'px';
      this.splitterObj.paneSettings[1].size = size;
      if (this.fileViewerTab) {
        this.fileViewerTab.resize();
      }
    }
  }

  protected onSplitterResizing(args: any) {
    this.attachmentsIsActive = true;
    if (this.fileViewerTab) {
      this.fileViewerTab.resize();
    }
  }

  protected onSplitterExpanded(args: any) {
    this.attachmentsIsActive = true;
    if (this.fileViewerTab) {
      this.fileViewerTab.resize();
    }
  }

  private attachmentsShowHide() {
    if (this.splitterObj != null) {
      if (this.attachmentsIsActive && this.splitterObj.paneSettings[1].collapsed) {
        this.splitterObj.expand(1);
      }
      else {
        this.attachmentsIsActive = !this.attachmentsIsActive;
        if (this.attachmentsIsActive) {
          this.splitterObj.expand(1);
        }
        else {
          this.splitterObj.collapse(1)
        }
      }
    }
    else {
      this.attachmentsIsActive = !this.attachmentsIsActive;
    }
    if (this.attachmentsIsActive && this.detailsVm == null && this.messageGrid.getRows().length > 0) {
      this.messageGrid.selectRow(0);
    }
  }

  public attachmentsPageNext() {
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault + 1 < this.detailsVm.voucherMessageAttachmentDtoList.length) {
        this.attachmentSelectedIdxDefault++;
      }
    }
  }
  public attachmentsPagePrev() {
    if (this.attachmentsIsActive && this.detailsVm && this.detailsVm.voucherMessageAttachmentDtoList) {
      if (this.attachmentSelectedIdxDefault > 0) {
        this.attachmentSelectedIdxDefault--;
      }
    }
  }

  public onAttachmentUploadChange(event: any) {
    if (event == 'Close') {
      this.attachmentsShowHide();
    }
    else {
      if (this.detailsVm != null) {
        var id: number = this.detailsVm.voucherMessageViewDto.id
        this.detailsVmMap.delete(id);
        this.detailsVmDataBind();
      }
    }
  }

  public onAttachmentCheckBoxApply(checkedFileNames: string[]) {
    var id: number = this.detailsVm?.voucherMessageViewDto?.id;
    if (id != null) {
      var callback = () => {
        this.detailsVmMap.delete(id);
        this.detailsVmDataBind();
      }
      VoucherAttachmentService.messageSplitByAttachment(id, checkedFileNames, callback, this.messageClient, this.dialogService, this.ui);
    }
  }

  public onAttachmentTabSelectedIndexChanged(tabActiveIdx: number) {
    this.attachmentSelectedIdxDefault = tabActiveIdx;
  }

  // Action handling
  protected actionRun(action: ActionType, ignoreDisabledClass: boolean = false) {
    if (!this.canEdit) {
      if (action == 'Close') {
        this.actionComplete(action);
      }
      return;
    }
    if (this.actionDisabledClass && !ignoreDisabledClass) {
      return;
    }
    this.actionDoRun(action);
  }

  private actionDeferred: ActionType = null;
  protected actionSetDeferred(action: ActionType) {
    this.actionDeferred = action;
    if (!action) {
      this.actionDisabledClass = '';
    }
  }

  protected actionRunDeferred() {
    if (this.actionDeferred) {
      var action: ActionType = this.actionDeferred;
      this.actionDeferred = null;
      this.actionDoRun(action);
    }
  }

  protected actionDisabledClass: string = '';
  private actionDoRun(action: ActionType) {
    this.actionDisabledClass = "button-disabled-class";

    if (this.actionPrepare(action)) {
      if (this.actionExecute(action)) {
        this.actionComplete(action);
      }
    }
    else {
      this.actionSetDeferred(action);
    }
  }

  protected actionPrepare(action: ActionType): boolean {
    var forceCancelEdit: boolean = (action == 'Close');
    var skipCancelUi: boolean = true;

    var isSaving = false;
    if (this.postingGrid?.isEdit == true) {
      if (this.gridEditSaveCancel(this.postingGrid, forceCancelEdit, skipCancelUi)) {
        isSaving = true;
      }
    }
    else if (this.voucherGrid?.isEdit == true) {
      if (this.gridEditSaveCancel(this.voucherGrid, forceCancelEdit, skipCancelUi)) {
        isSaving = true;
      }
    }
    return !isSaving;
  }

  protected actionExecute(action: ActionType): boolean {
    if (this.detailsVm?.voucherViewDtoList == null || this.detailsVm.voucherViewDtoList.length == 0) {
      return true;
    }
    var voucherDto: VoucherDto = this.detailsVm.voucherViewDtoList[0];
    switch (action) {
      case 'Close':
      case 'SaveAndClose':
        if (this.isProformaEditFromInbox && this.voucherDtoList != null && this.voucherDtoList.length > 0) {
          this.apiVoucherDeletePromise(this.voucherDtoList[0]).then(() => {
            this.actionComplete(action);
          });
          return false;
        }
        break;
      case 'ApprovedAndClose':
        if (this.voucherCanApprove && voucherDto != null) {
          this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Approved).finally(() => {
            this.actionComplete(action);
          });
          return false;
        }
        break;
      case 'PendingApprovalAndClose':
        if (this.canSendToPending && voucherDto) {
          if (voucherDto.approver == null) {
            this.setNewApproverPromise(voucherDto).then(detailsVm => {
              this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).finally(() => {
                this.actionComplete(action);
              });
            });
            return false;
          }
          else {
            this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).finally(() => {
              this.actionComplete(action);
            })
            return false;
          }
        }
        break;
      case 'NewApproverAndClose':
        if (this.canNewApprover && voucherDto) {
          this.setNewApproverPromise(voucherDto)
            .then(detailsVm => {
              this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.Pending).finally(() => {
                this.actionComplete(action);
              });
            })
            .catch(() => { this.actionSetDeferred(null) });
          return false;
        }
        break;
      case 'OnHoldAndClose':
        if (this.voucherCanHold && voucherDto != null) {
          this.setApprovalStatusPromise(voucherDto.id, VoucherApprovalStatusEnum.OnHold).finally(() => {
            this.actionComplete(action);
          });
          return false;
        }
        break;
      case 'AdjustAmounts':
        this.postingAdjustAmountsApi();
    }
    return true;
  }

  protected actionComplete(action: ActionType) {
    this.actionDisabledClass = "";
    if (action != 'AdjustAmounts') {
      this.dialogRef.close({ needRefresh: true });
    }
  }

  private setNewApproverPromise(voucherDto: VoucherDto): Promise<DetailsVm> {
    return new Promise<DetailsVm>((resolve, reject) => {
      this.getNewApprover(voucherDto)
        .then(newApprover => {
          if (newApprover != null) {
            if (voucherDto.approver != newApprover) {
              voucherDto.approver = newApprover;
              this.apiVoucherPutPromise(voucherDto).then(detailsVm => {
                resolve(detailsVm);
              });
            }
            else {
              resolve(this.detailsVm);
            }
          }
          else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  private getNewApprover(voucherDto: VoucherDto): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      var inputs: DialogInput[] = [
        {
          label: 'Godkender', id: "userId", placeholder: 'Angiv godkender', valueNumber: voucherDto?.approver,
          type: 'DropDownList', dataSource: this.users, fields: { text: 'shortName', value: 'id' }, element: null
        }
      ];
      this.ui.inputPrompt('', 'Angiv godkender', inputs).then(
        result => {
          if (result.ok) {
            resolve(result.dialogInputs[0].ej2.value);
          }
          else {
            reject();
          }
        }
      );
    });
  }

  // Grid navigation -->


  private postingGridRefresh(rowIdx: number) {
    if (this.postingGrid && this.postingGrid.headerModule) {
      if (rowIdx >= 0) {
        if (this.gridDeferred == null) {
          this.gridDeferred = this.postingGrid;
          this.gridSelectRowIdxDeferred = rowIdx;
        }
      }
      this.postingGrid.refresh();
      this.voucherGrid.refresh();
    }
  }


  private postingGridStartEdit(forceEdit: boolean = false) {
    if (this.postingGrid) {
      var idx: number = this.gridSelectRowIdxDeferred;
      this.gridSelectRowIdxDeferred = -1;

      if (idx > -1 && idx < this.postingGrid.getRows().length) {
        this.postingGrid.selectRow(idx); // Calls recursive using onRowSelected
      }
      else if (this.postingGrid.getSelectedRows().length > 0 && !this.postingGrid.isEdit && this.postingGrid.editSettings.allowEditing) {
        this.postingGrid.startEdit();
        this.postingGrid.focusModule.focus();
      }
    }
  }

  // Grid navigation <--
  // Grid edit complete
  /*
    /*
    private voucherGridMove(isDown: boolean) {
      var rowIdx: number = this.voucherGrid.selectedRowIndex;
      if (isDown) {
        var maxIdx: number = this.postingGrid.getRows().length - 1;
        if (rowIdx < maxIdx) {
          this.postingGrid.selectedRowIndex = rowIdx + 1;
          this.postingGrid.startEdit();
        }
        else {
          maxIdx = this.postingGrid.getRows().length - 1;
          if (maxIdx >= 0) {
            this.voucherGrid.selectedRowIndex = 0;
            this.voucherGrid.startEdit();
          }
        }
      }
      else if (this.voucherGrid.selectedRowIndex > 0) {
        this.voucherGrid.selectedRowIndex = this.voucherGrid.selectedRowIndex - 1;
      }
    }

  voucherGridChange(args) {
    console.log(args);
    let gridElement = document.getElementsByClassName('e-grid')[1] as any;
    if (gridElement != null) {
      let gridObj = gridElement.ej2_instances[0]; //get gridobj
      var selRow0 = <Element>gridObj.getSelectedRows()[0];
      if (selRow0) {
        var gridRow = this.voucherGrid.getRowObjectFromUID(selRow0.getAttribute('data-uid'));
        // refresh related data based
        //var kDto = <HovedkontraktViewDto> gridRow.data;
        //var fDto = <ForfatterDto> args.itemData;
        //kDto.agent = fDto.id;
        //kDto.agentId = fDto.forfatterId;
        //kDto.agentNavn = fDto.navn;
      }
    }
  }
  */

  /*
if (this.voucherGridRequestEditIdx != -1) {
  this.voucherGridStartEdit();
}
else if (this.postingGridRequestEditIdx != -1) {
  this.postingGridStartEdit());
}
*/

  //    this.messageGrid.refresh();

  //this.voucherGrid.refresh();
  //if (this.voucherDtoList.length > 0) {
  //this.voucherGrid.selectRow(0);
  // this.voucherGrid.beginEdit();
  //

  //this.postingGrid.refresh();

} // End component



/*
function convertDateToUTC(date: Date) {
  if (date != null) {
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  return date;
}
*/
