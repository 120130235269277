import { FieldEditChangedArgs } from 'src/cer/cer-data/cer-data.service';
import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ProductBudgetCategoryFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'udgivelse-arter', dataApiName: 'ProductBudgetCategory', text: 'Arter, udgivelser',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };

    this.fieldMetadata = [
      { name: 'isPrimary', text: 'Primær samarbejdsaftaler', tooltipText: 'Skal denne art tilknyttes automatisk for samarbejdsaftaler', format: 'CheckBox', defaultValue: true },
      { name: 'isBudget', text: 'Budget', format: 'CheckBox', defaultValue: true },
      { name: 'defaultAmount', text: 'Beløb standard', format: 'Amount' },
      { name: 'defaultPercentage', text: '% standard', format: 'Amount' },
      {
        name: 'legacyId', text: 'Oprindelig art', tooltipText: 'Reference til oprindelig database', required: true, 
        foreignKeyTableName: 'OmkArter', foreignKeyField: 'id', foreignKeyValue: 'omkArt', foreignKeyColumns: ['omkArt', 'oaid'], foreignKeyTitles: ['Navn', 'Art'], 
        foreignKeySortFields: ['sortering','omkArt'], onEditChanged: (args) => { this.editChangedLegacyId(args) }
      },
      { name: 'sorting', text: 'Sortering', format: 'Integer', orderBy: 'Ascending' }
      
    ];
  }
  
  private editChangedLegacyId(args: FieldEditChangedArgs) {
    var omkArt: any = args.itemData;
    if (omkArt) {
      var numValue: string = omkArt.oaid;
      var nameValue: string = omkArt.omkArt;
      var defaultAmountValue: number = omkArt.defaultUI;
      var sortingValue: number = omkArt.sortering;

      var e = this.grid.editService;
      var numInput = e.fieldInput('num');
      var nameInput = e.fieldInput('name');
      var defaultAmountInput = e.fieldInput('defaultAmount');
      var sortingInput = e.fieldInput('sorting');

      if (numInput && !(numValue.length > 0)) {
        e.inputValueSet(numInput, numValue);
      }
      if (nameInput && (nameValue?.length > 0)) {
        e.inputValueSet(nameInput, nameValue);
      }
      if (defaultAmountInput && defaultAmountValue >= 1) {
        e.inputValueSet(defaultAmountInput, defaultAmountValue);
      }
      if (sortingInput && sortingValue != 0) {
        e.inputValueSet(sortingInput, sortingValue);
      }
    }
  }
}
