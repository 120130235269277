<cer-form #form [panes]="panes">
  <ng-template #topTemplate>
    <cer-grid #salesOrderGrid id="salesOrderGrid" [viewMetadata]="salesOrderViewMetadata"
      [fieldMetadata]="salesOrderFieldMetadata" [fieldGroupMetadata]="salesOrderFieldGroupMetadata"
      [toolbarCommands]="salesOrderToolbarCommands" [contextMenuCommands]="salesOrderContextMenuCommands"
      [keyboardShortcuts]="formShortcuts" paging="virtual"></cer-grid>
  </ng-template>
  <ng-template #leftTemplate>
    <cer-grid #debtorGrid id="debtorGrid" [viewMetadata]="debtorViewMetadata" [fieldMetadata]="debtorFieldMetadata"
      [toolbarCommands]="debtorToolbarCommands" [contextMenuCommands]="debtorContextMenuCommands" [keyboardShortcuts]="formShortcuts" 
      paging="virtual" toolbarType="search" autoFocus="disabled"></cer-grid>
  </ng-template>
  <ng-template #mainTemplate>
  <cer-grid #refDataNemHandelGrid id="refDataNemHandelGrid" [viewMetadata]="refDataNemHandelViewMetadata"
    [fieldMetadata]="refDataNemHandelFieldMetadata" [toolbarCommands]="refDataNemHandelToolbarCommands" [keyboardShortcuts]="formShortcuts" 
    paging="virtual" [contextMenuCommands]="refDataNemHandelContextMenuCommands" toolbarType="search"></cer-grid>
  </ng-template>
  <ng-template #rightTemplate>
    <cer-grid #refDataGrid id="refDataGrid" [viewMetadata]="refDataViewMetadata" [fieldMetadata]="refDataFieldMetadata"
      [toolbarCommands]="refDataToolbarCommands" [contextMenuCommands]="refDataContextMenuCommands" [keyboardShortcuts]="formShortcuts" 
      paging="virtual" toolbarType="search" autoFocus="disabled"></cer-grid>
  </ng-template>
  <ng-template #bottomTemplate>
    <cer-grid #custCreateGrid id="custCreateGrid" [viewMetadata]="custCreateViewMetadata" paging="virtual"
      [fieldMetadata]="custCreateFieldMetadata" [fieldGroupMetadata]="custCreateFieldGroupMetadata"
      [toolbarCommands]="custCreateToolbarCommands" [contextMenuCommands]="custCreateContextMenuCommands"  
      [keyboardShortcuts]="formShortcuts" autoFocus="disabled"></cer-grid>
  </ng-template>
</cer-form>