import { CerAppFormModelAdapter } from 'src/platform/models/cer-app-form-model-adapter';
import { FieldWidthEnum } from 'src/cer/cer-data/cer-data.service';

export class TeamFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'team', dataApiName: 'Team', text: 'Teams', textSingular: 'Team',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
    ];
   
    this.detailViewMetadata = { name: 'team-medlem', dataApiName: 'TeamUser', text: 'Team medlemmer', textSingular: 'Team medlem',
       primaryKey: 'id', parentPrimaryKey: 'num', parentForeignKey: 'team', 
       baseFields: ['id', 'history'], titleFields: ['team'] };  
    this.detailFieldMetadata = [
      { name: 'team', text: 'Team', required: true, foreignKeyTableName: 'Team', foreignKeyField: 'num', visible: false, widthType: FieldWidthEnum.M },
      { name: 'user', text: 'Medlem', required: true, foreignKeyTableName: 'User', foreignKeyField: 'shortName', foreignKeyValue: 'shortName', widthType: FieldWidthEnum.S },  
      { name: 'isActive', text: 'Aktiv', defaultValue: 1, format: 'CheckBox', required: true, widthType: FieldWidthEnum.XXS  }
    ]
  }
}
