import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class ContractTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'aftale-typer', dataApiName: 'ContractType', text: 'Aftaletyper', textSingular: 'Aftaletype',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'sorting', 'history']
    };

    this.fieldMetadata = [
      { name: 'royalty', text: 'Royaltyafregning?', tooltipText: 'Omfattes af royaltyafregning?', format: 'CheckBox', defaultValue: false},
      { name: 'author', text: 'Forfatterafregning?', tooltipText: 'Omfattes af forfatterafregning?', format: 'CheckBox', defaultValue: false},
      { name: 'budget', text: 'Forfatterafregning, budget?', tooltipText: 'Anvendes budget i forfatterafregning?', format: 'CheckBox', defaultValue: false},
      { name: 'legacyId', text: 'Opr. Id', tooltipText: 'Id fra oprindelig database', visible: false, format: 'Integer'}
    ];
  }
}
