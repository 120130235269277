// Angular
import { Injectable } from '@angular/core';

// WebApi
import {
  VoucherMessageViewDetailsVm as DetailsVm,
  VoucherMessageViewDto as MessageDto,
  VoucherMessageStatusEnum,
  VoucherMessageStatusAllEnum,
  VoucherApprovalStatusEnum,
  VoucherMessageChannelTypeEnum,
} from "../../api";

// App
import { CerGridComponent } from 'src/cer/cer-grid/cer-grid.component';
import { CerAppChatService } from 'src/cer-app/cer-app-chat/cer-app-chat.service';
import { UiActionTypeEnum, UiCommand, UiCommandEvent, UiCommandSourceEnum, UiKeyboardShortcut, uiCmdSeparator, uiCmdSeparatorHeader } from 'src/cer/cer-grid/cer-grid-command.service';
import { FieldMetadata, ViewMetadata } from 'src/cer/cer-data/cer-data.service';

// Voucher
import { VoucherDataService, VoucherUpdateResult } from '../voucher-data.service';
import { uiCmdPaneRightClose, uiCmdPaneRightToggle } from 'src/cer/cer-form/cer-form-panes.service';
import { CerDialogService } from 'src/cer/cer-dialog/cer-dialog.service';
import { ContextMenuOpenEventArgs, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { VoucherHelper } from '../voucher.service';
import { Subscription } from 'rxjs';
import { CerGridSelectionService } from 'src/cer/cer-grid/cer-grid-selection.service';
import { CerFormComponent } from 'src/cer/cer-form/cer-form.component';
import { VoucherFormatterValidationStatus } from '../voucher-list/voucher-formatter-validation-status';

@Injectable(
)

export class VoucherMessageViewService {

  // Commands 
  private uiCmdSyncMsg: UiCommand = { id: 'syncAll', text: 'Synkroniser indbakke', tooltipText: 'Synkroniser meddelelser fra indbakke (ALT+Y)', iconCss: 'e-menu-icon e-icons-org e-repeat', target: '.e-header-content' };
  private uiCmdShowVoucherDetails: UiCommand = { id: 'showVoucherDetails', text: 'Vis godkendelse', tooltipText: ' godkendelse og kontering (ALT+X)', iconCss: 'e-menu-icon e-icons e-detail', target: '.e-content' };
  private uiCmdSetStatusVoucher: UiCommand = { id: 'setStatusVoucher', text: "Sæt status 'Godkendelse'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.Voucher } };
  private uiCmdSetStatusOnHold: UiCommand = { id: 'setStatusOnHold', text: "Sæt status 'Afventer'", iconCss: 'e-menu-icon e-icons e-document-01', target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.OnHold } };
  private uiCmdSetStatusStatement: UiCommand = { id: 'setStatusStatement', text: "Sæt status 'Kontoudtog'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.AccountStatement } };
  private uiCmdSetStatusForwarded: UiCommand = { id: 'setStatusForwarded', text: "Sæt status 'Videresendt'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.Forwarded } };
  private uiCmdSetStatusRejected: UiCommand = { id: 'setStatusRejected', text: "Sæt status 'Annulleret'", iconCss: "e-menu-icon e-icons e-close-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.Rejected } };
  private uiCmdSetStatusInbox: UiCommand = { id: 'setStatusInbox', text: "Sæt status 'Indbakke'", iconCss: "e-menu-icon e-icons e-document-01", target: ".e-content", data: { messageStatus: VoucherMessageStatusEnum.Inbox } };

  // Ui commands
  private uiCmdDetailToggle = Object.assign(new Object(), uiCmdPaneRightToggle, { text: 'Vis vedhæftninger', tooltipText: 'Vis vehæftninger i højre side [Ctrl]+[Pil højre]' });
  private uiCmdDetailClose = Object.assign(new Object(), uiCmdPaneRightClose, { text: '', tooltipText: 'Luk vedhæftninger [Ctrl]+[Pil op]' });

  // View
  private viewMetadata: ViewMetadata = {
    name: 'beskeder', dataApiName: 'VoucherMessageView', dataApiParams: [],
    text: 'Beskeder', textSingular: 'Besked', titleFields: ['description', 'statusName'],
    allowEdit: false, allowDelete: false, allowCreate: false,
    primaryKey: 'id', baseFields: ['id']
  };

  private fieldMetadata: FieldMetadata[] = [
    { name: 'id', text: "Besked id", format: 'Integer', visible: false, width: '80px' },
    { name: 'status', text: "Status id", format: 'Integer', visible: false, width: '80px' },
    { name: 'validationStatus', text: "Genkendelsestatus", width: '20px', foreignKeyTableName: 'VoucherValidationStatus', formatter: new VoucherFormatterValidationStatus() },
    { name: 'statusName', text: "Status besked", visible: false, width: '120px' },
    { name: 'channel', text: "Kanal id", format: 'Integer', visible: false, width: '80px' },
    { name: 'channelType', text: "Kanal type", visible: false, width: '80px' },
    { name: 'channelName', text: "Kanal", visible: false, width: '80px' },
    { name: 'recieved', text: "Modtaget", format: 'DateLong' },
    { name: 'description', text: "Beskrivelse", width: '200px' },
    { name: 'fromEmail', text: "Fra adresse", width: '150px' },
    { name: 'fromName', text: "Fra navn", width: '150px' },
    { name: 'toEmails', text: "Til adresse", visible: false, width: '150px' },
    { name: 'toNames', text: "Til navn", visible: false, width: '150px' },
    { name: 'ccEmails', text: "CC e-adresser", visible: false, width: '150px' },
    { name: 'ccNames', text: "CC navne", visible: false, width: '150px' },
    { name: 'attachmentCount', text: "Vedhæftninger", width: '100px', visible: false },
    { name: 'created', text: "Oprettet", format: 'DateLong', orderBy: 'Descending' },
    { name: 'createdByShortName', text: "Oprettet af", visible: false },
    { name: 'modified', text: "Ændret", format: 'DateLong', visible: false },
    { name: 'modifiedByShortName', text: "Ændret af", visible: false }
  ];
  private toolbarCommands: UiCommand[] = [this.uiCmdDetailToggle];
  private contextMenuCommands: UiCommand[] = [this.uiCmdSyncMsg, uiCmdSeparatorHeader, this.uiCmdShowVoucherDetails, uiCmdSeparator, this.uiCmdSetStatusVoucher, this.uiCmdSetStatusOnHold, this.uiCmdSetStatusStatement, this.uiCmdSetStatusForwarded, this.uiCmdSetStatusRejected, this.uiCmdSetStatusInbox];
  private keyboardShortcuts: UiKeyboardShortcut[] = [
    { code: 'ArrowRight', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowRight', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: false, cmd: this.uiCmdDetailToggle },
    { code: 'ArrowLeft', ctrl: true, alt: false, shift: true, cmd: this.uiCmdDetailToggle },
    //  { code: 'ArrowDown', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomToggle },
    //  { code: 'ArrowDown', ctrl: true, alt: false, shift: true, cmd: this.uiCmdBottomToggle },
    //  { code: 'ArrowUp', ctrl: true, alt: false, shift: false, cmd: this.uiCmdBottomClose },
    //  { code: 'ArrowUp', ctrl: true, alt: true, shift: true, cmd: this.uiCmdBottomClose }
    { code: 'KeyY', ctrl: false, alt: true, shift: false, cmd: this.uiCmdSyncMsg, global: true },
    { code: 'KeyO', ctrl: false, alt: true, shift: false, cmd: this.uiCmdShowVoucherDetails, global: true },
    { code: 'KeyX', ctrl: false, alt: true, shift: false, cmd: this.uiCmdShowVoucherDetails, global: true }
  ];

  // Grid
  private grid: CerGridComponent;

  constructor(
    private voucherDataService: VoucherDataService,
    private dialog: CerDialogService,
    private chatService: CerAppChatService
  ) {
  }

  public init() {
    this.manage(this.chatService.chats$.subscribe(chat => this.voucherDataService.detailsVmMapRefreshFromChat(chat)));
    this.manage(this.voucherDataService.setupComplete$.subscribe(ok => this.setup()));
    this.manage(this.voucherDataService.detailsVm$.subscribe(vm => this.onDetailVm(vm)));

  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }

  private form: CerFormComponent;
  public initForm(form: CerFormComponent) {
    this.form = form;
  }

  public initGrid(grid: CerGridComponent) {
    var isExpenseVoucher: boolean = this.voucherDataService.filterChannelType == VoucherMessageChannelTypeEnum.ExpenseVoucher;

    grid.viewMetadata = this.viewMetadata;
    grid.fieldMetadata = this.fieldMetadata;
    grid.selectionMode = isExpenseVoucher ? 'checkboxMulti' : 'row';
    grid.toolbarCommands = this.toolbarCommands;
    grid.contextMenuCommands = this.contextMenuCommands;
    grid.keyboardShortcuts = this.keyboardShortcuts;
    grid.commmand.subscribe(e => this.onCommand(e));
    this.grid = grid;
  }

  private onDetailVm(vm: DetailsVm): void {
    if (vm?.voucherMessageViewDto) {
      var messageDto: MessageDto = {} as MessageDto;
      Object.assign(messageDto, vm.voucherMessageViewDto);
      if (messageDto?.id) {
        var id: number = messageDto.id;
        var selectedData: any = this.grid?.selection.rowDataById(id);
        if (selectedData) {
          messageDto.modified.setMilliseconds(0);
          messageDto.created.setMilliseconds(0);
          if (!CerGridSelectionService.rowDataIsEqual(selectedData, messageDto)) {
            if (messageDto.modified < selectedData.modified) {
              console.error("Modified date is older than existing data", messageDto.modified, selectedData.modified);
            }
            else {
              this.grid.selection.rowDataSetById(id, messageDto);
            }
          }
        }
      }
    }
  }

  public research() {
    this.grid.selection.rowSelectedKeep();
    this.grid.research();
  }

  private researchUpdate(dataUpdate: VoucherUpdateResult) {
    if (dataUpdate.ok && dataUpdate.messageDtoOrig?.id) {
      var messageId: number = dataUpdate.messageDtoOrig.id;
      if (messageId > 0) {
        this.voucherDataService.detailsVmGetPromise(messageId, null).then(detailsVm => {
          this.research();
        });
      }
    }
  }

  private setup() {
    if (this.voucherDataService.isExpenseVoucher) {
      this.setupUiTextForExpense();
    }

    // Filter CustInvoice
    if (this.voucherDataService.filterCustInvoice) {
      this.viewMetadata.dataApiParams = ['$CustInvoice'];
    }

    // Filter status
    if (this.voucherDataService.filterMessageStatus) {
      var statusField = this.fieldMetadata.find(f => f.name == 'status');
      if (statusField) {
        statusField.filterValue = this.voucherDataService.filterMessageStatus;
        statusField.filterOperator = 'equal';
      }
      this.contextMenuCommands.filter(cmd => cmd.data?.messageStatus == this.voucherDataService.filterMessageStatus)
        .forEach(cmd => cmd.visible = false);
    }
    else {
      var statusNameField = this.fieldMetadata.find(f => f.name == 'statusName');
      if (statusNameField) {
        statusNameField.visible = true;
      }
    }

    // Filter channel
    if (this.voucherDataService.filterChannelType) {
      var channelTypeField = this.fieldMetadata.find(f => f.name == 'channelType');
      if (channelTypeField) {
        channelTypeField.filterValue = this.voucherDataService.filterChannelType;
        channelTypeField.filterOperator = 'equal';
      }

      var isVendorVoucher: boolean = this.voucherDataService.filterChannelType == VoucherMessageChannelTypeEnum.VendorVoucher;
      if (isVendorVoucher) {
        this.contextMenuCommands = this.contextMenuCommands.filter(c => c.id != this.uiCmdSetStatusVoucher.id);
      }
      else {
        this.fieldMetadataByNameHide('validationStatus');
      }

      // Show sync on toolbar
      if (this.voucherDataService.filterMessageStatus == VoucherMessageStatusAllEnum.Inbox) {
        this.toolbarCommands.unshift(this.uiCmdSyncMsg);
        if (!VoucherMessageViewService.messageStatusHarSupportDetailDialog(VoucherMessageStatusEnum.Inbox, this.voucherDataService.filterChannelType)) {
          this.contextMenuCommands = this.contextMenuCommands.filter(cmd => cmd.id != this.uiCmdShowVoucherDetails.id && cmd.id != uiCmdSeparator.id);
        }
        else if (isVendorVoucher) {
          this.contextMenuCommands = this.contextMenuCommands.filter(cmd => cmd.id != this.uiCmdSetStatusVoucher.id);
        }
      }
    }
  }

  private fieldMetadataByNameHide(fieldName: string, visible: boolean = false) {
    var field: FieldMetadata = this.fieldMetadata.find(f => f.name == fieldName);
    if (field) {
      field.visible = false;
    }
  }

  private setupUiTextForExpense() {
    this.uiCmdShowVoucherDetails.text = "Rediger bilag";
    this.uiCmdSetStatusVoucher.text = "Sæt status 'Bilag'";
  }


  private onCommand(e: UiCommandEvent): void {
    if (e.commandId != null) {
      switch (e.commandId) {
        case this.uiCmdSyncMsg.id:
          this.voucherDataService.apiVoucherChannelSync().then(result => { if (result > 0) { this.research() } });
          break;
        case this.uiCmdShowVoucherDetails.id:
          var calledFromChat: boolean = e.columnName == 'chatCount';
          this.voucherDetailsOpen(this.dtoFromArgs(e.args), calledFromChat);
          break;
      }
    }
    if (e.actionType == UiActionTypeEnum.ContextMenuOpen) {
      this.onContextMenuOpen(e.args);
    }
    if (e.actionType == UiActionTypeEnum.ContextMenuItem) {
      if (e.command?.data?.messageStatus) {
        this.setMessageStatus(e);
      }
    }
    if (e.actionType == UiActionTypeEnum.RowDoubleClick) {
      var dto: MessageDto = this.dtoFromArgs(e.args);
      if (dto?.id && VoucherMessageViewService.messageStatusHarSupportDetailDialog(dto.status, this.voucherDataService.filterChannelType)) {
        var calledFromChat: boolean = e.columnName == 'chatCount';
        this.voucherDetailsOpen(this.dtoFromArgs(e.args), calledFromChat);
      }
    }
    if (e.source == UiCommandSourceEnum.ActionComplete) {
      switch (e.actionType) {
        case UiActionTypeEnum.RowSelect:
          this.voucherDataService.detailsVmGetFromMap(this.dtoFromArgs(e.args)?.id);
          break;
      }
    }
  }

  private dtoFromArgs(args: any, useSelectedRow: boolean = true): MessageDto {
    var dto: MessageDto = args?.rowData;
    if (dto == null) {
      dto = args?.rowInfo?.rowData;
    }
    if (dto == null) {
      dto = args?.data;
    }
    if (dto == null) {
      dto = args?.rowData;
    }
    if (dto == null && args.args) {
      dto = this.dtoFromArgs(args.args, useSelectedRow);
    }
    if (dto == null && useSelectedRow) {
      dto = <MessageDto>this.grid.rowSelectedData();
      console.log("Command on row selection", dto?.id);
    }
    if (dto == null) {
      console.log("No row data");
    }
    return dto;
  }

  private voucherGridQueryCellInfoFromVmMap(args: QueryCellInfoEventArgs, detailsVmMap: Map<number, DetailsVm>) {
    if (args.column.field === 'chatCount') {
      var data: any = args.data;
      var detailsVm: DetailsVm = null;
      if (detailsVmMap.has(data.msgId)) {
        detailsVm = detailsVmMap.get(data.msgId);
      }
      this.voucherGridQueryCellInfoFromVm(args, detailsVm);
    }
  }

  private voucherGridQueryCellInfoFromVm(args: QueryCellInfoEventArgs, detailsVm: DetailsVm) {
    if (args.column.field === 'chatCount') {
      if (detailsVm && detailsVm.chatDtoList) {
        var n: number = detailsVm.chatDtoList.length;
        if (n > 0) {
          args.cell.classList.add('chat-active');
        }
        else {
          args.cell.classList.add('chat-none');
        }
      }
      else {
        args.cell.classList.add('chat-unknown');
      }
    }
  }

  // Grid context menu open - disable menu items
  private onContextMenuOpen(args: ContextMenuOpenEventArgs) {
    var messageDto: MessageDto = this.dtoFromArgs(args, false);
    var messageStatusFrom: VoucherMessageStatusEnum = messageDto?.status;
    this.grid.grid.contextMenuItems.forEach(item => {
      var id = (item as any).id;
      if (id?.length > 0) {
        if (id == this.uiCmdShowVoucherDetails.id) {
          var enabled: boolean = VoucherMessageViewService.messageStatusHarSupportDetailDialog(messageStatusFrom, this.voucherDataService.filterChannelType);
          //console.log("details" + " = " + enabled);
          this.grid.grid.contextMenuModule.contextMenu.enableItems([id], enabled, true);
        }
        else {
          var cmd: UiCommand = this.contextMenuCommands.find(cmd => cmd.id == id);
          if (cmd) {
            var messageStatusTo: VoucherMessageStatusEnum = (cmd?.data?.messageStatus);
            if (messageStatusTo != null) {
              var approvalStatusFrom: VoucherApprovalStatusEnum = null;
              var enabled: boolean = VoucherHelper.isMessageStatusChangeEnabled(messageStatusFrom, messageStatusTo, approvalStatusFrom);
              this.grid.grid.contextMenuModule.contextMenu.enableItems([id], enabled, true);
            }
          }
        }
      }
    });
    this.grid.grid.contextMenuModule.contextMenu
  }

  // Set message status
  private setMessageStatus(e: UiCommandEvent) {
    var messageDto: MessageDto = this.dtoFromArgs(e);
    var messageStatusTo: VoucherMessageStatusEnum = e.command?.data?.messageStatus;
    if (messageDto && messageStatusTo && messageDto.status != messageStatusTo) {
      var proformaStatusChange: boolean = messageStatusTo == VoucherMessageStatusEnum.Voucher &&
        VoucherMessageViewService.messageStatusHarSupportDetailDialog(messageDto.status, this.voucherDataService.filterChannelType);
      var dialogConfig: any = proformaStatusChange ? this.voucherDetailsDialogGetConfig(messageDto) : null;
      this.voucherDataService.apiSetMessageStatus(messageDto, messageStatusTo, proformaStatusChange, dialogConfig).then(
        result => this.researchUpdate(result)
      );
    }
  }

  // Open details form
  public voucherDetailsOpen(messageDto: MessageDto, calledFromChat: boolean = false) {
    if (messageDto && VoucherMessageViewService.messageStatusHarSupportDetailDialog(messageDto.status, this.voucherDataService.filterChannelType)) {
      var config: any = this.voucherDetailsDialogGetConfig(messageDto, calledFromChat);
      if (messageDto.status == VoucherMessageStatusEnum.Inbox) {
        this.voucherDataService.apiSetMessageStatus(messageDto, VoucherMessageStatusEnum.Voucher, true, config).then(
          result => this.researchUpdate(result)
        );
      }
      else {
        this.voucherDataService.voucherDetailsOpen(messageDto, config).then((result) => {
          this.researchUpdate(result);
        });
      }
    }
    else {
      this.dialog.snackBarError("Åbn besked ikke tilladt");
    }
  }

  public VoucherChannelSync() {
    this.voucherDataService.apiVoucherChannelSync().then(
      msgCount => { if (msgCount > 0) { this.research() } });
  }

  public voucherDetailsDialogAttachmentIdxDefault: number = 0;
  private voucherDetailsDialogGetConfig(messageDto: MessageDto, showChat: boolean = false): any {
    var attachmentsActive: boolean = (this.form.panesService.visible('right') || messageDto.status == VoucherMessageStatusEnum.Inbox || messageDto.status == VoucherMessageStatusEnum.Voucher);
    var calledFromVoucher: boolean = (messageDto.status == VoucherMessageStatusEnum.Voucher);
    var editPosting: boolean = !calledFromVoucher;
    var config: any = this.voucherDataService.voucherDetailsDialogGetConfig(messageDto, attachmentsActive, calledFromVoucher, editPosting, this.voucherDetailsDialogAttachmentIdxDefault, false, showChat);
    return config;
  }

  private static messageStatusHarSupportDetailDialog(messageStatusFrom: VoucherMessageStatusEnum, filterChannelType: VoucherMessageChannelTypeEnum): boolean {
    return (messageStatusFrom &&
      ((messageStatusFrom == VoucherMessageStatusEnum.Inbox && filterChannelType == VoucherMessageChannelTypeEnum.VendorVoucher) ||
        (messageStatusFrom == VoucherMessageStatusEnum.Voucher)));
  }
}
