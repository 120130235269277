import { CerAppFormModelAdapter } from '../../../platform/models/cer-app-form-model-adapter';

export class SalesPaymentTypeFormAdapter extends CerAppFormModelAdapter {

  override init() {

    this.viewMetadata = {
      name: 'salg-betalingstyper', dataApiName: 'SalesPaymentType', text: 'Salg betalingstyper',
      primaryKey: 'num', baseFields: ['id', 'num', 'name', 'history']
    };

    this.fieldMetadata = [
      { name: 'createInvoice', text: 'Dan faktura', format: 'CheckBox', defaultValue: true },
      { name: 'paymentCapture', text: 'Capture betaling', format: 'CheckBox', defaultValue: false }
    ];
   
  }
}
