import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { CerFormComponent } from '../../cer/cer-form/cer-form.component';
import { CerGridComponent } from '../../cer/cer-grid/cer-grid.component';
import { UiCommandEvent, UiCommand } from '../../cer/cer-grid/cer-grid-command.service';
import { FieldEditChangedArgs, FieldGroupMetadata, FieldMetadata, FieldWidthEnum, ViewMetadata } from '../../cer/cer-data/cer-data.service';
import { UserSessionService } from 'src/platform/app-user/user-session-service';

@Component({
  selector: 'contract',
  templateUrl: './contract.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ContractComponent {
  private form: CerFormComponent;
  @ViewChild('form', { static: false }) set formContent(content: CerFormComponent) {
    if (content && !this.form) {
      this.form = content;
    }
  }

  private grid: CerGridComponent;
  @ViewChild('grid', { static: false }) set gridContent(content: CerGridComponent) {
    if (content && !this.grid) {
      this.grid = content;
    }
  }

  public viewMetadata: ViewMetadata = {
    name: 'Kontrakter', dataApiName: 'Contract', text: 'Kontrakter', textSingular: 'Kontrakt',
    primaryKey: 'num', baseFields: ['num', 'name', 'history'], dialogWidth: '100%'
  };

  public fieldGroupMetadata: FieldGroupMetadata[] = [
    { idx: 1, name: 'identification', text: 'Identifikation', fields: ['num', 'name'] },
    { idx: 2, name: 'setup', text: 'Opsætning', fields: ['type', 'currency', 'responsible', 'closed'] },
    { idx: 3, name: 'references', text: 'Referencer', fields: ['conterParty','agentParty'] },
  ];

  public fieldMetadata: FieldMetadata[] =
    [{ name: 'id', text: 'Id', tooltipText: 'Unik nøgle for posten', widthType: FieldWidthEnum.XS, visible: false },
    { name: 'num', text: 'Kontrakt', tooltipText: 'Kontraktnummer', widthType: FieldWidthEnum.XXL, visibleAdd: false, allowEdit: false, filterOperator: 'startswith', orderBy: 'Descending'},
    { name: 'name', text: 'Titel', tooltipText: 'Titel', widthType: FieldWidthEnum.XXL },
    { name: 'type', text: 'Aftaletype', tooltipText: 'Aftalens type', widthType: FieldWidthEnum.S, foreignKeyTableName: 'ContractType', foreignKeyField: 'num', foreignKeyValue: 'name', foreignKeySortFields: ['sorting'], onEditChanged: (args) => { this.editChangedType(args) } },
    { name: 'currency', text: 'Aftalevaluta', tooltipText: 'Aftalens valuta', widthType: FieldWidthEnum.S, foreignKeyTableName: 'Currency', foreignKeyField: 'iso', foreignKeyValue: 'iso', foreignKeyColumns: ['iso','name'], foreignKeyTitles: ['Kode','Navn'] },  
    { name: 'responsible', text: 'Redaktør', tooltipText: 'Redaktørnavn', widthType: FieldWidthEnum.XS, foreignKeyTableName: 'User', foreignKeyField: 'id', foreignKeyValue: 'shortName', required: true },
    { name: 'counterParty', text: 'Modpart', tooltipText: 'Hvem er kontrakten indgået med', foreignKeyTableName: 'Forfatter', foreignKeyField: 'forfatterId', foreignKeyValue: 'navn', widthType: FieldWidthEnum.S },
    { name: 'agentParty', text: 'Agent', tooltipText: 'Hvem er agent på kontrakten', foreignKeyTableName: 'Forfatter', foreignKeyField: 'forfatterId', foreignKeyValue: 'navn', widthType: FieldWidthEnum.S },
    { name: 'legacyId', text: 'Opr. id', format: 'Integer', visible: false},
    { name: 'closed', text: 'Lukket dato', format: 'DateShort', visible: false}
    ];

  public toolbarCommands: UiCommand[] = [];
  public contextMenuCommands: UiCommand[] = [];

  constructor(userSessionService: UserSessionService) {
    userSessionService.user$.subscribe(user => {
      this.fieldMetadata.find(f => f.name == 'responsible').defaultValue = user?.dto?.id;
    });
  }

  protected onCommand($event: UiCommandEvent) {
  }

  private editChangedType(args: FieldEditChangedArgs) {
  }
  
}

