import { Component, ContentChild, Input, Output, TemplateRef, EventEmitter, ViewChild, OnDestroy, NgZone, OnInit } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { SplitterComponent } from '@syncfusion/ej2-angular-layouts';

import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { ICerViewPersistenceComponent, CerMetaUserView, CerFormUserViewType } from './cer-form-persistence.service';
import { CerFormPersistenceService } from './cer-form-persistence.service';
import { CerFormPanesService, CerFormPanesSettings } from './cer-form-panes.service';
import { UiCommandEvent, UiActionTypeEnum } from '../cer-grid/cer-grid-command.service';
import { CerAppRouteService } from 'src/cer-app/cer-app-route/cer-app-route.service';
import { CerFormService } from './cer-form.service';

export type CerFormActiveDisabled = 'active' | 'disabled';
export type CerDataRelationRole = 'parent' | 'child' | 'childAndParent';

export interface IPopupComponent {
}

@Component({
  selector: 'cer-form',
  templateUrl: './cer-form.component.html',
  styleUrls: ['./cer-form.component.css'],
  providers: [CerFormService, CerFormPersistenceService, CerFormPanesService]
})
export class CerFormComponent implements OnInit, OnDestroy, ICerViewPersistenceComponent {
  @Input() routerLink: CerFormActiveDisabled = 'active';
  @Input() persistence: CerFormActiveDisabled = 'active';
  @Input() designedViews: CerMetaUserView[] = [];
  @Input() panesRequired: boolean = false;
  get panes(): CerFormPanesSettings { return this.panesService.panesGet(); };
  @Input() set panes(value: CerFormPanesSettings) { this.panesService.panesInit(value)};
  @ContentChild('mainTemplate') mainTemplate: TemplateRef<any>;
  @ContentChild('leftTemplate') leftTemplate?: TemplateRef<any>;
  @ContentChild('rightTemplate') rightTemplate?: TemplateRef<any>;
  @ContentChild('topTemplate') topTemplate?: TemplateRef<any>;
  @ContentChild('bottomTemplate') bottomTemplate?: TemplateRef<any>;
  @ContentChild('popupTemplate') popupTemplate?: TemplateRef<any>;
  @ContentChild('popupTemplateContext') popupTemplateContext?: any;
  @Output() commmand: EventEmitter<UiCommandEvent> = new EventEmitter<UiCommandEvent>();

  protected isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public popupComponent$: Observable<IPopupComponent>;
  public popupComponentContext$: Observable<any>;

  private onFormCommand(event: UiCommandEvent) {
    //this.cdr.detectChanges();
    this.commmand.emit(event);
  }

  protected paneTopVisible: boolean = false;
  protected paneBottomVisible: boolean = false;
  protected paneLeftVisible: boolean = false;
  protected paneRightVisible: boolean = false;
  protected splitterHorizontalVisible: boolean = false;
  protected splitterVerticalVisible: boolean = false;
  protected splitterVerticalPriority: boolean = false;
  
  protected paneTopCollapsed: boolean = false;
  protected paneBottomCollapsed: boolean = false;
  protected paneLeftCollapsed: boolean = false;
  protected paneRightCollapsed: boolean = false;
  
  protected paneTopCollapsible: boolean = false;
  protected paneBottomCollapsible: boolean = false;
  protected paneLeftCollapsible: boolean = false;
  protected paneRightCollapsible: boolean = false;

  protected paneTopSize: string;
  protected paneMainVerticalSize: string;
  protected paneBottomSize: string;

  protected paneLeftSize: string;
  protected paneMainHorizontalSize: string;
  protected paneRightSize: string;
  
  public onPanesChanged(panes: CerFormPanesSettings): void {
    if (panes != null) {
      this.paneTopVisible = panes.top ? panes.top.visible != 'hide' : false;
      this.paneBottomVisible = panes.bottom ? panes.bottom.visible != 'hide' : false;
      this.paneLeftVisible = panes.left ? panes.left.visible != 'hide' : false;
      this.paneRightVisible = panes.right ? panes.right.visible != 'hide' : false;


      var primaryVertical: boolean = panes.orientationPrimary === 'vertical';
      this.splitterHorizontalVisible = this.paneLeftVisible || this.paneRightVisible;
      this.splitterVerticalVisible = this.paneTopVisible || this.paneBottomVisible;
      this.splitterVerticalPriority = primaryVertical && this.splitterVerticalVisible;
      
      this.paneTopCollapsed = panes.top ? panes.top.visible != 'show' : true;
      this.paneBottomCollapsed = panes.bottom ? panes.bottom.visible != 'show' : true;
      this.paneLeftCollapsed = panes.left ? panes.left.visible != 'show' : true;
      this.paneRightCollapsed = panes.right ? panes.right.visible != 'show' : true;

      this.paneTopCollapsible = panes.top ? panes.top.visibleWhenClose != 'show' : true;
      this.paneBottomCollapsible = panes.bottom ? panes.bottom.visibleWhenClose != 'show' : true;
      this.paneLeftCollapsible = panes.left ? panes.left.visibleWhenClose != 'show' : true;
      this.paneRightCollapsible = panes.right ? panes.right.visibleWhenClose != 'show' : true;

      this.paneTopSize = panes.top ? panes.top.size : '25%';
      var sizeMainV: string = panes.main ? primaryVertical ? panes.main.size : panes.main.sizeSecondary : '';
      this.paneMainVerticalSize = (!sizeMainV || sizeMainV.length === 0 ) ? '75%' : sizeMainV;
      this.paneBottomSize = panes.bottom ? panes.bottom.size : '25%';

      this.paneLeftSize = panes.left ? panes.left.size : '25%';
      var sizeMainH: string = panes.main ? !primaryVertical ?  panes.main.size : panes.main.sizeSecondary : '';
      this.paneMainHorizontalSize = (!sizeMainH || sizeMainH.length === 0 ) ? '75%' : sizeMainH;
      this.paneRightSize = panes.right ? panes.right.size : '25%';
    }
    if (this.panesRequired) {
      this.isReady$.next(true);
    }
  }

  // Horizontal splitter used in panes service
  public hSplitter: SplitterComponent;
  @ViewChild('hSplitter') set setHSplitter(hSplitter: SplitterComponent) {
    if (hSplitter) {
      this.hSplitter = hSplitter;
      this.panesService.hSplitterLink(hSplitter, this.panes);
    }
  }

  // Vertical splitter 
  public vSplitter: SplitterComponent;
  @ViewChild('vSplitter') set setvSplitter(vSplitter: SplitterComponent) {
    if (vSplitter) {
      this.vSplitter = vSplitter;
      this.panesService.vSplitterLink(vSplitter, this.panes);
    }
  }

  private subscriptionManager$: Subscription = new Subscription();

  constructor(
    public formService: CerFormService,
    public panesService: CerFormPanesService,
    private viewportRuler: ViewportRuler,
    private ngZone: NgZone,
    private routeService: CerAppRouteService,
    private persistenceService: CerFormPersistenceService,
    private authorizeService: AuthorizeService) { 

      this.manage(this.formService.formCommand$.subscribe(event => this.onFormCommand(event)));
      this.manage(this.panesService.panesChanged$.subscribe(panes => this.onPanesChanged(panes)));
  }

  ngOnInit(): void {
    this.routerSetup();
    this.viewPortSetup();
    this.persistenceSetup();
    if (!this.panesRequired) {
      this.isReady$.next(true);
    }

  }

  private subscriptionManager: Subscription = new Subscription();
  private manage(s: Subscription) {
    this.subscriptionManager.add(s);
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }
  
  private routerSetup() {
    this.subscriptionManager$.add(
      this.authorizeService.isAuthenticated().subscribe(ok => {
        this.isAuthenticated$.next(ok);
        if (this.routerLink === 'active') {
          this.routeService.isLoading$.next(ok);
        }
      })
    );
  }

  private viewPortSetup() {
    this.subscriptionManager$.add(
      this.viewportRuler.change(200).subscribe(() => this.ngZone.run(() => this.onViewPortChange()))
    );
  }

  private onViewPortChange() {
    this.formService.formDesignChangeNext(UiActionTypeEnum.ViewPort);
  }

  // Persistence
  private persistenceSetup() {
    if (this.persistence === 'active') {
      this.persistenceService.registerComponent(this);
      this.persistenceService.linkRouter();
    }
  }

  public getPersistenceId(): string {
    return 'form';
  }

  public getPersistence(): any {
    var o: any = new Object();
    o['panes'] = this.panesService.getPersistence();
    return o;
  }

  public applyPersistence(viewType: CerFormUserViewType, o: any) {
    this.panesService.applyPersistence(o ? o['panes'] : null);
  }

  public applyPersistenceComplete(viewType: CerFormUserViewType) {
  }

}
